export const BFFAdminSpec = {
  "openapi": "3.0.0",
  "info": {
    "title": "Reward Admin BFF API",
    "description": "API to manage activities including fetching, creating new activities, and retrieving activity metadata with configurations.",
    "version": "1.1.0"
  },
  "paths": {
    "/api/v1/activities": {
      "get": {
        "tags": ["Activities"],
        "summary": "Get list of all activities (with pagination)",
        "description": "Fetch a paginated list of activities along with their details.",
        "parameters": [
          {
            "in": "query",
            "name": "page",
            "schema": {
              "type": "integer",
              "minimum": 1,
              "default": 1
            },
            "description": "The page number to retrieve."
          },
          {
            "in": "query",
            "name": "pageSize",
            "schema": {
              "type": "integer",
              "minimum": 1,
              "default": 10
            },
            "description": "The number of items per page."
          }
        ],
        "responses": {
          "200": {
            "description": "Paginated list of activities.",
            "content": {
              "application/json": {
                "schema": {
                  "type": "object",
                  "properties": {
                    "items": {
                      "type": "array",
                      "items": {
                        "$ref": "#/components/schemas/Activity"
                      },
                      "description": "The list of activities for the current page."
                    },
                    "page": {
                      "type": "integer",
                      "description": "The current page number."
                    },
                    "pageSize": {
                      "type": "integer",
                      "description": "The number of items returned per page."
                    },
                    "totalItems": {
                      "type": "integer",
                      "description": "The total number of items available."
                    },
                    "totalPages": {
                      "type": "integer",
                      "description": "The total number of pages available."
                    }
                  }
                }
              }
            }
          },
          "500": {
            "description": "Internal server error.",
            "content": {
              "application/json": {
                "schema": {
                  "type": "object",
                  "properties": {
                    "error": {
                      "type": "string",
                      "description": "Error message describing the server issue."
                    }
                  }
                }
              }
            }
          }
        }
      },
      "post": {
        "tags": ["Activity"],
        "summary": "Create a new activity",
        "description": "Create a new activity by specifying its name and type. The configuration will be based on the selected activity type.",
        "requestBody": {
          "required": true,
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/NewActivityRequest"
              }
            }
          }
        },
        "responses": {
          "201": {
            "description": "Activity created successfully.",
            "content": {
              "application/json": {
                "schema": {
                  "type": "object",
                  "properties": {
                    "message": {
                      "type": "string",
                      "description": "Success message."
                    }
                  }
                }
              }
            }
          },
          "400": {
            "description": "Invalid input provided.",
            "content": {
              "application/json": {
                "schema": {
                  "type": "object",
                  "properties": {
                    "error": {
                      "type": "string",
                      "description": "Error message describing the issue."
                    }
                  }
                }
              }
            }
          }
        }
      }
    },
    "/api/v1/activity-metadata": {
      "get": {
        "tags": ["Activities"],
        "summary": "Fetch all activity metadata with configurations",
        "description": "Retrieve metadata for all available activity types along with their specific configuration options.",
        "responses": {
          "200": {
            "description": "List of available activity types and their configurations.",
            "content": {
              "application/json": {
                "schema": {
                  "type": "array",
                  "items": {
                    "$ref": "#/components/schemas/ActivityMetadata"
                  }
                }
              }
            }
          },
          "500": {
            "description": "Internal server error.",
            "content": {
              "application/json": {
                "schema": {
                  "type": "object",
                  "properties": {
                    "error": {
                      "type": "string",
                      "description": "Error message describing the server issue."
                    }
                  }
                }
              }
            }
          }
        }
      }
    },
    "/api/v1/segments": {
      "get": {
        "tags": ["Segments"],
        "summary": "Get all available segments",
        "description": "Fetch all available predefined segments such as 'low volume store', 'high volume store', etc.",
        "responses": {
          "200": {
            "description": "List of predefined segments.",
            "content": {
              "application/json": {
                "schema": {
                  "type": "array",
                  "items": {
                    "$ref": "#/components/schemas/Segment"
                  }
                },
                "example": [
                  {
                    "id": 1,
                    "name": "low volume store"
                  },
                  {
                    "id": 2,
                    "name": "high volume store"
                  }
                ]
              }
            }
          },
          "500": {
            "description": "Internal server error.",
            "content": {
              "application/json": {
                "schema": {
                  "type": "object",
                  "properties": {
                    "error": {
                      "type": "string",
                      "description": "Error message describing the server issue."
                    }
                  }
                }
              }
            }
          }
        }
      }
    },
    "/api/v1/order-types": {
      "get": {
        "tags": ["Order Types"],
        "summary": "Get all available order types",
        "description": "Fetch all available order types with their unique identifiers.",
        "responses": {
          "200": {
            "description": "List of order types.",
            "content": {
              "application/json": {
                "schema": {
                  "type": "array",
                  "items": {
                    "$ref": "#/components/schemas/OrderType"
                  }
                },
                "example": [
                  {
                    "id": 1,
                    "name": "Online"
                  },
                  {
                    "id": 2,
                    "name": "In-store"
                  }
                ]
              }
            }
          },
          "500": {
            "description": "Internal server error.",
            "content": {
              "application/json": {
                "schema": {
                  "type": "object",
                  "properties": {
                    "error": {
                      "type": "string",
                      "description": "Error message describing the server issue."
                    }
                  }
                }
              }
            }
          }
        }
      }
    },
    "/api/v1/sales-targets": {
      "get": {
        "tags": ["Sales Targets"],
        "summary": "Get list of all sales targets (with pagination)",
        "description": "Fetch a paginated list of sales targets along with their details.",
        "parameters": [
          {
            "in": "query",
            "name": "page",
            "schema": {
              "type": "integer",
              "minimum": 1,
              "default": 1
            },
            "description": "The page number to retrieve."
          },
          {
            "in": "query",
            "name": "pageSize",
            "schema": {
              "type": "integer",
              "minimum": 1,
              "default": 10
            },
            "description": "The number of items per page."
          }
        ],
        "responses": {
          "200": {
            "description": "Paginated list of sales targets.",
            "content": {
              "application/json": {
                "schema": {
                  "type": "object",
                  "properties": {
                    "items": {
                      "type": "array",
                      "items": {
                        "$ref": "#/components/schemas/SalesTarget"
                      },
                      "description": "The list of sales targets for the current page."
                    },
                    "page": {
                      "type": "integer",
                      "description": "The current page number."
                    },
                    "pageSize": {
                      "type": "integer",
                      "description": "The number of items returned per page."
                    },
                    "totalItems": {
                      "type": "integer",
                      "description": "The total number of items available."
                    },
                    "totalPages": {
                      "type": "integer",
                      "description": "The total number of pages available."
                    }
                  }
                }
              }
            }
          },
          "500": {
            "description": "Internal server error.",
            "content": {
              "application/json": {
                "schema": {
                  "type": "object",
                  "properties": {
                    "error": {
                      "type": "string",
                      "description": "Error message describing the server issue."
                    }
                  }
                }
              }
            }
          }
        }
      },
      "post": {
        "tags": ["Sales Targets"],
        "summary": "Create a new sales target",
        "description": "Create a new sales target by specifying its type and configuration.",
        "requestBody": {
          "required": true,
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/NewSalesTargetRequest"
              }
            }
          }
        },
        "responses": {
          "201": {
            "description": "Sales target created successfully.",
            "content": {
              "application/json": {
                "schema": {
                  "type": "object",
                  "properties": {
                    "message": {
                      "type": "string",
                      "description": "Success message."
                    }
                  }
                }
              }
            }
          },
          "400": {
            "description": "Invalid input provided.",
            "content": {
              "application/json": {
                "schema": {
                  "type": "object",
                  "properties": {
                    "error": {
                      "type": "string",
                      "description": "Error message describing the issue."
                    }
                  }
                }
              }
            }
          }
        }
      }
    },
    "/api/v1/sales-targets/{id}": {
      "get": {
        "tags": ["Sales Targets"],
        "summary": "Get a sales target by ID",
        "description": "Fetch details of a specific sales target using its ID.",
        "parameters": [
          {
            "in": "path",
            "name": "id",
            "required": true,
            "schema": {
              "type": "integer"
            },
            "description": "The ID of the sales target to retrieve."
          }
        ],
        "responses": {
          "200": {
            "description": "Sales target details retrieved successfully.",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/SalesTarget"
                }
              }
            }
          },
          "404": {
            "description": "Sales target not found.",
            "content": {
              "application/json": {
                "schema": {
                  "type": "object",
                  "properties": {
                    "error": {
                      "type": "string",
                      "description": "Error message indicating the sales target was not found."
                    }
                  }
                }
              }
            }
          },
          "500": {
            "description": "Internal server error.",
            "content": {
              "application/json": {
                "schema": {
                  "type": "object",
                  "properties": {
                    "error": {
                      "type": "string",
                      "description": "Error message describing the server issue."
                    }
                  }
                }
              }
            }
          }
        }
      }
    },
    "/api/v1/products": {
      "get": {
        "tags": ["Products"],
        "summary": "Get list of all products (with pagination)",
        "description": "Fetch a paginated list of products along with their details.",
        "parameters": [
          {
            "in": "query",
            "name": "page",
            "schema": {
              "type": "integer",
              "minimum": 1,
              "default": 1
            },
            "description": "The page number to retrieve."
          },
          {
            "in": "query",
            "name": "pageSize",
            "schema": {
              "type": "integer",
              "minimum": 1,
              "default": 10
            },
            "description": "The number of items per page."
          }
        ],
        "responses": {
          "200": {
            "description": "Paginated list of products.",
            "content": {
              "application/json": {
                "schema": {
                  "type": "object",
                  "properties": {
                    "items": {
                      "type": "array",
                      "items": {
                        "$ref": "#/components/schemas/Product"
                      },
                      "description": "The list of products for the current page."
                    },
                    "page": {
                      "type": "integer",
                      "description": "The current page number."
                    },
                    "pageSize": {
                      "type": "integer",
                      "description": "The number of items returned per page."
                    },
                    "totalItems": {
                      "type": "integer",
                      "description": "The total number of items available."
                    },
                    "totalPages": {
                      "type": "integer",
                      "description": "The total number of pages available."
                    }
                  }
                }
              }
            }
          },
          "500": {
            "description": "Internal server error.",
            "content": {
              "application/json": {
                "schema": {
                  "type": "object",
                  "properties": {
                    "error": {
                      "type": "string",
                      "description": "Error message describing the server issue."
                    }
                  }
                }
              }
            }
          }
        }
      }
    },
    "/api/v1/stores": {
      "get": {
        "tags": ["Stores"],
        "summary": "Get list of all stores (with pagination)",
        "description": "Fetch a paginated list of stores along with their details.",
        "parameters": [
          {
            "in": "query",
            "name": "page",
            "schema": {
              "type": "integer",
              "minimum": 1,
              "default": 1
            },
            "description": "The page number to retrieve."
          },
          {
            "in": "query",
            "name": "pageSize",
            "schema": {
              "type": "integer",
              "minimum": 1,
              "default": 10
            },
            "description": "The number of items per page."
          }
        ],
        "responses": {
          "200": {
            "description": "Paginated list of stores.",
            "content": {
              "application/json": {
                "schema": {
                  "type": "object",
                  "properties": {
                    "items": {
                      "type": "array",
                      "items": {
                        "$ref": "#/components/schemas/Store"
                      },
                      "description": "The list of stores for the current page."
                    },
                    "page": {
                      "type": "integer",
                      "description": "The current page number."
                    },
                    "pageSize": {
                      "type": "integer",
                      "description": "The number of items returned per page."
                    },
                    "totalItems": {
                      "type": "integer",
                      "description": "The total number of items available."
                    },
                    "totalPages": {
                      "type": "integer",
                      "description": "The total number of pages available."
                    }
                  }
                }
              }
            }
          },
          "500": {
            "description": "Internal server error.",
            "content": {
              "application/json": {
                "schema": {
                  "type": "object",
                  "properties": {
                    "error": {
                      "type": "string",
                      "description": "Error message describing the server issue."
                    }
                  }
                }
              }
            }
          }
        }
      }
    },
    "/api/v1/reports": {
      "post": {
        "tags": ["Reports"],
        "summary": "Initiates the report generation process with flexible parameters",
        "description": "This endpoint creates a job to generate a report (e.g., stores, point-multipliers, sales-targets, activities) for specific or all stores provided in the payload.",
        "requestBody": {
          "required": true,
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/ReportRequest"
              },
              "examples": {
                "all_stores": {
                  "summary": "Generate report for all stores",
                  "value": {
                    "reportType": "stores",
                    "filters": {
                      "storeIds": []
                    }
                  }
                },
                "specific_stores": {
                  "summary": "Generate report for specific stores",
                  "value": {
                    "reportType": "stores",
                    "filters": {
                      "storeIds": [12345, 67890]
                    }
                  }
                },
                "all_activities": {
                  "summary": "Generate report for all activities",
                  "value": {
                    "reportType": "activities",
                    "filters": {
                      "activityIds": []
                    }
                  }
                },
                "specific_activities": {
                  "summary": "Generate report for specific activities",
                  "value": {
                    "reportType": "activities",
                    "filters": {
                      "activityIds": [12345, 67890]
                    }
                  }
                },
              }
            }
          }
        },
        "responses": {
          "202": {
            "description": "Report generation accepted. A job ID is returned.",
            "content": {
              "application/json": {
                "schema": {
                  "type": "object",
                  "properties": {
                    "jobId": {
                      "type": "string",
                      "description": "Unique identifier for the background job handling report generation."
                    },
                    "message": {
                      "type": "string",
                      "description": "Confirmation message."
                    }
                  }
                }
              }
            }
          },
          "400": {
            "description": "Invalid input for report generation.",
            "content": {
              "application/json": {
                "schema": {
                  "type": "object",
                  "properties": {
                    "error": {
                      "type": "string",
                      "description": "Error message indicating invalid payload fields."
                    }
                  }
                }
              }
            }
          },
          "500": {
            "description": "Internal server error.",
            "content": {
              "application/json": {
                "schema": {
                  "type": "object",
                  "properties": {
                    "error": {
                      "type": "string",
                      "description": "Error message describing the server issue."
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  },
  "components": {
    "schemas": {
      "Activity": {
        "type": "object",
        "properties": {
          "id": {
            "type": "integer",
            "description": "Unique identifier for the activity."
          },
          "activityTypeName": {
            "type": "string",
            "description": "Name of the activity type"
          },
          "name": {
            "type": "string",
            "description": "Name of the activity - input by an admin."
          },
          "numberOfCustomers": {
            "type": "integer",
            "description": "Number of customers who participated in the activity."
          },
          "totalAwardedReward": {
            "type": "number",
            "description": "Total rewards awarded in the activity."
          },
          "totalCustomersCompleted": {
            "type": "integer",
            "description": "Number of customers who completed the activity."
          },
          "percentageCompletion": {
            "type": "number",
            "description": "Percentage of customers who completed the activity."
          },
          "updatedBy": {
            "type": "string",
            "description": "The user who last updated the activity."
          },
          "lastUpdatedOn": {
            "type": "string",
            "format": "date-time",
            "description": "The date and time when the activity was last updated."
          },
          "status": {
            "type": "string",
            "description": "Current status of the activity."
          }
        }
      },
      "NewActivityRequest": {
        "type": "object",
        "properties": {
          "name": {
            "type": "string",
            "description": "Name of the activity."
          },
          "type": {
            "type": "string",
            "enum": ["login", "welcome", "order_delivery"],
            "description": "Type of the activity."
          },
          "activityConfiguration": {
            "$ref": "#/components/schemas/ActivityConfiguration",
            "description": "Configuration details based on the selected activity type."
          }
        },
        "required": ["name", "type", "activityConfiguration"]
      },
      "ActivityMetadata": {
        "type": "object",
        "properties": {
          "id": {
            "type": "number",
            "description": "ID of the type of activity"
          },
          "type": {
            "type": "string",
            "description": "Type of the activity.",
            "enum": ["login", "welcome", "order_delivery"],
            "example": "login"
          },
          "configuration": {
            "$ref": "#/components/schemas/ActivityConfiguration",
            "description": "Specific configuration details for the activity type."
          }
        }
      },
      "ActivityConfiguration": {
        "type": "object",
        "properties": {
          "loginConfiguration": {
            "type": "object",
            "properties": {
              "pointsEarned": {
                "type": "number",
                "description": "Points earned for completing the login activity."
              },
              "dailyLoginLimit": {
                "type": "integer",
                "description": "Maximum number of login bonuses per day."
              },
              "allTimeLoginLimit": {
                "type": "integer",
                "description": "Maximum number of login bonuses for the entire duration."
              },
              "activityStartTime": {
                "type": "string",
                "format": "date-time",
                "description": "Start time of the login activity."
              },
              "activityEndTime": {
                "type": "string",
                "format": "date-time",
                "description": "End time of the login activity."
              }
            }
          },
          "welcomeConfiguration": {
            "type": "object",
            "properties": {
              "pointsEarned": {
                "type": "number",
                "description": "Points earned for completing the welcome activity."
              },
              "activityStartTime": {
                "type": "string",
                "format": "date-time",
                "description": "Start time of the welcome activity."
              },
              "activityEndTime": {
                "type": "string",
                "format": "date-time",
                "description": "End time of the welcome activity."
              }
            }
          },
          "orderDeliveryConfiguration": {
            "type": "object",
            "properties": {
              "orderType": {
                "type": "number",
                "description": "The id's of the order types (e.g., online, in-store)."
              },
              "segments": {
                "type": "array",
                "items": {
                  "type": "number"
                },
                "description": "The id's of the segments that the activity is available for (e.g., 'premium', 'standard')."
              },
              "localCurrency": {
                "type": "string",
                "description": "The local currency for the order."
              },
              "pointsEarned": {
                "type": "number",
                "description": "Points awarded for the order."
              },
              "activityStartTime": {
                "type": "string",
                "format": "date-time",
                "description": "Start time of the order delivery activity."
              },
              "activityEndTime": {
                "type": "string",
                "format": "date-time",
                "description": "End time of the order delivery activity."
              }
            }
          }
        }
      },
      "Segment": {
        "type": "object",
        "properties": {
          "id": {
            "type": "number",
            "description": "Unique identifier for the segment."
          },
          "name": {
            "type": "string",
            "description": "The name of the segment."
          }
        }
      },
      "OrderType": {
        "type": "object",
        "properties": {
          "id": {
            "type": "number",
            "description": "Unique identifier for the order type."
          },
          "name": {
            "type": "string",
            "description": "The name of the order type."
          }
        }
      },
      "SalesTarget": {
        "type": "object",
        "properties": {
          "id": {
            "type": "integer",
            "description": "Unique identifier for the sales target."
          },
          "name": {
            "type": "string",
            "description": "Name of the sales target."
          },
          "numEligibleStores": {
            "type": "integer",
            "description": "Number of stores eligible for the sales target."
          },
          "percentageAchievedTarget": {
            "type": "number",
            "format": "float",
            "description": "Percentage of the target achieved."
          },
          "pointsAwarded": {
            "type": "number",
            "description": "Total points awarded for the sales target."
          },
          "startDate": {
            "type": "string",
            "format": "date",
            "description": "Start date of the sales target."
          },
          "endDate": {
            "type": "string",
            "format": "date",
            "description": "End date of the sales target."
          },
          "status": {
            "type": "string",
            "enum": ["Draft", "Error", "Expired", "Published"],
            "description": "Current status of the sales target."
          },
          "report": {
            "type": "string",
            "description": "Link to or summary of the report for the sales target."
          }
        }
      },
      "NewSalesTargetRequest": {
        "type": "object",
        "properties": {
          "targetType": {
            "type": "string",
            "enum": ["main", "layered", "prerequisite"],
            "description": "Type of the sales target."
          },
          "targetName": {
            "type": "string",
            "description": "Name of the sales target."
          },
          "configuration": {
            "type": "object",
            "oneOf": [
              { "$ref": "#/components/schemas/MainTargetConfiguration" },
              { "$ref": "#/components/schemas/LayeredTargetConfiguration" }
            ]
          }
        },
        "required": ["targetType", "targetName", "configuration"]
      },
      "MainTargetConfiguration": {
        "type": "object",
        "properties": {
          "defaultSpendGoal": {
            "type": "number",
            "description": "Default spend goal for the target."
          },
          "defaultPointsToAward": {
            "type": "number",
            "description": "Default points to award for the target."
          },
          "listOfStores": {
            "type": "array",
            "items": {
              "type": "integer"
            },
            "description": "List of stores eligible for the target."
          },
          "storeSegment": {
            "type": "integer",
            "description": "Segment of the store."
          },
          "prerequisiteTarget": {
            "type": "integer",
            "description": "Prerequisite target name."
          },
          "userNeedsToJoin": {
            "type": "boolean",
            "description": "Whether users need to join the target explicitly."
          },
          "excludeTaxFromGoal": {
            "type": "boolean",
            "description": "Whether to exclude tax from the goal."
          },
          "includeOfflineHours": {
            "type": "boolean",
            "description": "Whether to include offline hours in the target."
          },
          "roundUpPoints": {
            "type": "boolean",
            "description": "Whether to round up points."
          },
          "roundUpPointsValue": {
            "type": "boolean",
            "description": "Whether to round up points value."
          },
          "startDate": {
            "type": "string",
            "format": "date-time",
            "description": "Start date of the target."
          },
          "endDate": {
            "type": "string",
            "format": "date-time",
            "description": "End date of the target."
          },
          "targetDeliveryDate": {
            "type": "string",
            "format": "date-time",
            "description": "Delivery date of the target."
          },
          "pointsDeliveryDate": {
            "type": "string",
            "format": "date-time",
            "description": "Points delivery date of the target."
          },
          "includedProducts": {
            "type": "array",
            "items": {
              "type": "integer"
            },
            "description": "List of products to count towards the progress of the target."
          },
          "excludedProducts": {
            "type": "array",
            "items": {
              "type": "integer"
            },
            "description": "List of products to exclude from the progress of the target."
          }
        },
        "required": ["defaultSpendGoal", "defaultPointsToAward", "startDate", "endDate"]
      },
      "LayeredTargetConfiguration": {
        "allOf": [
          { "$ref": "#/components/schemas/MainTargetConfiguration" },
          {
            "type": "object",
            "properties": {
              "levels": {
                "type": "array",
                "items": {
                  "$ref": "#/components/schemas/Level"
                },
                "description": "Levels associated with the layered target."
              }
            }
          }
        ]
      },
      "Level": {
        "type": "object",
        "properties": {
          "levelName": {
            "type": "string",
            "description": "Name of the level."
          },
          "milestoneOne": {
            "type": "number",
            "description": "First milestone value."
          },
          "milestoneTwo": {
            "type": "number",
            "description": "Second milestone value."
          },
          "milestoneThree": {
            "type": "number",
            "description": "Third milestone value."
          }
        },
        "required": ["levelName", "milestoneOne", "milestoneTwo", "milestoneThree"]
      },
      "Product": {
        "type": "object",
        "properties": {
          "productId": {
            "type": "integer",
            "description": "Unique identifier of the product."
          },
          "name": {
            "type": "string",
            "description": "Name of the product."
          },
          "status": {
            "type": "string",
            "enum": ["active", "inactive", "discontinued"],
            "description": "Current status of the product."
          },
          "size": {
            "type": "string",
            "description": "Size or volume of the product."
          },
          "category": {
            "type": "string",
            "description": "Product category or classification."
          },
          "brand": {
            "type": "string",
            "description": "Brand name of the product."
          },
          "include": {
            "type": "boolean",
            "description": "Flag indicating if the product should be included in promotions.",
            "default": true
          },
          "exclude": {
            "type": "boolean",
            "description": "Flag indicating if the product should be excluded from promotions.",
            "default": false
          }
        },
        "required": ["productId", "name", "status", "category", "brand"]
      },
      "Store": {
        "type": "object",
        "properties": {
          "storeId": {
            "type": "integer",
            "description": "Unique identifier for the store."
          },
          "storeName": {
            "type": "string",
            "description": "Name of the store."
          },
          "erpId": {
            "type": "string",
            "description": "ERP identifier for the store."
          },
          "phoneNumber": {
            "type": "string",
            "description": "Contact phone number for the store."
          },
          "email": {
            "type": "string",
            "description": "Contact email address for the store."
          },
          "currentPoints": {
            "type": "number",
            "description": "Current points balance for the store."
          }
        },
        "required": ["storeId", "storeName", "erpId"]
      }
    }
  }
}