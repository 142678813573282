import './App.css';
import React from 'react';
import {BrowserRouter} from 'react-router-dom';

import {Switch, Route, useHistory} from 'react-router';
import {OktaAuth} from '@okta/okta-auth-js';
import {Security, LoginCallback, SecureRoute} from '@okta/okta-react';

// Pages
import Swagger from './Components/Swagger';
import SwaggerPublic from './Components/SwaggerPublic';
import Login from './Components/Login';
import SwaggerB2B from './Components/SwaggerB2B';
import SwaggerB2C from './Components/SwaggerB2C';
import SwaggerUhokta from './Components/SwaggerUhokta';
import SwaggerLoyality from './Components/SwaggerLoyality';
import SwaggerHR from './Components/SwaggerHR';
import SwaggerKSA from './Components/SwaggerKSA';
import SwaggerB2BForApex from './Components/SwaggerB2BForApex';
import SwaggerBDR from './Components/SwaggerBDR';
import SwaggerB2bRewardsSpec from './Components/SwaggerB2BRewards';
import SwaggerBFFAdmin from './Components/SwaggerBFFAdmin';
function App() {
  // Check if app is indevelopment
  const isDevelopment = process.env.NODE_ENV === 'development';
  const skipAuth = isDevelopment && process.env.APP_SKIP_AUTH === 'true';


  const history = useHistory();

  const onAuthRequired = () => {
      history.push('/swagger');
  };

  const oktaAuth = new OktaAuth({
    issuer: "https://secure.pepsico.com/oauth2/aus1ljxpv3z9G8NGl0h8",
    clientId: "0oa1r5agv1tglOkoS0h8",
    redirectUri: "https://swagger.cep.pepsico.com/pepsico/authenticate",
    pkce: true,
    onAuthRequired: onAuthRequired,
  });

  const restoreOriginalUri = async () => {
    window.location.href = window.location.origin + '/swagger';
  };

  const dynamicRoute = ({ ...args }) => {
    return skipAuth ? (
      <Route exact {...args} />
    ) : (
      <SecureRoute exact {...args} />
    );
  };

  return (
    <BrowserRouter>
      <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri} >
        <Switch>
          <Route exact path='/' component={Login} />
          {dynamicRoute({ path: '/swagger', component: Swagger })}
          {dynamicRoute({ path: '/swagger/uhokta', component: SwaggerUhokta })}
          {dynamicRoute({ path: '/swagger/B2B', component: SwaggerB2B })}
          {dynamicRoute({ path: '/swagger/BFF-Admin', component: SwaggerBFFAdmin })}
          {dynamicRoute({ path: '/swagger/B2C', component: SwaggerB2C })}
          {dynamicRoute({ path: '/swagger/loyalty', component: SwaggerLoyality })}
          {dynamicRoute({ path: '/swagger/KSA', component: SwaggerKSA })}
          {dynamicRoute({ path: '/swagger/HR', component: SwaggerHR })}
          {dynamicRoute({ path: '/swagger/BDR', component: SwaggerBDR })}
          {dynamicRoute({ path: '/swagger/B2B-Rewards', component: SwaggerB2bRewardsSpec })}
          {dynamicRoute({ path: '/SwaggerB2B.json' })}
          {dynamicRoute({ path: '/swagger/b2b/apex', component: SwaggerB2BForApex })}
          {dynamicRoute({ path: '/swagger/public', component: SwaggerPublic })}
          {dynamicRoute({ path: '/swagger/public/b2b/apex', component: SwaggerB2BForApex })}
          <Route exact path='/pepsico/authenticate' component={LoginCallback} />
        </Switch>
      </Security>
    </BrowserRouter>
  );
}

export default App;
