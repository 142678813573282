import outputAllServers from "../util/outputAllServers";
import {servers} from "./servers";

export const SwaggerB2BSpec = {
    openapi: "3.0.0",
    info: {
        title: "PepsiCo CEP B2B API",
        description: "PepsiCo Consumer Engagement Platform B2B API",
        version: "2.0.3",
    },
    servers: outputAllServers(servers),
    paths: {
        "/api/v2/order/credit": {
            get: {
                operationId: "verifyStoreGet",
                tags: ["Order"],
                summary: "Retrieve order credit details",
                description: "Get available credit limit",
                parameters: [
                    {
                        name: "storeId",
                        in: "header",
                        required: true,
                        description: "ERP Customer ID",
                        schema: {
                            type: "string",
                        },
                    },
                    {
                        name: "x-api-key",
                        in: "header",
                        required: true,
                        description: "x-api-key",
                        schema: {
                            type: "string",
                            default: "00Ynu7Osnis3pcorDPsI2T7lK_Cs32gkP2wQU4K46X",
                        },
                    },
                    {
                        name: "siteId",
                        in: "header",
                        required: true,
                        description: "Market Identification",
                        schema: {
                            type: "string",
                            default: "21",
                        },
                    },
                ],
                responses: {
                    200: {
                        description: "OK",
                        content: {
                            "application/json": {
                                example: {
                                    success: true,
                                    data: {
                                        credit: {
                                            limit: 10000,
                                            available: 5000,
                                            used: 5000,
                                            block: "N"
                                        },
                                    },
                                },
                            },
                        },
                        400: {
                            description: "Bad Request",
                            content: {
                                "application/json": {
                                    examples: {
                                        "Invalid Store ID": {
                                            value: {
                                                success: false,
                                                data: {
                                                    message: "Invalid Store ID provided.",
                                                    code: "1",
                                                },
                                            },
                                        },
                                        "Store Not Found": {
                                            value: {
                                                success: false,
                                                data: {
                                                    message: "Store not found.",
                                                    code: "2",
                                                },
                                            },
                                        },
                                    },
                                },
                            },
                        },
                        401: {
                            description: "Unauthorized.",
                            content: {
                                "application/json": {
                                    example: {
                                        success: false,
                                        msg: "Unauthorized."
                                    }
                                }
                            }
                        },
                    },
                },
            },
        },
        "/api/v1/auth/verifyStore": {
            get: {
                operationId: "verifyStoreGet",
                tags: ["Authentication"],
                summary: "Verify Store",
                description: "Verify if a store is eligible for registration",
                parameters: [
                    {
                        name: "storeId",
                        in: "query",
                        required: true,
                        description: "ERP Customer ID",
                        schema: {
                            type: "string",
                        },
                    },
                    {
                        name: "x-api-key",
                        in: "header",
                        required: true,
                        description: "x-api-key",
                        schema: {
                            type: "string",
                            default: "00Ynu7Osnis3pcorDPsI2T7lK_Cs32gkP2wQU4K46X",
                        },
                    },
                    {
                        name: "siteId",
                        in: "header",
                        required: true,
                        description: "Market Identification",
                        schema: {
                            type: "string",
                            default: "21",
                        },
                    },
                ],
                responses: {
                    200: {
                        description: "OK",
                        content: {
                            "application/json": {
                                example: {
                                    success: true,
                                    data: {
                                        storeId: "0103424244",
                                        storeName: "ABTS EL TRIUNFO DE MARY",
                                        type: "",
                                        city: "LA MAGDALENA CONTRERAS",
                                        state: "CX",
                                        country: "MX",
                                        postalCode: "10360",
                                        addresses: {
                                            addressLine1: "CALLE LAS FLORES MZ. 13, LT. 6",
                                            addressLine2: " ",
                                            city: "LA MAGDALENA CONTRERAS",
                                            state: "CX",
                                            country: "MX",
                                        },
                                    },
                                },
                            },
                        },
                    },
                    400: {
                        description: "Bad Request",
                        content: {
                            "application/json": {
                                examples: {
                                    "Customer Already Registered": {
                                        value: {
                                            success: false,
                                            data: {
                                                message: "Customer is already registered.",
                                                code: "1",
                                            },
                                        },
                                    },
                                    "Store Not Found": {
                                        value: {
                                            success: false,
                                            data: {
                                                message: "Store not found.",
                                                code: "2",
                                            },
                                        },
                                    },
                                },
                            },
                        },
                    },
                },
            },
        },
        "/api/v1/auth/validateProfile": {
            post: {
                operationId: "validateProfilePost",
                tags: ["Authentication"],
                summary: "Validate Profile",
                description: "Validate Profile",
                requestBody: {
                    content: {
                        "application/json": {
                            schema: {
                                $ref: "#/components/schemas/ValidateProfileRequestDto",
                            },
                            example: {
                                userIdType: "Email || Phone || SSO || CustomerID",
                                userId: "xyz@gmail.com",
                                email: "email@business.com (Only used for type CustomerID)",
                                erpID: "xyz (Optional in case for Update)",
                                taxID: "xyz (Optional in case of Poland)",
                                registrationType: "New || Update (Default is New) || SSO",
                                phoneNumber: "1234567890",
                            },
                        },
                    },
                },
                parameters: [
                    {
                        $ref: "#/components/parameters/siteId",
                    },
                    {
                        $ref: "#/components/parameters/xApiKey",
                    },
                ],
                responses: {
                    200: {
                        description: "OK",
                        content: {
                            "application/json": {
                                schema: {
                                    $ref: "#/components/schemas/BasicResponseDto",
                                },
                                example: {
                                    success: true,
                                    msg: "Verification code sent on xyz@gmail.com.",
                                },
                            },
                        },
                    },
                    400: {
                        description: "Bad Request",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/ErrorMessage",
                                },
                            },
                        },
                    },
                    415: {
                        description: "Unsupported Media Type",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/ErrorMessage",
                                },
                            },
                        },
                    },
                    500: {
                        description: "Internal Server Error",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/Problem",
                                },
                            },
                        },
                    },
                },
            },
        },
        "/api/v1/auth/verifyCode": {
            post: {
                operationId: "verifyCodePost",
                tags: ["Authentication"],
                summary: "Verify Code",
                description: "Verify Code",
                requestBody: {
                    content: {
                        "application/json": {
                            schema: {
                                $ref: "#/components/schemas/VerifyCodeRequestDto",
                            },
                            example: {
                                userId: "xyz@gmail.com",
                                code: "123456",
                                registrationType: "New || Update (Default is New) || SSO",
                            },
                        },
                    },
                },
                parameters: [
                    {
                        $ref: "#/components/parameters/siteId",
                    },
                    {
                        $ref: "#/components/parameters/xApiKey",
                    },
                ],
                responses: {
                    200: {
                        description: "OK",
                        content: {
                            "application/json": {
                                schema: {
                                    $ref: "#/components/schemas/BasicResponseDto",
                                },
                                example: {
                                    success: true,
                                    msg: "Successfully verify the code.",
                                },
                            },
                        },
                    },
                    400: {
                        description: "Bad Request",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/ErrorMessage",
                                },
                            },
                        },
                    },
                    415: {
                        description: "Unsupported Media Type",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/ErrorMessage",
                                },
                            },
                        },
                    },
                    500: {
                        description: "Internal Server Error",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/Problem",
                                },
                            },
                        },
                    },
                },
            },
        },
        "/api/v1/auth": {
            post: {
                operationId: "registerProfilePost",
                tags: ["Authentication"],
                summary: "Registration Profile",
                description: "Registration Profile",
                requestBody: {
                    content: {
                        "application/json": {
                            schema: {
                                $ref: "#/components/schemas/ProfileRegistrationRequestDto",
                            },
                            example: {
                                profile: {
                                    userId: "xyz@gmail.com",
                                    registrationType: "New || Update (Default is New) || SSO",
                                    firstName: "Test",
                                    lastName: "Test",
                                    secondLastName: "string",
                                    email: "xyz@gmail.com",
                                    mobilePhone: "9999999999",
                                    role: "MGR",
                                    dob: "06/24/1997",
                                    language: "en",
                                },
                                credentials: {
                                    password: {
                                        value: "Test@123",
                                    },
                                },
                                preferences: {
                                    allowEmailNotifications: true,
                                    allowNewsletterNotifications: true,
                                },
                                termsAccepted: [
                                    {
                                        contentId: "terms-and-conditions",
                                        version_number: "1",
                                        deviceSerial: "123456789",
                                        ipAddress: "192.168.0.1",
                                        date: "2022-11-22",
                                        time: "12:15",
                                    },
                                    {
                                        contentId: "privacy-policy",
                                        version_number: "2",
                                        deviceSerial: "23456789",
                                        ipAddress: "192.168.0.1",
                                        dateTime: "2022-11-13",
                                        date: "2022-11-13",
                                        time: "11:15",
                                    },
                                ],
                                consent: {
                                    pushNotification: false,
                                    emailNotification: false,
                                    smsNotification: false,
                                    callNotification: false,
                                },
                            },
                        },
                    },
                },
                parameters: [
                    {
                        $ref: "#/components/parameters/siteId",
                    },
                    {
                        $ref: "#/components/parameters/xApiKey",
                    },
                    {
                        $ref: "#/components/parameters/oktaAccessToken",
                    },
                ],
                responses: {
                    200: {
                        description: "OK",
                        content: {
                            "application/json": {
                                schema: {
                                    $ref: "#/components/schemas/BasicResponseDto",
                                },
                                example: {
                                    success: true,
                                    msg: "User registered Successfully.",
                                },
                            },
                        },
                    },
                    400: {
                        description: "Bad Request",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/ErrorMessage",
                                },
                            },
                        },
                    },
                    415: {
                        description: "Unsupported Media Type",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/ErrorMessage",
                                },
                            },
                        },
                    },
                    500: {
                        description: "Internal Server Error",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/Problem",
                                },
                            },
                        },
                    },
                },
            },
            delete: {
                operationId: "deleteProfileDelete",
                tags: ["Authentication"],
                summary: "Delete Profile",
                description: "Delete Profile",
                requestBody: {
                    content: {
                        "application/json": {
                            schema: {
                                $ref: "#/components/schemas/ProfileDeletionRequestDto",
                            },
                            example: {
                                userId: "xyz@gmail.com",
                            },
                        },
                    },
                },
                parameters: [
                    {
                        $ref: "#/components/parameters/siteId",
                    },
                    {
                        $ref: "#/components/parameters/xApiKey",
                    },
                ],
                responses: {
                    200: {
                        description: "OK",
                        content: {
                            "application/json": {
                                schema: {
                                    $ref: "#/components/schemas/BasicResponseDto",
                                },
                                example: {
                                    success: true,
                                    msg: "Okta Profile Account is suspended Successfully.",
                                },
                            },
                        },
                    },
                    400: {
                        description: "Bad Request",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/ErrorMessage",
                                },
                            },
                        },
                    },
                    415: {
                        description: "Unsupported Media Type",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/ErrorMessage",
                                },
                            },
                        },
                    },
                    500: {
                        description: "Internal Server Error",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/Problem",
                                },
                            },
                        },
                    },
                },
            },
        },
        "/api/v1/auth/forgotpwd": {
            post: {
                operationId: "sendForgotPasswordRequestPost",
                tags: ["Authentication"],
                summary: "Forgot Password",
                description: "Forgot Password",
                requestBody: {
                    content: {
                        "application/json": {
                            schema: {
                                $ref: "#/components/schemas/ForgotPasswordRequestDto",
                            },
                            example: {
                                userIdType: "Email",
                                username: "xyz@gmail.com",
                            },
                        },
                    },
                },
                parameters: [
                    {
                        $ref: "#/components/parameters/siteId",
                    },
                    {
                        $ref: "#/components/parameters/xApiKey",
                    },
                ],
                responses: {
                    200: {
                        description: "OK",
                        content: {
                            "application/json": {
                                schema: {
                                    $ref: "#/components/schemas/ForgotPasswordResponseDto",
                                },
                                example: {
                                    expiresAt: "2022-03-28T10:12:53.000Z",
                                    status: "RECOVERY",
                                    recoveryToken: "dfpsWNOrN_6rHDTdtAvz",
                                    recoveryType: "PASSWORD",
                                    _embedded: {
                                        user: {
                                            id: "00u16496v54XTeH6R0h8",
                                            passwordChanged: "2022-03-24T11:26:55.000Z",
                                            profile: {
                                                login: "jeevraj.shekhawat.contractor@pepsico.com",
                                                firstName: "Jeev",
                                                lastName: "Shek",
                                                locale: "en_US",
                                                timeZone: "America/Los_Angeles",
                                            },
                                        },
                                    },
                                    _links: {
                                        next: {
                                            name: "recovery",
                                            href: "https://pepsico.oktapreview.com/api/v1/authn/recovery/token",
                                            hints: {
                                                allow: ["POST"],
                                            },
                                        },
                                        cancel: {
                                            href: "https://pepsico.oktapreview.com/api/v1/authn/cancel",
                                            hints: {
                                                allow: ["POST"],
                                            },
                                        },
                                    },
                                },
                            },
                        },
                    },
                    400: {
                        description: "Bad Request",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/ErrorMessage",
                                },
                            },
                        },
                    },
                    415: {
                        description: "Unsupported Media Type",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/ErrorMessage",
                                },
                            },
                        },
                    },
                    500: {
                        description: "Internal Server Error",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/Problem",
                                },
                            },
                        },
                    },
                },
            },
        },
        "/api/v1/auth/forgotpwd/verifyRecovery": {
            post: {
                operationId: "verifyRecoveryOTPPost",
                tags: ["Authentication"],
                summary: "Verify Recovery OTP",
                description: "Verify Recovery OTP",
                requestBody: {
                    content: {
                        "application/json": {
                            schema: {
                                $ref: "#/components/schemas/VerifyRecoveryRequestDto",
                            },
                            example: {
                                username: "jeevrajshekhawat161@gmail.com",
                                recoveryToken: "dfp5kdJjOMloIlP1u-kg",
                                code: 449937,
                            },
                        },
                    },
                },
                parameters: [
                    {
                        $ref: "#/components/parameters/siteId",
                    },
                    {
                        $ref: "#/components/parameters/xApiKey",
                    },
                ],
                responses: {
                    200: {
                        description: "OK",
                        content: {
                            "application/json": {
                                schema: {
                                    $ref: "#/components/schemas/VerifyRecoveryResponseDto",
                                },
                                example: {
                                    stateToken: "00hCItuPVd6X8Y5EYXm7GZvCSB_JnT5ze1wGYvJRRJ",
                                    expiresAt: "2022-05-27T14:09:25.000Z",
                                    status: "PASSWORD_RESET",
                                    recoveryType: "PASSWORD",
                                    _embedded: {
                                        user: {
                                            id: "00u18bwxvxoXS0kCy0h8",
                                            passwordChanged: "2022-05-27T09:15:02.000Z",
                                            profile: {
                                                login: "cep07-bigbazaar34@gmail.com",
                                                firstName: "big12",
                                                lastName: "bz34",
                                                locale: "en_US",
                                                timeZone: "America/Los_Angeles",
                                            },
                                        },
                                        policy: {
                                            complexity: {
                                                minLength: 8,
                                                minLowerCase: 1,
                                                minUpperCase: 1,
                                                minNumber: 1,
                                                minSymbol: 0,
                                                excludeUsername: true,
                                            },
                                            age: {
                                                minAgeMinutes: 0,
                                                historyCount: 4,
                                            },
                                        },
                                    },
                                    _links: {
                                        next: {
                                            name: "resetPassword",
                                            href: "https://pepsico.oktapreview.com/api/v1/authn/credentials/reset_password",
                                            hints: {
                                                allow: ["POST"],
                                            },
                                        },
                                        cancel: {
                                            href: "https://pepsico.oktapreview.com/api/v1/authn/cancel",
                                            hints: {
                                                allow: ["POST"],
                                            },
                                        },
                                    },
                                },
                            },
                        },
                    },
                    400: {
                        description: "Bad Request",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/ErrorMessage",
                                },
                            },
                        },
                    },
                    415: {
                        description: "Unsupported Media Type",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/ErrorMessage",
                                },
                            },
                        },
                    },
                    500: {
                        description: "Internal Server Error",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/Problem",
                                },
                            },
                        },
                    },
                },
            },
        },
        "/api/v1/auth/credentials/reset_password": {
            post: {
                operationId: "resetPasswordPost",
                tags: ["Authentication"],
                summary: "Reset Password",
                description: "Reset Password",
                requestBody: {
                    content: {
                        "application/json": {
                            schema: {
                                $ref: "#/components/schemas/ResetPasswordRequestDto",
                            },
                            example: {
                                stateToken: "00SRNCsdoPc1hnLHbtqEIIJUUrviQSKxFkY7FBuGWe",
                                newPassword: "test@132",
                            },
                        },
                    },
                },
                parameters: [
                    {
                        $ref: "#/components/parameters/siteId",
                    },
                    {
                        $ref: "#/components/parameters/xApiKey",
                    },
                ],
                responses: {
                    200: {
                        description: "OK",
                        content: {
                            "application/json": {
                                schema: {
                                    $ref: "#/components/schemas/ResetPasswordResponseDto",
                                },
                                example: {
                                    expiresAt: "2022-03-28T09:23:19.000Z",
                                    status: "SUCCESS",
                                    sessionToken:
                                        "20111b7CkvXC8Gxvt9dC24QoP26_srmo3CFUrsIKoDGaUvy4HUMzD7d",
                                    _embedded: {
                                        user: {
                                            id: "00u16496v54XTeH6R0h8",
                                            passwordChanged: "2022-03-28T09:18:19.000Z",
                                            profile: {
                                                login: "jeevraj.shekhawat.contractor@pepsico.com",
                                                firstName: "Jeev",
                                                lastName: "Shek",
                                                locale: "en_US",
                                                timeZone: "America/Los_Angeles",
                                            },
                                        },
                                    },
                                    _links: {
                                        cancel: {
                                            href: "https://pepsico.oktapreview.com/api/v1/authn/cancel",
                                            hints: {
                                                allow: ["POST"],
                                            },
                                        },
                                    },
                                },
                            },
                        },
                    },
                    400: {
                        description: "Bad Request",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/ErrorMessage",
                                },
                            },
                        },
                    },
                    415: {
                        description: "Unsupported Media Type",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/ErrorMessage",
                                },
                            },
                        },
                    },
                    500: {
                        description: "Internal Server Error",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/Problem",
                                },
                            },
                        },
                    },
                },
            },
        },
        "/api/v1/auth/changepwd": {
            post: {
                operationId: "changePasswordPost",
                tags: ["Authentication"],
                summary: "Change Password",
                description: "Change Password",
                requestBody: {
                    content: {
                        "application/json": {
                            schema: {
                                $ref: "#/components/schemas/ChangePasswordRequestDto",
                            },
                            example: {
                                user_id: {
                                    value: "xyz@gmail.com",
                                },
                                oldPassword: {
                                    value: "test@132",
                                },
                                newPassword: {
                                    value: "test@1324",
                                },
                            },
                        },
                    },
                },
                parameters: [
                    {
                        $ref: "#/components/parameters/siteId",
                    },
                    {
                        $ref: "#/components/parameters/oktaAccessToken",
                    },
                ],
                responses: {
                    200: {
                        description: "OK",
                        content: {
                            "application/json": {
                                schema: {
                                    $ref: "#/components/schemas/ChangePasswordResponseDto",
                                },
                                example: {
                                    password: [],
                                    provider: {
                                        type: "OKTA",
                                        name: "OKTA",
                                    },
                                },
                            },
                        },
                    },
                    400: {
                        description: "Bad Request",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/ErrorMessage",
                                },
                            },
                        },
                    },
                    415: {
                        description: "Unsupported Media Type",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/ErrorMessage",
                                },
                            },
                        },
                    },
                    500: {
                        description: "Internal Server Error",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/Problem",
                                },
                            },
                        },
                    },
                },
            },
        },
        "/api/v1/auth/stores": {
            get: {
                operationId: "getAuthorizedStoresGet",
                tags: ["Authentication"],
                summary: "Get Auth Stores",
                description: "Auth Stores - Provide either Tax ID or Mobile Number",
                parameters: [
                    {
                        $ref: "#/components/parameters/taxId",
                    },
                    {
                        $ref: "#/components/parameters/mobile",
                    },
                    {
                        $ref: "#/components/parameters/siteId",
                    },
                    {
                        $ref: "#/components/parameters/xApiKey",
                    },
                ],
                responses: {
                    200: {
                        description: "OK",
                        content: {
                            "application/json": {
                                schema: {
                                    $ref: "#/components/schemas/GetAuthStoresResponseDto",
                                },
                                example: {
                                    success: "true",
                                    data: [
                                        {
                                            storeId: "0102161788",
                                            storeName: "ZAMUDIO",
                                            type: "",
                                            city: "PALMIRA",
                                            state: "76",
                                            country: "CO",
                                            postalCode: "763531",
                                            permissions: "CE",
                                            addresses: {
                                                addressLine1: "CL 32 16 01 COLOMBINA",
                                                addressLine2: " ",
                                                city: "PALMIRA",
                                                state: "76",
                                                country: "CO",
                                            },
                                        },
                                    ],
                                },
                            },
                        },
                    },
                    400: {
                        description: "Bad Request",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/ErrorMessage",
                                },
                            },
                        },
                    },
                    415: {
                        description: "Unsupported Media Type",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/ErrorMessage",
                                },
                            },
                        },
                    },
                    500: {
                        description: "Internal Server Error",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/Problem",
                                },
                            },
                        },
                    },
                },
            },
        },
        "/api/v1/auth/deactivate": {
            post: {
                operationId: "deactivateUserPost",
                tags: ["Authentication"],
                summary: "Deactivate User",
                description: "Deactivate User",
                parameters: [
                    {
                        $ref: "#/components/parameters/oktaAccessToken",
                    },
                ],
                responses: {
                    200: {
                        description: "OK",
                        content: {
                            "application/json": {
                                schema: {
                                    $ref: "#/components/schemas/BasicResponseDto",
                                },
                                example: {
                                    success: true,
                                    msg: "User deactivated succesfully",
                                },
                            },
                        },
                    },
                    400: {
                        description: "Bad Request",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/ErrorMessage",
                                },
                            },
                        },
                    },
                    415: {
                        description: "Unsupported Media Type",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/ErrorMessage",
                                },
                            },
                        },
                    },
                    500: {
                        description: "Internal Server Error",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/Problem",
                                },
                            },
                        },
                    },
                },
            },
        },
        "/api/v1/auth/employee/register": {
            post: {
                operationId: "registerEmployeePost",
                tags: ["Authentication"],
                summary: "Register Employee",
                description: "Register Employee",
                requestBody: {
                    content: {
                        "application/json": {
                            schema: {
                                $ref: "#/components/schemas/RegisterEmployeeRequestDto",
                            },
                            example: {
                                userId: "xyz@gmail.com",
                                parentErpId: "7887423",
                                stores: [
                                    {
                                        storeId: "0112",
                                        permission: "CE",
                                    },
                                    {
                                        storeId: "0112",
                                        permission: "CE",
                                    },
                                ],
                            },
                        },
                    },
                },
                parameters: [
                    {
                        $ref: "#/components/parameters/oktaAccessToken",
                    },
                    {
                        $ref: "#/components/parameters/siteId",
                    },
                ],
                responses: {
                    200: {
                        description: "OK",
                        content: {
                            "application/json": {
                                schema: {
                                    $ref: "#/components/schemas/BasicResponseDto",
                                },
                                example: {
                                    success: true,
                                    msg: "User Created succesfully",
                                },
                            },
                        },
                    },
                    400: {
                        description: "Bad Request",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/ErrorMessage",
                                },
                            },
                        },
                    },
                    415: {
                        description: "Unsupported Media Type",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/ErrorMessage",
                                },
                            },
                        },
                    },
                    500: {
                        description: "Internal Server Error",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/Problem",
                                },
                            },
                        },
                    },
                },
            },
        },
        "/api/v1/auth/validateTaxId": {
            post: {
                operationId: "validateTaxIdPost",
                tags: ["Authentication"],
                summary: "Auth Profile - Validate the Tax ID",
                description: "Auth Profile - Validate the Tax ID",
                requestBody: {
                    content: {
                        "application/json": {
                            schema: {
                                $ref: "#/components/schemas/ValidateTaxIdRequestDto",
                            },
                            example: {
                                erpId: "1212",
                                taxId: "31443324",
                            },
                        },
                    },
                },
                parameters: [
                    {
                        $ref: "#/components/parameters/siteId",
                    },
                    {
                        $ref: "#/components/parameters/xApiKey",
                    },
                ],
                responses: {
                    200: {
                        description: "OK",
                        content: {
                            "application/json": {
                                schema: {
                                    $ref: "#/components/schemas/BasicResponseDto",
                                },
                                example: {
                                    success: true,
                                    msg: "Profile Validated",
                                },
                            },
                        },
                    },
                    400: {
                        description: "Bad Request",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/ErrorMessage",
                                },
                            },
                        },
                    },
                    415: {
                        description: "Unsupported Media Type",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/ErrorMessage",
                                },
                            },
                        },
                    },
                    500: {
                        description: "Internal Server Error",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/Problem",
                                },
                            },
                        },
                    },
                },
            },
        },
        "/api/v1/auth/generateCode": {
            post: {
                operationId: "generateVerificationCodePost",
                tags: ["Authentication"],
                summary: "Auth Profile",
                description:
                    "Sends verification code to the user's phone number|email address ",
                requestBody: {
                    content: {
                        "application/json": {
                            schema: {
                                $ref: "#/components/schemas/GenerateCodeRequestDto",
                            },
                            example: {
                                userId: "7289091210@pepsico.com",
                                userIdType: "Phone || SSO",
                                alternativePhone: "3423432423",
                            },
                        },
                    },
                },
                parameters: [
                    {
                        $ref: "#/components/parameters/siteId",
                    },
                    {
                        $ref: "#/components/parameters/xApiKey",
                    },
                ],
                responses: {
                    200: {
                        description: "OK",
                        content: {
                            "application/json": {
                                schema: {
                                    $ref: "#/components/schemas/BasicResponseDto",
                                },
                                example: {
                                    success: true,
                                    msg: "Profile Validated",
                                },
                            },
                        },
                    },
                    400: {
                        description: "Bad Request",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/ErrorMessage",
                                },
                            },
                        },
                    },
                    415: {
                        description: "Unsupported Media Type",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/ErrorMessage",
                                },
                            },
                        },
                    },
                    500: {
                        description: "Internal Server Error",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/Problem",
                                },
                            },
                        },
                    },
                },
            },
        },
        "/api/v1/auth/resendOtp": {
            post: {
                operationId: "resendOtpPost",
                tags: ["Authentication"],
                summary: "Resend OTP",
                description: "Resend OTP",
                requestBody: {
                    content: {
                        "application/json": {
                            schema: {
                                $ref: "#/components/schemas/ResendOtpRequestDto",
                            },
                            example: {
                                otpType: "Email || Phone",
                                otpId: "abc@gmail.com",
                                userId: "xyz@pepsiconnect.com",
                                registrationType: "Update (Default is New)",
                            },
                        },
                    },
                },
                parameters: [
                    {
                        $ref: "#/components/parameters/siteId",
                    },
                    {
                        $ref: "#/components/parameters/xApiKey",
                    },
                ],
                responses: {
                    200: {
                        description: "OK",
                        content: {
                            "application/json": {
                                schema: {
                                    $ref: "#/components/schemas/BasicResponseDto",
                                },
                                example: {
                                    success: true,
                                    msg: "Verification code sent on xyz@pepsiconnect.com.",
                                },
                            },
                        },
                    },
                    400: {
                        description: "Bad Request",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/ErrorMessage",
                                },
                            },
                        },
                    },
                    415: {
                        description: "Unsupported Media Type",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/ErrorMessage",
                                },
                            },
                        },
                    },
                    500: {
                        description: "Internal Server Error",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/Problem",
                                },
                            },
                        },
                    },
                },
            },
        },
        "/api/v1/auth/verifyUserId": {
            get: {
                operationId: "verifyUserIdGet",
                tags: ["Authentication"],
                summary: "Get Customer Account Status",
                description: "Get Customer Account Status",
                parameters: [
                    {
                        $ref: "#/components/parameters/siteId",
                    },
                    {
                        $ref: "#/components/parameters/xApiKey",
                    },
                    {
                        $ref: "#/components/parameters/userId",
                    },
                ],
                responses: {
                    200: {
                        description: "OK",
                        content: {
                            "application/json": {
                                schema: {
                                    $ref: "#/components/schemas/VerifyUserIdResponseDto",
                                },
                                example: {
                                    userId: "500066@pepsiconnect.com",
                                    status: "ACTIVE (ACTIVE/INACTIVE)",
                                    numberUpdateInProgress: "false (true/false)",
                                    oktaStatus:
                                        "ACTIVE (ACTIVE/DEPROVISIONED/LOCKED_OUT/SUSPENDED/PROVISIONED/null)",
                                },
                            },
                        },
                    },
                    400: {
                        description: "Bad Request",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/ErrorMessage",
                                },
                            },
                        },
                    },
                    415: {
                        description: "Unsupported Media Type",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/ErrorMessage",
                                },
                            },
                        },
                    },
                    500: {
                        description: "Internal Server Error",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/Problem",
                                },
                            },
                        },
                    },
                },
            },
        },
        "/api/v1/auth/newUserIdOTP": {
            post: {
                operationId: "generateNewUserOtpPost",
                tags: ["Authentication"],
                summary: "Send OTP To Update Mobile Number when user Logged In",
                description: "Send OTP To Update Mobile Number when user Logged In",
                requestBody: {
                    content: {
                        "application/json": {
                            schema: {
                                $ref: "#/components/schemas/NewUserIdOtpRequestDto",
                            },
                            example: {
                                userIdType: "Phone || Email",
                                newUserId: "xyz@pepsiconnect.com",
                            },
                        },
                    },
                },
                parameters: [
                    {
                        $ref: "#/components/parameters/oktaAccessToken",
                    },
                ],
                responses: {
                    200: {
                        description: "OK",
                        content: {
                            "application/json": {
                                schema: {
                                    $ref: "#/components/schemas/BasicResponseDto",
                                },
                                example: {
                                    success: true,
                                    msg: "Verification code sent on xyz@pepsiconnect.com.",
                                },
                            },
                        },
                    },
                    400: {
                        description: "Bad Request",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/ErrorMessage",
                                },
                            },
                        },
                    },
                    415: {
                        description: "Unsupported Media Type",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/ErrorMessage",
                                },
                            },
                        },
                    },
                    500: {
                        description: "Internal Server Error",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/Problem",
                                },
                            },
                        },
                    },
                },
            },
        },
        "/api/v1/auth/newEmailOTP": {
            post: {
                tags: ["Authentication"],
                summary: "Generate new email OTP POLAND ONLY",
                description: "Generates a new OTP for the given email.",
                operationId: "newEmailOTP",
                parameters: [
                    {
                        $ref: "#/components/parameters/siteId",
                    },
                    {
                        $ref: "#/components/parameters/oktaAccessToken",
                    },
                ],
                requestBody: {
                    required: true,
                    content: {
                        "application/json": {
                            schema: {
                                type: "object",
                                properties: {
                                    userId: {
                                        type: "string",
                                        example: "87897777@pepsiconnect.com",
                                    },
                                    userIdType: {
                                        type: "string",
                                        example: "Email",
                                    },
                                    email: {
                                        type: "string",
                                        example: "abc@gmail.com",
                                    },
                                },
                                required: ["userId", "userIdType", "email"],
                            },
                        },
                    },
                },
                responses: {
                    200: {
                        description: "OTP generated successfully",
                        content: {
                            "application/json": {
                                schema: {
                                    $ref: "#/components/schemas/BasicResponseDto",
                                },
                            },
                        },
                    },
                    400: {
                        description: "Invalid request",
                        content: {
                            "application/json": {
                                schema: {
                                    $ref: "#/components/schemas/BasicResponseDto",
                                },
                            },
                        },
                    },
                },
            },
        },
        "/api/v1/auth/changeUserId": {
            post: {
                operationId: "changeUserIdPost",
                tags: ["Authentication"],
                summary: "Update Mobile Number when user Logged In",
                description: "Update Mobile Number when user Logged In",
                requestBody: {
                    content: {
                        "application/json": {
                            schema: {
                                $ref: "#/components/schemas/ChangeUserIdRequestDto",
                            },
                            example: {
                                profile: {
                                    newUserId: "500066@pepsiconnect.com",
                                    storeId: "500066 (in case of Poland)",
                                },
                                credentials: {
                                    password: {
                                        value: "Test@123",
                                    },
                                },
                            },
                        },
                    },
                },
                parameters: [
                    {
                        $ref: "#/components/parameters/oktaAccessToken",
                    },
                ],
                responses: {
                    200: {
                        description: "OK",
                        content: {
                            "application/json": {
                                schema: {
                                    $ref: "#/components/schemas/BasicResponseDto",
                                },
                                example: {
                                    success: true,
                                    msg: "Account updated succesfully",
                                },
                            },
                        },
                    },
                    400: {
                        description: "Bad Request",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/ErrorMessage",
                                },
                            },
                        },
                    },
                    415: {
                        description: "Unsupported Media Type",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/ErrorMessage",
                                },
                            },
                        },
                    },
                    500: {
                        description: "Internal Server Error",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/Problem",
                                },
                            },
                        },
                    },
                },
            },
        },
        "/api/v1/auth/addStore": {
            post: {
                operationId: "addStore",
                tags: ["Authentication"],
                summary: "Add Store",
                description: "Add Store in exiting store",
                requestBody: {
                    content: {
                        "application/json": {
                            schema: {
                                $ref: "#/components/schemas/AddStoreRequestDto",
                            },
                            example: {
                                userIdType: "Phone",
                                userId: "xyz@gmail.com",
                                erpID: "xyz",
                                taxID: "xyz",
                            },
                        },
                    },
                },
                parameters: [
                    {
                        $ref: "#/components/parameters/siteId",
                    },
                    {
                        $ref: "#/components/parameters/oktaAccessToken",
                    },
                ],
                responses: {
                    200: {
                        description: "OK",
                        content: {
                            "application/json": {
                                schema: {
                                    $ref: "#/components/schemas/BasicResponseDto",
                                },
                                example: {
                                    success: true,
                                    msg: "Store Added.",
                                },
                            },
                        },
                    },
                    400: {
                        description: "Bad Request",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/ErrorMessage",
                                },
                            },
                        },
                    },
                    415: {
                        description: "Unsupported Media Type",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/ErrorMessage",
                                },
                            },
                        },
                    },
                    500: {
                        description: "Internal Server Error",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/Problem",
                                },
                            },
                        },
                    },
                },
            },
        },
        "/api/v1/navigation": {
            get: {
                operationId: "getNavigationGet",
                tags: ["Navigation"],
                summary: "Get Navigation",
                description: "Get Navigation Items and SubItems",
                parameters: [
                    {
                        $ref: "#/components/parameters/siteId",
                    },
                ],
                responses: {
                    200: {
                        description: "OK",
                        content: {
                            "application/json": {
                                schema: {
                                    $ref: "#/components/schemas/GetNavigationResponseDto",
                                },
                                example: {
                                    metadata: {
                                        itemKey: "navitems",
                                        totalItems: 6,
                                    },
                                    navItems: {
                                        footer: [
                                            {
                                                title: {
                                                    en: "©️ 2022 PepsiCo Inc.",
                                                    es: "©️ 2022 PepsiCo Inc.",
                                                },
                                                displayOrder: 3,
                                                path: null,
                                            },
                                            {
                                                title: {
                                                    en: "Our call center is here to support you Mon-Fri from 9am-5pm",
                                                    es: "Our call center is here to support you Mon-Fri from 9am-5pm",
                                                },
                                                displayOrder: 2,
                                                path: null,
                                            },
                                            {
                                                title: {
                                                    en: "93 547 89 41",
                                                    es: "93 547 89 41",
                                                },
                                                displayOrder: 1,
                                                path: null,
                                            },
                                        ],
                                        header: {
                                            0: {
                                                title: {
                                                    en: "Shop",
                                                    es: "Shop Es",
                                                },
                                                displayOrder: 3,
                                                path: "/shop",
                                            },
                                            1: {
                                                title: {
                                                    en: "Order",
                                                    es: "Order",
                                                },
                                                displayOrder: 2,
                                                path: "/order",
                                            },
                                            2: {
                                                title: {
                                                    en: "Home",
                                                    es: "Home Es",
                                                },
                                                displayOrder: 1,
                                                path: "/home",
                                            },
                                            subItems: [
                                                {
                                                    title: {
                                                        en: "Products",
                                                        es: "Products in spanish",
                                                    },
                                                    displayOrder: null,
                                                    path: "/products",
                                                },
                                            ],
                                        },
                                    },
                                },
                            },
                        },
                    },
                    400: {
                        description: "Bad Request",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/ErrorMessage",
                                },
                            },
                        },
                    },
                    415: {
                        description: "Unsupported Media Type",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/ErrorMessage",
                                },
                            },
                        },
                    },
                    500: {
                        description: "Internal Server Error",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/Problem",
                                },
                            },
                        },
                    },
                },
            },
        },
        "/api/v1/account": {
            get: {
                operationId: "getAccountDetailsGetV1",
                tags: ["Account v1"],
                summary: "Account Details",
                description: "Account Details",
                parameters: [
                    {
                        $ref: "#/components/parameters/oktaAccessToken",
                    },
                ],
                responses: {
                    200: {
                        description: "OK",
                        content: {
                            "application/json": {
                                schema: {
                                    $ref: "#/components/schemas/AccountDetailsDto",
                                },
                                example: {
                                    metadata: {
                                        __comment: "Info about the JSON File.",
                                        itemKey: "customers",
                                        totalItems: 1,
                                    },
                                    customers: {
                                        id: 249548,
                                        userId: "4137095123@pepsiconnect.com",
                                        role: "Manager",
                                        memberType: "",
                                        firstName: "ANYI",
                                        lastName: "PAOLA",
                                        profileImgUrl:
                                            "https://cepadmin2.spo.nonprod.wspdop.pepsico.com/CustomerImages/7/Fireworks%20Coloring%20Page%20%20%20Disney%20LOL-1658407922004.png",
                                        dob: null,
                                        deviceToken: null,
                                        maid: null,
                                        deviceType: null,
                                        email: "test1@gmail.com",
                                        phone: "311876297",
                                        alternateNumber: "321321321312",
                                        contact: [
                                            {
                                                isActive: true,
                                                creditBlock: "yes",
                                                creditLimitAmount: "0",
                                                mobile: "4137095123",
                                                default: true,
                                                isStore: true,
                                                storeId: "0100104110",
                                                name: "OLIMPICA",
                                                nickName: null,
                                                minimumOrderAmount: 40,
                                                image:
                                                    "https://cepadmin2.spo.nonprod.wspdop.pepsico.com/StoreMapImages/7/Fireworks%20Coloring%20Page%20%20%20Disney%20LOL-0100104110-1658407851730.png",
                                                companyCode: null,
                                                type: "",
                                                postalCode: "1111",
                                                VATNumber: null,
                                                phoneNumber: null,
                                                permissions: null,
                                                profileStatus: null,
                                                points: 1000,
                                                lifetimePoints: 902,
                                                addresses: {
                                                    addressLine1: "CL 138 52 03",
                                                    addressLine2: " ",
                                                    city: "MOSQUERA",
                                                    state: "52",
                                                    country: "CO",
                                                },
                                                location: [
                                                    {
                                                        locationId: "8616",
                                                        locationType: "External",
                                                        accountCode: "8616",
                                                    },
                                                    {
                                                        locationId: "8717",
                                                        locationType: "Internal",
                                                        accountCode: "WH-8717",
                                                    },
                                                ],
                                                businessType: "",
                                            },
                                        ],
                                        customAddresses: [
                                            {
                                                name: "Golu Tulshyan",
                                                isdefault: true,
                                                position: 0,
                                                type: "shipTo",
                                                buildingNumber: "2",
                                                addressLine1: "ABC",
                                                addressLine2: null,
                                                province: "3234234",
                                                city: "dfdfdf",
                                                district: "Kolkata",
                                                state: "ewrwer",
                                                country: "IN",
                                                postalCode: "534534534",
                                                taxAreaCode: "3234234",
                                                phoneNumber: 324324234,
                                            },
                                        ],
                                        termsAccepted: [
                                            {
                                                contentId: "terms-and-conditions",
                                                version_number: "1",
                                                deviceSerial: "123456789",
                                                ipAddress: "192.168.0.1",
                                                date: "2022-11-22",
                                                time: "12:15",
                                            },
                                            {
                                                contentId: "privacy-policy",
                                                version_number: "2",
                                                deviceSerial: "23456789",
                                                ipAddress: "192.168.0.1",
                                                dateTime: "2022-11-13",
                                                date: "2022-11-13",
                                                time: "11:15",
                                            },
                                        ],
                                        preferences: {
                                            accessType: null,
                                            businessType: null,
                                            executionType: null,
                                            smsPermission:
                                                "NewOrder;OrderStatusChanged;OrderCancelled",
                                            whatsappPermission: null,
                                            emailPermission:
                                                "OrderShipped;OrderStatusChanged;OrderCancelled",
                                            pushNotificationPermission: null,
                                            cookiePermission: null,
                                            segments: [],
                                        },
                                        extra: {
                                            totalCredit: 0,
                                            levelId: 0,
                                            level: null,
                                            points: 0,
                                            lifetimePoints: 0,
                                        },
                                    },
                                },
                            },
                        },
                    },
                    400: {
                        description: "Bad Request",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/ErrorMessage",
                                },
                            },
                        },
                    },
                    415: {
                        description: "Unsupported Media Type",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/ErrorMessage",
                                },
                            },
                        },
                    },
                    500: {
                        description: "Internal Server Error",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/Problem",
                                },
                            },
                        },
                    },
                },
            },
            put: {
                operationId: "putAccountDetailsPutV1",
                tags: ["Account v1"],
                summary: "Account Details",
                description: "Account Details",
                requestBody: {
                    content: {
                        "application/json": {
                            schema: {
                                $ref: "#/components/schemas/PutAccountDetailsRequestDto",
                            },
                            example: {
                                firstName: "ANYI",
                                lastName: "PAOLA",
                                email: "test1@gmail.com",
                                phone: "311876297",
                                alternateNumber: "321321321312",
                                notificationSettings: {
                                    SMS: ["NewOrder", "OrderStatusChanged", "OrderCancelled"],
                                    Email: [
                                        "OrderShipped",
                                        "OrderStatusChanged",
                                        "OrderCancelled",
                                    ],
                                },
                                customAddress: {
                                    name: "Golu Tulshyan",
                                    district: "Kolkata",
                                    isdefault: true,
                                    position: 0,
                                    type: "shipTo",
                                    buildingNumber: 2,
                                    addressLine1: "ABC",
                                    addressLine2: null,
                                    province: "3234234",
                                    city: "dfdfdf",
                                    state: "ewrwer",
                                    country: "IN",
                                    postalCode: "534534534",
                                    taxAreaCode: "3234234",
                                    phoneNumber: 324324234,
                                },
                                termsAccepted: [
                                    {
                                        contentId: "terms-and-conditions",
                                        version_number: "1",
                                        deviceSerial: "123456789",
                                        ipAddress: "192.168.0.1",
                                        date: "2022-11-22",
                                        time: "12:15",
                                    },
                                ],
                            },
                        },
                    },
                },
                parameters: [
                    {
                        $ref: "#/components/parameters/oktaAccessToken",
                    },
                ],
                responses: {
                    200: {
                        description: "OK",
                        content: {
                            "application/json": {
                                schema: {
                                    $ref: "#/components/schemas/AccountDetailsDto",
                                },
                                example: {
                                    metadata: {
                                        __comment: "Info about the JSON File.",
                                        itemKey: "customers",
                                        totalItems: 1,
                                    },
                                    customers: [
                                        {
                                            id: 2588,
                                            externalId: "",
                                            isActive: true,
                                            userId: "311876297@pepsico.com",
                                            role: "Manager",
                                            firstName: "ANYI",
                                            lastName: "PAOLA",
                                            username: "ANYI PAOLA OSORIO RODRIGUEZ",
                                            profileImgUrl: null,
                                            dob: "08/06/2022",
                                            deviceToken: null,
                                            deviceType: null,
                                            contact: {
                                                email: "test1@gmail.com",
                                                phone: "311876297",
                                                mobile: 311876297,
                                                addresses: [
                                                    {
                                                        default: true,
                                                        isStore: true,
                                                        name: "EL EMIGRANTE",
                                                        nickName: "EMIGRANTE",
                                                        companyCode: null,
                                                        type: "soldTo",
                                                        buildingNumber: null,
                                                        addressLine1: null,
                                                        addressLine2: "",
                                                        province: "",
                                                        city: "ENVIGADO",
                                                        state: "",
                                                        country: "CO",
                                                        postalCode: "055420",
                                                        taxAreaCode: "",
                                                        VATNumber: null,
                                                        phoneNumber: 311876297,
                                                        permissions: "CEDO",
                                                        profileStatus: "Enrolled",
                                                        points: 0,
                                                        lifetimePoints: 0,
                                                    },
                                                ],
                                            },
                                            customAddresses: [
                                                {
                                                    name: "Golu Tulshyan",
                                                    isdefault: true,
                                                    position: 0,
                                                    type: "shipTo",
                                                    buildingNumber: "2",
                                                    addressLine1: "ABC",
                                                    addressLine2: null,
                                                    province: "3234234",
                                                    city: "dfdfdf",
                                                    district: "Kolkata",
                                                    state: "ewrwer",
                                                    country: "IN",
                                                    postalCode: "534534534",
                                                    taxAreaCode: "3234234",
                                                    phoneNumber: 324324234,
                                                },
                                            ],
                                            preferences: {
                                                accessType: null,
                                                businessType: null,
                                                executionType: null,
                                                smsPermission:
                                                    "NewOrder;OrderStatusChanged;OrderCancelled",
                                                whatsappPermission: null,
                                                emailPermission:
                                                    "OrderShipped;OrderStatusChanged;OrderCancelled",
                                                pushNotificationPermission: null,
                                                cookiePermission: null,
                                                language: null,
                                                segments: [],
                                            },
                                            extra: {
                                                totalCredit: 0,
                                                levelId: 2442,
                                                level: "Bronze",
                                                points: 0,
                                                lifetimePoints: 0,
                                            },
                                        },
                                    ],
                                },
                            },
                        },
                    },
                    400: {
                        description: "Bad Request",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/ErrorMessage",
                                },
                            },
                        },
                    },
                    415: {
                        description: "Unsupported Media Type",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/ErrorMessage",
                                },
                            },
                        },
                    },
                    500: {
                        description: "Internal Server Error",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/Problem",
                                },
                            },
                        },
                    },
                },
            },
        },
        "/api/v2/account": {
            get: {
                operationId: "getAccountDetailsGetV2",
                tags: ["Account v2"],
                summary: "Account Details",
                description: "Account Details",
                parameters: [
                    {
                        $ref: "#/components/parameters/oktaAccessToken",
                    },
                ],
                responses: {
                    200: {
                        description: "OK",
                        content: {
                            "application/json": {
                                schema: {
                                    $ref: "#/components/schemas/GetAccountResponseDtoV2",
                                },
                                example: {
                                    metadata: {
                                        __comment: "Info about the JSON File.",
                                        itemKey: "customers",
                                        paymentType: null,
                                        totalItems: 9,
                                    },
                                    customers: {
                                        id: 15197350,
                                        externalId: null,
                                        isActive: null,
                                        creditBlock: null,
                                        creditLimitAmount: null,
                                        userId: "5332753844@pepsiconnect.com",
                                        role: "Manager",
                                        memberType: "",
                                        firstName: "Active",
                                        lastName: "Inactive",
                                        username: null,
                                        profileImgUrl: null,
                                        dob: null,
                                        deviceToken: null,
                                        loginExpiryDate: null,
                                        maid: null,
                                        deviceType: null,
                                        email: "5332753844@pepsiconnect.com",
                                        phone: "5332753844",
                                        gender: "",
                                        emailVerifyStatus: "",
                                        mobileVerifyStatus: "",
                                        isStorePermissionUpdated: false,
                                        showOnBoardingScreen: true,
                                        alternateNumber: null,
                                        shouldSendNotificationReminder: false,
                                        contact: {
                                            default: true,
                                            isStore: false,
                                            name: null,
                                            permissions: null,
                                            profileStatus: null,
                                            businessType: null,
                                            addresses: [],
                                            location: null,
                                            userCategoryType: null,
                                        },
                                        conatact: {
                                            points: 0,
                                            lifetimePoints: null,
                                        },
                                        termsAccepted: [],
                                        relatedStore: [
                                            {
                                                erpId: "500016  only for ploand",
                                                typeCode: "Bill-to party  only for ploand",
                                            },
                                            {
                                                erpId: "5000015 only for ploand",
                                                typeCode: "Payer only for ploand",
                                            },
                                            {
                                                erpId: "5000015 only for ploand ",
                                                typeCode: "Sold-to party only for ploand",
                                            },
                                            {
                                                erpId: "5000015 only for ploand",
                                                typeCode: "Ship-to party only for ploand",
                                            },
                                        ],
                                        preferences: {
                                            accessType: null,
                                            businessType: null,
                                            executionType: null,
                                            smsPermission: null,
                                            whatsappPermission: null,
                                            emailPermission: null,
                                            pushNotificationPermission: null,
                                            cookiePermission: null,
                                            obtainPermission: null,
                                            segments: [],
                                        },
                                        extra: {
                                            totalCredit: 0,
                                            levelId: 0,
                                            level: null,
                                            points: 0,
                                            lifetimePoints: 0,
                                        },
                                        autoVerified: false,
                                        msg: "",
                                    },
                                },
                            },
                        },
                    },
                    400: {
                        description: "Bad Request",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/ErrorMessage",
                                },
                            },
                        },
                    },
                    415: {
                        description: "Unsupported Media Type",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/ErrorMessage",
                                },
                            },
                        },
                    },
                    500: {
                        description: "Internal Server Error",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/Problem",
                                },
                            },
                        },
                    },
                },
            },
            put: {
                operationId: "putAccountDetailsPutV2",
                tags: ["Account v2"],
                summary: "Account Details",
                description: "Account Details",
                requestBody: {
                    content: {
                        "application/json": {
                            schema: {
                                $ref: "#/components/schemas/PutAccountDetailsRequestDto",
                            },
                            example: {
                                firstName: "ANYI",
                                lastName: "PAOLA",
                                email: "test1@gmail.com",
                                phone: "311876297",
                                alternateNumber: "321321321312",
                                notificationSettings: {
                                    SMS: ["NewOrder", "OrderStatusChanged", "OrderCancelled"],
                                    Email: [
                                        "OrderShipped",
                                        "OrderStatusChanged",
                                        "OrderCancelled",
                                    ],
                                },
                                customAddress: {
                                    name: "Golu Tulshyan",
                                    district: "Kolkata",
                                    isdefault: true,
                                    position: 0,
                                    type: "shipTo",
                                    buildingNumber: 2,
                                    addressLine1: "ABC",
                                    addressLine2: null,
                                    province: "3234234",
                                    city: "dfdfdf",
                                    state: "ewrwer",
                                    country: "IN",
                                    postalCode: "534534534",
                                    taxAreaCode: "3234234",
                                    phoneNumber: 324324234,
                                },
                                termsAccepted: [
                                    {
                                        contentId: "terms-and-conditions",
                                        version_number: "1",
                                        deviceSerial: "123456789",
                                        ipAddress: "192.168.0.1",
                                        date: "2022-11-22",
                                        time: "12:15",
                                    },
                                ],
                            },
                        },
                    },
                },
                parameters: [
                    {
                        $ref: "#/components/parameters/oktaAccessToken",
                    },
                ],
                responses: {
                    200: {
                        description: "OK",
                        content: {
                            "application/json": {
                                schema: {
                                    $ref: "#/components/schemas/GetAccountResponseDtoV2",
                                },
                                example: {
                                    metadata: {
                                        __comment: "Info about the JSON File.",
                                        itemKey: "customers",
                                        paymentType: null,
                                        totalItems: 1,
                                    },
                                    customers: {
                                        id: 821347,
                                        userId: "5372480381@pepsiconnect.com",
                                        role: "Manager",
                                        memberType: "DO;CE",
                                        firstName: "Test",
                                        lastName: "r9B7n1K8h5",
                                        profileImgUrl:
                                            "https://admin.cep.qa.tr.pepsico.com/CustomerImages/14/00u1tuxm5ch86L2z70h8-1702397540108.png",
                                        dob: "01/01/1984",
                                        deviceToken: null,
                                        loginExpiryDate: "2024-03-26",
                                        maid: null,
                                        deviceType: "Web-Mobile",
                                        email: "",
                                        gender: "",
                                        phone: "1234567890",
                                        emailVerifyStatus: "",
                                        mobileVerifyStatus: "",
                                        alternateNumber: null,
                                        isStorePermissionUpdated: false,
                                        showOnBoardingScreen: null,
                                        contact: [
                                            {
                                                isActive: true,
                                                creditBlock: "yes",
                                                creditLimitAmount: "0",
                                                salesBlockStatus: false,
                                                mobile: "5372480381",
                                                default: true,
                                                isStore: true,
                                                storeId: "5468507",
                                                name: "YUNUS EMRE GIDA-HEYBETULLAH ÜZMAN",
                                                nickName: null,
                                                returnAllowed: false,
                                                surveyCompleted: true,
                                                internalMOA: 1000,
                                                externalMOA: 500,
                                                image:
                                                    "https://admin.cep.qa.tr.pepsico.com/StoreMapImages/14/5468507-1702397536141.png",
                                                companyCode: null,
                                                companyName: "HEYBETULLAH ÜZMAN",
                                                type: "",
                                                postalCode: "34494",
                                                VATNumber: "18733609568",
                                                taxId: "18733609568",
                                                phoneNumber: null,
                                                permissions: "CEDO",
                                                profileStatus: "Enrolled",
                                                newCustomerWelcomeSegmentFlag: false,
                                                welcomePointsEarned: "10.0000000000",
                                                points: 0,
                                                lifetimePoints: 0,
                                                addresses: {
                                                    addressLine1: null,
                                                    addressLine2:
                                                        "GÜVERCİNTEPE MAH. KARADENİZ CAD. NO:78/01",
                                                    city: "İSTANBUL",
                                                    state: null,
                                                    country: "TURKEY",
                                                    province: "BAŞAKŞEHİR",
                                                },
                                                location: [
                                                    {
                                                        locationId: "1202Snx",
                                                        locationType: "Internal",
                                                        accountCode: "",
                                                    },
                                                    {
                                                        locationId: "17524Bev",
                                                        locationType: "External",
                                                        accountCode: "",
                                                    },
                                                    {
                                                        locationId: "WH-500",
                                                        locationType: "Internal",
                                                        accountCode: "",
                                                    },
                                                    {
                                                        locationId: "WH-243",
                                                        locationType: "External",
                                                        accountCode: "",
                                                    },
                                                ],
                                                businessType: "Snack,Beverage",
                                                videoBannerURL:
                                                    "https://www.youtube.com/watch?v=Ux8_zmLSnNk",
                                                userCategoryType: "Snack,Beverage",
                                            },
                                        ],
                                        customAddresses: [],
                                        termsAccepted: [
                                            {
                                                contentId: "terms-and-conditions",
                                                version_number: "{{293*555}}",
                                                deviceSerial: "iPhone15,4",
                                                ipAddress: null,
                                                date: "2024-01-09",
                                                time: "12:00:00",
                                            },
                                            {
                                                contentId: "gdpr",
                                                version_number: "{{310*608}}",
                                                deviceSerial: "iPhone15,4",
                                                ipAddress: null,
                                                date: "2024-01-07",
                                                time: "12:00:00",
                                            },
                                            {
                                                contentId: "gdpr-additional",
                                                version_number: "82",
                                                deviceSerial: "sdm710",
                                                ipAddress: "2401:4900:81de:e774:847:3c24:3d0a:5b48",
                                                date: "2024-02-16T06:00:26.199Z",
                                                time: "11:30:26",
                                            },
                                        ],
                                        preferences: {
                                            accessType: "DO;CE",
                                            businessType: null,
                                            executionType: "Snack;Beverage",
                                            smsPermission:
                                                "NewOrder;OrderStatusChanged;OrderCancelled",
                                            whatsappPermission: null,
                                            emailPermission:
                                                "OrderShipped;OrderStatusChanged;OrderCancelled",
                                            pushNotificationPermission: "OrderCancelled",
                                            cookiePermission: true,
                                            obtainPermission: "1",
                                            segments: [
                                                {
                                                    segmentCode: "BATI",
                                                    segmentName: "BATI",
                                                },
                                            ],
                                        },
                                        extra: {
                                            totalCredit: 0,
                                            levelId: 0,
                                            level: null,
                                            points: 0,
                                            lifetimePoints: 0,
                                        },
                                        autoVerified: false,
                                        msg: "",
                                    },
                                },
                            },
                        },
                    },
                    400: {
                        description: "Bad Request",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/ErrorMessage",
                                },
                            },
                        },
                    },
                    415: {
                        description: "Unsupported Media Type",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/ErrorMessage",
                                },
                            },
                        },
                    },
                    500: {
                        description: "Internal Server Error",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/Problem",
                                },
                            },
                        },
                    },
                },
            },
        },
        "/api/v1/account/employees": {
            get: {
                operationId: "getAccountEmployeesGetV1",
                tags: ["Account v1"],
                summary: "Employees for current account",
                description: "Get all employees for the current account",
                parameters: [
                    {
                        $ref: "#/components/parameters/oktaAccessToken",
                    },
                ],
                responses: {
                    200: {
                        description: "OK",
                        content: {
                            "application/json": {
                                schema: {
                                    $ref: "#/components/schemas/GetAccountEmployeesResponseDto",
                                },
                                example: {
                                    metadata: {
                                        itemKey: "employees",
                                        totalItems: 1,
                                    },
                                    employees: [
                                        {
                                            id: 364,
                                            externalId: "123456789",
                                            isActive: true,
                                            role: "Manager",
                                            memberType: "Sold-To / Payer",
                                            firstName: "Jeevraj",
                                            lastName: "Singh",
                                            username: "jeevrajtest@gmail.com",
                                            profileImgUrl: null,
                                            contact: {
                                                email: "jeevrajshekhawat161@gmail.com",
                                                phone: 8090012316,
                                                mobile: 918130815202,
                                                address: {
                                                    name: "T01",
                                                    id: "T01",
                                                    companyCode: "Pepsico",
                                                    type: "Sold-To / Payer",
                                                    buildingNumber: null,
                                                    addressLine1: null,
                                                    addressLine2: "",
                                                    province: null,
                                                    city: null,
                                                    state: null,
                                                    country: null,
                                                    postalCode: null,
                                                    taxAreaCode: null,
                                                    VATNumber: 1230987,
                                                    phoneNumber: 8090012316,
                                                    permissions: "CEDO",
                                                    profileStatus: "enrolled",
                                                    points: 250,
                                                    lifetimePoints: 500,
                                                },
                                            },
                                            preferences: {
                                                accessType: "CE",
                                                businessType: "Snack;Beverage",
                                                executionType: null,
                                                smsPermission: null,
                                                cookiePermission: null,
                                                language: null,
                                                segments: [
                                                    {
                                                        segmentCode: "003",
                                                        segmentName: "Pepsi",
                                                    },
                                                ],
                                            },
                                            extra: {
                                                totalCredit: 0,
                                                levelId: 1,
                                                level: "Platinum",
                                                points: 250,
                                                lifetimePoints: 500,
                                            },
                                        },
                                    ],
                                },
                            },
                        },
                    },
                    400: {
                        description: "Bad Request",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/ErrorMessage",
                                },
                            },
                        },
                    },
                    415: {
                        description: "Unsupported Media Type",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/ErrorMessage",
                                },
                            },
                        },
                    },
                    500: {
                        description: "Internal Server Error",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/Problem",
                                },
                            },
                        },
                    },
                },
            },
        },
        "/api/v1/account/customerImage": {
            post: {
                operationId: "addCustomerImagePostV1",
                tags: ["Account v1"],
                summary: "Add Customer Image",
                description: "Add Customer Image",
                requestBody: {
                    content: {
                        "application/json": {
                            schema: {
                                $ref: "#/components/schemas/AddCustomerImageRequestDto",
                            },
                            example: {
                                imagebase64:
                                    "iVBORw0KGgoAAAANSUhEUgAABHwAAAQQCAYAAACQvkwOAAAK3GlDQ1BJQ0MgUHJvZmlsZQAASImVlwdUk1kWx9",
                            },
                        },
                    },
                },
                parameters: [
                    {
                        $ref: "#/components/parameters/oktaAccessToken",
                    },
                ],
                responses: {
                    200: {
                        description: "OK",
                        content: {
                            "application/json": {
                                schema: {
                                    $ref: "#/components/schemas/AddCustomerImageV1ResponseDto",
                                },
                                example: {
                                    success: {
                                        imageURL:
                                            "https://cepadmin2.spo.nonprod.wspdop.pepsico.com/CustomerImages/7/Game_1_1-1658828236990.png",
                                    },
                                },
                            },
                        },
                    },
                    400: {
                        description: "Bad Request",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/ErrorMessage",
                                },
                            },
                        },
                    },
                    415: {
                        description: "Unsupported Media Type",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/ErrorMessage",
                                },
                            },
                        },
                    },
                    500: {
                        description: "Internal Server Error",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/Problem",
                                },
                            },
                        },
                    },
                },
            },
        },
        "/api/v1/account/storeMapImage": {
            post: {
                operationId: "addStoreMapImagePostV1",
                tags: ["Account v1"],
                summary: "Add Store Map Image",
                description: "Add Store Map Image",
                requestBody: {
                    content: {
                        "application/json": {
                            schema: {
                                $ref: "#/components/schemas/AddStoreMapImageRequestDto",
                            },
                            example: {
                                imagebase64:
                                    "iVBORw0KGgoAAAANSUhEUgAABHwAAAQQCAYAAACQvkwOAAAK3GlDQ1BJQ0MgUHJvZmlsZQAASImVlwdUk1kWx9",
                                storeId: "0102137511",
                                nickName: "Dummy Text",
                            },
                        },
                    },
                },
                parameters: [
                    {
                        $ref: "#/components/parameters/oktaAccessToken",
                    },
                ],
                responses: {
                    200: {
                        description: "OK",
                        content: {
                            "application/json": {
                                schema: {
                                    $ref: "#/components/schemas/AddStoreMapImageResponseDto",
                                },
                                example: {
                                    success: {
                                        imageURL:
                                            "https://cepadmin2.spo.nonprod.wspdop.pepsico.com/StoreMapImages/7/Game_1_1-0100104110-1658402930760.png",
                                        nickName: "Dummy Text",
                                    },
                                },
                            },
                        },
                    },
                    400: {
                        description: "Bad Request",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/ErrorMessage",
                                },
                            },
                        },
                    },
                    415: {
                        description: "Unsupported Media Type",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/ErrorMessage",
                                },
                            },
                        },
                    },
                    500: {
                        description: "Internal Server Error",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/Problem",
                                },
                            },
                        },
                    },
                },
            },
        },
        "/api/v1/cart": {
            post: {
                operationId: "addItemToCartPost",
                tags: ["Cart"],
                summary: "Add Item",
                description: "Add items to cart",
                requestBody: {
                    content: {
                        "application/json": {
                            schema: {
                                $ref: "#/components/schemas/CartRequestDto",
                            },
                            example: {
                                orderType: "ZOR | Rewards",
                                items: [
                                    {
                                        id: 315,
                                        quantity: 3,
                                        salesUOM: "CS",
                                    },
                                ],
                            },
                        },
                    },
                },
                parameters: [
                    {
                        $ref: "#/components/parameters/oktaAccessToken",
                    },
                    {
                        name: "storeId",
                        in: "header",
                        required: true,
                        description: "Store Id",
                        schema: {
                            type: "string",
                        },
                    },
                ],
                responses: {
                    200: {
                        description: "OK",
                        content: {
                            "application/json": {
                                schema: {
                                    $ref: "#/components/schemas/CartResponseDto",
                                },
                                example: {
                                    orderType: "ZOR | Rewards",
                                    items: [
                                        {
                                            id: 315,
                                            materialCode: 315,
                                            materialName: "Diet Pepsi",
                                            image: "",
                                            count: "20",
                                            total: "300",
                                        },
                                    ],
                                    freeItems: [],
                                    appliedCoupons: ["5", "1"],
                                    subtotal: "300.00",
                                    grandTotal: "300.00",
                                    count: 1,
                                    currency: "€",
                                },
                            },
                        },
                    },
                    400: {
                        description: "Bad Request",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/ErrorMessage",
                                },
                            },
                        },
                    },
                    415: {
                        description: "Unsupported Media Type",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/ErrorMessage",
                                },
                            },
                        },
                    },
                    500: {
                        description: "Internal Server Error",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/Problem",
                                },
                            },
                        },
                    },
                },
            },
            get: {
                operationId: "getCartGet",
                tags: ["Cart"],
                summary: "Get cart",
                description: "Get cart contents",
                parameters: [
                    {
                        $ref: "#/components/parameters/oktaAccessToken",
                    },
                    {
                        $ref: "#/components/parameters/storeIdInHeader",
                    },
                    {
                        $ref: "#/components/parameters/orderType",
                    },
                ],
                responses: {
                    200: {
                        description: "OK",
                        content: {
                            "application/json": {
                                schema: {
                                    $ref: "#/components/schemas/CartResponseDto",
                                },
                                example: {
                                    orderType: "ZOR | Rewards",
                                    items: [
                                        {
                                            id: 315,
                                            materialCode: 315,
                                            materialName: "Diet Pepsi",
                                            image: "",
                                            count: "20",
                                            total: 300,
                                        },
                                    ],
                                    freeItems: [],
                                    appliedCoupons: ["5", "1"],
                                    subtotal: "300.00",
                                    grandTotal: "300.00",
                                    count: 1,
                                    currency: "€",
                                },
                            },
                        },
                    },
                    400: {
                        description: "Bad Request",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/ErrorMessage",
                                },
                            },
                        },
                    },
                    415: {
                        description: "Unsupported Media Type",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/ErrorMessage",
                                },
                            },
                        },
                    },
                    500: {
                        description: "Internal Server Error",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/Problem",
                                },
                            },
                        },
                    },
                },
            },
            put: {
                operationId: "updateItemsInCartPut",
                tags: ["Cart"],
                summary: "Update Item(s)",
                description: "Update item(s) in cart",
                requestBody: {
                    content: {
                        "application/json": {
                            schema: {
                                $ref: "#/components/schemas/CartRequestDto",
                            },
                            example: {
                                orderType: "ZOR",
                                items: [
                                    {
                                        id: 315,
                                        quantity: 3,
                                        salesUOM: "CS",
                                    },
                                    {
                                        id: 316,
                                        quantity: 0,
                                        __comment: "If quantity 0, you are removing from BE cart.",
                                    },
                                ],
                            },
                        },
                    },
                },
                parameters: [
                    {
                        $ref: "#/components/parameters/oktaAccessToken",
                    },
                    {
                        $ref: "#/components/parameters/storeIdInHeader",
                    },
                ],
                responses: {
                    200: {
                        description: "OK",
                        content: {
                            "application/json": {
                                schema: {
                                    $ref: "#/components/schemas/CartResponseDto",
                                },
                                example: {
                                    orderType: "ZOR",
                                    items: [
                                        {
                                            id: 315,
                                            materialCode: 315,
                                            materialName: "Diet Pepsi",
                                            image: "",
                                            count: "10",
                                            total: "150",
                                        },
                                    ],
                                    freeItems: [],
                                    appliedCoupons: ["5", "1"],
                                    subtotal: "150.00",
                                    grandTotal: "150.00",
                                    count: 1,
                                    currency: "€",
                                },
                            },
                        },
                    },
                    400: {
                        description: "Bad Request",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/ErrorMessage",
                                },
                            },
                        },
                    },
                    415: {
                        description: "Unsupported Media Type",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/ErrorMessage",
                                },
                            },
                        },
                    },
                    500: {
                        description: "Internal Server Error",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/Problem",
                                },
                            },
                        },
                    },
                },
            },
            delete: {
                operationId: "clearCartDelete",
                tags: ["Cart"],
                summary: "Clear Cart",
                description: "",
                parameters: [
                    {
                        $ref: "#/components/parameters/oktaAccessToken",
                    },
                    {
                        $ref: "#/components/parameters/storeIdInHeader",
                    },
                    {
                        $ref: "#/components/parameters/orderType",
                    },
                ],
                responses: {
                    200: {
                        description: "OK",
                        content: {
                            "application/json": {
                                schema: {
                                    $ref: "#/components/schemas/BasicResponseDto",
                                },
                                examples: {
                                    success: {
                                        value: {
                                            success: true,
                                            msg: "Cart cleared",
                                        },
                                    },
                                    error: {
                                        value: {
                                            success: false,
                                            msg: "Cart could not be cleared",
                                        },
                                    },
                                },
                            },
                        },
                    },
                    400: {
                        description: "Bad Request",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/ErrorMessage",
                                },
                            },
                        },
                    },
                    415: {
                        description: "Unsupported Media Type",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/ErrorMessage",
                                },
                            },
                        },
                    },
                    500: {
                        description: "Internal Server Error",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/Problem",
                                },
                            },
                        },
                    },
                },
            },
        },
        "/api/v1/content": {
            get: {
                operationId: "getContentGet",
                tags: ["Content"],
                summary: "Content List",
                description: "Content List",
                parameters: [
                    {
                        $ref: "#/components/parameters/oktaAccessToken",
                    },
                    {
                        $ref: "#/components/parameters/siteId",
                    },
                    {
                        $ref: "#/components/parameters/xApiKey",
                    },
                    {
                        $ref: "#/components/parameters/storeIdInQuery",
                    },
                    {
                        $ref: "#/components/parameters/limit",
                    },
                    {
                        $ref: "#/components/parameters/offset",
                    },
                    {
                        $ref: "#/components/parameters/plainText",
                    },
                    {
                        $ref: "#/components/parameters/tags",
                    },
                    {
                        name: "id",
                        in: "query",
                        required: false,
                        description: "ID of Content",
                        schema: {
                            type: "string",
                            default: null,
                        },
                    },
                ],
                responses: {
                    200: {
                        description: "OK",
                        content: {
                            "application/json": {
                                schema: {
                                    $ref: "#/components/schemas/GetContentResponseDto",
                                },
                                example: {
                                    totalCount: 2,
                                    data: [
                                        {
                                            id: "bxgy",
                                            images: [
                                                {
                                                    image:
                                                        "http://localhost:89/_default_upload_bucket/lays_2.png",
                                                    imageText: {
                                                        en: "Extra Description for the promotion in case the",
                                                        es: "Descripción adicional para",
                                                    },
                                                    imageTextWithTags: {
                                                        en: "<p>Extra Description for the promotion in case the</p>",
                                                        es: "<pre>Descripción adicional para</pre>",
                                                    },
                                                    id: "BG1",
                                                },
                                            ],
                                            videos: [
                                                {
                                                    video: "",
                                                    videoText: {
                                                        en: "",
                                                        es: "",
                                                    },
                                                    videoTextWithTags: {
                                                        en: "<p>Test video text</p>",
                                                        es: "<p>Test video testo</p>",
                                                    },
                                                    id: "sas",
                                                    metaData: [],
                                                },
                                            ],
                                            attachment: [
                                                {
                                                    attachmentUrl:
                                                        "https://admin-fd.cep.qa.co.pepsico.com/_default_upload_bucket/000000000300033980.png",
                                                    attachmentId: "Test Attachment Id",
                                                    attachmentInfo: {
                                                        title: {
                                                            en: "Test Attachment ",
                                                            es: "Test Attachment ",
                                                        },
                                                        description: {
                                                            en: "Test Attachment ",
                                                            es: "Test Attachment ",
                                                        },
                                                    },
                                                },
                                            ],
                                            title: {
                                                en: "Buy X",
                                                es: "buyX ",
                                            },
                                            excerpt: {
                                                en: "Online exclusive offer",
                                                es: "Oferta exclusiva",
                                            },
                                            content: {
                                                en: "",
                                                es: "",
                                            },
                                            contentWithTags: {
                                                en: "<p>Buy 3 packs of GUDIZ VAINILLA &amp; get 1 GUDIZ VAINILLA pack free</p>",
                                                es: "<pre>Compra 3 paquetes de GUDIZ VAINILLA y llévate 1 paque</pre>",
                                            },
                                            parameters: [
                                                {
                                                    key: "background-color",
                                                    value: "#ED8B00",
                                                },
                                                {
                                                    key: "subTitleColor",
                                                    value: "#000000",
                                                },
                                                {
                                                    key: "textColor",
                                                    value: "#000000",
                                                },
                                            ],
                                            StartDate: "2022-06-01 17:30:00",
                                            EndDate: "2023-12-26 18:30:00",
                                            created: "2022-04-26 11:37:16",
                                            modified: "2022-12-27 07:26:40",
                                            displayOrder: null,
                                            url: null,
                                            siteId: 7,
                                            is_public: false,
                                            version_number: null,
                                            activity: {
                                                activityId: 385483,
                                                activitySlug: "reward-points::content",
                                                name: "content",
                                                points: 40,
                                                allowPointOverride: false,
                                                pointsMax: null,
                                                dailyLimit: null,
                                                lifetimeLimit: 3,
                                                requireUniqueIdentifier: false,
                                                activityPerformed: "0",
                                                activityCompleted: false,
                                                pointsEarned: 0,
                                            },
                                            selector: null,
                                            reward: [],
                                            points: 0,
                                            promotionID: "",
                                            tags: ["Promotions"],
                                            navigations: [
                                                {
                                                    id: 385577,
                                                    title: {
                                                        en: "MyHome Nav",
                                                    },
                                                    description: {
                                                        en: "MyHome",
                                                    },
                                                    tags: ["384737", "384736"],
                                                    displayOrder: 1,
                                                    isPublic: true,
                                                    metadata: "aaa meta",
                                                    startDate: "2022-12-31 18:30:00",
                                                    endDate: "2023-01-30 18:30:00",
                                                    items: [
                                                        {
                                                            title: {
                                                                en: "MyHome items",
                                                            },
                                                            ariaLabel: {
                                                                en: "MyHome label",
                                                            },
                                                            url: "http://MyHome",
                                                            linkTarget: "_self",
                                                            image:
                                                                "http://localhost:99/pepsico/products/Lays%20Crispy.jpg",
                                                            textColor: "#762c2c",
                                                            textHoverColor: "#a52f2f",
                                                            backgroundColor: "#2f1d1d",
                                                            backgroundHoverColor: "#23aa6c",
                                                            borderColor: "#c23a3a",
                                                            borderHoverColor: "#ecd645",
                                                            subItems: [
                                                                {
                                                                    title: {
                                                                        en: "subItems",
                                                                    },
                                                                    ariaLabel: {
                                                                        en: "subItems",
                                                                    },
                                                                    url: "subItems",
                                                                    linkTarget: "_self",
                                                                    image:
                                                                        "http://localhost:99/pepsico/carousel/1476799918_banner.jpg",
                                                                    textColor: "#c45b5b",
                                                                    textHoverColor: "#0f0505",
                                                                    backgroundColor: "#691919",
                                                                    backgroundHoverColor: "#240707",
                                                                    borderColor: "#330c0c",
                                                                    borderHoverColor: "#1b0606",
                                                                },
                                                            ],
                                                        },
                                                    ],
                                                },
                                            ],
                                        },
                                        {
                                            id: "bxgy-2",
                                            images: [
                                                {
                                                    image:
                                                        "http://localhost:89/_default_upload_bucket/cheetos.jpg",
                                                    imageText: {
                                                        en: "",
                                                        es: "",
                                                    },
                                                    imageTextWithTags: {
                                                        en: "<p>Extra Description for the promotion in case there is a need to explain more with 3 lines available</p>",
                                                        es: "<pre>Descripción adicional para la promoción en caso de que sea necesario explicar más con 3 líneas disponibles</pre>",
                                                    },
                                                    id: "BG2",
                                                },
                                            ],
                                            videos: [],
                                            title: {
                                                en: " BuyXGetY_Promo2",
                                                es: "BuyXGetY_Cheetos",
                                            },
                                            excerpt: {
                                                en: "Exclusive Online offer",
                                                es: "Oferta exclusiva en línea",
                                            },
                                            content: {
                                                en: "",
                                                es: "",
                                            },
                                            contentWithTags: {
                                                en: "<p>&nbsp;Buy 3 Cheetos Boli Queso 160gr and get 1 for free</p>",
                                                es: "<pre>Compra 3 paquetes de Cheetos Boli Queso 160gr y llévate 1 paquete gratis</pre>",
                                            },
                                            parameters: [
                                                {
                                                    key: "background-color",
                                                    value: "#c90076",
                                                },
                                                {
                                                    key: "subTitleColor",
                                                    value: "#000000",
                                                },
                                                {
                                                    key: "textColor",
                                                    value: "#000000",
                                                },
                                            ],
                                            StartDate: "2022-08-31 22:00:00",
                                            EndDate: "2023-09-05 21:45:00",
                                            created: "2022-09-02 10:59:49",
                                            modified: "2022-09-12 17:41:43",
                                            displayOrder: 1,
                                            url: null,
                                            siteId: 7,
                                            is_public: false,
                                            version_number: 0,
                                            activity: [],
                                            selector: null,
                                            reward: [],
                                            points: 0,
                                            promotionID: "12",
                                            tags: ["Promotions"],
                                        },
                                    ],
                                },
                            },
                        },
                    },
                    400: {
                        description: "Bad Request",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/ErrorMessage",
                                },
                            },
                        },
                    },
                    415: {
                        description: "Unsupported Media Type",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/ErrorMessage",
                                },
                            },
                        },
                    },
                    500: {
                        description: "Internal Server Error",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/Problem",
                                },
                            },
                        },
                    },
                },
            },
        },
        "/api/v1/faqs": {
            get: {
                operationId: "getFaqsGet",
                tags: ["Content"],
                summary: "Content List",
                description: "FAQ List",
                parameters: [
                    {
                        $ref: "#/components/parameters/oktaAccessToken",
                    },
                    {
                        $ref: "#/components/parameters/siteId",
                    },
                    {
                        $ref: "#/components/parameters/xApiKey",
                    },
                    {
                        $ref: "#/components/parameters/limit",
                    },
                    {
                        $ref: "#/components/parameters/offset",
                    },
                    {
                        $ref: "#/components/parameters/tags",
                    },
                ],
                responses: {
                    200: {
                        description: "OK",
                        content: {
                            "application/json": {
                                schema: {
                                    $ref: "#/components/schemas/FAQsResponseDto",
                                },
                                example: {
                                    totalCount: 1,
                                    data: [
                                        {
                                            id: 12,
                                            title: {
                                                en: "Frequently Asked Questions",
                                            },
                                            description: {
                                                en: "This is our frequently asked questions",
                                            },
                                            image: "https://domain.com/image.png",
                                            tags: ["General"],
                                            siteId: 8,
                                            is_public: true,
                                            section: [
                                                {
                                                    title: {
                                                        en: "General Questions",
                                                    },
                                                    faqs: [
                                                        {
                                                            question: {
                                                                en: "How much does it cost?",
                                                            },
                                                            answer: {
                                                                en: "It costs $20",
                                                            },
                                                            image: "https://domain.com/image2.png",
                                                        },
                                                        {
                                                            question: {
                                                                en: "How good is the quality?",
                                                            },
                                                            answer: {
                                                                en: "The quality is very good",
                                                            },
                                                            image: "https://domain.com/image3.png",
                                                        },
                                                    ],
                                                },
                                                {
                                                    title: {
                                                        en: "Legal Questions",
                                                    },
                                                    faqs: [
                                                        {
                                                            question: {
                                                                en: "Is this legal?",
                                                            },
                                                            answer: {
                                                                en: "Yes, it's totally legal",
                                                            },
                                                            image: "https://domain.com/image4.png",
                                                        },
                                                    ],
                                                },
                                            ],
                                        },
                                    ],
                                },
                            },
                        },
                    },
                    400: {
                        description: "Bad Request",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/ErrorMessage",
                                },
                            },
                        },
                    },
                    415: {
                        description: "Unsupported Media Type",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/ErrorMessage",
                                },
                            },
                        },
                    },
                    500: {
                        description: "Internal Server Error",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/Problem",
                                },
                            },
                        },
                    },
                },
            },
        },
        "/api/v1/order": {
            post: {
                operationId: "createOrderPostV1",
                tags: ["Order v1"],
                summary: "Create Order",
                description: "Create order",
                requestBody: {
                    content: {
                        "application/json": {
                            schema: {
                                $ref: "#/components/schemas/CreateOrderRequestOrder",
                            },
                            examples: {
                                normalPost: {
                                    summary: "POST",
                                    value: {
                                        orderType: "ZOR | Rewards",
                                        vendorId: "42342342",
                                        poNumber: "PEP-PO12435",
                                        ediNumber: "",
                                        invoiceNumber: "",
                                        orderDate: "2017-01-01",
                                        poDate: "2017-01-01",
                                        estimatedDeliveryDate: "2017-01-01",
                                        estimatedPoints: 60,
                                        currency: "USD",
                                        subTotal: 4500,
                                        promotionDiscounts: 2.5,
                                        productDiscounts: 1,
                                        totalTax: 5.25,
                                        totalPrice: 4447.4,
                                        browserCart: true,
                                        split: "Internal",
                                        promotionCode: ["16"],
                                        cartItems: [
                                            {
                                                id: 315,
                                                externalId: 12456,
                                                categoryId: 3,
                                                isPromotional: false,
                                                deliveryMethod: "Digital | Shipment",
                                                digitalAddress:
                                                    "null | testing@test.com | 1-123-456-7890",
                                                saleUOM: "Case",
                                                baseUOM: "Case",
                                                conversionMulitplierToBase: 1,
                                                quantity: 3,
                                                basePrice: 2000,
                                                finalPrice: 1500,
                                            },
                                            {
                                                id: 509,
                                                externalId: 789456,
                                                categoryId: 3,
                                                isPromotional: false,
                                                deliveryMethod: "Digital | Shipment",
                                                digitalAddress:
                                                    "null | testing@test.com | 1-123-456-7890",
                                                saleUOM: "Case",
                                                baseUOM: "EA",
                                                conversionMulitplierToBase: 12,
                                                quantity: 3,
                                                basePrice: 3000,
                                                finalPrice: 1500,
                                            },
                                        ],
                                        soldTo: {
                                            name: "Golu",
                                            addressLine1: 35434534534,
                                            addressLine2: null,
                                            city: "dfdfdf",
                                            district: "Kolkata",
                                            state: "645reert",
                                            country: "IN",
                                            postalCode: "534534534",
                                            phoneNumber: 324324234,
                                            IDNumber: "12345IDNum",
                                            DOB: "01-April-2023",
                                        },
                                        shippingAddress: {
                                            externalId: "12345",
                                            customerName: "John Doe",
                                            email: "test@tester.com",
                                            phone: "1234567890",
                                            addressLine1: "123 Main St",
                                            addressLine2: "",
                                            city: "San Francisco",
                                            countryCode: "US",
                                        },
                                        billingAddress: {
                                            externalId: "12345",
                                            customerName: "John Doe",
                                            email: "test@tester.com",
                                            phone: "1234567890",
                                            addressLine1: "123 Main St",
                                            addressLine2: "",
                                            city: "San Francisco",
                                            countryCode: "US",
                                        },
                                        customerAddress: {
                                            externalId: "12345",
                                            customerName: "John Doe",
                                            email: "test@tester.com",
                                            phone: "1234567890",
                                            addressLine1: "123 Main St",
                                            addressLine2: "",
                                            city: "San Francisco",
                                            countryCode: "US",
                                        },
                                        orderNote: "Please ship this next week..",
                                    },
                                },
                                reservedOrderPost: {
                                    summary: "POST Request for Reserved Order",
                                    value: {
                                        orderType: "REWARDS",
                                        orderDate: "2022-12-12",
                                        isResereved: true,
                                        totalPoints: 1000,
                                        pointDeduction: "500",
                                        topUpPaymentAmount: "580.5",
                                        browserCart: true,
                                        vendorId: "42342342",
                                        cartItems: [
                                            {
                                                id: 17,
                                                externalId: "000000000300033532",
                                                categoryId: 3,
                                                isPromotional: false,
                                                deliveryMethod: "Digital | Shipment",
                                                digitalAddress: "1-123-456-7890",
                                                taxRate: 10,
                                                quantity: 1,
                                                pointsPerItem: 5,
                                                totalPoints: 5,
                                            },
                                            {
                                                id: 21,
                                                externalId: "000000000300033534",
                                                categoryId: 3,
                                                isPromotional: false,
                                                deliveryMethod: "Digital | Shipment",
                                                digitalAddress: "1-123-456-7890",
                                                taxRate: 15,
                                                quantity: 50,
                                                pointsPerItem: 20,
                                                totalPoints: 1000,
                                            },
                                        ],
                                        soldTo: {
                                            name: "Golu",
                                            addressLine1: 35434534534,
                                            addressLine2: null,
                                            city: "dfdfdf",
                                            district: "Kolkata",
                                            state: "645reert",
                                            country: "IN",
                                            postalCode: "534534534",
                                            phoneNumber: 324324234,
                                        },
                                        orderNote: "Shipment scheduled next week..",
                                    },
                                },
                            },
                        },
                    },
                },
                parameters: [
                    {
                        $ref: "#/components/parameters/oktaAccessToken",
                    },
                    {
                        $ref: "#/components/parameters/storeIdInHeader",
                    },
                    {
                        name: "siteId",
                        in: "header",
                        required: true,
                        description: "siteId",
                        schema: {
                            type: "string",
                            default: null,
                        },
                    },
                    {
                        $ref: "#/components/parameters/orderNumber",
                    },
                ],
                responses: {
                    200: {
                        description: "OK",
                        content: {
                            "application/json": {
                                schema: {
                                    $ref: "#/components/schemas/CreateOrderResponseDto",
                                },
                                examples: {
                                    success: {
                                        value: {
                                            Internal: {
                                                orderId: "208794",
                                                orderNumber: "ord_641080d98eb7f",
                                                sourceOrderId: "B2B.C0001.1678803161.208794",
                                                estimatedPoints: 30,
                                                cartId: "1086",
                                            },
                                        },
                                    },
                                    failure: {
                                        value: {
                                            success: false,
                                            msg: "Order needs at least one product",
                                        },
                                    },
                                    successForApex: {
                                        summary: "Success response for Apex POST to CEP",
                                        value: {
                                            success: true,
                                            message: "Order updated successfully.",
                                        },
                                    },
                                    errorForApex: {
                                        summary: "Error response for Apex POST to CEP",
                                        value: {
                                            success: false,
                                            message: "Error updating order. Try again",
                                        },
                                    },
                                },
                            },
                        },
                    },
                    400: {
                        description: "Bad Request",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/ErrorMessage",
                                },
                            },
                        },
                    },
                    415: {
                        description: "Unsupported Media Type",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/ErrorMessage",
                                },
                            },
                        },
                    },
                    500: {
                        description: "Internal Server Error",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/Problem",
                                },
                            },
                        },
                    },
                },
            },
            get: {
                operationId: "getOrderDetailsGetV1",
                tags: ["Order v1"],
                summary: "Get Order Details",
                description: "Get orders details",
                parameters: [
                    {
                        $ref: "#/components/parameters/oktaAccessToken",
                    },
                    {
                        $ref: "#/components/parameters/storeIdInHeader",
                    },
                    {
                        $ref: "#/components/parameters/orderType",
                    },
                    {
                        $ref: "#/components/parameters/orderNumber",
                    },
                    {
                        $ref: "#/components/parameters/startFromDate",
                    },
                    {
                        $ref: "#/components/parameters/tillDate",
                    },
                    {
                        $ref: "#/components/parameters/limit",
                    },
                    {
                        $ref: "#/components/parameters/offset",
                    },
                ],
                responses: {
                    200: {
                        description: "OK",
                        content: {
                            "application/json": {
                                schema: {
                                    $ref: "#/components/schemas/GetOrderResponseDto",
                                },
                                example: {
                                    metadata: {
                                        __comment: "Info about the JSON File. ",
                                        itemKey: "orders",
                                        totalItems: 1,
                                    },
                                    orders: [
                                        {
                                            id: 460,
                                            sourceOrderId: "B2B.C0001.1671613402.208103",
                                            orderType: "ZOR",
                                            orderStatus: "",
                                            orderNumber: "ord_62a1afc8e5c35",
                                            erpOrderNumber: null,
                                            poNumber: null,
                                            ediNumber: null,
                                            invoiceNumber: null,
                                            orderLocationId: "123",
                                            orderDate: "2022-06-09",
                                            poDate: "",
                                            estimatedDeliveryDate: null,
                                            deliveryDate: null,
                                            erpShippingCondition: "S1",
                                            productDiscount: 0,
                                            promotionalDiscount: 0,
                                            totalTax: 5.25,
                                            subtotal: 165,
                                            totalPrice: 170.25,
                                            estimatedPoints: 65,
                                            deliveredPoints: 70,
                                            paymentTermCode: "1234",
                                            currency: "EUR",
                                            routeId: null,
                                            routeRepId: null,
                                            routeOrderTypeCode: null,
                                            routeOrderTypeSubCode: null,
                                            routeVisitSchedule: null,
                                            routeMarketplaceId: null,
                                            sourceSystemId: null,
                                            voucherCode: null,
                                            routeTypeName: {
                                                pt_BR: "Português",
                                                es_MX: "Español",
                                                es_CO: "Español",
                                            },
                                            items: [
                                                {
                                                    id: 12,
                                                    externalId: "",
                                                    name: {
                                                        en: "Test Product NAme",
                                                        es: "Test Product NAme Spanish",
                                                    },
                                                    imageURL: "",
                                                    quantity: 11,
                                                    quantityFulfilled: null,
                                                    fulfilledPrice: null,
                                                    acceptedQuantity: 0,
                                                    rejectedQuantity: 1,
                                                    baseUnit: "EA",
                                                    saleUnit: "Case",
                                                    converstionMultiplierToBase: 12,
                                                    basePrice: 1.5,
                                                    finalPrice: 12.5,
                                                    categoryId: null,
                                                    isPromotional: false,
                                                    isSubstituted: false,
                                                    substitutedById: null,
                                                    status: "committed",
                                                    gtInValue: null,
                                                    rejectedReason: null,
                                                    deliveryMethod: null,
                                                    digitalAddress: null,
                                                    type: "Digital",
                                                    counponInfo: [
                                                        {
                                                            code: "010206",
                                                            codeExpiredDate: "2023-12-30",
                                                            expired: false,
                                                        },
                                                    ],
                                                },
                                            ],
                                            shippingAddress: {
                                                externalId: null,
                                                customerName: null,
                                                email: null,
                                                phone: null,
                                                addressLine1: null,
                                                addressLine2: null,
                                                city: null,
                                                countryCode: null,
                                            },
                                            billingAddress: {
                                                externalId: null,
                                                customerName: null,
                                                email: null,
                                                phone: null,
                                                addressLine1: null,
                                                addressLine2: "",
                                                city: null,
                                                countryCode: null,
                                            },
                                            customerAddress: {
                                                externalId: null,
                                                customerName: null,
                                                email: null,
                                                phone: null,
                                                addressLine1: null,
                                                addressLine2: null,
                                                city: null,
                                                countryCode: null,
                                                IDNumber: "12345IDNum",
                                                DOB: "01-April-2023",
                                            },
                                            orderNote: null,
                                            rejectedNote: null,
                                        },
                                    ],
                                    rewardType: [
                                        "Cash",
                                        "Digital Pre-Order",
                                        "Product",
                                        "Digital",
                                        "Physical",
                                    ],
                                    rewardTypeCount: 5,
                                },
                            },
                        },
                    },
                    400: {
                        description: "Bad Request",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/ErrorMessage",
                                },
                            },
                        },
                    },
                    415: {
                        description: "Unsupported Media Type",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/ErrorMessage",
                                },
                            },
                        },
                    },
                    500: {
                        description: "Internal Server Error",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/Problem",
                                },
                            },
                        },
                    },
                },
            },
        },
        "/api/v2/order": {
            post: {
                operationId: "createOrderPostV2",
                tags: ["Order v2"],
                summary: "Create Order",
                description: "Create order",
                requestBody: {
                    content: {
                        "application/json": {
                            schema: {
                                $ref: "#/components/schemas/CreateOrderRequestDto",
                            },
                            examples: {
                                normalPost: {
                                    summary: "POST",
                                    value: {
                                        orderType: "ZOR | Rewards",
                                        vendorId: "42342342",
                                        poNumber: "PEP-PO12435",
                                        ediNumber: "",
                                        invoiceNumber: "",
                                        orderDate: "2017-01-01",
                                        poDate: "2017-01-01",
                                        estimatedDeliveryDate: "2017-01-01",
                                        estimatedPoints: 60,
                                        currency: "USD",
                                        subTotal: 4500,
                                        promotionDiscounts: 2.5,
                                        productDiscounts: 1,
                                        totalTax: 5.25,
                                        totalPrice: 4447.4,
                                        browserCart: true,
                                        split: "Internal",
                                        promotionCode: ["16"],
                                        rewardItems: [
                                            {
                                                orderNumber: "ord_66b60b5b38914",
                                                itemId: "850623"
                                            }
                                        ],
                                        cartItems: [
                                            {
                                                id: 315,
                                                externalId: 12456,
                                                categoryId: 3,
                                                isPromotional: false,
                                                deliveryMethod: "Digital | Shipment",
                                                digitalAddress:
                                                    "null | testing@test.com | 1-123-456-7890",
                                                saleUOM: "Case",
                                                baseUOM: "Case",
                                                conversionMulitplierToBase: 1,
                                                quantity: 3,
                                                basePrice: 2000,
                                                finalPrice: 1500,
                                            },
                                            {
                                                id: 509,
                                                externalId: 789456,
                                                categoryId: 3,
                                                isPromotional: false,
                                                deliveryMethod: "Digital | Shipment",
                                                digitalAddress:
                                                    "null | testing@test.com | 1-123-456-7890",
                                                saleUOM: "Case",
                                                baseUOM: "EA",
                                                conversionMulitplierToBase: 12,
                                                quantity: 3,
                                                basePrice: 3000,
                                                finalPrice: 1500,
                                            },
                                        ],
                                        soldTo: {
                                            name: "Golu",
                                            addressLine1: 35434534534,
                                            addressLine2: null,
                                            city: "dfdfdf",
                                            district: "Kolkata",
                                            state: "645reert",
                                            country: "IN",
                                            postalCode: "534534534",
                                            phoneNumber: 324324234,
                                            IDNumber: "12345IDNum",
                                            DOB: "01-April-2023",
                                        },
                                        shippingAddress: {
                                            externalId: "12345",
                                            customerName: "John Doe",
                                            email: "test@tester.com",
                                            phone: "1234567890",
                                            addressLine1: "123 Main St",
                                            addressLine2: "",
                                            city: "San Francisco",
                                            countryCode: "US",
                                        },
                                        billingAddress: {
                                            externalId: "12345",
                                            customerName: "John Doe",
                                            email: "test@tester.com",
                                            phone: "1234567890",
                                            addressLine1: "123 Main St",
                                            addressLine2: "",
                                            city: "San Francisco",
                                            countryCode: "US",
                                        },
                                        customerAddress: {
                                            externalId: "12345",
                                            customerName: "John Doe",
                                            email: "test@tester.com",
                                            phone: "1234567890",
                                            addressLine1: "123 Main St",
                                            addressLine2: "",
                                            city: "San Francisco",
                                            countryCode: "US",
                                        },
                                        orderNote: "Please ship this next week..",
                                    },
                                },
                                reservedOrderPost: {
                                    summary: "POST Request for Reserved Order",
                                    value: {
                                        orderType: "REWARDS",
                                        orderDate: "2022-12-12",
                                        isResereved: true,
                                        totalPoints: 1000,
                                        pointDeduction: "500",
                                        topUpPaymentAmount: "580.5",
                                        browserCart: true,
                                        vendorId: "42342342",
                                        cartItems: [
                                            {
                                                id: 17,
                                                externalId: "000000000300033532",
                                                categoryId: 3,
                                                isPromotional: false,
                                                deliveryMethod: "Digital | Shipment",
                                                digitalAddress: "1-123-456-7890",
                                                taxRate: 10,
                                                quantity: 1,
                                                pointsPerItem: 5,
                                                totalPoints: 5,
                                            },
                                            {
                                                id: 21,
                                                externalId: "000000000300033534",
                                                categoryId: 3,
                                                isPromotional: false,
                                                deliveryMethod: "Digital | Shipment",
                                                digitalAddress: "1-123-456-7890",
                                                taxRate: 15,
                                                quantity: 50,
                                                pointsPerItem: 20,
                                                totalPoints: 1000,
                                            },
                                        ],
                                        soldTo: {
                                            name: "Golu",
                                            addressLine1: 35434534534,
                                            addressLine2: null,
                                            city: "dfdfdf",
                                            district: "Kolkata",
                                            state: "645reert",
                                            country: "IN",
                                            postalCode: "534534534",
                                            phoneNumber: 324324234,
                                        },
                                        orderNote: "Shipment scheduled next week..",
                                    },
                                },
                            },
                        },
                    },
                },
                parameters: [
                    {
                        $ref: "#/components/parameters/oktaAccessToken",
                    },
                    {
                        $ref: "#/components/parameters/storeIdInHeader",
                    },
                    {
                        name: "siteId",
                        in: "header",
                        required: true,
                        description: "siteId",
                        schema: {
                            type: "string",
                            default: null,
                        },
                    },
                    {
                        $ref: "#/components/parameters/orderNumber",
                    },
                ],
                responses: {
                    200: {
                        description: "OK",
                        content: {
                            "application/json": {
                                schema: {
                                    $ref: "#/components/schemas/CreateOrderResponseDto",
                                },
                                examples: {
                                    success: {
                                        value: {
                                            Internal: [
                                                {
                                                    orderId: "5255136",
                                                    orderNumber: "ord_65fd2e778c783",
                                                    sourceOrderId: "B2B.5255136",
                                                    estimatedPoints: 171.27000000000004,
                                                    cartId: "43181",
                                                },
                                            ],
                                            External: [
                                                {
                                                    orderId: "5255137",
                                                    orderNumber: "ord_65fd2e77929c4",
                                                    sourceOrderId: "B2B.5255137",
                                                    estimatedPoints: 328.52,
                                                    cartId: "43182",
                                                },
                                            ],
                                        },
                                    },
                                    failure: {
                                        value: {
                                            success: false,
                                            msg: "Order needs at least one product",
                                        },
                                    },
                                    successForApex: {
                                        summary: "Success response for Apex POST to CEP",
                                        value: {
                                            success: true,
                                            message: "Order updated successfully.",
                                        },
                                    },
                                    errorForApex: {
                                        summary: "Error response for Apex POST to CEP",
                                        value: {
                                            success: false,
                                            message: "Error updating order. Try again",
                                        },
                                    },
                                },
                            },
                        },
                    },
                    400: {
                        description: "Bad Request",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/BasicResponseDto",
                                },
                            },
                        },
                    },
                    415: {
                        description: "Unsupported Media Type",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/ErrorMessage",
                                },
                            },
                        },
                    },
                    500: {
                        description: "Internal Server Error",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/Problem",
                                },
                            },
                        },
                    },
                },
            },
            get: {
                operationId: "getOrderDetailsGetV2",
                tags: ["Order v2"],
                summary: "Get Order Details",
                description: "Get orders details",
                parameters: [
                    {
                        $ref: "#/components/parameters/oktaAccessToken",
                    },
                    {
                        $ref: "#/components/parameters/storeIdInHeader",
                    },
                    {
                        $ref: "#/components/parameters/orderType",
                    },
                    {
                        $ref: "#/components/parameters/orderNumber",
                    },
                    {
                        $ref: "#/components/parameters/startFromDate",
                    },
                    {
                        $ref: "#/components/parameters/tillDate",
                    },
                    {
                        $ref: "#/components/parameters/limit",
                    },
                    {
                        $ref: "#/components/parameters/offset",
                    },
                ],
                responses: {
                    200: {
                        description: "OK",
                        content: {
                            "application/json": {
                                schema: {
                                    $ref: "#/components/schemas/GetOrderResponseDto",
                                },
                                example: {
                                    metadata: {
                                        __comment: "Info about the JSON File. ",
                                        itemKey: "orders",
                                        totalItems: 1,
                                    },
                                    orders: [
                                        {
                                            id: 460,
                                            sourceOrderId: "B2B.C0001.1671613402.208103",
                                            orderType: "ZOR",
                                            orderStatus: "",
                                            orderNumber: "ord_62a1afc8e5c35",
                                            customerPONumber: "1234567890",
                                            erpOrderNumber: null,
                                            ediNumber: null,
                                            invoiceNumber: null,
                                            orderLocationId: "123",
                                            orderDate: "2022-06-09",
                                            poDate: "",
                                            estimatedDeliveryDate: null,
                                            deliveryDate: null,
                                            erpShippingCondition: "S1",
                                            productDiscount: 0,
                                            promotionalDiscount: 0,
                                            totalTax: 5.25,
                                            subtotal: 165,
                                            totalPrice: 170.25,
                                            estimatedPoints: 65,
                                            deliveredPoints: 70,
                                            paymentTermCode: "1234",
                                            currency: "EUR",
                                            routeId: null,
                                            routeRepId: null,
                                            routeOrderTypeCode: null,
                                            routeOrderTypeSubCode: null,
                                            routeVisitSchedule: null,
                                            routeMarketplaceId: null,
                                            sourceSystemId: null,
                                            voucherCode: null,
                                            routeTypeName: {
                                                pt_BR: "Português",
                                                es_MX: "Español",
                                                es_CO: "Español",
                                            },
                                            items: [
                                                {
                                                    id: 12,
                                                    externalId: "",
                                                    name: {
                                                        en: "Test Product NAme",
                                                        es: "Test Product NAme Spanish",
                                                    },
                                                    imageURL: "",
                                                    quantity: 11,
                                                    quantityFulfilled: null,
                                                    fulfilledPrice: null,
                                                    acceptedQuantity: 0,
                                                    rejectedQuantity: 1,
                                                    baseUnit: "EA",
                                                    saleUnit: "Case",
                                                    converstionMultiplierToBase: 12,
                                                    basePrice: 1.5,
                                                    finalPrice: 12.5,
                                                    categoryId: null,
                                                    isPromotional: false,
                                                    isSubstituted: false,
                                                    substitutedById: null,
                                                    status: "committed",
                                                    gtInValue: null,
                                                    rejectedReason: null,
                                                    deliveryMethod: null,
                                                    digitalAddress: null,
                                                    type: "Digital",
                                                    counponInfo: [
                                                        {
                                                            code: "010206",
                                                            codeExpiredDate: "2023-12-30",
                                                            expired: false,
                                                        },
                                                    ],
                                                },
                                            ],
                                            shippingAddress: {
                                                externalId: null,
                                                customerName: null,
                                                email: null,
                                                phone: null,
                                                addressLine1: null,
                                                addressLine2: null,
                                                city: null,
                                                countryCode: null,
                                            },
                                            billingAddress: {
                                                externalId: null,
                                                customerName: null,
                                                email: null,
                                                phone: null,
                                                addressLine1: null,
                                                addressLine2: "",
                                                city: null,
                                                countryCode: null,
                                            },
                                            customerAddress: {
                                                externalId: null,
                                                customerName: null,
                                                email: null,
                                                phone: null,
                                                addressLine1: null,
                                                addressLine2: null,
                                                city: null,
                                                countryCode: null,
                                                IDNumber: "12345IDNum",
                                                DOB: "01-April-2023",
                                            },
                                            orderNote: null,
                                            rejectedNote: null,
                                        },
                                    ],
                                    rewardType: [
                                        "Cash",
                                        "Digital Pre-Order",
                                        "Product",
                                        "Digital",
                                        "Physical",
                                    ],
                                    rewardTypeCount: 5,
                                },
                            },
                        },
                    },
                    400: {
                        description: "Bad Request",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/ErrorMessage",
                                },
                            },
                        },
                    },
                    415: {
                        description: "Unsupported Media Type",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/ErrorMessage",
                                },
                            },
                        },
                    },
                    500: {
                        description: "Internal Server Error",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/Problem",
                                },
                            },
                        },
                    },
                },
            },
        },
        "/api/v3/order": {
            get: {
                operationId: "getOrderHistoryGetV3",
                tags: ["Order v3"],
                summary: "Get Order History",
                description:
                    "Get orders history list \n For ZOR Order it does not return order's item Detail,Order Detail History can be used for getting the Detail of the Order , For Rewards Order it works sames as Order V2 and we dont need to call the Order detail API",
                parameters: [
                    {
                        $ref: "#/components/parameters/oktaAccessToken",
                    },
                    {
                        $ref: "#/components/parameters/storeIdInHeader",
                    },
                    {
                        $ref: "#/components/parameters/orderType",
                    },
                    {
                        $ref: "#/components/parameters/orderNumber",
                    },
                    {
                        $ref: "#/components/parameters/startFromDate",
                    },
                    {
                        $ref: "#/components/parameters/tillDate",
                    },
                    {
                        $ref: "#/components/parameters/limit",
                    },
                    {
                        $ref: "#/components/parameters/offset",
                    },
                ],
                responses: {
                    200: {
                        description: "OK",
                        content: {
                            "application/json": {
                                schema: {
                                    $ref: "#/components/schemas/GetOrderResponseDtoV3",
                                },
                                examples: {
                                    ZOR: {
                                        value: {
                                            metadata: {
                                                __comment: "Info about the JSON File.",
                                                itemKey: "orders",
                                                totalItems: 354,
                                            },
                                            orders: [
                                                {
                                                    id: 15256113,
                                                    sourceOrderId: "B2B.1708523393.15256113",
                                                    orderType: "ZOR",
                                                    orderStatus: "In Progress",
                                                    orderNumber: "ord_65d5ff8129522",
                                                    poNUmber: "",
                                                    releaseFlag: "R",
                                                    erpOrderNumber: null,
                                                    poNumber: null,
                                                    ediNumber: null,
                                                    invoiceNumber: null,
                                                    orderLocationId: null,
                                                    orderDate: "2024-02-21",
                                                    orderDateTime: "2024-02-21T16:49:53.000Z",
                                                    estimatedDeliveryDate: "2024-02-21",
                                                    deliveryDate: null,
                                                    erpShippingCondition: null,
                                                    contractualDiscount: 0,
                                                    promotionalDiscount: 0,
                                                    totalDiscount: 0,
                                                    taxAmount: "1245.35",
                                                    subtotal: "8913.654",
                                                    totalPrice: "10159",
                                                    totalNetPrice: null,
                                                    estimatedPoints: 9999999999,
                                                    deliveredPoints: null,
                                                    paymentTermCode: null,
                                                    currency: "TRY",
                                                    routeId: null,
                                                    routeRepId: null,
                                                    routeOrderTypeCode: null,
                                                    routeOrderTypeSubCode: null,
                                                    routeVisitSchedule: null,
                                                    routeMarketplaceId: null,
                                                    sourceSystemId: null,
                                                    voucherCode: null,
                                                    itemCount: 45,
                                                    customerAddress: {
                                                        externalId: null,
                                                        customerName: "",
                                                        email: null,
                                                        phone: "5346606519",
                                                        addressLine1: "",
                                                        addressLine2: "MUSTAFA KEMAL MH. 284/5. SK. NO: 43",
                                                        city: "İZMİR",
                                                        state: "",
                                                        postalCode: "35380",
                                                        district: "BUCA",
                                                        countryCode: "TURKEY",
                                                        IDNumber: "",
                                                        DOB: "",
                                                    },
                                                    orderNote: "",
                                                    rejectedNote: "",
                                                    invoiceDownload: null,
                                                    categoryType: "Beverage",
                                                },
                                                {
                                                    id: 15256112,
                                                    sourceOrderId: "B2B.1708523391.15256112",
                                                    orderType: "ZOR",
                                                    orderStatus: "In Progress",
                                                    orderNumber: "ord_65d5ff7f2f9ba",
                                                    poNUmber: "",
                                                    releaseFlag: "R",
                                                    erpOrderNumber: null,
                                                    poNumber: null,
                                                    ediNumber: null,
                                                    invoiceNumber: null,
                                                    orderLocationId: null,
                                                    orderDate: "2024-02-21",
                                                    orderDateTime: "2024-02-21T16:49:51.000Z",
                                                    estimatedDeliveryDate: "2024-02-21",
                                                    deliveryDate: null,
                                                    erpShippingCondition: null,
                                                    contractualDiscount: 0,
                                                    promotionalDiscount: 0,
                                                    totalDiscount: 0,
                                                    taxAmount: "1245.35",
                                                    subtotal: "8913.654",
                                                    totalPrice: "10159",
                                                    totalNetPrice: null,
                                                    estimatedPoints: 24066.8658,
                                                    deliveredPoints: null,
                                                    paymentTermCode: null,
                                                    currency: "TRY",
                                                    routeId: null,
                                                    routeRepId: null,
                                                    routeOrderTypeCode: null,
                                                    routeOrderTypeSubCode: null,
                                                    routeVisitSchedule: null,
                                                    routeMarketplaceId: null,
                                                    sourceSystemId: null,
                                                    voucherCode: null,
                                                    itemCount: 45,
                                                    customerAddress: {
                                                        externalId: null,
                                                        customerName: "",
                                                        email: null,
                                                        phone: "5346606519",
                                                        addressLine1: "",
                                                        addressLine2: "MUSTAFA KEMAL MH. 284/5. SK. NO: 43",
                                                        city: "İZMİR",
                                                        state: "",
                                                        postalCode: "35380",
                                                        district: "BUCA",
                                                        countryCode: "TURKEY",
                                                        IDNumber: "",
                                                        DOB: "",
                                                    },
                                                    orderNote: "",
                                                    rejectedNote: "",
                                                    invoiceDownload: null,
                                                    categoryType: "Beverage",
                                                },
                                            ],
                                        },
                                    },
                                    REWARDS: {
                                        value: {
                                            metadata: {
                                                __comment: "Info about the JSON File.",
                                                itemKey: "orders",
                                                totalItems: 2309,
                                            },
                                            orders: [
                                                {
                                                    id: 15274045,
                                                    sourceOrderId: "B2B.1708938563.15274045",
                                                    orderType: "REWARDS",
                                                    orderStatus: "Delivered",
                                                    orderNumber: "ord_65dc5543c54a7",
                                                    poNUmber: "",
                                                    releaseFlag: "R",
                                                    erpOrderNumber: null,
                                                    poNumber: null,
                                                    ediNumber: null,
                                                    invoiceNumber: null,
                                                    orderLocationId: null,
                                                    orderDate: "2024-02-26",
                                                    orderDateTime: "2024-02-26T12:09:23.000Z",
                                                    estimatedDeliveryDate: null,
                                                    deliveryDate: null,
                                                    erpShippingCondition: null,
                                                    contractualDiscount: 0,
                                                    promotionalDiscount: 0,
                                                    totalDiscount: 0,
                                                    taxAmount: "0",
                                                    subtotal: "0",
                                                    totalPrice: "0",
                                                    totalNetPrice: null,
                                                    estimatedPoints: 0,
                                                    deliveredPoints: null,
                                                    paymentTermCode: null,
                                                    currency: "TRY",
                                                    routeId: null,
                                                    routeRepId: null,
                                                    routeOrderTypeCode: null,
                                                    routeOrderTypeSubCode: null,
                                                    routeVisitSchedule: null,
                                                    routeMarketplaceId: null,
                                                    sourceSystemId: null,
                                                    voucherCode: null,
                                                    itemCount: 1,
                                                    items: [
                                                        {
                                                            id: 5921802,
                                                            externalId: "",
                                                            name: {
                                                                en: "10 GB Değerinde Hediye Çeki",
                                                                tr: "10 GB Değerinde Hediye Çeki",
                                                            },
                                                            size: {
                                                                en: null,
                                                                tr: null,
                                                            },
                                                            imageURL:
                                                                "https://360drcmarketing.s3.eu-central-1.amazonaws.com/reward-images/DKPN18103.jpg",
                                                            points: 13750,
                                                            rewardType: "Digital Coupon",
                                                            quantity: 1,
                                                            quantityFulfilled: null,
                                                            unitType: null,
                                                            packageSize: null,
                                                            unitPrice: 0,
                                                            subTotal: 0,
                                                            tax: 0,
                                                            fulfilledPrice: null,
                                                            acceptedQuantity: null,
                                                            rejectedQuantity: null,
                                                            baseUnit: "",
                                                            saleUnit: "",
                                                            conversionMultiplierToBase: "",
                                                            basePrice: "0",
                                                            finalPrice: "0",
                                                            finalNetPrice: null,
                                                            category: null,
                                                            categoryId: null,
                                                            isPromotional: false,
                                                            isSubstituted: false,
                                                            substitutedById: null,
                                                            status: null,
                                                            gtInValue: null,
                                                            rejectedReason: null,
                                                            deliveryMethod: "Shipment",
                                                            digitalAddress: "",
                                                            type: "Digital Coupon",
                                                            counponInfo: [
                                                                {
                                                                    code: "DummyCodeTestQA741",
                                                                    ReferenceNo: "DKPN18103PP",
                                                                    Url: null,
                                                                    codeExpiredDate: "",
                                                                    expired: true,
                                                                },
                                                            ],
                                                            removeStatus: false,
                                                            additionalItemStatus: false,
                                                            isReturn: false,
                                                        },
                                                    ],
                                                    customerAddress: {
                                                        externalId: null,
                                                        customerName: "Golu Tulshyan",
                                                        email: null,
                                                        phone: "324324234",
                                                        addressLine1: "ABC",
                                                        addressLine2: "",
                                                        city: "dfdfdf",
                                                        state: "ewrwer",
                                                        postalCode: "534534534",
                                                        district: "Kolkata",
                                                        countryCode: "IN",
                                                        IDNumber: "",
                                                        DOB: "",
                                                    },
                                                    orderNote: "",
                                                    rejectedNote: "",
                                                    invoiceDownload: null,
                                                    categoryType: "",
                                                },
                                                {
                                                    id: 15273925,
                                                    sourceOrderId: "B2B.1708680977.15273925",
                                                    orderType: "REWARDS",
                                                    orderStatus: "Delivered",
                                                    orderNumber: "ord_65d86711664ef",
                                                    poNUmber: "",
                                                    releaseFlag: "O",
                                                    erpOrderNumber: null,
                                                    poNumber: null,
                                                    ediNumber: null,
                                                    invoiceNumber: null,
                                                    orderLocationId: null,
                                                    orderDate: "2024-02-23",
                                                    orderDateTime: "2024-02-23T12:36:17.000Z",
                                                    estimatedDeliveryDate: null,
                                                    deliveryDate: null,
                                                    erpShippingCondition: null,
                                                    contractualDiscount: 0,
                                                    promotionalDiscount: 0,
                                                    totalDiscount: 0,
                                                    taxAmount: "0",
                                                    subtotal: "0",
                                                    totalPrice: "0",
                                                    totalNetPrice: null,
                                                    estimatedPoints: 0,
                                                    deliveredPoints: null,
                                                    paymentTermCode: null,
                                                    currency: "TRY",
                                                    routeId: null,
                                                    routeRepId: null,
                                                    routeOrderTypeCode: null,
                                                    routeOrderTypeSubCode: null,
                                                    routeVisitSchedule: null,
                                                    routeMarketplaceId: null,
                                                    sourceSystemId: null,
                                                    voucherCode: null,
                                                    itemCount: 1,
                                                    items: [
                                                        {
                                                            id: null,
                                                            externalId: null,
                                                            name: null,
                                                            size: [],
                                                            imageURL: null,
                                                            points: null,
                                                            rewardType: null,
                                                            quantity: 1,
                                                            quantityFulfilled: null,
                                                            unitType: null,
                                                            packageSize: null,
                                                            unitPrice: 0,
                                                            subTotal: 0,
                                                            tax: 0,
                                                            fulfilledPrice: null,
                                                            acceptedQuantity: null,
                                                            rejectedQuantity: null,
                                                            baseUnit: "",
                                                            saleUnit: "",
                                                            conversionMultiplierToBase: "",
                                                            basePrice: "0",
                                                            finalPrice: "0",
                                                            finalNetPrice: null,
                                                            category: null,
                                                            categoryId: null,
                                                            isPromotional: null,
                                                            isSubstituted: false,
                                                            substitutedById: null,
                                                            status: null,
                                                            gtInValue: null,
                                                            rejectedReason: null,
                                                            deliveryMethod: "Shipment",
                                                            digitalAddress: "",
                                                            type: "Loyalty",
                                                            counponInfo: [
                                                                {
                                                                    code: "ABCD-GGGG-5551",
                                                                    ReferenceNo: "ABCD-GGGG-5551",
                                                                    Url: "",
                                                                    codeExpiredDate: "2024-12-06",
                                                                    expired: false,
                                                                },
                                                            ],
                                                            removeStatus: false,
                                                            additionalItemStatus: false,
                                                            isReturn: false,
                                                        },
                                                    ],
                                                    customerAddress: {
                                                        externalId: null,
                                                        customerName: "",
                                                        email: null,
                                                        phone: "5346606519",
                                                        addressLine1: "",
                                                        addressLine2: "MUSTAFA KEMAL MH. 284/5. SK. NO: 43",
                                                        city: "İZMİR",
                                                        state: "",
                                                        postalCode: "35380",
                                                        district: "",
                                                        countryCode: "TURKEY",
                                                        IDNumber: "",
                                                        DOB: "",
                                                    },
                                                    orderNote: "",
                                                    rejectedNote: "",
                                                    invoiceDownload: null,
                                                    categoryType: "",
                                                },
                                            ],
                                            rewardType: [
                                                "Digital Coupon",
                                                "Pep Voucher",
                                                "Digital",
                                                "Loyalty",
                                            ],
                                            rewardTypeCount: 4,
                                        },
                                    },
                                },
                            },
                        },
                    },
                    400: {
                        description: "Bad Request",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/ErrorMessage",
                                },
                            },
                        },
                    },
                    415: {
                        description: "Unsupported Media Type",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/ErrorMessage",
                                },
                            },
                        },
                    },
                    500: {
                        description: "Internal Server Error",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/Problem",
                                },
                            },
                        },
                    },
                },
            },
        },
        "/api/v3/order/{id}": {
            get: {
                operationId: "getOrderDetailsGetV3",
                tags: ["Order v3"],
                summary: "Get Order Detail",
                description:
                    "Get Order Detail , it will also include the Order's Item detail",
                parameters: [
                    {
                        $ref: "#/components/parameters/oktaAccessToken",
                    },
                    {
                        $ref: "#/components/parameters/storeIdInHeader",
                    },
                    {
                        name: "id",
                        in: "path",
                        required: true,
                        description: "Order Id",
                        schema: {
                            type: "number",
                            default: "",
                        },
                    },
                ],
                responses: {
                    200: {
                        description: "OK",
                        content: {
                            "application/json": {
                                schema: {
                                    $ref: "#/components/schemas/GetOrderResponseDtoV3",
                                },
                                example: {
                                    metadata: {
                                        __comment: "Info about the JSON File.",
                                        itemKey: "orders",
                                        totalItems: 1,
                                    },
                                    orders: {
                                        id: 15256113,
                                        sourceOrderId: "B2B.1708523393.15256113",
                                        orderType: "ZOR",
                                        orderStatus: "In Progress",
                                        orderNumber: "ord_65d5ff8129522",
                                        poNUmber: "",
                                        releaseFlag: "R",
                                        erpOrderNumber: null,
                                        poNumber: null,
                                        ediNumber: null,
                                        invoiceNumber: null,
                                        orderLocationId: null,
                                        orderDate: "2024-02-21",
                                        orderDateTime: "2024-02-21T16:49:53.000Z",
                                        estimatedDeliveryDate: "2024-02-21",
                                        deliveryDate: null,
                                        erpShippingCondition: null,
                                        contractualDiscount: 0,
                                        promotionalDiscount: 0,
                                        totalDiscount: 0,
                                        taxAmount: "1245.35",
                                        subtotal: "8913.654",
                                        totalPrice: "10159",
                                        totalNetPrice: null,
                                        estimatedPoints: 9999999999,
                                        deliveredPoints: null,
                                        paymentTermCode: null,
                                        currency: "TRY",
                                        routeId: null,
                                        routeRepId: null,
                                        routeOrderTypeCode: null,
                                        routeOrderTypeSubCode: null,
                                        routeVisitSchedule: null,
                                        routeMarketplaceId: null,
                                        sourceSystemId: null,
                                        voucherCode: null,
                                        itemCount: 45,
                                        items: [
                                            {
                                                id: 437461,
                                                externalId: "38167",
                                                name: {
                                                    en: "Fruko Pet 2.5 L - 11098",
                                                    tr: "Fruko Pet 2.5 L - 11098",
                                                },
                                                size: {
                                                    en: null,
                                                    tr: null,
                                                },
                                                imageURL:
                                                    "https://admin-fd.cep.qa.tr.pepsico.com/Images/Product/38167.png",
                                                points: null,
                                                rewardType: null,
                                                quantity: 22,
                                                quantityFulfilled: null,
                                                unitType: null,
                                                packageSize: null,
                                                unitPrice: 236.58,
                                                subTotal: 5204.76,
                                                tax: 520.476,
                                                fulfilledPrice: null,
                                                acceptedQuantity: null,
                                                rejectedQuantity: null,
                                                baseUnit: "",
                                                saleUnit: "",
                                                conversionMultiplierToBase: "",
                                                basePrice: "236.58",
                                                finalPrice: "5725.236",
                                                finalNetPrice: null,
                                                category: "BEVERAGE",
                                                categoryId: "CMPGN-9142",
                                                isPromotional: false,
                                                isSubstituted: false,
                                                substitutedById: null,
                                                status: null,
                                                gtInValue: null,
                                                rejectedReason: null,
                                                deliveryMethod: "",
                                                digitalAddress: "",
                                                type: "",
                                                counponInfo: [],
                                                removeStatus: false,
                                                additionalItemStatus: false,
                                                isReturn: false,
                                            },
                                            {
                                                id: 530914,
                                                externalId: "821",
                                                name: {
                                                    en: "Yedigün Portakal Pet 2.5 L - 234",
                                                    tr: "Yedigün Portakal Pet 2.5 L - 234",
                                                },
                                                size: {
                                                    en: null,
                                                    tr: null,
                                                },
                                                imageURL:
                                                    "https://admin-fd.cep.qa.tr.pepsico.com/Images/Product/821.png",
                                                points: null,
                                                rewardType: null,
                                                quantity: 22,
                                                quantityFulfilled: null,
                                                unitType: null,
                                                packageSize: null,
                                                unitPrice: 160.90200000000002,
                                                subTotal: 3539.844,
                                                tax: 707.9688,
                                                fulfilledPrice: null,
                                                acceptedQuantity: null,
                                                rejectedQuantity: null,
                                                baseUnit: "",
                                                saleUnit: "",
                                                conversionMultiplierToBase: "",
                                                basePrice: "229.86",
                                                finalPrice: "4247.8128",
                                                finalNetPrice: null,
                                                category: "BEVERAGE",
                                                categoryId: "CMPGN-4632",
                                                isPromotional: false,
                                                isSubstituted: false,
                                                substitutedById: null,
                                                status: null,
                                                gtInValue: null,
                                                rejectedReason: null,
                                                deliveryMethod: "",
                                                digitalAddress: "",
                                                type: "",
                                                counponInfo: [],
                                                removeStatus: false,
                                                additionalItemStatus: false,
                                                isReturn: false,
                                            },
                                            {
                                                id: 605323,
                                                externalId: "38718",
                                                name: {
                                                    en: "Pepsi Pet 2.5 L - 11134",
                                                    tr: "Pepsi Pet 2.5 L - 11134",
                                                },
                                                size: {
                                                    en: null,
                                                    tr: null,
                                                },
                                                imageURL:
                                                    "https://admin-fd.cep.qa.tr.pepsico.com/Images/Product/38718.png",
                                                points: null,
                                                rewardType: null,
                                                quantity: 1,
                                                quantityFulfilled: null,
                                                unitType: null,
                                                packageSize: null,
                                                unitPrice: 169.05,
                                                subTotal: 169.05,
                                                tax: 16.905,
                                                fulfilledPrice: null,
                                                acceptedQuantity: null,
                                                rejectedQuantity: null,
                                                baseUnit: "",
                                                saleUnit: "",
                                                conversionMultiplierToBase: "",
                                                basePrice: "241.5",
                                                finalPrice: "185.955",
                                                finalNetPrice: null,
                                                category: "BEVERAGE",
                                                categoryId: "CMPGN-9142",
                                                isPromotional: false,
                                                isSubstituted: false,
                                                substitutedById: null,
                                                status: null,
                                                gtInValue: null,
                                                rejectedReason: null,
                                                deliveryMethod: "",
                                                digitalAddress: "",
                                                type: "",
                                                counponInfo: [],
                                                removeStatus: false,
                                                additionalItemStatus: false,
                                                isReturn: false,
                                            },
                                        ],
                                        customerAddress: {
                                            externalId: null,
                                            customerName: "",
                                            email: null,
                                            phone: "5346606519",
                                            addressLine1: "",
                                            addressLine2: "MUSTAFA KEMAL MH. 284/5. SK. NO: 43",
                                            city: "İZMİR",
                                            state: "",
                                            postalCode: "35380",
                                            district: "BUCA",
                                            countryCode: "TURKEY",
                                            IDNumber: "",
                                            DOB: "",
                                        },
                                        orderNote: "",
                                        rejectedNote: "",
                                        invoiceDownload: null,
                                        categoryType: "Beverage",
                                    },
                                },
                            },
                        },
                    },
                    400: {
                        description: "Bad Request",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/ErrorMessage",
                                },
                            },
                        },
                    },
                    415: {
                        description: "Unsupported Media Type",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/ErrorMessage",
                                },
                            },
                        },
                    },
                    500: {
                        description: "Internal Server Error",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/Problem",
                                },
                            },
                        },
                    },
                },
            },
        },
        "/api/v4/order": {
            post: {
                operationId: "createOrderPostV4",
                tags: ["Order v4"],
                summary: "Create Order",
                description: "Create order",
                requestBody: {
                    content: {
                        "application/json": {
                            schema: {
                                $ref: "#/components/schemas/CreateOrderRequestDtoV4",
                            },
                            examples: {
                                normalPost: {
                                    summary: "POST",
                                    value: {
                                        orderType: "ZOR",
                                        orderDate: "2017-01-01",
                                        sourceOrderId: "B2B.DO9998.1705975647.157",
                                        customerPONumber: "1234567890",
                                        subTotal: 4500,
                                        productDiscounts: 1,
                                        totalTax: 5.25,
                                        totalPrice: 4447.4,
                                        billingAddress: {
                                            city: "San Francisco",
                                            countryCode: "US",
                                            customerName: "John Doe",
                                            email: "test@tester.com",
                                            phone: "1234567890",
                                        },
                                        browserCart: true,
                                        cartItems: [
                                            {
                                                id: 315,
                                                externalId: 12456,
                                                categoryId: 3,
                                                sourceOrderLineId: 857,
                                                isPromotional: false,
                                                saleUoms: {
                                                    saleUomsName: "each",
                                                    saleUomsCode: "EA",
                                                },
                                                conversionMulitplierToBase: 1,
                                                quantity: 3,
                                                basePrice: 2000,
                                                finalPrice: 1500,
                                                subTotal: 9.206928,
                                                tax: 0.736632,
                                                discountedPrice: 0,
                                                depositPrice: 0,
                                                isReturnItem: false,
                                            },
                                            {
                                                id: 509,
                                                externalId: 789456,
                                                categoryId: 3,
                                                sourceOrderLineId: 858,
                                                isPromotional: false,
                                                saleUoms: {
                                                    saleUomsName: "each",
                                                    saleUomsCode: "EA",
                                                },
                                                conversionMulitplierToBase: 12,
                                                quantity: 3,
                                                basePrice: 3000,
                                                finalPrice: 1500,
                                                subTotal: 9.206928,
                                                tax: 0.736632,
                                                discountedPrice: 0,
                                                depositPrice: 0,
                                                isReturnItem: false,
                                            },
                                        ],
                                        estimatedDeliveryDate: "2023-11-15",
                                        split: "Internal",
                                        promotionCode: ["16"],
                                        estimatedPoints: 1.2844000000000002,
                                        useNetAmount: true,
                                        status:
                                            "In Progress | Delivered | Cancelled | In-Transit | Rejected | Partially Accepted | Not Created | Test Order | Reserved Order",
                                    },
                                },
                            },
                        },
                    },
                },
                parameters: [
                    {
                        $ref: "#/components/parameters/oktaAccessToken",
                    },
                    {
                        $ref: "#/components/parameters/storeIdInHeader",
                    },
                    {
                        name: "siteId",
                        in: "header",
                        required: true,
                        description: "siteId",
                        schema: {
                            type: "string",
                            default: null,
                        },
                    },
                ],
                responses: {
                    200: {
                        description: "OK",
                        content: {
                            "application/json": {
                                schema: {
                                    $ref: "#/components/schemas/CreateOrderResponseDto",
                                },
                                examples: {
                                    success: {
                                        value: {
                                            Internal: {
                                                orderId: "208794",
                                                orderNumber: "ord_641080d98eb7f",
                                                sourceOrderId: "B2B.C0001.1678803161.208794",
                                                estimatedPoints: 30,
                                            },
                                        },
                                    },
                                    failure: {
                                        value: {
                                            success: false,
                                            msg: "Order needs at least one product",
                                        },
                                    },
                                    successForApex: {
                                        summary: "Success response for Apex POST to CEP",
                                        value: {
                                            success: true,
                                            message: "Order updated successfully.",
                                        },
                                    },
                                    errorForApex: {
                                        summary: "Error response for Apex POST to CEP",
                                        value: {
                                            success: false,
                                            message: "Error updating order. Try again",
                                        },
                                    },
                                },
                            },
                        },
                    },
                    400: {
                        description: "Bad Request",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/ErrorMessage",
                                },
                            },
                        },
                    },
                    415: {
                        description: "Unsupported Media Type",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/ErrorMessage",
                                },
                            },
                        },
                    },
                    500: {
                        description: "Internal Server Error",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/Problem",
                                },
                            },
                        },
                    },
                },
            },
        },
        "/api/v1/order/update": {
            post: {
                operationId: "updateOrderPaymentFromApexPostV1",
                tags: ["Order v1"],
                summary: "Top-Up Payment (Order Payment Update From Apex)",
                description: "Top-Up Payment fields updated",
                requestBody: {
                    content: {
                        "application/json": {
                            schema: {
                                type: "object",
                            },
                            examples: {
                                normalPost: {
                                    summary: "POST",
                                    value: {
                                        orderId: "ord_644f8c49048a9",
                                        paymentTransactionId:
                                            "bf5a5761-5d8b-2dc2-63e2-9de56a455674",
                                        sfOrderId: "a2K7Y000000djv8UAA",
                                        PEPOrderId: "order1",
                                        status: "Success",
                                        orderItems: [
                                            {
                                                rewardId: "123",
                                                digitalCodes: ["code1"],
                                            },
                                            {
                                                rewardId: "18",
                                                digitalCodes: ["code6", "code7"],
                                            },
                                        ],
                                        errors: null,
                                    },
                                },
                            },
                        },
                    },
                },
                parameters: [
                    {
                        $ref: "#/components/parameters/siteId",
                    },
                    {
                        $ref: "#/components/parameters/xApiKey",
                    },
                ],
                responses: {
                    200: {
                        description: "OK",
                        content: {
                            "application/json": {
                                examples: {
                                    success: {
                                        value: {
                                            success: true,
                                            msg: "Order updated successfully",
                                        },
                                    },
                                    failure: {
                                        value: {
                                            success: false,
                                            msg: "Order Number not exist | Please provide order number",
                                        },
                                    },
                                },
                            },
                        },
                    },
                    400: {
                        description: "Bad Request",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/ErrorMessage",
                                },
                            },
                        },
                    },
                    415: {
                        description: "Unsupported Media Type",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/ErrorMessage",
                                },
                            },
                        },
                    },
                    500: {
                        description: "Internal Server Error",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/Problem",
                                },
                            },
                        },
                    },
                },
            },
        },
        "/api/v1/order/deliveryEstimate": {
            get: {
                operationId: "getDeliveryEstimateGetV1",
                tags: ["Order v1"],
                summary: "Get Delivery Estimate",
                description: "Get Delivery Estimate",
                parameters: [
                    {
                        $ref: "#/components/parameters/oktaAccessToken",
                    },
                    {
                        $ref: "#/components/parameters/storeIdInQuery",
                    },
                ],
                responses: {
                    200: {
                        description: "OK",
                        content: {
                            "application/json": {
                                schema: {
                                    $ref: "#/components/schemas/GetDeliveryEstimateResponseDto",
                                },
                                example: {
                                    deliveryDay: "Saturday",
                                    deliveryDate: "2022-07-02",
                                },
                            },
                        },
                    },
                    400: {
                        description: "Bad Request",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/ErrorMessage",
                                },
                            },
                        },
                    },
                    415: {
                        description: "Unsupported Media Type",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/ErrorMessage",
                                },
                            },
                        },
                    },
                    500: {
                        description: "Internal Server Error",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/Problem",
                                },
                            },
                        },
                    },
                },
            },
        },
        "/api/v1/order/rewardsEstimatedPoints": {
            get: {
                operationId: "getRewardsEstimatedPointsGetV1",
                tags: ["Order v1"],
                summary: "Get Estimated rewards point for order",
                description: "Get Estimated rewards point for order",
                parameters: [
                    {
                        $ref: "#/components/parameters/oktaAccessToken",
                    },
                    {
                        $ref: "#/components/parameters/storeIdInHeader",
                    },
                    {
                        name: "totalPrice",
                        in: "query",
                        required: true,
                        description: "totalPrice",
                        schema: {
                            type: "string",
                            default: "5119.618",
                        },
                    },
                ],
                responses: {
                    200: {
                        description: "OK",
                        content: {
                            "application/json": {
                                schema: {
                                    $ref: "#/components/schemas/GetRewardsEstimatedPointsResponseDto",
                                },
                                example: {
                                    success: true,
                                    pointsEarned: 0.854873,
                                },
                            },
                        },
                    },
                    400: {
                        description: "Bad Request",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/ErrorMessage",
                                },
                            },
                        },
                    },
                    415: {
                        description: "Unsupported Media Type",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/ErrorMessage",
                                },
                            },
                        },
                    },
                    500: {
                        description: "Internal Server Error",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/Problem",
                                },
                            },
                        },
                    },
                },
            },
        },
        "/api/v1/order/process": {
            post: {
                operationId: "sendOrderAcknowledgementToCEPPostV1",
                tags: ["Order v1"],
                summary: "Update AcknowledgeType Information",
                description: "Update AcknowledgeType Information",
                parameters: [
                    {
                        $ref: "#/components/parameters/oktaAccessToken",
                    },
                    {
                        $ref: "#/components/parameters/siteId",
                    },
                ],
                requestBody: {
                    content: {
                        "application/json": {
                            schema: {
                                type: "string",
                            },
                            example: {
                                sourceSystemId: "995",
                                sourceAcknowledgementId: "SAP_PO_Unique_Id",
                                acknowledgementType: "NEW / CANCEL / ASN",
                                createdDateTime: "2021-04-19T11:47:44Z",
                                acknowledgedOrder: {
                                    sourceOrderId: "W.mx9606.20220412111900.7MP2S1MI",
                                    alternateLocationId: 1002,
                                    alternateOrderId: 1003319619,
                                    marketPlaceOrderId: "7MP2S1MI",
                                    sourceSystemId: "995",
                                    items: [
                                        {
                                            sourceOrderLineId: "001",
                                            alternateOrderLineNumber: "000100",
                                            materialId: "012000206573",
                                            requestedQty: 1,
                                            confirmedQty: 1,
                                        },
                                    ],
                                },
                            },
                        },
                    },
                },
                responses: {
                    200: {
                        description: "OK",
                        content: {
                            "application/json": {
                                example: {
                                    success: true,
                                    msg: "Order Information Update for the basis of acknowledge type ASN",
                                },
                            },
                        },
                    },
                    400: {
                        description: "Bad Request",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/ErrorMessage",
                                },
                            },
                        },
                    },
                    415: {
                        description: "Unsupported Media Type",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/ErrorMessage",
                                },
                            },
                        },
                    },
                    500: {
                        description: "Internal Server Error",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/Problem",
                                },
                            },
                        },
                    },
                },
            },
        },
        "/api/v2/order/deliveryEstimate": {
            get: {
                operationId: "getDeliveryEstimateGetV2",
                tags: ["Order v2"],
                summary: "Get Delivery Estimate",
                description: "Get Delivery Estimate",
                parameters: [
                    {
                        $ref: "#/components/parameters/oktaAccessToken",
                    },
                    {
                        $ref: "#/components/parameters/storeIdInQuery",
                    },
                ],
                responses: {
                    200: {
                        description: "OK",
                        content: {
                            "application/json": {
                                schema: {
                                    $ref: "#/components/schemas/GetDeliveryEstimateResponseDtoV2",
                                },
                                example: {
                                    internal: {
                                        deliveryDay: "Monday",
                                        deliveryDate: "2024-03-25",
                                        timeZone: "Europe/Madrid",
                                        deliveryDays: [
                                            "Wednesday",
                                            "Tuesday",
                                            "Monday",
                                            "Sunday",
                                            "Saturday",
                                        ],
                                        deliveryService: "48",
                                        cutoffTime: "16:00",
                                    },
                                    external: {
                                        deliveryDay: "Wednesday",
                                        deliveryDate: "2024-03-27",
                                        timeZone: "Europe/Madrid",
                                        deliveryDays: [
                                            "Tuesday",
                                            "Wednesday",
                                            "Thursday",
                                            "Friday",
                                            "Saturday",
                                        ],
                                        deliveryService: "72",
                                        cutoffTime: "16:00",
                                    },
                                },
                            },
                        },
                    },
                    400: {
                        description: "Bad Request",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/ErrorMessage",
                                },
                            },
                        },
                    },
                    415: {
                        description: "Unsupported Media Type",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/ErrorMessage",
                                },
                            },
                        },
                    },
                    500: {
                        description: "Internal Server Error",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/Problem",
                                },
                            },
                        },
                    },
                },
            },
        },
        "/api/v2/order/delivery-dates": {
            get: {
                operationId: "getDeliveryDates",
                tags: ["Order v2"],
                summary: "Get Delivery Dates",
                description: "Get Delivery Dates considering lack, term, non-working days of the week, year holidays and business days term",
                parameters: [
                    {
                        $ref: "#/components/parameters/oktaAccessToken",
                    },
                    {
                        $ref: "#/components/parameters/xApiKey"
                    },
                    {
                        $ref: "#/components/parameters/siteId"
                    }
                ],
                responses: {
                    200: {
                        description: "OK",
                        content: {
                            "application/json": {
                                schema: {
                                    $ref: "#/components/schemas/GetDeliveryDates",
                                },
                                example: {
                                    deliveryDates: [
                                        {
                                            date: "10-18-2024"
                                        },
                                        {
                                            date: "10-21-2024"
                                        },
                                        {
                                            date: "10-22-2024"
                                        },
                                        {
                                            date: "10-23-2024"
                                        },
                                        {
                                            date: "10-24-2024"
                                        },
                                        {
                                            date: "10-25-2024"
                                        },
                                        {
                                            date: "10-28-2024"
                                        },
                                        {
                                            date: "10-29-2024"
                                        },
                                        {
                                            date: "10-31-2024"
                                        },
                                        {
                                            date: "11-01-2024"
                                        },
                                        {
                                            date: "11-04-2024"
                                        },
                                        {
                                            date: "11-05-2024"
                                        },
                                        {
                                            date: "11-06-2024"
                                        },
                                        {
                                            date: "11-07-2024"
                                        },
                                        {
                                            date: "11-08-2024"
                                        },
                                        {
                                            date: "11-11-2024"
                                        },
                                        {
                                            date: "11-12-2024"
                                        },
                                        {
                                            date: "11-13-2024"
                                        },
                                        {
                                            date: "11-14-2024"
                                        },
                                        {
                                            date: "11-15-2024"
                                        },
                                        {
                                            date: "11-18-2024"
                                        },
                                        {
                                            date: "11-19-2024"
                                        },
                                        {
                                            date: "11-20-2024"
                                        },
                                        {
                                            date: "11-21-2024"
                                        },
                                        {
                                            date: "11-22-2024"
                                        },
                                        {
                                            date: "11-25-2024"
                                        },
                                        {
                                            date: "11-26-2024"
                                        },
                                        {
                                            date: "11-27-2024"
                                        },
                                        {
                                            date: "11-28-2024"
                                        },
                                        {
                                            date: "11-29-2024"
                                        },
                                        {
                                            date: "12-02-2024"
                                        },
                                        {
                                            date: "12-03-2024"
                                        },
                                        {
                                            date: "12-04-2024"
                                        },
                                        {
                                            date: "12-05-2024"
                                        },
                                        {
                                            date: "12-06-2024"
                                        },
                                        {
                                            date: "12-09-2024"
                                        },
                                        {
                                            date: "12-10-2024"
                                        },
                                        {
                                            date: "12-11-2024"
                                        },
                                        {
                                            date: "12-12-2024"
                                        },
                                        {
                                            date: "12-13-2024"
                                        },
                                        {
                                            date: "12-16-2024"
                                        },
                                        {
                                            date: "12-17-2024"
                                        },
                                        {
                                            date: "12-18-2024"
                                        },
                                        {
                                            date: "12-19-2024"
                                        },
                                        {
                                            date: "12-20-2024"
                                        },
                                        {
                                            date: "12-23-2024"
                                        },
                                        {
                                            date: "12-24-2024"
                                        },
                                        {
                                            date: "12-26-2024"
                                        },
                                        {
                                            date: "12-27-2024"
                                        },
                                        {
                                            date: "12-30-2024"
                                        },
                                        {
                                            date: "12-31-2024"
                                        },
                                        {
                                            date: "01-01-2025"
                                        },
                                        {
                                            date: "01-02-2025"
                                        },
                                        {
                                            date: "01-03-2025"
                                        },
                                        {
                                            date: "01-06-2025"
                                        },
                                        {
                                            date: "01-07-2025"
                                        },
                                        {
                                            date: "01-08-2025"
                                        },
                                        {
                                            date: "01-09-2025"
                                        },
                                        {
                                            date: "01-10-2025"
                                        },
                                        {
                                            date: "01-13-2025"
                                        },
                                        {
                                            date: "01-14-2025"
                                        },
                                        {
                                            date: "01-15-2025"
                                        },
                                        {
                                            date: "01-16-2025"
                                        },
                                        {
                                            date: "01-17-2025"
                                        },
                                        {
                                            date: "01-20-2025"
                                        },
                                        {
                                            date: "01-21-2025"
                                        },
                                        {
                                            date: "01-22-2025"
                                        },
                                        {
                                            date: "01-23-2025"
                                        },
                                        {
                                            date: "01-24-2025"
                                        },
                                        {
                                            date: "01-27-2025"
                                        },
                                        {
                                            date: "01-28-2025"
                                        },
                                        {
                                            date: "01-29-2025"
                                        },
                                        {
                                            date: "01-30-2025"
                                        },
                                        {
                                            date: "01-31-2025"
                                        },
                                        {
                                            date: "02-03-2025"
                                        },
                                        {
                                            date: "02-04-2025"
                                        },
                                        {
                                            date: "02-05-2025"
                                        },
                                        {
                                            date: "02-06-2025"
                                        },
                                        {
                                            date: "02-07-2025"
                                        },
                                        {
                                            date: "02-10-2025"
                                        },
                                        {
                                            date: "02-11-2025"
                                        },
                                        {
                                            date: "02-12-2025"
                                        },
                                        {
                                            date: "02-13-2025"
                                        },
                                        {
                                            date: "02-14-2025"
                                        },
                                        {
                                            date: "02-17-2025"
                                        },
                                        {
                                            date: "02-18-2025"
                                        },
                                        {
                                            date: "02-19-2025"
                                        },
                                        {
                                            date: "02-20-2025"
                                        },
                                        {
                                            date: "02-21-2025"
                                        },
                                        {
                                            date: "02-24-2025"
                                        },
                                        {
                                            date: "02-25-2025"
                                        },
                                        {
                                            date: "02-26-2025"
                                        },
                                        {
                                            date: "02-27-2025"
                                        },
                                        {
                                            date: "02-28-2025"
                                        },
                                        {
                                            date: "03-03-2025"
                                        },
                                        {
                                            date: "03-04-2025"
                                        },
                                        {
                                            date: "03-05-2025"
                                        },
                                        {
                                            date: "03-06-2025"
                                        },
                                        {
                                            date: "03-07-2025"
                                        },
                                        {
                                            date: "03-10-2025"
                                        },
                                        {
                                            date: "03-11-2025"
                                        },
                                        {
                                            date: "03-12-2025"
                                        },
                                        {
                                            date: "03-13-2025"
                                        },
                                        {
                                            date: "03-14-2025"
                                        },
                                        {
                                            date: "03-17-2025"
                                        },
                                        {
                                            date: "03-18-2025"
                                        },
                                        {
                                            date: "03-19-2025"
                                        },
                                        {
                                            date: "03-20-2025"
                                        },
                                        {
                                            date: "03-21-2025"
                                        },
                                        {
                                            date: "03-24-2025"
                                        },
                                        {
                                            date: "03-25-2025"
                                        },
                                        {
                                            date: "03-26-2025"
                                        },
                                        {
                                            date: "03-27-2025"
                                        },
                                        {
                                            date: "03-28-2025"
                                        },
                                        {
                                            date: "03-31-2025"
                                        },
                                        {
                                            date: "04-01-2025"
                                        },
                                        {
                                            date: "04-02-2025"
                                        },
                                        {
                                            date: "04-03-2025"
                                        },
                                        {
                                            date: "04-04-2025"
                                        },
                                        {
                                            date: "04-07-2025"
                                        },
                                        {
                                            date: "04-08-2025"
                                        }
                                    ]
                                },
                            },
                        },
                    },
                    400: {
                        description: "Bad Request",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/ErrorMessage",
                                },
                            },
                        },
                    },
                    401: {
                        description: "Unauthorized.",
                        content: {
                            "application/json": {
                                example: {
                                    success: false,
                                    msg: "Unauthorized."
                                }
                            }
                        }
                    },
                    500: {
                        description: "Internal Server Error",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/Problem",
                                },
                            },
                        },
                    },
                },
            },
        },
        "/api/v2/order/rewardsEstimatedPoints": {
            get: {
                operationId: "getRewardsEstimatedPointsGetV2",
                tags: ["Order v2"],
                summary: "Get Estimated rewards point for order",
                description: "Get Estimated rewards point for order",
                parameters: [
                    {
                        $ref: "#/components/parameters/oktaAccessToken",
                    },
                    {
                        $ref: "#/components/parameters/storeIdInHeader",
                    },
                    {
                        name: "internalTotalPrice",
                        in: "query",
                        required: true,
                        description: "internalTotalPrice",
                        schema: {
                            type: "string",
                            default: "5119.618",
                        },
                    },
                    {
                        name: "externalTotalPrice",
                        in: "query",
                        required: true,
                        description: "externalTotalPrice",
                        schema: {
                            type: "string",
                            default: "5119.618",
                        },
                    },
                ],
                responses: {
                    200: {
                        description: "OK",
                        content: {
                            "application/json": {
                                schema: {
                                    $ref: "#/components/schemas/GetRewardsEstimatedPointsResponseDtoV2",
                                },
                                example: {
                                    success: true,
                                    internalPointsEarned: 171.27000000000004,
                                    externalPointsEarned: 328.52,
                                },
                            },
                        },
                    },
                    400: {
                        description: "Bad Request",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/ErrorMessage",
                                },
                            },
                        },
                    },
                    415: {
                        description: "Unsupported Media Type",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/ErrorMessage",
                                },
                            },
                        },
                    },
                    500: {
                        description: "Internal Server Error",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/Problem",
                                },
                            },
                        },
                    },
                },
            },
        },
        "/api/v1/product": {
            get: {
                operationId: "getProductsGetV1",
                tags: ["Product"],
                summary: "Product - List",
                description: "Get list of products",
                parameters: [
                    {
                        $ref: "#/components/parameters/oktaAccessToken",
                    },
                    {
                        $ref: "#/components/parameters/storeIdInHeader",
                    },
                    {
                        $ref: "#/components/parameters/offset",
                    },
                    {
                        $ref: "#/components/parameters/limit",
                    },
                    {
                        name: "sort",
                        in: "query",
                        required: false,
                        description: "Sort records",
                        schema: {
                            type: "string",
                        },
                    },
                    {
                        name: "sortBy",
                        in: "query",
                        required: false,
                        description: "Sort records by",
                        schema: {
                            type: "string",
                            enum: ["asc", "desc"],
                            default: "asc",
                        },
                    },
                    {
                        name: "wishlist",
                        in: "query",
                        required: false,
                        description: "Number of favourite records",
                        schema: {
                            type: "number",
                            default: 0,
                        },
                    },
                    {
                        name: "name",
                        in: "query",
                        required: false,
                        description: "Name of the product",
                        schema: {
                            type: "string",
                        },
                    },
                    {
                        name: "brand",
                        in: "query",
                        required: false,
                        description: "Comma separated brand ids",
                        schema: {
                            type: "string",
                        },
                    },
                    {
                        name: "subBrand",
                        in: "query",
                        required: false,
                        description: "Comma separated subBrand ids",
                        schema: {
                            type: "string",
                        },
                    },
                    {
                        name: "productIds",
                        in: "query",
                        required: false,
                        description: "Comma separated product ids",
                        schema: {
                            type: "string",
                        },
                    },
                ],
                responses: {
                    200: {
                        description: "OK",
                        content: {
                            "application/json": {
                                schema: {
                                    $ref: "#/components/schemas/GetProductsResponseDto",
                                },
                                example: {
                                    metadata: {
                                        __comment: "Info about the JSON File.",
                                        itemKey: "products",
                                        itemType: "ZOR",
                                        totalItems: 259,
                                        allProducts:
                                            "https://admin-fd.cep.qa.es.pepsico.com/Images/All_Products/AllProductsCategory.png",
                                        beverageImage: null,
                                        snackImage: null,
                                        displayCategory: {
                                            Snacks: [
                                                "Formato grande",
                                                "Formato mediano",
                                                "Formato peque\u00f1o",
                                                "Formato bar",
                                                "Formato sin PVP",
                                            ],
                                            Bebidas: ["Rockstar", "Pepsi", "Lipton", "Kas", "Otros"],
                                            Otros: ["Cereales", "Dulces", "Frutos Secos", "Salsas"],
                                        },
                                        displaySize: ["500"],
                                    },
                                    products: [
                                        {
                                            id: 393268,
                                            externalId: "B2592",
                                            edoFlag: "FALSE",
                                            displayOrder: null,
                                            name: "PEPSI MAX SLEEK 330 MP12",
                                            displayName: {
                                                en: "Pepsi Max Sleek 330 ",
                                                es: "Pepsi Max Sleek 330 ",
                                                zh: "Pepsi Max Sleek 330 ",
                                            },
                                            displaySize: {
                                                en: null,
                                                es: null,
                                                zh: null,
                                            },
                                            shortDescription: {
                                                en: null,
                                                es: null,
                                                zh: null,
                                            },
                                            longDescription: {
                                                en: "Pepsi MAX\u00ae\ufe0f es el refresco de cola con M\u00c1XIMO SABOR y ZERO AZ\u00daCAR.  Una bebida refrescante, atrevida y retadora.",
                                                es: "Pepsi MAX\u00ae\ufe0f es el refresco de cola con M\u00c1XIMO SABOR y ZERO AZ\u00daCAR.  Una bebida refrescante, atrevida y retadora.",
                                                zh: "Pepsi MAX\u00ae\ufe0f es el refresco de cola con M\u00c1XIMO SABOR y ZERO AZ\u00daCAR.  Una bebida refrescante, atrevida y retadora.",
                                            },
                                            thumbnailUrl: "",
                                            barCode: "08410494300258",
                                            skuNumber: "B2592",
                                            brandId: "B29",
                                            brand: {
                                                en: "Pepsi",
                                                es: "Pepsi",
                                                zh: "Pepsi",
                                            },
                                            brandImageUrl:
                                                "https://admin-fd.cep.qa.es.pepsico.com/Images/Brand/B29.png",
                                            subBrandId: "PCBDI04BR48SB77",
                                            subBrand: {
                                                en: "PEPSI MAX",
                                                es: "PEPSI MAX",
                                                zh: "PEPSI MAX",
                                            },
                                            subBrandImageUrl: null,
                                            categoryId: "C2",
                                            categoryType: "BEVERAGE",
                                            category: {
                                                en: "BEVERAGES",
                                                es: "BEBIDAS",
                                                zh: "BEBIDAS",
                                            },
                                            categoryImageUrl: null,
                                            subCategoryId: "S13",
                                            subCategory: {
                                                en: "Pepsi",
                                                es: "Pepsi",
                                                zh: "Pepsi",
                                            },
                                            subCategoryImageUrl:
                                                "https://admin-fd.cep.qa.es.pepsico.com/Images/Category/S13.png",
                                            details: {
                                                erpCategory: "MAX",
                                                size: "330",
                                                package: null,
                                                flavor: {
                                                    en: "COLA MAX/-/FL36",
                                                    es: "COLA MAX/-/FL36",
                                                    zh: "COLA MAX/-/FL36",
                                                },
                                                colourCode: "",
                                                imageUrl:
                                                    "https://admin-fd.cep.qa.es.pepsico.com/Images/Product/B2592.png",
                                                additionalImageUrls: [],
                                            },
                                            price: {
                                                suggestedRetailSellingPrice: null,
                                                basePrice: 1.32,
                                                pricelistId: "10000001",
                                                campaignMultiplier: 1,
                                                baseUOM: "CS",
                                                baseUnitMSRP: null,
                                                returnBaseUOM: "Case",
                                                adjustments: [
                                                    {
                                                        type: "Percentage",
                                                        rate: -61.89,
                                                        unit: "%",
                                                        id: "CD8_NAC_B",
                                                        rewardId: null,
                                                        ruleId: null,
                                                    },
                                                ],
                                                adjustmentPrice: null,
                                                saleUoms: [
                                                    {
                                                        code: "Case",
                                                        name: "Case",
                                                        conversionMultiplierToBase: 12,
                                                    },
                                                ],
                                                returnUoms: [
                                                    {
                                                        code: "Case",
                                                        name: "Case",
                                                        conversionMultiplierToBase: 12,
                                                    },
                                                ],
                                                tax: [
                                                    {
                                                        type: "Eco Tax",
                                                        valueType: "FLAT",
                                                        value: 0.003,
                                                    },
                                                    {
                                                        type: "VAT",
                                                        valueType: "Percentage",
                                                        value: 21,
                                                    },
                                                    {
                                                        type: "RE",
                                                        valueType: "Percentage",
                                                        value: 5.2,
                                                    },
                                                ],
                                                depositPrice: null,
                                                currency: "\u20ac",
                                            },
                                            outStock: false,
                                            warehouse: ["Internal", "External"],
                                            minimumOrderQuantity: null,
                                            maximumOrderQuantity: null,
                                            allowedInMultiplesOf: 1,
                                            returnPriceForBaseUnit: 0,
                                            tags: null,
                                            wishlist: "0",
                                            status: true,
                                            rewardPoints: 0,
                                            hasDeposit: false,
                                            isReturnable: true,
                                        },
                                    ],
                                },
                            },
                        },
                    },
                    400: {
                        description: "Bad Request",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/ErrorMessage",
                                },
                            },
                        },
                    },
                    415: {
                        description: "Unsupported Media Type",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/ErrorMessage",
                                },
                            },
                        },
                    },
                    500: {
                        description: "Internal Server Error",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/Problem",
                                },
                            },
                        },
                    },
                },
            },
        },
        "/api/v2/product": {
            get: {
                operationId: "getProductsGetV2",
                tags: ["Product"],
                summary: "Product - List",
                description: "Get list of products",
                parameters: [
                    {
                        $ref: "#/components/parameters/oktaAccessToken",
                    },
                    {
                        $ref: "#/components/parameters/storeIdInHeader",
                    },
                    {
                        $ref: "#/components/parameters/offset",
                    },
                    {
                        $ref: "#/components/parameters/limit",
                    },
                    {
                        name: "sort",
                        in: "query",
                        required: false,
                        description: "Sort records",
                        schema: {
                            type: "string",
                        },
                    },
                    {
                        name: "sortBy",
                        in: "query",
                        required: false,
                        description: "Sort records by",
                        schema: {
                            type: "string",
                            enum: ["asc", "desc"],
                            default: "asc",
                        },
                    },
                    {
                        name: "wishlist",
                        in: "query",
                        required: false,
                        description: "Number of favourite records",
                        schema: {
                            type: "number",
                            default: 0,
                        },
                    },
                    {
                        name: "name",
                        in: "query",
                        required: false,
                        description: "Name of the product",
                        schema: {
                            type: "string",
                        },
                    },
                    {
                        name: "brand",
                        in: "query",
                        required: false,
                        description: "Comma separated brand ids",
                        schema: {
                            type: "string",
                        },
                    },
                    {
                        name: "subBrand",
                        in: "query",
                        required: false,
                        description: "Comma separated subBrand ids",
                        schema: {
                            type: "string",
                        },
                    },
                    {
                        name: "productIds",
                        in: "query",
                        required: false,
                        description: "Comma separated product ids",
                        schema: {
                            type: "string",
                        },
                    },
                ],
                responses: {
                    200: {
                        description: "OK",
                        content: {
                            "application/json": {
                                schema: {
                                    $ref: "#/components/schemas/GetProductsResponseDto",
                                },
                                example: {
                                    metadata: {
                                        __comment: "Info about the JSON File.",
                                        itemKey: "products",
                                        itemType: "ZOR",
                                        totalItems: 2,
                                        productPointsEligibleCategory: "ZFIN,ZTRG  only for poland",
                                        brands: {
                                            132: {
                                                en: "CHEETOS",
                                                es: "CHEETOS",
                                            },
                                        },
                                        subBrands: {
                                            132: [
                                                {
                                                    en: "CHEETOS TORCIDITOS",
                                                    es: "CHEETOS TORCIDITOS",
                                                },
                                                {
                                                    en: "CHEETOS BOLITAS",
                                                    es: "CHEETOS BOLITAS",
                                                },
                                                {
                                                    en: "CHEETOS COLMILLOS",
                                                    es: "CHEETOS COLMILLOS",
                                                },
                                            ],
                                        },
                                    },
                                    products: [
                                        {
                                            id: 2484,
                                            externalId: "",
                                            displayOrder: null,
                                            name: "CHEETOS NATURAL 15GX7X12 BX12 IMP",
                                            displayName: {
                                                en: "CHEETOS NATURAL 15GX7X12 BX12 IMP",
                                                es: null,
                                            },
                                            shortDescription: {
                                                en: null,
                                                es: null,
                                            },
                                            longDescription: {
                                                en: null,
                                                es: null,
                                            },
                                            thumbnailUrl: "",
                                            barCode: null,
                                            skuNumber: null,
                                            brandId: "132",
                                            brand: {
                                                en: "CHEETOS",
                                                es: "CHEETOS",
                                            },
                                            brandImageUrl: null,
                                            subBrandId: "163",
                                            subBrand: {
                                                en: "CHEETOS",
                                                es: "CHEETOS",
                                            },
                                            subBrandImageUrl: null,
                                            categoryId: "A0",
                                            category: {
                                                en: "SAVOURY SNACKS",
                                                es: "SAVOURY SNACKS",
                                            },
                                            categoryImageUrl: null,
                                            subCategoryId: null,
                                            subCategory: {
                                                en: null,
                                                es: null,
                                            },
                                            subCategoryImageUrl: null,
                                            details: {
                                                erpCategory: null,
                                                size: "SMALL",
                                                package: "BAG",
                                                flavor: {
                                                    en: "NATURAL",
                                                    es: null,
                                                },
                                                colourCode: "",
                                                imageUrl: null,
                                                additionalImageUrls: [],
                                            },
                                            price: {
                                                basePrice: 435,
                                                baseUOM: "EA",
                                                baseUnitMSRP: 435,
                                                saleUoms: [
                                                    {
                                                        code: "CS",
                                                        name: "Case",
                                                        conversionMultiplierToBase: "84.00",
                                                    },
                                                ],
                                                discounts: [
                                                    {
                                                        type: "adjustment",
                                                        amount: 66,
                                                    },
                                                ],
                                                tax: [
                                                    {
                                                        type: "adjustment",
                                                        percent: "19%",
                                                    },
                                                ],
                                                depositPrice: null,
                                                currency: "€",
                                            },
                                            inStock: true,
                                            minimumOrderQuantity: 5,
                                            maximumOrderQuantity: 10,
                                            allowedInMultiplesOf: 1,
                                            returnPriceForBaseUnit: 0,
                                            tags: ["794"],
                                            wishlist: 0,
                                            status: true,
                                            rewardPoints: 50,
                                            hasDeposit: false,
                                            isReturnable: null,
                                            productContainer: "CB only for poland",
                                            productLine: "RTT only for poland",
                                            productGroup: "PEP only for poland",
                                            productSize: "123 only for poland",
                                        },
                                        {
                                            id: 2490,
                                            externalId: "",
                                            displayOrder: null,
                                            name: "DORITOS PAKETON MEGAQUESO 60GX25X1",
                                            displayName: {
                                                en: "DORITOS PAKETON MEGAQUESO 60GX25X1",
                                                es: null,
                                            },
                                            shortDescription: {
                                                en: null,
                                                es: null,
                                            },
                                            longDescription: {
                                                en: null,
                                                es: null,
                                            },
                                            thumbnailUrl: "",
                                            barCode: null,
                                            skuNumber: null,
                                            brandId: "098",
                                            brand: {
                                                en: "DORITOS",
                                                es: "DORITOS",
                                            },
                                            brandImageUrl: null,
                                            subBrandId: "378",
                                            subBrand: {
                                                en: "DORITOS",
                                                es: "DORITOS",
                                            },
                                            subBrandImageUrl: null,
                                            categoryId: "A0",
                                            category: {
                                                en: "SAVOURY SNACKS",
                                                es: null,
                                            },
                                            categoryImageUrl: null,
                                            subCategoryId: null,
                                            subCategory: {
                                                en: null,
                                                es: null,
                                            },
                                            subCategoryImageUrl: null,
                                            details: {
                                                erpCategory: null,
                                                size: "MEDIUM",
                                                flavor: {
                                                    en: "CHEESE",
                                                    es: null,
                                                },
                                                colourCode: "",
                                                imageUrl:
                                                    "https://cepadmin2.spo.nonprod.wspdop.pepsico.com/_default_upload_bucket/7702189039682.png",
                                                additionalImageUrls: [],
                                            },
                                            price: {
                                                basePrice: 1304,
                                                baseUOM: "EA",
                                                baseUnitMSRP: 1315,
                                                saleUoms: [
                                                    {
                                                        code: "CS",
                                                        name: "Case",
                                                        conversionMultiplierToBase: "25.00",
                                                    },
                                                ],
                                                discounts: [
                                                    {
                                                        type: "adjustment",
                                                        amount: null,
                                                    },
                                                ],
                                                tax: [
                                                    {
                                                        type: "adjustment",
                                                        percent: "19%",
                                                    },
                                                ],
                                                depositPrice: null,
                                                currency: "€",
                                            },
                                            inStock: true,
                                            minimumOrderQuantity: 6,
                                            maximumOrderQuantity: 10,
                                            allowedInMultiplesOf: 3,
                                            returnPriceForBaseUnit: 0,
                                            tags: ["639"],
                                            wishlist: 0,
                                            status: true,
                                            rewardPoints: 0,
                                            hasDeposit: false,
                                            isReturnable: null,
                                            productContainer: "CB only for poland",
                                            productLine: "RTT only for poland",
                                            productGroup: "PEP only for poland",
                                            productSize: "123 only for poland",
                                        },
                                    ],
                                },
                            },
                        },
                    },
                    400: {
                        description: "Bad Request",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/ErrorMessage",
                                },
                            },
                        },
                    },
                    415: {
                        description: "Unsupported Media Type",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/ErrorMessage",
                                },
                            },
                        },
                    },
                    500: {
                        description: "Internal Server Error",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/Problem",
                                },
                            },
                        },
                    },
                },
            },
        },
        "/api/v1/product/inventory": {
            get: {
                operationId: "getProductInventoryGetV1",
                tags: ["Product"],
                summary: "Product Inventory",
                description: "Get information of products inventory",
                parameters: [
                    {
                        $ref: "#/components/parameters/oktaAccessToken",
                    },
                    {
                        $ref: "#/components/parameters/storeIdInHeader",
                    },
                ],
                responses: {
                    200: {
                        description: "OK",
                        content: {
                            "application/json": {
                                schema: {
                                    type: "object",
                                    properties: {
                                        metadata: {
                                            type: "object",
                                            properties: {
                                                __comment: {
                                                    type: "string",
                                                },
                                                itemKey: {
                                                    type: "string",
                                                },
                                                totalItems: {
                                                    type: "number",
                                                },
                                            },
                                        },
                                        products: {
                                            type: "array",
                                            items: {
                                                $ref: "#/components/schemas/InventoryProductItem",
                                            },
                                        },
                                    },
                                },
                                example: {
                                    metadata: {
                                        __comment: "Info about the JSON File.",
                                        itemKey: "Inventory",
                                        totalItems: 6,
                                    },
                                    products: [
                                        {
                                            id: "512019",
                                            externalId: "000000000300033634",
                                            outStock: true,
                                        },
                                        {
                                            id: "512987",
                                            externalId: "000000000300033690",
                                            outStock: false,
                                        },
                                        {
                                            id: "524436",
                                            externalId: "000000000300034194",
                                            outStock: false,
                                        },
                                        {
                                            id: "524509",
                                            externalId: "000000000300034197",
                                            outStock: false,
                                        },
                                        {
                                            id: "545987",
                                            externalId: "000000000300049877",
                                            outStock: false,
                                        },
                                        {
                                            id: "1524716",
                                            externalId: "000000000300050901",
                                            outStock: false,
                                        },
                                    ],
                                },
                            },
                        },
                    },
                    400: {
                        description: "Bad Request",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/ErrorMessage",
                                },
                            },
                        },
                    },
                    415: {
                        description: "Unsupported Media Type",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/ErrorMessage",
                                },
                            },
                        },
                    },
                    500: {
                        description: "Internal Server Error",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/Problem",
                                },
                            },
                        },
                    },
                },
            },
        },
        "/api/v1/promotion/rule": {
            get: {
                operationId: "getPromotionRulesGet",
                tags: ["Promotion"],
                summary: "Promotion Rules - List",
                description: "Get Rules associated with promotions",
                parameters: [
                    {
                        $ref: "#/components/parameters/oktaAccessToken",
                    },
                    {
                        $ref: "#/components/parameters/siteId",
                    },
                    {
                        $ref: "#/components/parameters/storeIdInQuery",
                    },
                ],
                responses: {
                    200: {
                        description: "OK",
                        content: {
                            "application/json": {
                                schema: {
                                    $ref: "#/components/schemas/GetPromotionResponseDto",
                                },
                                example: {
                                    metadata: {
                                        totalItems: 4,
                                    },
                                    promotions: [
                                        {
                                            id: "6",
                                            title: {
                                                en: "OrderOnlineGetFreeItem",
                                                es: "OrderOnlineGetFreeItem",
                                            },
                                            description: "",
                                            startDate: "2021-12-31 18:30:00",
                                            endDate: "2023-07-31 17:30:00",
                                            isPublic: false,
                                            isActive: "1",
                                            behavior: "additiv",
                                            actions: [
                                                {
                                                    type: "OrdGyDiscount",
                                                    message: {
                                                        en: "",
                                                        es: "",
                                                    },
                                                    fields: [
                                                        {
                                                            reward_qty: 2,
                                                        },
                                                        {
                                                            product: 2498,
                                                        },
                                                        {
                                                            maxAllowedUsage: 1,
                                                        },
                                                        {
                                                            resetFrequency: 28,
                                                        },
                                                    ],
                                                },
                                            ],
                                            conditions: [
                                                {
                                                    operator: "and",
                                                    type: "DateRange",
                                                    starting: "06.04.2022",
                                                    ending: "31.12.2022",
                                                },
                                            ],
                                        },
                                        {
                                            id: "5",
                                            title: {
                                                en: "BuyXGetY",
                                                es: "BuyXGetY",
                                            },
                                            description: "",
                                            startDate: "2021-12-31 18:30:00",
                                            endDate: "2022-09-29 18:30:00",
                                            isPublic: false,
                                            isActive: "1",
                                            behavior: "additiv",
                                            actions: [
                                                {
                                                    type: "BxGyDiscount",
                                                    message: {
                                                        en: "Buy 3 Get 3 Free!",
                                                        es: "Compre 3 y obtenga 3 gratis",
                                                    },
                                                    fields: [
                                                        {
                                                            buy_qty: 3,
                                                        },
                                                        {
                                                            reward_qty: 3,
                                                        },
                                                        {
                                                            product_quota: 0,
                                                        },
                                                        {
                                                            gift_product: 2495,
                                                        },
                                                        {
                                                            max_allowed_items: 60,
                                                        },
                                                        {
                                                            maxAllowedUsage: 4,
                                                        },
                                                        {
                                                            resetFrequency: 1,
                                                        },
                                                    ],
                                                },
                                            ],
                                            conditions: [
                                                {
                                                    operator: "and",
                                                    type: "CatalogProduct",
                                                    products: [2484, 2469],
                                                },
                                                {
                                                    operator: "and",
                                                    type: "DateRange",
                                                    starting: "01.06.2022",
                                                    ending: "31.12.2022",
                                                },
                                            ],
                                        },
                                        {
                                            id: "13",
                                            title: {
                                                en: "Fixed % Discount",
                                                es: "Fixed % Discount",
                                            },
                                            description: "",
                                            startDate: "2021-12-31 18:30:00",
                                            endDate: "2023-12-31 17:30:00",
                                            isPublic: false,
                                            isActive: "1",
                                            behavior: "additiv",
                                            actions: [
                                                {
                                                    type: "ProductDiscount",
                                                    message: {
                                                        en: "",
                                                        es: "",
                                                    },
                                                    fields: [
                                                        {
                                                            amount: 0,
                                                        },
                                                        {
                                                            fixed_discount: 35,
                                                        },
                                                        {
                                                            additional_discount: 0,
                                                        },
                                                        {
                                                            percent: 100,
                                                        },
                                                        {
                                                            maxAllowedUsage: 8,
                                                        },
                                                        {
                                                            resetFrequency: null,
                                                        },
                                                    ],
                                                },
                                            ],
                                            conditions: [
                                                {
                                                    operator: "and",
                                                    type: "DateRange",
                                                    starting: "01.03.2022",
                                                    ending: "31.01.2023",
                                                },
                                            ],
                                        },
                                        {
                                            id: "14",
                                            title: {
                                                en: "BuyXGetY2",
                                                es: "BuyXGetY2",
                                            },
                                            description: "",
                                            startDate: "2021-12-31 18:30:00",
                                            endDate: "2022-09-29 18:30:00",
                                            isPublic: false,
                                            isActive: "1",
                                            behavior: "additiv",
                                            actions: [
                                                {
                                                    type: "BxGyDiscount",
                                                    message: {
                                                        en: "Buy 2 Get 1 Free!",
                                                        es: "Compre 2 y obtenga 1 gratis",
                                                    },
                                                    fields: [
                                                        {
                                                            buy_qty: 2,
                                                        },
                                                        {
                                                            reward_qty: 1,
                                                        },
                                                        {
                                                            product_quota: 0,
                                                        },
                                                        {
                                                            gift_product: 2487,
                                                        },
                                                        {
                                                            max_allowed_items: 20,
                                                        },
                                                        {
                                                            maxAllowedUsage: 2,
                                                        },
                                                        {
                                                            resetFrequency: null,
                                                        },
                                                    ],
                                                },
                                            ],
                                            conditions: [
                                                {
                                                    operator: "or",
                                                    type: "ProductCategoryCond",
                                                    id: "A01",
                                                    name: "Snacks",
                                                },
                                                {
                                                    operator: "or",
                                                    type: "BrandCond",
                                                    id: "566",
                                                    name: "NAATU",
                                                },
                                                {
                                                    operator: "and",
                                                    type: "DateRange",
                                                    starting: "01.06.2022",
                                                    ending: "31.12.2022",
                                                },
                                            ],
                                        },
                                    ],
                                },
                            },
                        },
                    },
                    400: {
                        description: "Bad Request",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/ErrorMessage",
                                },
                            },
                        },
                    },
                    415: {
                        description: "Unsupported Media Type",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/ErrorMessage",
                                },
                            },
                        },
                    },
                    500: {
                        description: "Internal Server Error",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/Problem",
                                },
                            },
                        },
                    },
                },
            },
        },
        "/api/v1/perfectstore/firstposition/{storeId}": {
            get: {
                operationId: "getPerfectStoreFirstPositionTargetListGet",
                tags: ["Perfect Store"],
                summary:
                    "Get the current target list of an perfect store for firstposition",
                description:
                    "Get the current target list of an perfect store for firstposition",
                parameters: [
                    {
                        $ref: "#/components/parameters/storeIdInPath",
                    },
                    {
                        $ref: "#/components/parameters/oktaAccessToken",
                    },
                    {
                        $ref: "#/components/parameters/siteId",
                    },
                    {
                        $ref: "#/components/parameters/limit",
                    },
                    {
                        $ref: "#/components/parameters/offset",
                    },
                    {
                        name: "date",
                        in: "query",
                        required: false,
                        description:
                            "Matches with Target StartDate to query - Format YYYY-MM",
                        schema: {
                            type: "string",
                            default:
                                "Current date is in between DisplayStartDate And DisplayEndDate of Perfect Store Target",
                        },
                    },
                    {
                        name: "order",
                        in: "query",
                        required: false,
                        description:
                            "Target item order – ordered by target startDate – desc || asc",
                        schema: {
                            type: "string",
                            default: "desc",
                        },
                    },
                ],
                responses: {
                    200: {
                        description: "OK",
                        content: {
                            "application/json": {
                                schema: {
                                    $ref: "#/components/schemas/PerfectStoreFirstPositionTargetListDto",
                                },
                                example: {
                                    count: 1,
                                    data: [
                                        {
                                            id: "385560",
                                            targetName: {
                                                en: "TargetDec2022",
                                            },
                                            targetDescription: {
                                                en: "TargetDec2022",
                                            },
                                            targetImage:
                                                "https://localhost:99/_default_upload_bucket/pepsico-img-1_3.jpeg",
                                            targetStartDate: "2022-12-22T18:30:00.000000Z",
                                            targetEndDate: "2022-12-30T18:30:00.000000Z",
                                            store: {
                                                id: 385559,
                                                erpId: "0100104110",
                                                visits: [
                                                    {
                                                        visitNumber: 1,
                                                        pointAvailable: 30,
                                                        status: "Yes",
                                                        pointAwarded: 30,
                                                        visitUploadedDate: "2022-12-22 18:30:00",
                                                    },
                                                    {
                                                        visitNumber: 2,
                                                        pointAvailable: 20,
                                                        status: "No",
                                                        pointAwarded: 0,
                                                        visitUploadedDate: "2022-12-22 18:30:00",
                                                    },
                                                ],
                                                totalVisit: 2,
                                                totalPointAvailable: 50,
                                                totalPointAwarded: 30,
                                                status: "Completed",
                                            },
                                        },
                                    ],
                                },
                            },
                        },
                    },
                    400: {
                        description: "Bad Request",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/ErrorMessage",
                                },
                            },
                        },
                    },
                    415: {
                        description: "Unsupported Media Type",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/ErrorMessage",
                                },
                            },
                        },
                    },
                    500: {
                        description: "Internal Server Error",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/Problem",
                                },
                            },
                        },
                    },
                },
            },
        },
        "/api/v1/perfectstore/musthaveproducts/{storeId}": {
            get: {
                operationId: "getPerfectStoreMustHaveTargetListGet",
                tags: ["Perfect Store"],
                summary:
                    "Get the current target list of an perfect store for musthaveproducts",
                description:
                    "Get the current target list of an perfect store for musthaveproducts",
                parameters: [
                    {
                        $ref: "#/components/parameters/storeIdInPath",
                    },
                    {
                        $ref: "#/components/parameters/oktaAccessToken",
                    },
                    {
                        $ref: "#/components/parameters/siteId",
                    },
                    {
                        $ref: "#/components/parameters/limit",
                    },
                    {
                        $ref: "#/components/parameters/offset",
                    },
                    {
                        name: "date",
                        in: "query",
                        required: false,
                        description:
                            "Matches with Target StartDate to query - Format YYYY-MM",
                        schema: {
                            type: "string",
                            default:
                                "Current date is in between DisplayStartDate And DisplayEndDate of Perfect Store Target",
                        },
                    },
                    {
                        name: "order",
                        in: "query",
                        required: false,
                        description:
                            "Target item order – ordered by target startDate – desc || asc",
                        schema: {
                            type: "string",
                            default: "desc",
                        },
                    },
                ],
                responses: {
                    200: {
                        description: "OK",
                        content: {
                            "application/json": {
                                schema: {
                                    $ref: "#/components/schemas/PerfectStoreMustHaveTargetListDto",
                                },
                                example: {
                                    count: 2,
                                    data: [
                                        {
                                            id: "385604",
                                            targetType: "Must Have Products Target",
                                            mustHaveTargetType: "ProductGroup-with minimum threshold",
                                            mustHaveAwardBasis: "Complete",
                                            targetName: {
                                                en: "Must Have Target-1",
                                            },
                                            targetDescription: {
                                                en: "Must Have Target 1",
                                            },
                                            targetImage:
                                                "https://localhost:99/pepsico/Logo/login_logo1.jpg",
                                            targetDisplayStartDate: "2023-01-31T18:30:00.000000Z",
                                            targetDisplayEndDate: "2023-02-27T18:30:00.000000Z",
                                            targetActiveStartDate: "2022-12-31T18:30:00.000000Z",
                                            targetActiveEndDate: "2023-02-03T18:30:00.000000Z",
                                            targetRewardDate: "2023-02-02T18:30:00.000000Z",
                                            targetDeliveredDate: null,
                                            targetGoalStatus: "not_started",
                                            pointsToEarn: 200,
                                            targetProductFlag: false,
                                            targetProductGroupFlag: true,
                                            minThresholdFlag: true,
                                            minThreshold: 1,
                                            minThresholdPercentage: 50,
                                            targetAmount: 2,
                                            actualAmount: 1,
                                            productGroupList: [
                                                {
                                                    productGroupName: "ProductGroup2",
                                                    productGroupImage:
                                                        "https://admin.cep.qa.co.pepsico.com/Images/All_Products/AllProductsCategory.png",
                                                    productList: [
                                                        {
                                                            "000000000300033716": true,
                                                        },
                                                        {
                                                            "000000000300033720": false,
                                                        },
                                                    ],
                                                },
                                            ],
                                            availableProductGroup: ["000000000300033531"],
                                            actualAvailableSKUPercentage: 50,
                                            estimatedPointsToAllocate: 100,
                                        },
                                        {
                                            id: "385612",
                                            targetType: "Must Have Products Target",
                                            mustHaveTargetType: "Product-without minimum threshold",
                                            mustHaveAwardBasis: "Complete",
                                            targetName: {
                                                en: "FebMustHaveTarget",
                                            },
                                            targetDescription: {
                                                en: "FebMustHaveTarget",
                                            },
                                            targetImage:
                                                "https://localhost:99/pepsico/carousel/banner.jpg",
                                            targetDisplayStartDate: "2023-01-31T18:30:00.000000Z",
                                            targetDisplayEndDate: "2023-02-27T18:30:00.000000Z",
                                            targetActiveStartDate: "2023-01-31T18:30:00.000000Z",
                                            targetActiveEndDate: "2023-02-27T18:30:00.000000Z",
                                            targetRewardDate: "2023-02-28T18:30:00.000000Z",
                                            targetDeliveredDate: "2023-02-28T18:30:00.000000Z",
                                            targetGoalStatus: "not_started",
                                            pointsToEarn: 400,
                                            targetProductFlag: true,
                                            targetProductGroupFlag: false,
                                            minThresholdFlag: false,
                                            minThreshold: 0,
                                            minThresholdPercentage: 0,
                                            targetAmount: 2,
                                            actualAmount: 0,
                                            productList: [
                                                {
                                                    "000000000300033531": true,
                                                },
                                                {
                                                    "000000000300055816": false,
                                                },
                                            ],
                                            availableProduct: null,
                                            actualAvailableSKUPercentage: 0,
                                            estimatedPointsToAllocate: 0,
                                        },
                                    ],
                                },
                            },
                        },
                    },
                    400: {
                        description: "Bad Request",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/ErrorMessage",
                                },
                            },
                        },
                    },
                    415: {
                        description: "Unsupported Media Type",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/ErrorMessage",
                                },
                            },
                        },
                    },
                    500: {
                        description: "Internal Server Error",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/Problem",
                                },
                            },
                        },
                    },
                },
            },
        },
        "/api/v1/perfectstore/racksandcoolers/{storeId}": {
            get: {
                operationId: "getPerfectStoreRacksAndCoolersTargetListGet",
                tags: ["Perfect Store"],
                summary:
                    "Get the current target list of an perfect store for racks and coolers",
                description:
                    "Get the current target list of an perfect store for racks and coolers",
                parameters: [
                    {
                        $ref: "#/components/parameters/storeIdInPath",
                    },
                    {
                        $ref: "#/components/parameters/oktaAccessToken",
                    },
                    {
                        $ref: "#/components/parameters/siteId",
                    },
                    {
                        $ref: "#/components/parameters/limit",
                    },
                    {
                        $ref: "#/components/parameters/offset",
                    },
                    {
                        name: "date",
                        in: "query",
                        required: false,
                        description:
                            "Matches with Target StartDate to query - Format YYYY-MM",
                        schema: {
                            type: "string",
                            default:
                                "Current date is in between DisplayStartDate And DisplayEndDate of Perfect Store Target",
                        },
                    },
                    {
                        name: "order",
                        in: "query",
                        required: false,
                        description:
                            "Target item order – ordered by target startDate – desc || asc",
                        schema: {
                            type: "string",
                            default: "desc",
                        },
                    },
                ],
                responses: {
                    200: {
                        description: "OK",
                        content: {
                            "application/json": {
                                schema: {
                                    $ref: "#/components/schemas/PerfectStoreRacksCoolersTargetListDto",
                                },
                                example: {
                                    count: 2,
                                    data: [
                                        {
                                            id: "381724",
                                            targetType: "Racks And Coolers Target",
                                            mustHaveTargetType:
                                                "Beverage Racks Without Min Threshold",
                                            mustHaveAwardBasis: "Percent Progress",
                                            targetName: {
                                                en: "Racks",
                                            },
                                            targetDescription: {
                                                en: null,
                                            },
                                            targetImage: null,
                                            targetDisplayStartDate: "2023-02-12T18:30:00.000000Z",
                                            targetDisplayEndDate: "2023-02-27T18:30:00.000000Z",
                                            targetActiveStartDate: "2023-02-12T18:30:00.000000Z",
                                            targetActiveEndDate: "2023-02-27T18:30:00.000000Z",
                                            targetRewardDate: null,
                                            targetDeliveredDate: null,
                                            targetGoalStatus: "not_started",
                                            pointsToEarn: 100,
                                            minThresholdFlag: false,
                                            minThreshold: 0,
                                            minThresholdPercentage: 0,
                                            targetAmount: 200,
                                            actualAmount: 0,
                                            rackList: [
                                                {
                                                    imageId: "rack1",
                                                    imageUrl:
                                                        "http://localhost:99/_default_upload_bucket/permissions.jpg",
                                                    title: {
                                                        en: "SnacksRacks",
                                                    },
                                                    count: 0,
                                                    productAvailableFlag: false,
                                                },
                                                {
                                                    imageId: "rack2",
                                                    imageUrl:
                                                        "http://localhost:99/_default_upload_bucket/ls-command.jpg",
                                                    title: {
                                                        en: "SnacksRacks",
                                                    },
                                                    count: 3,
                                                    productAvailableFlag: true,
                                                },
                                            ],
                                            estimatedPointsToAllocate: 0,
                                        },
                                        {
                                            id: "381928",
                                            targetType: "Racks And Coolers Target",
                                            mustHaveTargetType: "Beverage Coolers With Efficiency",
                                            targetName: {
                                                en: "test",
                                                es: "test",
                                            },
                                            targetDescription: {
                                                en: null,
                                                es: null,
                                            },
                                            targetImage: null,
                                            targetDisplayStartDate: "2023-02-18T18:30:00.000000Z",
                                            targetDisplayEndDate: "2023-02-22T18:30:00.000000Z",
                                            targetActiveStartDate: "2023-02-18T18:30:00.000000Z",
                                            targetActiveEndDate: "2023-02-22T18:30:00.000000Z",
                                            targetRewardDate: "2023-02-22T18:30:00.000000Z",
                                            targetDeliveredDate: "2023-02-22T18:30:00.000000Z",
                                            targetGoalStatus: "achieved",
                                            pointsToEarn: 100,
                                            minThresholdFlag: true,
                                            minThreshold: null,
                                            minThresholdPercentage: 0,
                                            targetAmount: 20,
                                            actualAmount: 6,
                                            actualTargetAmountPercentage: 30,
                                            targetEfficiency: 10,
                                            actualEfficiency: 2,
                                            efficienyPercentage: 20,
                                            rackList: [
                                                {
                                                    imageId: "coolerID1",
                                                    imageUrl:
                                                        "http://localhost:89/Images/Product/300033695.png",
                                                    title: {
                                                        en: "<p>coolerID1 title eng</p>\n",
                                                        es: "<p>coolerID1 title spn</p>\n",
                                                    },
                                                    count: "1",
                                                    productAvailableFlag: true,
                                                    coolerEfficiency: "3",
                                                },
                                                {
                                                    imageId: "coolerID2",
                                                    imageUrl:
                                                        "http://localhost:89/Images/Product/300033885.png",
                                                    title: {
                                                        en: "<p>coolerID2 title eng</p>\n",
                                                        es: "<p>coolerID2 title spn</p>\n",
                                                    },
                                                    count: "2",
                                                    productAvailableFlag: true,
                                                    coolerEfficiency: "5",
                                                },
                                            ],
                                            estimatedPointsToAllocate: 0,
                                        },
                                    ],
                                },
                            },
                        },
                    },
                    400: {
                        description: "Bad Request",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/ErrorMessage",
                                },
                            },
                        },
                    },
                    415: {
                        description: "Unsupported Media Type",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/ErrorMessage",
                                },
                            },
                        },
                    },
                    500: {
                        description: "Internal Server Error",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/Problem",
                                },
                            },
                        },
                    },
                },
            },
        },
        "/api/v1/perfectstore/storeexecution/{storeId}": {
            get: {
                tags: ["Perfect Store"],
                summary: "Get the store execution list",
                description: "Get the store execution list",
                parameters: [
                    {
                        $ref: "#/components/parameters/storeIdInPath",
                    },
                    {
                        $ref: "#/components/parameters/oktaAccessToken",
                    },
                    {
                        $ref: "#/components/parameters/siteId",
                    },
                ],
                responses: {
                    200: {
                        description: "OK",
                        content: {
                            "application/json": {
                                example: {
                                    count: 1,
                                    data: [
                                        {
                                            id: "15284480",
                                            targetType: "Store Execution Target",
                                            targetName: {
                                                en: "16_PS",
                                                tr: "16_PS",
                                            },
                                            targetDescription: {
                                                en: "16_PS",
                                                tr: "16_PS",
                                            },
                                            targetImage: null,
                                            targetStartDate: "2024-03-13T18:30:00.000000Z",
                                            targetEndDate: "2024-06-27T18:30:00.000000Z",
                                            store: {
                                                id: 15284482,
                                                erpId: "5036479",
                                                totalTasks: 2,
                                                tasks: [
                                                    {
                                                        taskName1: {
                                                            en: "Task_001",
                                                            tr: "Task_001",
                                                        },
                                                        taskName2: {
                                                            en: "Task_002",
                                                            tr: "Task_002",
                                                        },
                                                        status: "Approved",
                                                        reason: "",
                                                        imageArray: [],
                                                    },
                                                    {
                                                        taskName1: {
                                                            en: "Task_001",
                                                            tr: "Task_001",
                                                        },
                                                        taskName2: {
                                                            en: "Task_002",
                                                            tr: "Task_002",
                                                        },
                                                        status: "Approved",
                                                        reason: "",
                                                        imageArray: [],
                                                    },
                                                ],
                                                totalPointAvailable: 100,
                                                pointsToAward: 100,
                                                totalPointAwarded: 100,
                                                status: "Completed",
                                                approvedDate: "2024-03-18T07:52:13.000000Z",
                                                pointAwardedDate: "2024-03-18T07:52:13.000000Z",
                                            },
                                        },
                                    ],
                                },
                            },
                        },
                    },
                    400: {
                        description: "Bad Request",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/ErrorMessage",
                                },
                            },
                        },
                    },
                    415: {
                        description: "Unsupported Media Type",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/ErrorMessage",
                                },
                            },
                        },
                    },
                    500: {
                        description: "Internal Server Error",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/Problem",
                                },
                            },
                        },
                    },
                },
            },
        },
        "/api/v1/perfectstore/executionUpdateStatus": {
            post: {
                tags: ["Perfect Store"],
                summary:
                    "Get the current target list of an perfect store for racks and coolers",
                description:
                    "Get the current target list of an perfect store for racks and coolers",
                requestBody: {
                    content: {
                        "application/json": {
                            schema: {
                                $ref: "#/components/schemas/ExecutionUpdateStatusDto",
                            },
                            examples: {},
                        },
                    },
                },
                parameters: [
                    {
                        $ref: "#/components/parameters/oktaAccessToken",
                    },
                    {
                        $ref: "#/components/parameters/siteId",
                    },
                ],
                responses: {
                    200: {
                        description: "OK",
                        content: {
                            "application/json": {
                                example: {
                                    success: {
                                        msg: " Status Update Successfully",
                                    },
                                },
                            },
                        },
                    },
                    400: {
                        description: "Bad Request",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/ErrorMessage",
                                },
                            },
                        },
                    },
                    415: {
                        description: "Unsupported Media Type",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/ErrorMessage",
                                },
                            },
                        },
                    },
                    500: {
                        description: "Internal Server Error",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/Problem",
                                },
                            },
                        },
                    },
                },
            },
        },
        "/api/v1/perfectstore/executionTargetImageUpload": {
            post: {
                tags: ["Perfect Store"],
                summary: "upload the images",
                description: "upload the images",
                requestBody: {
                    content: {
                        "application/json": {
                            schema: {
                                $ref: "#/components/schemas/ExecutionTargetImageUploadDto",
                            },
                            examples: {},
                        },
                    },
                },
                parameters: [
                    {
                        $ref: "#/components/parameters/oktaAccessToken",
                    },
                    {
                        $ref: "#/components/parameters/siteId",
                    },
                ],
                responses: {
                    200: {
                        description: "OK",
                        content: {
                            "application/json": {
                                example: {
                                    success: {
                                        msg: " images Update Successfully",
                                    },
                                },
                            },
                        },
                    },
                    400: {
                        description: "Bad Request",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/ErrorMessage",
                                },
                            },
                        },
                    },
                    415: {
                        description: "Unsupported Media Type",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/ErrorMessage",
                                },
                            },
                        },
                    },
                    500: {
                        description: "Internal Server Error",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/Problem",
                                },
                            },
                        },
                    },
                },
            },
            delete: {
                tags: ["Perfect Store"],
                summary: "Delete execution target images",
                description: "Deletes images associated with a specific execution ID.",
                parameters: [
                    {
                        $ref: "#/components/parameters/oktaAccessToken",
                    },
                    {
                        $ref: "#/components/parameters/siteId",
                    },
                ],
                requestBody: {
                    required: true,
                    content: {
                        "application/json": {
                            schema: {
                                type: "object",
                                properties: {
                                    imageId: {
                                        type: "array",
                                        items: {
                                            type: "string",
                                        },
                                        description: "Array of image IDs to be deleted",
                                        example: ["27627", "27628"],
                                    },
                                    executionId: {
                                        type: "string",
                                        description:
                                            "ID of the execution associated with the images",
                                        example: "612293",
                                    },
                                },
                            },
                        },
                    },
                },
                responses: {
                    200: {
                        description: "Successful deletion of images",
                        content: {
                            "application/json": {
                                schema: {
                                    type: "object",
                                    properties: {
                                        message: {
                                            type: "string",
                                            example: "Images successfully deleted",
                                        },
                                    },
                                },
                            },
                        },
                    },
                    400: {
                        description: "Bad request",
                        content: {
                            "application/json": {
                                schema: {
                                    type: "object",
                                    properties: {
                                        error: {
                                            type: "string",
                                            example: "Invalid input data",
                                        },
                                    },
                                },
                            },
                        },
                    },
                    404: {
                        description: "Not found",
                        content: {
                            "application/json": {
                                schema: {
                                    type: "object",
                                    properties: {
                                        error: {
                                            type: "string",
                                            example: "Execution or images not found",
                                        },
                                    },
                                },
                            },
                        },
                    },
                    500: {
                        description: "Internal server error",
                        content: {
                            "application/json": {
                                schema: {
                                    type: "object",
                                    properties: {
                                        error: {
                                            type: "string",
                                            example: "An unexpected error occurred",
                                        },
                                    },
                                },
                            },
                        },
                    },
                },
            },
        },
        "/api/v1/perfectstore/executionUpdateNotifyUser": {
            post: {
                tags: ["Perfect Store"],
                summary: "Update notification status for an execution",
                description:
                    "Updates the notification status for a specific execution ID.",
                parameters: [
                    {
                        $ref: "#/components/parameters/oktaAccessToken",
                    },
                    {
                        $ref: "#/components/parameters/storeIdInPath",
                    },
                ],
                requestBody: {
                    required: true,
                    content: {
                        "application/json": {
                            schema: {
                                type: "object",
                                properties: {
                                    executionId: {
                                        type: "string",
                                        description: "ID of the execution",
                                        example: "15284182",
                                    },
                                    notifyUserStatus: {
                                        type: "boolean",
                                        description: "Status to notify user",
                                        example: true,
                                    },
                                },
                                required: ["executionId", "notifyUserStatus"],
                            },
                        },
                    },
                },
                responses: {
                    200: {
                        description: "Status Update Successfully",
                        content: {
                            "application/json": {
                                schema: {
                                    type: "object",
                                    properties: {
                                        success: {
                                            type: "object",
                                            properties: {
                                                msg: {
                                                    type: "string",
                                                    example: "Status Update Successfully",
                                                },
                                            },
                                        },
                                    },
                                },
                            },
                        },
                    },
                    400: {
                        description: "Bad request",
                        content: {
                            "application/json": {
                                schema: {
                                    type: "object",
                                    properties: {
                                        error: {
                                            type: "string",
                                            example: "Invalid input data",
                                        },
                                    },
                                },
                            },
                        },
                    },
                    404: {
                        description: "Not found",
                        content: {
                            "application/json": {
                                schema: {
                                    type: "object",
                                    properties: {
                                        error: {
                                            type: "string",
                                            example: "Execution not found",
                                        },
                                    },
                                },
                            },
                        },
                    },
                    500: {
                        description: "Internal server error",
                        content: {
                            "application/json": {
                                schema: {
                                    type: "object",
                                    properties: {
                                        error: {
                                            type: "string",
                                            example: "An unexpected error occurred",
                                        },
                                    },
                                },
                            },
                        },
                    },
                },
            },
        },
        "/api/v1/perfectstore/updateExecutionRedirectionFlag": {
            post: {
                tags: ["Perfect Store"],
                summary: "Updates redirect to Guildline Flag",
                description:
                    "Updates redirect to Guildline Flag in Store Execution Target",
                parameters: [
                    {
                        $ref: "#/components/parameters/oktaAccessToken",
                    },
                    {
                        $ref: "#/components/parameters/storeIdInPath",
                    },
                ],
                requestBody: {
                    required: true,
                    content: {
                        "application/json": {
                            schema: {
                                type: "object",
                                properties: {
                                    storeExecutionTargetId: {
                                        type: "string",
                                        description: "ID of the store execution",
                                        example: "15284182",
                                    },
                                    isRedirectToGuideline: {
                                        type: "boolean",
                                        description: "Status to update ",
                                        example: true,
                                    },
                                },
                                required: ["storeExecutionTargetId", "isRedirectToGuideline"],
                            },
                        },
                    },
                },
                responses: {
                    200: {
                        description: "Status Update Successfully",
                        content: {
                            "application/json": {
                                schema: {
                                    type: "object",
                                    properties: {
                                        success: {
                                            type: "object",
                                            properties: {
                                                msg: {
                                                    type: "string",
                                                    example: "Status Update Successfully",
                                                },
                                                success: {
                                                    type: "boolean",
                                                    example: true
                                                }
                                            },
                                        },
                                    },
                                },
                            },
                        },
                    },
                    400: {
                        description: "Bad request",
                        content: {
                            "application/json": {
                                schema: {
                                    type: "object",
                                    properties: {
                                        error: {
                                            type: "string",
                                            example: "Invalid input data",
                                        },
                                    },
                                },
                            },
                        },
                    },
                    404: {
                        description: "Not found",
                        content: {
                            "application/json": {
                                schema: {
                                    type: "object",
                                    properties: {
                                        error: {
                                            type: "string",
                                            example: "Invalid storeExecutionTargetId provided",
                                        },
                                    },
                                },
                            },
                        },
                    },
                    500: {
                        description: "Internal server error",
                        content: {
                            "application/json": {
                                schema: {
                                    type: "object",
                                    properties: {
                                        error: {
                                            type: "string",
                                            example: "An unexpected error occurred",
                                        },
                                    },
                                },
                            },
                        },
                    },
                },
            },
        },
        "/api/v1/game": {
            get: {
                operationId: "getGameListGet",
                tags: ["Game"],
                summary: "Get the current game list as active",
                description: "Get the active current games",
                parameters: [
                    {
                        $ref: "#/components/parameters/oktaAccessToken",
                    },
                    {
                        $ref: "#/components/parameters/siteId",
                    },
                    {
                        $ref: "#/components/parameters/storeIdInHeader",
                    },
                ],
                responses: {
                    200: {
                        description: "OK",
                        content: {
                            "application/json": {
                                schema: {
                                    $ref: "#/components/schemas/GamesResponseDTO",
                                },
                                example: {
                                    count: 2,
                                    data: [
                                        {
                                            Id: 1659406,
                                            Url: "https://admin-latam.cep.dev.pepsico.com/api/v1/games/play/1659406",
                                            Image:
                                                "https://admin-latam.cep.dev.pepsico.com/_default_upload_bucket/shutterstock_1725983926_1.png",
                                            LogoImage:
                                                "https://admin-latam.cep.dev.pepsico.com/_default_upload_bucket/logo_1.png",
                                            GameType: "Free Kick",
                                            Title: {
                                                en: "Free kick",
                                                es: "Tiro libre",
                                                pt_BR: "tiro livre",
                                            },
                                            Description: {
                                                en: "Perfect your aim, score goals and earn points",
                                                es: "Perfecciona tu puntería, marca goles y gana puntos",
                                                pt_BR:
                                                    "Aperfeiçoe sua pontaria, marque gols e ganhe pontos",
                                            },
                                            LogoDescription: {
                                                en: "This game is a game that wins checks according to monthly and weekly rankings among the participants.",
                                                es: "Este juego es un juego que gana cheques según clasificaciones mensuales y semanales entre los participantes.",
                                                pt_BR:
                                                    "Este jogo é um jogo que ganha cheques de acordo com as classificações mensais e semanais entre os participantes.",
                                            },
                                            HowToPlayRule: {
                                                en: "The points you earn in the backgammon game will only determine your ranking in the backgammon game. These points will not be included in the points you earn from the sale.\n5 Coins are spent for each participation.\nOn a monthly basis, the rank coefficient check gains are defined for the first 3 people in the ranking.\nOn a weekly basis, rank coefficient check gains are defined for the first 50 people in the ranking.\nPoints are earned multiplied by the number of checkers left by the opponent. (6 * 100 = 600)\nWhen you Mars, you gain 3,000 points.\nCheck amounts won in the weekly ranking will be reflected in your accounts on Sunday night.\nCheck amounts won in the monthly ranking will be reflected in your accounts on the first day of the next month.\nPepsiCo reserves the right to unilaterally change the gift certificates and surprise gifts within the scope of the campaign. PepsiCo will notify any changes to the campaign conditions and rewards on this platform.",
                                                es: "Los puntos que gane en el juego de backgammon solo determinarán su clasificación en el juego de backgammon. Estos puntos no se incluirán en los puntos que gane por la venta.\nSe gastan 5 Coins por cada participación.\nMensualmente, las ganancias de verificación del coeficiente de rango se definen para las primeras 3 personas en el ranking.\nSemanalmente, se definen las ganancias de verificación del coeficiente de rango para las primeras 50 personas en el ranking.\nLos puntos se ganan multiplicados por el número de fichas que deja el oponente. (6 * 100 = 600)\nCuando llegas a Marte, ganas 3.000 puntos.\nLos montos de los cheques ganados en el ranking semanal se reflejarán en sus cuentas el domingo por la noche.\nLos montos de los cheques ganados en la clasificación mensual se verán reflejados en sus cuentas el primer día del mes siguiente.\nPepsiCo se reserva el derecho de cambiar unilateralmente los certificados de regalo y los regalos sorpresa dentro del alcance de la campaña. PepsiCo notificará cualquier cambio en las condiciones y recompensas de la campaña en esta plataforma.",
                                                pt_BR:
                                                    "Os pontos que você ganha no jogo de gamão determinarão apenas sua classificação no jogo de gamão. Esses pontos não serão incluídos nos pontos que você ganhar com a venda.\n5 Moedas são gastas para cada participação.\nMensalmente, os ganhos de verificação do coeficiente de classificação são definidos para as 3 primeiras pessoas no ranking.\nSemanalmente, os ganhos de verificação do coeficiente de classificação são definidos para as primeiras 50 pessoas no ranking.\nOs pontos são ganhos multiplicados pelo número de peças deixadas pelo oponente. (6 * 100 = 600)\nQuando você Marte, você ganha 3.000 pontos.\nOs valores dos cheques ganhos no ranking semanal serão refletidos em suas contas no domingo à noite.\nOs valores dos cheques ganhos no ranking mensal serão refletidos em suas contas no primeiro dia do próximo mês.\nA PepsiCo reserva-se o direito de alterar unilateralmente os certificados de oferta e os brindes surpresa no âmbito da campanha. A PepsiCo notificará quaisquer alterações nas condições e recompensas da campanha nesta plataforma.",
                                            },
                                            DisplayStartDate: "2023-03-02T18:30:00.000000Z",
                                            DisplayEndDate: "2023-03-23T18:30:00.000000Z",
                                            Status: "Active",
                                            StartTime: "06:30",
                                            EndTime: "22:00",
                                            DaysOfTheWeekUnavailable: ["SATURDAY", "SUNDAY"],
                                            IsPublic: true,
                                            Leaderboard: true,
                                            DailyLimit: 50,
                                            AvailableDailyLimit: 50,
                                            ActivityId: 1672609,
                                            ActivitySlug: "reward-points::freekick-play",
                                        },
                                        {
                                            Id: 1674101,
                                            Url: "https://admin-latam.cep.dev.pepsico.com/api/v1/games/play/1674101",
                                            Image:
                                                "https://admin-latam.cep.dev.pepsico.com/_default_upload_bucket/image%2019.png",
                                            LogoImage: null,
                                            GameType: "Spin The wheel",
                                            Title: {
                                                en: "Wheel of fortune",
                                                es: "Rueda de la fortuna",
                                                pt_BR: "Roda da fortuna",
                                            },
                                            Description: {
                                                en: "Spin the wheel, answer the questions and earn points.",
                                                es: "Gira la rueda, responde las preguntas y gana puntos.",
                                                pt_BR:
                                                    "Gire a roda, responda às perguntas e ganhe pontos.",
                                            },
                                            LogoDescription: {
                                                en: null,
                                                es: null,
                                                pt_BR: null,
                                            },
                                            HowToPlayRule: {
                                                en: null,
                                                es: null,
                                                pt_BR: null,
                                            },
                                            DisplayStartDate: "2023-02-28T18:30:00.000000Z",
                                            DisplayEndDate: "2023-04-29T18:30:00.000000Z",
                                            Status: "Active",
                                            StartTime: "00:00",
                                            EndTime: "23:45",
                                            DaysOfTheWeekUnavailable: ["SUNDAY"],
                                            IsPublic: true,
                                            Leaderboard: false,
                                            DailyLimit: 50,
                                            AvailableDailyLimit: 49,
                                            ActivityId: 1674172,
                                            ActivitySlug: "reward-points::luckygame_final-play",
                                            GameSettings: {
                                                Budget: 16000,
                                                BudgetPeriod: null,
                                                SliceSettings: [
                                                    {
                                                        SliceText: {
                                                            en: "points",
                                                            es: "puntos",
                                                            pt_BR: "pontos",
                                                        },
                                                        Point: 100,
                                                        Probability: 3,
                                                        SliceColor: "#c1515c",
                                                    },
                                                    {
                                                        SliceText: {
                                                            en: "points",
                                                            es: "puntos",
                                                            pt_BR: "pontos",
                                                        },
                                                        Point: 250,
                                                        Probability: 4,
                                                        SliceColor: "#cc4264",
                                                    },
                                                ],
                                                Quiz: {
                                                    en: [
                                                        {
                                                            Question: "What is the capital of Turkey? ",
                                                            correctAnswer: "Ankara",
                                                            answers: ["Istanbul", "Izmir", "Ankara"],
                                                        },
                                                    ],
                                                    es: [
                                                        {
                                                            Question: "¿Cuál es la capital de Turquía?",
                                                            correctAnswer: "Ankara",
                                                            answers: ["Istanbul", "Izmir", "Ankara"],
                                                        },
                                                    ],
                                                    pt_BR: [
                                                        {
                                                            Question: "¿Cuál es la capital de Turquía?",
                                                            correctAnswer: "Ankara",
                                                            answers: ["Istanbul", "Izmir", "Ankara"],
                                                        },
                                                    ],
                                                },
                                            },
                                        },
                                    ],
                                },
                            },
                        },
                    },
                    400: {
                        description: "Bad Request",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/ErrorMessage",
                                },
                            },
                        },
                    },
                    415: {
                        description: "Unsupported Media Type",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/ErrorMessage",
                                },
                            },
                        },
                    },
                    500: {
                        description: "Internal Server Error",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/Problem",
                                },
                            },
                        },
                    },
                },
            },
        },
        "/api/v1/games/score/{gameId}/{storeId}": {
            post: {
                operationId: "gamesScorePost",
                tags: ["Game"],
                summary: "Score Save API",
                description: "Save Games Score",
                parameters: [
                    {
                        $ref: "#/components/parameters/gameId",
                    },
                    {
                        $ref: "#/components/parameters/storeIdInPath",
                    },
                    {
                        $ref: "#/components/parameters/oktaAccessToken",
                    },
                    {
                        $ref: "#/components/parameters/siteId",
                    },
                ],
                requestBody: {
                    content: {
                        "application/json": {
                            schema: {
                                $ref: "#/components/schemas/GamesScoreRequestDto",
                            },
                            example: {
                                score: "100",
                            },
                        },
                    },
                },

                responses: {
                    200: {
                        description: "OK",
                        content: {
                            "application/json": {
                                schema: {
                                    $ref: "#/components/schemas/BasicResponseDto",
                                },
                                example: {
                                    success: true,
                                    msg: "score submitted successfully",
                                },
                            },
                        },
                    },
                    400: {
                        description: "Bad Request",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/ErrorMessage",
                                },
                            },
                        },
                    },
                    500: {
                        description: "Internal Server Error",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/Problem",
                                },
                            },
                        },
                    },
                },
            },
        },
        "/games/leaderboard/{gameId}": {
            post: {
                tags: ["Game"],
                summary: "Leaderboard API",
                description: "Leaderboard API",
                parameters: [
                    {
                        $ref: "#/components/parameters/gameId",
                    },
                    {
                        $ref: "#/components/parameters/siteId",
                    },
                ],
                responses: {
                    200: {
                        description: "OK",
                        content: {
                            "application/json": {
                                example: {
                                    MonthlyPrizes: [
                                        {
                                            rank: 1,
                                            points: "5000",
                                        },
                                        {
                                            rank: 2,
                                            points: "4000",
                                        },
                                        {
                                            rank: 3,
                                            points: "3000",
                                        },
                                        {
                                            rank: 4,
                                            points: "2000",
                                        },
                                        {
                                            rank: 5,
                                            points: "1000",
                                        },
                                    ],
                                    MonthlyRanking: [
                                        {
                                            type: "MonthlyRanking",
                                            storeName: "JUAN GOMEZ",
                                            storeId: "2211221122",
                                            value: "28119",
                                        },
                                        {
                                            type: "MonthlyRanking",
                                            storeName: "FABIAN ORTIZ",
                                            storeId: "0102127757",
                                            value: "100",
                                        },
                                        {
                                            type: "MonthlyRanking",
                                            storeName: "TIENDA MARCELLA",
                                            storeId: "0102161606",
                                            value: "50",
                                        },
                                    ],
                                    WinnerOfPreviousMonth: [
                                        {
                                            type: "WinnerOfPreviousMonth",
                                            storeName: "FABIAN ORTIZ",
                                            storeId: "0102127757",
                                            value: "200",
                                        },
                                        {
                                            type: "MonthlyRanking",
                                            storeName: "FABIAN ORTIZ",
                                            storeId: "0102127757",
                                            value: "100",
                                        },
                                        {
                                            type: "MonthlyRanking",
                                            storeName: "TIENDA MARCELLA",
                                            storeId: "0102161606",
                                            value: "50",
                                        },
                                    ],
                                    WeeklyPrizes: [
                                        {
                                            rank: 1,
                                            points: "1000",
                                        },
                                        {
                                            rank: 2,
                                            points: "900",
                                        },
                                        {
                                            rank: 3,
                                            points: "800",
                                        },
                                        {
                                            rank: 4,
                                            points: "700",
                                        },
                                        {
                                            rank: 5,
                                            points: "600",
                                        },
                                    ],
                                    WeeklyRanking: [
                                        {
                                            type: "WeeklyRanking",
                                            storeName: "JUAN GOMEZ",
                                            storeId: "2211221122",
                                            value: "28119",
                                        },
                                        {
                                            type: "WeeklyRanking",
                                            storeName: "FABIAN ORTIZ",
                                            storeId: "0102127757",
                                            value: "100",
                                        },
                                        {
                                            type: "WeeklyRanking",
                                            storeName: "TIENDA MARCELLA",
                                            storeId: "0102161606",
                                            value: "50",
                                        },
                                    ],
                                    WinnerOfPreviousWeek: [
                                        {
                                            type: "WinnerOfPreviousWeek",
                                            storeName: "FABIAN ORTIZ",
                                            storeId: "0102127757",
                                            value: "200",
                                        },
                                        {
                                            type: "WeeklyRanking",
                                            storeName: "FABIAN ORTIZ",
                                            storeId: "0102127757",
                                            value: "100",
                                        },
                                        {
                                            type: "WeeklyRanking",
                                            storeName: "TIENDA MARCELLA",
                                            storeId: "0102161606",
                                            value: "50",
                                        },
                                    ],
                                    GameId: "1659406",
                                },
                            },
                        },
                    },
                    400: {
                        description: "Bad Request",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/ErrorMessage",
                                },
                            },
                        },
                    },
                    500: {
                        description: "Internal Server Error",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/Problem",
                                },
                            },
                        },
                    },
                },
            },
        },
        "/api/v1/games/spin/{gameId}": {
            get: {
                operationId: "spinGameGet",
                tags: ["Game"],
                summary: "Create Spin for a user and allot the points",
                description: "Get points on spin",
                parameters: [
                    {
                        $ref: "#/components/parameters/gameId",
                    },
                    {
                        $ref: "#/components/parameters/oktaAccessToken",
                    },
                    {
                        $ref: "#/components/parameters/siteId",
                    },
                    {
                        $ref: "#/components/parameters/storeIdInHeader",
                    },
                ],
                responses: {
                    200: {
                        description: "OK",
                        content: {
                            "application/json": {
                                schema: {
                                    $ref: "#/components/schemas/SpinGameGetResponseDto",
                                },
                                example: {
                                    success: true,
                                    msg: "user spin",
                                    data: {
                                        sliceWon: 4,
                                        pointsWon: 20,
                                        pointsLeft: 2980,
                                    },
                                },
                            },
                        },
                    },
                    400: {
                        description: "Bad Request",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/ErrorMessage",
                                },
                            },
                        },
                    },
                    415: {
                        description: "Unsupported Media Type",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/ErrorMessage",
                                },
                            },
                        },
                    },
                    500: {
                        description: "Internal Server Error",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/Problem",
                                },
                            },
                        },
                    },
                },
            },
        },
        "/api/v1/games/quiz/{gameId}": {
            post: {
                operationId: "playQuizPost",
                tags: ["Game"],
                summary: "Save Quiz Status",
                description: "Save Quiz Status",
                parameters: [
                    {
                        $ref: "#/components/parameters/gameId",
                    },
                    {
                        $ref: "#/components/parameters/oktaAccessToken",
                    },
                    {
                        $ref: "#/components/parameters/siteId",
                    },
                    {
                        $ref: "#/components/parameters/storeIdInHeader",
                    },
                ],
                requestBody: {
                    content: {
                        "application/json": {
                            schema: {
                                $ref: "#/components/schemas/PlayquizRequestDto",
                            },
                            example: {
                                quizPassed: "true",
                            },
                        },
                    },
                },
                responses: {
                    200: {
                        description: "OK",
                        content: {
                            "application/json": {
                                schema: {
                                    $ref: "#/components/schemas/PlayquizResponseDto",
                                },
                                example: {
                                    success: true,
                                    msg: "Updated Successfully",
                                },
                            },
                        },
                    },
                    400: {
                        description: "Bad Request",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/ErrorMessage",
                                },
                            },
                        },
                    },
                    500: {
                        description: "Internal Server Error",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/Problem",
                                },
                            },
                        },
                    },
                },
            },
        },
        "/activity": {
            post: {
                tags: ["Rewards"],
                summary: "Add activity event",
                description: "Post reward activity event for logged in customer",
                parameters: [
                    {
                        $ref: "#/components/parameters/oktaAccessToken",
                    },
                    {
                        $ref: "#/components/parameters/siteId",
                    },
                    {
                        $ref: "#/components/parameters/storeIdInHeader",
                    },
                ],
                requestBody: {
                    required: true,
                    content: {
                        "application/json": {
                            schema: {
                                type: "object",
                                required: ["email", "activityId", "activitySlug"],
                                properties: {
                                    activityId: {
                                        type: "integer",
                                    },
                                    points: {
                                        type: "integer",
                                    },
                                    identifier: {
                                        type: "string",
                                    },
                                    tags: {
                                        type: "string",
                                    },
                                    metadata: {
                                        type: "string",
                                    },
                                    activitySlug: {
                                        type: "string",
                                        description:
                                            "Add reward points for storeId based on activity's activitySlug",
                                    },
                                },
                            },
                            example: {
                                activitySlug: "reward-points::content3",
                                tag: "video",
                            },
                        },
                    },
                },
                responses: {
                    200: {
                        description: "OK",
                        content: {
                            "application/json": {
                                examples: {
                                    EventAdded: {
                                        summary: "Activity successfully added",
                                        value: {
                                            success: true,
                                            message: "Event added",
                                            pointsAwarded: 5,
                                            points: 100,
                                            lifetimePoints: 500,
                                        },
                                    },
                                    EventExists: {
                                        summary: "Activity already completed",
                                        value: {
                                            success: false,
                                            message: "Activity already completed",
                                            pointsAwarded: 0,
                                            points: 0,
                                            lifetimePoints: 0,
                                            levelChanged: false,
                                            rewardEarned: false,
                                            goalsAchieved: 0,
                                            applicationId: 605984,
                                        },
                                    },
                                    VideoPointAdded: {
                                        summary:
                                            "Add reward points for storeId based on activity's activitySlug",
                                        value: {
                                            success: true,
                                            message: "Event added",
                                            pointsAwarded: 5,
                                            points: 100,
                                            lifetimePoints: 500,
                                        },
                                    },
                                },
                            },
                        },
                    },
                    400: {
                        description: "Bad Request",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/ErrorMessage",
                                },
                            },
                        },
                    },
                    415: {
                        description: "Unsupported Media Type",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/ErrorMessage",
                                },
                            },
                        },
                    },
                    500: {
                        description: "Internal Server Error",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/Problem",
                                },
                            },
                        },
                    },
                },
            },
            get: {
                tags: ["Rewards"],
                summary: "Get activity events",
                description:
                    "Get list of reward activity events for logged in customer",
                parameters: [
                    {
                        $ref: "#/components/parameters/siteId",
                    },
                    {
                        $ref: "#/components/parameters/oktaAccessToken",
                    },
                    {
                        $ref: "#/components/parameters/limit",
                    },
                    {
                        $ref: "#/components/parameters/offset",
                    },
                    {
                        name: "orderBy",
                        in: "query",
                        description: "orderBy",
                        required: false,
                        schema: {
                            type: "string",
                            default: "desc",
                        },
                    },
                    {
                        name: "order",
                        in: "query",
                        description: "order",
                        required: false,
                        schema: {
                            type: "string",
                            default: "desc",
                        },
                    },
                ],
                responses: {
                    200: {
                        description: "OK",
                        content: {
                            "application/json": {
                                example: {
                                    totalCount: 1,
                                    data: [
                                        {
                                            id: 1234,
                                            name: "User Added",
                                            eventType: "User",
                                            eventObjectId: 4567,
                                            identifier: "abc-123",
                                            tags: "dog,cat,mouse",
                                            metadata: '{"key":"value"}',
                                            created: "2022-01-01 00:00:01",
                                        },
                                    ],
                                },
                            },
                        },
                    },
                    400: {
                        description: "Bad Request",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/ErrorMessage",
                                },
                            },
                        },
                    },
                    415: {
                        description: "Unsupported Media Type",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/ErrorMessage",
                                },
                            },
                        },
                    },
                    500: {
                        description: "Internal Server Error",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/Problem",
                                },
                            },
                        },
                    },
                },
            },
        },
        "/api/v1/activity/history/{accountId}": {
            get: {
                operationId: "getActivityHistoryGET",
                tags: ["Rewards"],
                summary: "Get reward activity history events",
                description: "Get reward activity history events",
                parameters: [
                    {
                        $ref: "#/components/parameters/oktaAccessToken",
                    },
                    {
                        $ref: "#/components/parameters/siteId",
                    },
                    {
                        $ref: "#/components/parameters/accountId",
                    },
                    {
                        name: "type",
                        in: "query",
                        required: false,
                        description:
                            "History event type – all || activity || redemption || target",
                        schema: {
                            type: "string",
                            default: "all",
                        },
                    },
                    {
                        name: "date",
                        in: "query",
                        required: false,
                        description:
                            "Date from which to if start query on month basis then  query - Format YYYY-MM and else if start query on the particular date range filter then use the query - Format YYYY-MM-DD",
                        schema: {
                            type: "string",
                            default: "Current date",
                        },
                    },
                    {
                        name: "endDate",
                        in: "query",
                        required: false,
                        description:
                            "endDate from which to start query - Format YYYY-MM-DD for the particular date range filter",
                        schema: {
                            type: "string",
                        },
                    },
                    {
                        $ref: "#/components/parameters/limit",
                    },
                    {
                        $ref: "#/components/parameters/offset",
                    },
                    {
                        name: "order",
                        in: "query",
                        required: false,
                        description:
                            "History event order – Events ordered by date – desc || asc",
                        schema: {
                            type: "string",
                            default: "desc",
                        },
                    },
                ],
                responses: {
                    200: {
                        description: "OK",
                        content: {
                            "application/json": {
                                schema: {
                                    $ref: "#/components/schemas/GetActivityHistoryResponseDto",
                                },
                                example: {
                                    totalCount: 20,
                                    data: [
                                        {
                                            id: "17501450",
                                            type: "activity",
                                            points: 727.5,
                                            identifier: "1230b923-7ccc-4596-b983-eae7fda10fa4",
                                            pointStatus: "earned",
                                            tags: "",
                                            metadata: {
                                                ActivityTimestamp: null,
                                                ActivityDate: 1705925908,
                                                ActivitySlug: "reward-points::product-purchase-offline",
                                                ActivityName: "Product Purchase",
                                                StartingPointBalance: 34103.7,
                                                EndingPointBalance: 34831.2,
                                            },
                                            event_name: "Product Purchase",
                                            display_content: [],
                                            created: "2024-04-19 17:30:20",
                                        },
                                        {
                                            id: "17501449",
                                            type: "activity",
                                            points: 727.5,
                                            identifier: "d3aa45bc-71e2-4bda-98f5-0626000779bf",
                                            pointStatus: "earned",
                                            tags: "",
                                            metadata: {
                                                ActivityTimestamp: null,
                                                ActivityDate: 1705925908,
                                                ActivitySlug: "reward-points::product-purchase-offline",
                                                ActivityName: "Product Purchase",
                                                StartingPointBalance: 33376.2,
                                                EndingPointBalance: 34103.7,
                                            },
                                            event_name: "Product Purchase",
                                            display_content: [],
                                            created: "2024-04-19 17:30:14",
                                        },
                                        {
                                            id: "17501300",
                                            type: "activity",
                                            points: 50,
                                            identifier: null,
                                            pointStatus: "earned",
                                            tags: "luckyGameActivity",
                                            metadata: {
                                                GUID: "5468507",
                                                GameId: 13801813,
                                                ActivityTimestamp: null,
                                                ActivityDate: 1713528543,
                                                ActivitySlug: "reward-points::luckygame-play",
                                                ActivityName: "LuckyGame-play",
                                                StartingPointBalance: 33326.2,
                                                EndingPointBalance: 33376.2,
                                            },
                                            event_name: "LuckyGame-play",
                                            display_content: {
                                                en: {
                                                    title: "Lucky Game",
                                                    description: null,
                                                },
                                                tr: {
                                                    title: "Lucky Game",
                                                    description: null,
                                                },
                                            },
                                            created: "2024-04-19 15:09:32",
                                        },
                                        {
                                            id: "17501299",
                                            type: "activity",
                                            points: 5,
                                            identifier: null,
                                            pointStatus: "earned",
                                            tags: "",
                                            metadata: {
                                                ActivityTimestamp: null,
                                                ActivityDate: 1713528483,
                                                ActivitySlug: "reward-points::login-activity",
                                                ActivityName: "Login Activity",
                                                StartingPointBalance: 33321.2,
                                                EndingPointBalance: 33326.2,
                                            },
                                            event_name: "Login Activity",
                                            display_content: [],
                                            created: "2024-04-19 15:09:01",
                                        },
                                    ],
                                },
                            },
                        },
                    },
                    400: {
                        description: "Bad Request",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/ErrorMessage",
                                },
                            },
                        },
                    },
                    415: {
                        description: "Unsupported Media Type",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/ErrorMessage",
                                },
                            },
                        },
                    },
                    500: {
                        description: "Internal Server Error",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/Problem",
                                },
                            },
                        },
                    },
                },
            },
        },
        "/activity/points/{points}": {
            get: {
                tags: ["Rewards"],
                summary: "Get activity points",
                description: "Get estimated point value for activity",
                parameters: [
                    {
                        $ref: "#/components/parameters/oktaAccessToken",
                    },
                    {
                        $ref: "#/components/parameters/siteId",
                    },
                    {
                        name: "points",
                        in: "path",
                        required: true,
                        description: "Point Value",
                        schema: {
                            type: "integer",
                        },
                    },
                    {
                        name: "activity",
                        in: "query",
                        required: false,
                        description: "Activity Id or Activity Slug",
                        schema: {
                            type: "string",
                        },
                    },
                ],
                responses: {
                    200: {
                        description: "OK",
                        content: {
                            "application/json": {
                                example: {
                                    success: true,
                                    pointEstimate: 4321,
                                },
                            },
                        },
                    },
                    400: {
                        description: "Bad Request",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/ErrorMessage",
                                },
                            },
                        },
                    },
                    415: {
                        description: "Unsupported Media Type",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/ErrorMessage",
                                },
                            },
                        },
                    },
                    500: {
                        description: "Internal Server Error",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/Problem",
                                },
                            },
                        },
                    },
                },
            },
        },
        "/api/v1/games/leaderboard/{gameId}": {
            get: {
                operationId: "getLeaderboardGameGet",
                tags: ["Rewards"],
                summary: "Get list of leaderboards",
                description: "Get list of leaderboard collections",
                parameters: [
                    {
                        $ref: "#/components/parameters/gameId",
                    },
                    {
                        $ref: "#/components/parameters/oktaAccessToken",
                    },
                    {
                        $ref: "#/components/parameters/storeIdInHeader",
                    },
                    {
                        $ref: "#/components/parameters/limit",
                    },
                    {
                        $ref: "#/components/parameters/offset",
                    },
                    {
                        name: "order",
                        in: "query",
                        description: "order",
                        required: false,
                        schema: {
                            type: "string",
                            default: "desc",
                        },
                    },
                ],
                responses: {
                    200: {
                        description: "OK",
                        content: {
                            "application/json": {
                                schema: {
                                    $ref: "#/components/schemas/LeaderBoardGameResponseDto",
                                },
                                example: {
                                    totalCount: 1,
                                    data: [
                                        {
                                            id: 1234,
                                            name: "Pepsi Coins",
                                            metadata: '{"key":"value"}',
                                        },
                                    ],
                                },
                            },
                        },
                    },
                    400: {
                        description: "Bad Request",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/ErrorMessage",
                                },
                            },
                        },
                    },
                    415: {
                        description: "Unsupported Media Type",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/ErrorMessage",
                                },
                            },
                        },
                    },
                    500: {
                        description: "Internal Server Error",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/Problem",
                                },
                            },
                        },
                    },
                },
            },
        },
        "/leaderboard/{leaderboardId}": {
            get: {
                tags: ["Rewards"],
                summary: "Get leaderboard",
                description: "Get leaderboard listing",
                parameters: [
                    {
                        $ref: "#/components/parameters/oktaAccessToken",
                    },
                    {
                        $ref: "#/components/parameters/storeIdInHeader",
                    },
                    {
                        name: "leaderboardId",
                        in: "path",
                        required: true,
                        description: "Leaderboard ID",
                        schema: {
                            type: "integer",
                        },
                    },
                    {
                        $ref: "#/components/parameters/limit",
                    },
                    {
                        $ref: "#/components/parameters/offset",
                    },
                    {
                        name: "order",
                        in: "query",
                        description: "order",
                        required: false,
                        schema: {
                            type: "string",
                            default: "desc",
                        },
                    },
                ],
                responses: {
                    200: {
                        description: "OK",
                        content: {
                            "application/json": {
                                example: {
                                    totalCount: 1,
                                    data: [
                                        {
                                            id: 1234,
                                            points: 42.7,
                                            username: "JaneDoe",
                                            levelName: "Bronze",
                                            user: {
                                                tags: "dog,cat,mouse",
                                                metadata: '{"key":"value"}',
                                            },
                                            collectionName: "Pepsi Coins",
                                        },
                                    ],
                                },
                            },
                        },
                    },
                    400: {
                        description: "Bad Request",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/ErrorMessage",
                                },
                            },
                        },
                    },
                    415: {
                        description: "Unsupported Media Type",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/ErrorMessage",
                                },
                            },
                        },
                    },
                    500: {
                        description: "Internal Server Error",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/Problem",
                                },
                            },
                        },
                    },
                },
            },
        },
        "/api/v1/points/{accountId}": {
            get: {
                operationId: "getRewardPointsBalanceGET",
                tags: ["Rewards"],
                summary: "Reward Point Balance",
                description: "Returns points for account specified by AccountId.",
                parameters: [
                    {
                        $ref: "#/components/parameters/oktaAccessToken",
                    },
                    {
                        $ref: "#/components/parameters/siteId",
                    },
                    {
                        $ref: "#/components/parameters/accountId",
                    },
                ],
                responses: {
                    200: {
                        description: "OK",
                        content: {
                            "application/json": {
                                schema: {
                                    $ref: "#/components/schemas/GetRewardPointsBalanceResponseDto",
                                },
                                example: {
                                    accountId: "T1",
                                    points: 250,
                                    lifetimePoints: 500,
                                },
                            },
                        },
                    },
                    400: {
                        description: "Bad Request",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/ErrorMessage",
                                },
                            },
                        },
                    },
                    415: {
                        description: "Unsupported Media Type",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/ErrorMessage",
                                },
                            },
                        },
                    },
                    500: {
                        description: "Internal Server Error",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/Problem",
                                },
                            },
                        },
                    },
                },
            },
        },
        "/api/v1/points/transfer": {
            post: {
                operationId: "pointsTransferPost",
                tags: ["Rewards"],
                summary: "Transfer Reward Points",
                description: "Transfer rewards points from one account to another",
                parameters: [
                    {
                        $ref: "#/components/parameters/oktaAccessToken",
                    },
                    {
                        $ref: "#/components/parameters/siteId",
                    },
                ],
                requestBody: {
                    required: true,
                    content: {
                        "application/json": {
                            schema: {
                                $ref: "#/components/schemas/PointsTransferRequestDto",
                            },
                            example: {
                                fromAccountId: "abc123",
                                toAccountId: "def456",
                                points: 50,
                                identifier:
                                    "acolumbia::transferpoints i.e Colombia: columbia::transferpoints, Brazil: brazil::transferpoints, New Zealand: newzealand::transferpoints, Spain: spain::transferpoints, Turkey: turkey::transferpoints, Dominican Republic: dominicanrepublic::transferpoints",
                                tags: "abc,def,ghi",
                                metdata: '{"key": "value"}',
                            },
                        },
                    },
                },
                responses: {
                    200: {
                        description: "OK",
                        content: {
                            "application/json": {
                                schema: {
                                    $ref: "#/components/schemas/PointsTransferResponseDto",
                                },
                                examples: {
                                    Success: {
                                        description: "Points successfully transferred",
                                        value: {
                                            success: true,
                                            message: "Points transferred",
                                            pointsTransferred: 50,
                                            fromAccountPoints: 100,
                                            toAccountPoints: 450,
                                        },
                                    },
                                    Failure: {
                                        description: "Point transfer failed",
                                        value: {
                                            success: false,
                                            message: "Insufficient point balance",
                                            pointsTransferred: 0,
                                        },
                                    },
                                },
                            },
                        },
                    },
                    400: {
                        description: "Bad Request",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/ErrorMessage",
                                },
                            },
                        },
                    },
                    415: {
                        description: "Unsupported Media Type",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/ErrorMessage",
                                },
                            },
                        },
                    },
                    500: {
                        description: "Internal Server Error",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/Problem",
                                },
                            },
                        },
                    },
                },
            },
        },
        "/api/v1/points": {
            put: {
                operationId: "NBA Bulk Points transfer",
                tags: ["Rewards"],
                summary: "NBA Bulk Points transfer",
                description: `Awards reward points through trusted source NBA
        , **"transactionId"** is Combination of NBA ID + Store ID
        `,
                parameters: [
                    {
                        $ref: "#/components/parameters/oktaAccessToken",
                    }
                ],
                requestBody: {
                    required: true,
                    content: {
                        "application/json": {
                            example: [
                                {
                                    "storeId": "0000002626",
                                    "activityType": "reward-points::nba",
                                    "transactionId": "33c7ffa2-5580-4a25-ad7b-964c690a5f26",
                                    "points": 100.00
                                },
                                {
                                    "storeId": "0000002616",
                                    "activityType": "reward-points::nba",
                                    "transactionId": "33c7ffa2-5580-4a25-ad7b-964c690a5f21",
                                    "points": 200.00
                                }
                            ],
                            schema: {
                                type: "object",
                            },
                        },
                    },
                },
                responses: {
                    200: {
                        description: "OK",
                        content: {
                            "application/json": {
                                schema: {
                                    $ref: "#/components/schemas/PointsTransferResponseDto",
                                },
                                examples: {
                                    Success: {
                                        description: "Successfully scheduled",
                                        value: {
                                            success: true,
                                            message: "Points scheduled",
                                            jobId: 25
                                        },
                                    },
                                    Failure: {
                                        description: "Invalid request",
                                        value: {
                                            success: false,
                                            message: "Something went wrong",
                                            jobId: 26
                                        },
                                    },
                                },
                            },
                        },
                    },
                    400: {
                        description: "Bad Request",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/ErrorMessage",
                                },
                            },
                        },
                    },
                    415: {
                        description: "Unsupported Media Type",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/ErrorMessage",
                                },
                            },
                        },
                    },
                    500: {
                        description: "Internal Server Error",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/Problem",
                                },
                            },
                        },
                    },
                },
            },
        },
        "/api/v1/rewards": {
            get: {
                operationId: "getRewardListGET",
                tags: ["Rewards"],
                summary: "Reward – List",
                description: "Get list of rewards",
                parameters: [
                    {
                        $ref: "#/components/parameters/oktaAccessToken",
                    },
                    {
                        $ref: "#/components/parameters/storeIdInHeader",
                    },
                    {
                        $ref: "#/components/parameters/offset",
                    },
                    {
                        $ref: "#/components/parameters/limit",
                    },
                    {
                        $ref: "#/components/parameters/tags",
                    },
                    {
                        name: "rewardId",
                        in: "query",
                        description: "Reward ID",
                        required: false,
                        schema: {
                            type: "string",
                            default: null,
                        },
                    },
                    {
                        name: "rewardType",
                        in: "query",
                        description: "Reward Type",
                        required: false,
                        schema: {
                            type: "string",
                        },
                    },
                ],
                responses: {
                    200: {
                        description: "OK",
                        content: {
                            "application/json": {
                                schema: {
                                    $ref: "#/components/schemas/GetRewardListResponseDto",
                                },
                                example: {
                                    items: [
                                        {
                                            id: 317,
                                            productId: "B01I0ZQZQY",
                                            rewardCode: "DKPN5845PP",
                                            rewardType: "DigitalCoupon",
                                            mainCategory: {
                                                en: "Gift Voucher",
                                                es: "Vale regalo",
                                            },
                                            subCategory: {
                                                en: "Textile Gift Vouchers",
                                                es: "Vales regalo textiles",
                                            },
                                            title: {
                                                en: "Catalog1",
                                                es: "Catolog1 In Spanish",
                                            },
                                            shortDescription: {
                                                en: "Short description",
                                                es: "Descripcion short in spanish",
                                            },
                                            longDescription: {
                                                en: "Long description",
                                                es: "Descripcion long in spanish",
                                            },
                                            showOnHomePage: true,
                                            details: {
                                                size: {
                                                    en: "S",
                                                    es: "S",
                                                },
                                                dimensions: {
                                                    en: "15.5 x 11.5 x 1.5 inches",
                                                    es: "15,5 x 11,5 x 1,5 pulgadas",
                                                },
                                                weight: {
                                                    en: "0.5 lbs",
                                                    es: "0.5 libras",
                                                },
                                                color: {
                                                    en: "Red",
                                                    es: "Roja",
                                                },
                                                package: {
                                                    en: "Case",
                                                    es: "Caso",
                                                },
                                            },
                                            thumbnailImage:
                                                "https://cepadmin2.spo.nonprod.wspdop.pepsico.com/_default_upload_bucket/BlackPepsi.png",
                                            additionalImages: [],
                                            tags: ["Featured", "Tangible"],
                                            points: 50,
                                            value: 0,
                                            inStock: true,
                                            routeTypeId: '1',
                                            price: 0.01
                                        },
                                    ],
                                    currency: "€",
                                    totalCount: 1,
                                    PointsPerCurrencyUnit: 10,
                                },
                            },
                        },
                    },
                    400: {
                        description: "Bad Request",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/ErrorMessage",
                                },
                            },
                        },
                    },
                    415: {
                        description: "Unsupported Media Type",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/ErrorMessage",
                                },
                            },
                        },
                    },
                    500: {
                        description: "Internal Server Error",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/Problem",
                                },
                            },
                        },
                    },
                },
            },
        },
        "/api/v1/targets/{storeId}": {
            get: {
                operationId: "getTargetsGet",
                tags: ["Rewards"],
                summary: "Sales Targets",
                description:
                    "Returns list of active sales targets for a specific store",
                parameters: [
                    {
                        $ref: "#/components/parameters/oktaAccessToken",
                    },
                    {
                        $ref: "#/components/parameters/siteId",
                    },
                    {
                        $ref: "#/components/parameters/storeIdInPath",
                    },
                    {
                        $ref: "#/components/parameters/plainText",
                    },
                ],
                responses: {
                    200: {
                        description: "OK",
                        content: {
                            "application/json": {
                                schema: {
                                    $ref: "#/components/schemas/TargetResponseDTO",
                                },
                                examples: {
                                    monthlyTarget: {
                                        value: {
                                            totalCount: 1,
                                            data: [
                                                {
                                                    salesTargetId: 345678,
                                                    type: "Monthly Target",
                                                    title: {
                                                        en: "Sales Target Title",
                                                        es: "Título del objetivo de ventas",
                                                    },
                                                    excerpt: {
                                                        en: "Sales target excerpt",
                                                        es: "Extracto de objetivo de ventas",
                                                    },
                                                    content: {
                                                        en: "Sales target content",
                                                        es: "Contenido objetivo de ventas",
                                                    },
                                                    terms: {
                                                        en: "Sales target terms",
                                                        es: "Términos objetivo de ventas",
                                                    },
                                                    category: {
                                                        en: "Beverage",
                                                        es: "Beverage",
                                                    },
                                                    design: {
                                                        textColor: "#ffffff",
                                                        backgroundColor: "#ff0000",
                                                        buttonTextColor: "#ffffff",
                                                        buttonBackgroundColor: "#ff0000",
                                                    },
                                                    images: [
                                                        {
                                                            image:
                                                                "https://cepadmin2.spo.nonprod.wspdop.pepsico.com/_default_upload_bucket/BB_Originals_1920x650_V1_3.png",
                                                            imageText: {
                                                                en: "",
                                                                es: "",
                                                            },
                                                            id: "background-image",
                                                            optIn: false,
                                                        },
                                                        {
                                                            image:
                                                                "https://cepadmin2.spo.nonprod.wspdop.pepsico.com/_default_upload_bucket/BB_Originals_1920x650_V1_3.png",
                                                            imageText: {
                                                                en: "",
                                                                es: "",
                                                            },
                                                            id: "foreground-image",
                                                            optIn: true,
                                                        },
                                                    ],
                                                    url: "/optional/url/or/path/parameter",
                                                    product: null,
                                                    displayStartDate: "2022-08-28 00:00:00",
                                                    displayEndDate: "2022-10-04 00:00:00",
                                                    activeStartDate: "2022-09-01 00:00:00",
                                                    activeEndDate: "2022-09-30 00:00:00",
                                                    rewardDate: "2022-10-03 00:00:00",
                                                    rewardsAwarded: false,
                                                    requireOptIn: false,
                                                    optedIn: false,
                                                    status: "not_started",
                                                    dollarSpend: 0,
                                                    dollarTarget: 100,
                                                    pointsToAward: 50,
                                                    pointsAwarded: 0,
                                                    pointDisplay: "cumulative",
                                                    prerequisiteTargets: [null],
                                                    prerequisiteParent: 0,
                                                },
                                            ],
                                        },
                                    },
                                    productTarget: {
                                        value: {
                                            totalCount: 1,
                                            data: [
                                                {
                                                    salesTargetId: 345678,
                                                    type: "Product Target",
                                                    title: {
                                                        en: "Sales Target Title",
                                                        es: "Título del objetivo de ventas",
                                                    },
                                                    excerpt: {
                                                        en: "Sales target excerpt",
                                                        es: "Extracto de objetivo de ventas",
                                                    },
                                                    content: {
                                                        en: "Sales target content",
                                                        es: "Contenido objetivo de ventas",
                                                    },
                                                    terms: {
                                                        en: "Sales target terms",
                                                        es: "Términos objetivo de ventas",
                                                    },
                                                    category: {
                                                        en: "Beverage",
                                                        es: "Beverage",
                                                    },
                                                    design: {
                                                        textColor: "#ffffff",
                                                        backgroundColor: "#ff0000",
                                                        buttonTextColor: "#ffffff",
                                                        buttonBackgroundColor: "#ff0000",
                                                    },
                                                    images: [
                                                        {
                                                            image:
                                                                "https://cepadmin2.spo.nonprod.wspdop.pepsico.com/_default_upload_bucket/BB_Originals_1920x650_V1_3.png",
                                                            imageText: {
                                                                en: "",
                                                                es: "",
                                                            },
                                                            id: "background-image",
                                                            optIn: false,
                                                        },
                                                        {
                                                            image:
                                                                "https://cepadmin2.spo.nonprod.wspdop.pepsico.com/_default_upload_bucket/BB_Originals_1920x650_V1_3.png",
                                                            imageText: {
                                                                en: "",
                                                                es: "",
                                                            },
                                                            id: "foreground-image",
                                                            optIn: true,
                                                        },
                                                    ],
                                                    url: "/optional/url/or/path/parameter",
                                                    product: [123, 456, 789],
                                                    displayStartDate: "2022-08-28 00:00:00",
                                                    displayEndDate: "2022-10-04 00:00:00",
                                                    activeStartDate: "2022-09-01 00:00:00",
                                                    activeEndDate: "2022-09-30 00:00:00",
                                                    rewardDate: "2022-10-03 00:00:00",
                                                    rewardsAwarded: false,
                                                    requireOptIn: false,
                                                    optedIn: false,
                                                    status: "not_started",
                                                    dollarSpend: 0,
                                                    dollarTarget: 100,
                                                    pointsToAward: 50,
                                                    pointsAwarded: 0,
                                                    pointDisplay: "cumulative",
                                                    prerequisiteTargets: [null],
                                                    prerequisiteParent: 0,
                                                },
                                            ],
                                        },
                                    },
                                    productLayeredTarget: {
                                        value: {
                                            totalCount: 1,
                                            data: [
                                                {
                                                    salesTargetId: 456789,
                                                    type: "Product Target Layered",
                                                    title: {
                                                        en: "Sales Target Title",
                                                        es: "Título del objetivo de ventas",
                                                    },
                                                    excerpt: {
                                                        en: "Sales target excerpt",
                                                        es: "Extracto de objetivo de ventas",
                                                    },
                                                    content: {
                                                        en: "Sales target content",
                                                        es: "Contenido objetivo de ventas",
                                                    },
                                                    terms: {
                                                        en: "Sales target terms",
                                                        es: "Términos objetivo de ventas",
                                                    },
                                                    category: {
                                                        en: "Snacks",
                                                        es: "Snacks",
                                                    },
                                                    design: {
                                                        textColor: "#ffffff",
                                                        backgroundColor: "#ff0000",
                                                        buttonTextColor: "#ffffff",
                                                        buttonBackgroundColor: "#ff0000",
                                                    },
                                                    images: [
                                                        {
                                                            image:
                                                                "https://cepadmin2.spo.nonprod.wspdop.pepsico.com/_default_upload_bucket/BB_Originals_1920x650_V1_3.png",
                                                            imageText: {
                                                                en: "",
                                                                es: "",
                                                            },
                                                            id: "background-image",
                                                            optIn: false,
                                                        },
                                                        {
                                                            image:
                                                                "https://cepadmin2.spo.nonprod.wspdop.pepsico.com/_default_upload_bucket/BB_Originals_1920x650_V1_3.png",
                                                            imageText: {
                                                                en: "",
                                                                es: "",
                                                            },
                                                            id: "foreground-image",
                                                            optIn: true,
                                                        },
                                                    ],
                                                    url: null,
                                                    product: [456, 789, 890],
                                                    displayStartDate: "2022-08-28 00:00:00",
                                                    displayEndDate: "2022-10-04 00:00:00",
                                                    activeStartDate: "2022-09-01 00:00:00",
                                                    activeEndDate: "2022-09-30 00:00:00",
                                                    rewardDate: "2022-10-03 00:00:00",
                                                    rewardsAwarded: false,
                                                    requireOptIn: true,
                                                    optedIn: true,
                                                    status: "tier_achieved",
                                                    dollarSpend: 125,
                                                    tierTable: [
                                                        {
                                                            dollarTarget: 100,
                                                            pointsToAward: 150,
                                                        },
                                                        {
                                                            dollarTarget: 150,
                                                            pointsToAward: 150,
                                                        },
                                                        {
                                                            dollarTarget: 300,
                                                            pointsToAward: 300,
                                                        },
                                                    ],
                                                    tierAchieved: 1,
                                                    pointsAwarded: 150,
                                                    pointDisplay: "individual",
                                                    prerequisiteTargets: [null],
                                                    prerequisiteParent: 0,
                                                },
                                            ],
                                        },
                                    },
                                    prerequisiteTarget: {
                                        value: {
                                            totalCount: 2,
                                            data: [
                                                {
                                                    salesTargetId: 567890,
                                                    type: "Product Target Prerequisite",
                                                    title: {
                                                        en: "Sales Target Title",
                                                        es: "Título del objetivo de ventas",
                                                    },
                                                    excerpt: {
                                                        en: "Sales target excerpt",
                                                        es: "Extracto de objetivo de ventas",
                                                    },
                                                    content: {
                                                        en: "Sales target content",
                                                        es: "Contenido objetivo de ventas",
                                                    },
                                                    terms: {
                                                        en: "Sales target terms",
                                                        es: "Términos objetivo de ventas",
                                                    },
                                                    category: {
                                                        en: "Snacks",
                                                        es: "Snacks",
                                                    },
                                                    design: {
                                                        textColor: "#ffffff",
                                                        backgroundColor: "#ff0000",
                                                        buttonTextColor: "#ffffff",
                                                        buttonBackgroundColor: "#ff0000",
                                                    },
                                                    images: [
                                                        {
                                                            image:
                                                                "https://cepadmin2.spo.nonprod.wspdop.pepsico.com/_default_upload_bucket/BB_Originals_1920x650_V1_3.png",
                                                            imageText: {
                                                                en: "",
                                                                es: "",
                                                            },
                                                            id: "background-image",
                                                            optIn: false,
                                                        },
                                                        {
                                                            image:
                                                                "https://cepadmin2.spo.nonprod.wspdop.pepsico.com/_default_upload_bucket/BB_Originals_1920x650_V1_3.png",
                                                            imageText: {
                                                                en: "",
                                                                es: "",
                                                            },
                                                            id: "foreground-image",
                                                            optIn: true,
                                                        },
                                                    ],
                                                    url: "/optional/url/or/path/parameter",
                                                    product: [123, 456, 789],
                                                    displayStartDate: "2022-08-28 00:00:00",
                                                    displayEndDate: "2022-10-04 00:00:00",
                                                    activeStartDate: "2022-09-01 00:00:00",
                                                    activeEndDate: "2022-09-30 00:00:00",
                                                    rewardDate: "2022-10-03 00:00:00",
                                                    rewardsAwarded: false,
                                                    requireOptIn: false,
                                                    optedIn: false,
                                                    status: "in_progress",
                                                    dollarSpend: 150,
                                                    dollarTarget: 1000,
                                                    pointsToAward: 500,
                                                    pointsAwarded: 0,
                                                    pointDisplay: "individual",
                                                    prerequisiteTargets: [
                                                        {
                                                            salesTargetId: 1674241,
                                                            type: "Product Target Prerequisite",
                                                            title: {
                                                                en: "Ruffels Target Campaign",
                                                                es: "Ruffels Target Campaign",
                                                                pt_BR: "Ruffels Target Campaign",
                                                            },
                                                            excerpt: {
                                                                en: null,
                                                                es: null,
                                                                pt_BR: null,
                                                            },
                                                            content: {
                                                                en: "<p>This is a Snackes Sales Target. The Pre-rquisite Target must be completed before this target is awarded.</p>\n",
                                                                es: "<p>This is a Snackes Sales Target. The Pre-rquisite Target must be completed before this target is awarded.</p>\n",
                                                                pt_BR:
                                                                    "<p>This is a Snackes Sales Target. The Pre-rquisite Target must be completed before this target is awarded.</p>\n",
                                                            },
                                                            terms: {
                                                                en: "<ul>\n\t<li>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</li>\n\t<li>Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.</li>\n\t<li>When an unknown printer took a galley of type and scrambled.</li>\n</ul>\n",
                                                                es: null,
                                                                pt_BR: null,
                                                            },
                                                            category: {
                                                                en: "Beverage",
                                                                es: null,
                                                                pt_BR: null,
                                                            },
                                                            design: {
                                                                textColor: "#ffffff",
                                                                backgroundColor: "#004c97",
                                                                buttonTextColor: "#0073da",
                                                                buttonBackgroundColor: "#ffffff",
                                                            },
                                                            images: [
                                                                {
                                                                    image:
                                                                        "https://admin-latam.cep.dev.pepsico.com/_default_upload_bucket/Banner_2.png",
                                                                    imageText: {
                                                                        en: null,
                                                                        es: null,
                                                                        pt_BR: null,
                                                                    },
                                                                    id: "background-image",
                                                                    optIn: null,
                                                                },
                                                            ],
                                                            url: null,
                                                            product: [],
                                                            displayStartDate: "2023-02-28T18:30:00.000000Z",
                                                            displayEndDate: "2023-03-30T18:30:00.000000Z",
                                                            activeStartDate: "2023-02-28T18:30:00.000000Z",
                                                            activeEndDate: "2023-03-30T18:30:00.000000Z",
                                                            rewardDate: "2023-03-30T18:30:00.000000Z",
                                                            rewardsAwarded: false,
                                                            requireOptIn: false,
                                                            optedIn: true,
                                                            status: "",
                                                            dollarSpend: 0,
                                                            dollarTarget: 100,
                                                            pointsToAward: 100,
                                                            prerequisiteTargets: null,
                                                            prerequisiteParent: [1529456],
                                                        },
                                                    ],
                                                    prerequisiteParent: 0,
                                                },
                                                {
                                                    salesTargetId: 567891,
                                                    type: "Product Target Prerequisite",
                                                    title: {
                                                        en: "Sales Target Title",
                                                        es: "Título del objetivo de ventas",
                                                    },
                                                    excerpt: {
                                                        en: "Sales target excerpt",
                                                        es: "Extracto de objetivo de ventas",
                                                    },
                                                    content: {
                                                        en: "Sales target content",
                                                        es: "Contenido objetivo de ventas",
                                                    },
                                                    terms: {
                                                        en: "Sales target terms",
                                                        es: "Términos objetivo de ventas",
                                                    },
                                                    category: {
                                                        en: "Beverages",
                                                        es: "Beverages",
                                                    },
                                                    design: {
                                                        textColor: "#ffffff",
                                                        backgroundColor: "#ff0000",
                                                        buttonTextColor: "#ffffff",
                                                        buttonBackgroundColor: "#ff0000",
                                                    },
                                                    images: [
                                                        {
                                                            image:
                                                                "https://cepadmin2.spo.nonprod.wspdop.pepsico.com/_default_upload_bucket/BB_Originals_1920x650_V1_3.png",
                                                            imageText: {
                                                                en: "",
                                                                es: "",
                                                            },
                                                            id: "background-image",
                                                            optIn: false,
                                                        },
                                                        {
                                                            image:
                                                                "https://cepadmin2.spo.nonprod.wspdop.pepsico.com/_default_upload_bucket/BB_Originals_1920x650_V1_3.png",
                                                            imageText: {
                                                                en: "",
                                                                es: "",
                                                            },
                                                            id: "foreground-image",
                                                            optIn: true,
                                                        },
                                                    ],
                                                    url: "/optional/url/or/path/parameter",
                                                    product: [123, 456, 789],
                                                    displayStartDate: "2022-08-28 00:00:00",
                                                    displayEndDate: "2022-10-04 00:00:00",
                                                    activeStartDate: "2022-09-01 00:00:00",
                                                    activeEndDate: "2022-09-30 00:00:00",
                                                    rewardDate: "2022-10-03 00:00:00",
                                                    rewardsAwarded: false,
                                                    requireOptIn: false,
                                                    optedIn: false,
                                                    status: "in_progress",
                                                    dollarSpend: 50,
                                                    dollarTarget: 250,
                                                    pointsToAward: 0,
                                                    pointsAwarded: 0,
                                                    pointDisplay: "individual",
                                                    prerequisiteTargets: [null],
                                                    prerequisiteParent: 567890,
                                                },
                                            ],
                                        },
                                    },
                                    noTargets: {
                                        value: {
                                            totalCount: 0,
                                            data: null,
                                        },
                                    },
                                },
                            },
                        },
                    },
                    400: {
                        description: "Bad Request",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/ErrorMessage",
                                },
                            },
                        },
                    },
                    415: {
                        description: "Unsupported Media Type",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/ErrorMessage",
                                },
                            },
                        },
                    },
                    500: {
                        description: "Internal Server Error",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/Problem",
                                },
                            },
                        },
                    },
                },
            },
        },
        "/targets/{storeId}/{salesTargetId}": {
            post: {
                tags: ["Rewards"],
                summary: "Sales Targets",
                description: "Opt in to a sales target for a specific store",
                parameters: [
                    {
                        $ref: "#/components/parameters/oktaAccessToken",
                    },
                    {
                        $ref: "#/components/parameters/siteId",
                    },
                    {
                        $ref: "#/components/parameters/storeIdInPath",
                    },
                    {
                        name: "salesTargetId",
                        in: "path",
                        description: "Sales Target Id",
                        required: true,
                        schema: {
                            type: "number",
                        },
                    },
                ],
                responses: {
                    200: {
                        description: "OK",
                        content: {
                            "application/json": {
                                example: {
                                    salesTargetId: 345678,
                                    optedIn: true,
                                },
                            },
                        },
                    },
                    400: {
                        description: "Bad Request",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/ErrorMessage",
                                },
                            },
                        },
                    },
                    415: {
                        description: "Unsupported Media Type",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/ErrorMessage",
                                },
                            },
                        },
                    },
                    500: {
                        description: "Internal Server Error",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/Problem",
                                },
                            },
                        },
                    },
                },
            },
        },
        "/capture/click/reward/{rewardId}": {
            get: {
                tags: ["Rewards"],
                summary: "Click capture",
                description: "Click capture when you call the api.",
                parameters: [
                    {
                        $ref: "#/components/parameters/oktaAccessToken",
                    },
                    {
                        $ref: "#/components/parameters/siteId",
                    },
                    {
                        name: "rewardId",
                        in: "query",
                        description: "Reward ID",
                        required: false,
                        schema: {
                            type: "string",
                            default: null,
                        },
                    },
                ],
                responses: {
                    200: {
                        description: "OK",
                        content: {
                            "application/json": {
                                example: {
                                    success: "true",
                                    msg: "Click captured successfully",
                                },
                            },
                        },
                    },
                    400: {
                        description: "Loyalty Offer not found",
                        content: {
                            "application/json": {
                                example: {
                                    success: "false",
                                    msg: "Loyalty Offer not found",
                                },
                            },
                        },
                    },
                    415: {
                        description: "Unsupported Media Type",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/ErrorMessage",
                                },
                            },
                        },
                    },
                    500: {
                        description: "Internal Server Error",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/Problem",
                                },
                            },
                        },
                    },
                },
            },
        },
        "/api/v1/HolidayList": {
            post: {
                tags: ["Holiday"],
                summary: "List of Holidays",
                description: "Provide list of Yearly and Weekly holidays",
                requestBody: {
                    content: {
                        "application/json": {
                            schema: {
                                type: "object",
                                properties: {
                                    id: {
                                        type: "string",
                                        format: "int64",
                                    },
                                },
                            },
                            example: {},
                        },
                    },
                },
                parameters: [
                    {
                        $ref: "#/components/parameters/siteId",
                    },
                ],
                responses: {
                    200: {
                        description: "OK",
                        content: {
                            "application/json": {
                                example: {
                                    yearlyHoliday: ["29-06-22", "16-06-22"],
                                    weeklyHoliday: ["Sunday", "Monday"],
                                },
                            },
                        },
                    },
                    400: {
                        description: "Bad Request",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/ErrorMessage",
                                },
                            },
                        },
                    },
                    415: {
                        description: "Unsupported Media Type",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/ErrorMessage",
                                },
                            },
                        },
                    },
                    500: {
                        description: "Internal Server Error",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/Problem",
                                },
                            },
                        },
                    },
                },
            },
        },
        "/featuresList": {
            get: {
                tags: ["Features"],
                summary: "Features - List",
                description: "Get Rules associated with promotions",
                parameters: [
                    {
                        $ref: "#/components/parameters/siteId",
                    },
                    {
                        $ref: "#/components/parameters/xApiKey",
                    },
                ],
                responses: {
                    200: {
                        description: "OK",
                        content: {
                            "application/json": {
                                example: {
                                    featureFlags: [
                                        {
                                            uid: "FF.Columbia.WelcomeContainer",
                                            enable: "true",
                                        },
                                        {
                                            uid: "FF.Columbia.SystemInfoContainer",
                                            enable: "true",
                                        },
                                    ],
                                },
                            },
                        },
                    },
                    400: {
                        description: "Bad Request",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/ErrorMessage",
                                },
                            },
                        },
                    },
                    415: {
                        description: "Unsupported Media Type",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/ErrorMessage",
                                },
                            },
                        },
                    },
                    500: {
                        description: "Internal Server Error",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/Problem",
                                },
                            },
                        },
                    },
                },
            },
        },
        "/vendorList": {
            get: {
                tags: ["Vendor"],
                summary: "Vendor - List",
                description: "Get vendor list",
                parameters: [
                    {
                        $ref: "#/components/parameters/siteId",
                    },
                    {
                        $ref: "#/components/parameters/xApiKey",
                    },
                ],
                responses: {
                    200: {
                        description: "OK",
                        content: {
                            "application/json": {
                                example: [
                                    {
                                        name: "Nequi",
                                        vendorId: "Nequi",
                                        vendorType: "Products",
                                        image:
                                            "http://localhost:88/_default_upload_bucket/Bluebird_380x200px.png",
                                        externalId: "4656456",
                                        businessCategory: "Snack",
                                    },
                                    {
                                        name: "Daviplata",
                                        vendorId: "Daviplata",
                                        vendorType: "Products",
                                        image:
                                            "http://localhost:88/_default_upload_bucket/Bluebird_380x200px.png",
                                        externalId: "4656456",
                                        businessCategory: "Snack",
                                    },
                                ],
                            },
                        },
                    },
                    400: {
                        description: "Bad Request",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/ErrorMessage",
                                },
                            },
                        },
                    },
                    415: {
                        description: "Unsupported Media Type",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/ErrorMessage",
                                },
                            },
                        },
                    },
                    500: {
                        description: "Internal Server Error",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/Problem",
                                },
                            },
                        },
                    },
                },
            },
        },
        "/api/v1/notification/processRead": {
            post: {
                operationId: "notificationProcessReadPost",
                tags: ["Notification"],
                summary: "Notification Process Read",
                description: "Notification Process Read",
                parameters: [
                    {
                        $ref: "#/components/parameters/oktaAccessToken",
                    },
                ],
                requestBody: {
                    required: true,
                    content: {
                        "application/json": {
                            schema: {
                                $ref: "#/components/schemas/NotificationProcessReadRequestDto",
                            },
                            example: {
                                notificationID: "12344",
                            },
                        },
                    },
                },
                responses: {
                    200: {
                        description: "OK",
                        content: {
                            "application/json": {
                                schema: {
                                    $ref: "#/components/schemas/BasicResponseDto",
                                },
                                example: {
                                    success: true,
                                },
                            },
                        },
                    },
                    400: {
                        description: "Bad Request",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/ErrorMessage",
                                },
                            },
                        },
                    },
                    415: {
                        description: "Unsupported Media Type",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/ErrorMessage",
                                },
                            },
                        },
                    },
                    500: {
                        description: "Internal Server Error",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/Problem",
                                },
                            },
                        },
                    },
                },
            },
        },
        "/api/v1/notification/sentMessages": {
            get: {
                operationId: "getSentMessagesGet",
                tags: ["Notification"],
                summary: "Sent Messages Listing",
                description: "Sent Messages Listing",
                parameters: [
                    {
                        $ref: "#/components/parameters/oktaAccessToken",
                    },
                ],
                responses: {
                    200: {
                        description: "OK",
                        content: {
                            "application/json": {
                                schema: {
                                    $ref: "#/components/schemas/SentmessagesResponseDto",
                                },
                                example: {
                                    metadata: {
                                        totalItems: 1,
                                        unreadCount: 1,
                                    },
                                    notification: [
                                        {
                                            id: 1551454,
                                            title: "Just for testing",
                                            subTitle: "Spain SubTitle",
                                            description: "Your PepsiCo order is being prepared.",
                                            landingPage: null,
                                            type: "Order In Progress",
                                            imageURL:
                                                "http://localhost:99/PushNotification/Order In Progress/sample_2023_04_26_01_04_221682490742.jpeg",
                                            isUnread: true,
                                            date: "2023-04-06",
                                        },
                                    ],
                                },
                            },
                        },
                    },
                    400: {
                        description: "Bad Request",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/ErrorMessage",
                                },
                            },
                        },
                    },
                    415: {
                        description: "Unsupported Media Type",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/ErrorMessage",
                                },
                            },
                        },
                    },
                    500: {
                        description: "Internal Server Error",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/Problem",
                                },
                            },
                        },
                    },
                },
            },
        },
        "/api/v1/notification/customerSupport": {
            post: {
                tags: ["Notification"],
                summary: "Creates a Customer Support push notification",
                description:
                    "Creates a Notification object with Open status with one of Customer Support notification types.",
                parameters: [
                    {
                        $ref: "#/components/parameters/siteId",
                    },
                    {
                        $ref: "#/components/parameters/storeIdInHeader",
                    },
                    {
                        $ref: "#/components/parameters/xApiKey",
                    },
                ],
                requestBody: {
                    content: {
                        "application/json": {
                            schema: {
                                type: "object",
                                properties: {
                                    notificationType: {
                                        type: "string",
                                        enum: ["Customer Support Opened"],
                                    },
                                },
                            },
                        },
                    },
                },
                responses: {
                    201: {
                        description: "Created",
                        content: {
                            "application/json": {
                                example: {
                                    success: true,
                                    msg: "Notification created successfully",
                                    notificationId: 123456,
                                },
                            },
                        },
                    },
                    400: {
                        description: "Bad Request",
                        content: {
                            "application/json": {
                                example: {
                                    success: false,
                                    msg: "Notification type not allowed",
                                },
                            },
                        },
                    },
                },
            },
        },
        "/api/v1/sendFeedbackForm": {
            post: {
                operationId: "sendFeedbackFormPost",
                tags: ["Feedback Form"],
                summary: "Send Feedback Form",
                description: "Send Feedback Form",
                parameters: [
                    {
                        $ref: "#/components/parameters/oktaAccessToken",
                    },
                ],
                requestBody: {
                    content: {
                        "application/json": {
                            schema: {
                                $ref: "#/components/schemas/SendFeedbackFormRequestDto",
                            },
                            example: {
                                storeId: "100069",
                                storeName: "TARR LLARS MUNDET CHIRING",
                                note: "Good one!!",
                            },
                        },
                    },
                },
                responses: {
                    200: {
                        description: "OK",
                        content: {
                            "application/json": {
                                schema: {
                                    $ref: "#/components/schemas/BasicResponseDto",
                                },
                                example: {
                                    success: true,
                                },
                            },
                        },
                    },
                    400: {
                        description: "Bad Request",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/ErrorMessage",
                                },
                            },
                        },
                    },
                    415: {
                        description: "Unsupported Media Type",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/ErrorMessage",
                                },
                            },
                        },
                    },
                    500: {
                        description: "Internal Server Error",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/Problem",
                                },
                            },
                        },
                    },
                },
            },
        },
        "/data-import/order": {
            post: {
                tags: ["Data Migration"],
                summary: "Create Order",
                description: "Migration For Order",
                requestBody: {
                    content: {
                        "application/json": {
                            schema: {
                                type: "object",
                            },
                            example: [
                                {
                                    customerPONumber: "6942002037",
                                    orderType: "REWARDS",
                                    EstimateRewardPoints: 100,
                                    DeliveryPoints: 50,
                                    VendorId: "41",
                                    releaseFlag: "O",
                                    orderState: "Delivered",
                                    orderSubState: "",
                                    comment: "abcd comment",
                                    rejectedReason: "abcd reject",
                                    orderDate: "2023-01-20 14:09",
                                    deliveryDate: "2023-01-21",
                                    actualDeliveryDate: "2023-01-22",
                                    items: [
                                        {
                                            product: "000000000300069424",
                                            quantityRequested: 12,
                                            basePrice: "20",
                                            materialUOM: "efgh",
                                            grandTotal: "240",
                                            isPromotional: "Yes",
                                            deliveryMethod: "Shipment",
                                            digitalAddress: "abcd",
                                            saleUOM: "saleuom",
                                            baseUOM: "baseuom",
                                            AlternateOrderLineNumber: "onumber",
                                            conversionMulitplierToBase: "2",
                                            promotionId: "36",
                                            promotionName: "test",
                                            comments: "test comment",
                                            itemNote: "test note",
                                            quantityConfirmed: 12,
                                            itemPrice: 40,
                                            discount: 5,
                                            taxAmount: 35,
                                            totalAmount: 35,
                                            invoiceUnitPriceAmount: 20,
                                            invoiceTotalGrossAmount: 30,
                                            invoiceTotalDiscountAmount: 40,
                                            invoiceTotalTaxAmount: 50,
                                            invoiceTotalNetAmount: 60,
                                            invoiceTotalPromotionAmount: 70,
                                        },
                                    ],
                                    currency: "R",
                                    orderTotal: 7,
                                    totalPrice: 45,
                                    taxableAmount: 45,
                                    subTotalPrice: 42,
                                    subTotalNetPrice: 40,
                                    invoiceTotalGrossAmount: 55,
                                    invoiceTotalTaxAmount: 3,
                                    invoiceTotalDiscountAmount: 2,
                                    invoiceTotalPromotionAmount: 0,
                                    invoiceTotalNetAmount: 56,
                                    customerID: "7890098420",
                                    customerAddrLine1: "abcd",
                                    customerAddrLine2: "efgh",
                                    customerCity: "Hyderabad",
                                    customerSate: "TL",
                                    customerZip: "500755",
                                    customerCountryCode: "COL",
                                    phoneNumber: "7584995867",
                                    ochId: "abcd1234",
                                    sourceOrderId: "B2B1669019841381382",
                                    routeId: "7",
                                    orderLocationId: "KOK",
                                    customerGtmu: "78987",
                                    routeSalesRepWorkForceId: "88905543",
                                    orderTypeCode: "ZQT1",
                                    orderSubTypeCode: "Z1",
                                    visitScheduleId: "abcd2222",
                                    countryIsoCode: "COL",
                                    paymentTermsCode: "222",
                                    orderPaymentStatus: "Complete",
                                    AlternateOrderId: "0912091209120912",
                                    ProcessedStatus: "Success",
                                    RewardsProcessedDate: "2023-01-20 16:09",
                                    DollarSpendProcessedDate: "2023-01-20 15:09",
                                    DollarSpend: "56",
                                    DollarSpendCalculated: "56",
                                },
                            ],
                        },
                    },
                },
                parameters: [
                    {
                        $ref: "#/components/parameters/xApiKey",
                    },
                    {
                        name: "siteId",
                        in: "header",
                        required: true,
                        description: "siteId",
                        schema: {
                            type: "string",
                            default: "7",
                        },
                    },
                ],
                responses: {
                    200: {
                        description: "OK",
                        content: {
                            "application/json": {
                                examples: {
                                    success: {
                                        value: {
                                            success: true,
                                            message: "All orders created successfully!",
                                        },
                                    },
                                    failure: {
                                        value: {
                                            success: false,
                                            msg: "Some orders could not be created",
                                        },
                                    },
                                },
                            },
                        },
                    },
                    400: {
                        description: "Bad Request",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/ErrorMessage",
                                },
                            },
                        },
                    },
                    500: {
                        description: "Internal Server Error",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/Problem",
                                },
                            },
                        },
                    },
                },
            },
        },
        "/data-import/customer-erpcustomer": {
            post: {
                tags: ["Data Migration"],
                summary: "Create Customer Erp Customer",
                description: "Migration For Customer and Erp Customer",
                requestBody: {
                    content: {
                        "application/json": {
                            schema: {
                                type: "object",
                            },
                            example: [
                                {
                                    userId: "612@pepsico.com",
                                    firstName: "Faisal",
                                    lastName: "Khan",
                                    email: "khanf664@gmail.com",
                                    phone: "6090102",
                                    loginExpiryDate: "01-03-2023",
                                    alternativeNumber: "8707208556",
                                    dob: "13-02-1997",
                                    settings: {
                                        role: "Manager",
                                        userType: "Payer",
                                        deviceType: "TYPE",
                                        deviceToken: "TOKEN-DATA",
                                        MAID: "12121212",
                                        status: "Active",
                                    },
                                    prefrences: {
                                        smsPermission: "Promotions;Marketing",
                                        digitalPlatformType: "CE;DO",
                                        emailPermission: "",
                                        whatsappPermission: "Promotions;Marketing",
                                        executionType: "Snack;Beverage",
                                        pushNotificationPermission: "",
                                        businessType: "Snack;Beverage",
                                        cookiePermission: true,
                                        obtainPermission: "SMS;Email",
                                    },
                                    erpCustomers: [
                                        {
                                            storeName: "FAS",
                                            erpId: 612,
                                            loginName: "T033",
                                            nickName: "T01",
                                            customerRefNumber: "Pepsico",
                                            customerType: "T1",
                                            billingType: "",
                                            isDefault: "121212",
                                            activeStatus: "billTo",
                                            waConsent: "UPoosadP",
                                            taxAreaCode: "9881",
                                            vatNumber: "00110578854",
                                            taxType: "TYPE1",
                                            taxId: "000123",
                                            phoneNumberContactPoint: "Something",
                                            parentMemberid: "4535353",
                                            email: [
                                                {
                                                    email: "afsa@kdasd.ocm",
                                                },
                                            ],
                                            mobile: [
                                                {
                                                    mobile: "609012",
                                                },
                                            ],
                                            address: {
                                                isDefault: true,
                                                addressId: "34353",
                                                addressType: "ABCd",
                                                buildingNumber: "32434",
                                                addressLine1: "Salora Vihar",
                                                addressLine2: "C Block",
                                                city: "Noida",
                                                country: "India",
                                                state: "UP",
                                                stateOrProvinceCode: "45345",
                                                stateOrProvinceName: "Test",
                                                countyName: "UP",
                                                countryIsoName: "UP",
                                                postalCode: "201301",
                                                latitude: "1002",
                                                longitude: "84",
                                            },
                                            deliveryData: {
                                                deliveryService: "DS",
                                                deliveryDays: "Sunday,Monday",
                                                cutoffTime: "11:30",
                                            },
                                            location: [
                                                {
                                                    locationId: "8717",
                                                    accountCode: "12324234234",
                                                    locationtype: "Internal",
                                                },
                                            ],
                                            segments: [
                                                "/Colombia/Segments/Channel/Away From Homess",
                                                "/Colombia/Segments/Channel/Away From Home",
                                            ],
                                            salesDetails: {
                                                gtmuId: "13123213",
                                                relationshipToCustomerId: "asdasd",
                                                division: "dwdeqwe",
                                                distributionCenterId: "ewqe",
                                                distributionChannel: "wqeqwe",
                                                companyName: "company name",
                                                companyCode: "code",
                                                salesOfficeName: "sales office ",
                                                salesVolumeClassificationValue: "value11",
                                                salesChannelCode: "00000111",
                                                salesOrganization: "Pepsico",
                                                salesBlockStatus: "lock",
                                                salesOfficeCode: "121212",
                                                classificationTypeCode: 4444,
                                                classificationCode: 122,
                                                businessSegmentationNodeCode: "sadsadasd",
                                                relationShipTypeCode: "2313123123",
                                                businessType: "Bussiness Type",
                                                executionType: "executionType",
                                                digitalPlatformType: "digitalPlatformType",
                                            },
                                            paymentDetails: {
                                                paymentMethodCode: "PHONPE",
                                                creditLimitAmount: "1000000",
                                                creditBlock: "NO",
                                                paymentTermCode: "PTC",
                                            },
                                        },
                                    ],
                                },
                            ],
                        },
                    },
                },
                parameters: [
                    {
                        $ref: "#/components/parameters/xApiKey",
                    },
                    {
                        name: "siteId",
                        in: "header",
                        required: true,
                        description: "siteId",
                        schema: {
                            type: "string",
                            default: "7",
                        },
                    },
                ],
                responses: {
                    200: {
                        description: "OK",
                        content: {
                            "application/json": {
                                examples: {
                                    success: {
                                        value: {
                                            success: true,
                                            topicName: "Customer",
                                            message: "imported successfully",
                                        },
                                    },
                                    failure: {
                                        value: {
                                            success: false,
                                            msg: "You have exceed the limit request of the body",
                                        },
                                    },
                                },
                            },
                        },
                    },
                    400: {
                        description: "Bad Request",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/ErrorMessage",
                                },
                            },
                        },
                    },
                    415: {
                        description: "Unsupported Media Type",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/ErrorMessage",
                                },
                            },
                        },
                    },
                    500: {
                        description: "Internal Server Error",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/Problem",
                                },
                            },
                        },
                    },
                },
            },
        },
        "/data-import/products": {
            post: {
                tags: ["Data Migration"],
                summary: "Create Product",
                description: "Migration For Product",
                requestBody: {
                    content: {
                        "application/json": {
                            schema: {
                                type: "object",
                            },
                            example: [
                                {
                                    productId: "127435",
                                    published: true,
                                    ProductStatus: "active",
                                    name: "ProductNAme",
                                    barCode: "barCode3242",
                                    skuNumber: "skuNumber89",
                                    saleUnitName: "saleUnitName98398",
                                    productLine: "productLine8389",
                                    productGroup: "productGroup94",
                                    productContainer: "productContainer83982",
                                    productSize: "productSize8932984",
                                    gtmuId: "gtmuId329",
                                    salesOrg: "salesOrg98398",
                                    package: "package89894",
                                    distributionChannel: "distributionChannel893289",
                                    displayOrder: 1,
                                    minimumOrderQuantity: 100,
                                    maximumOrderQuantity: 1000,
                                    unitMultiplier: 3,
                                    wishlist: 4,
                                    rewardPoints: 23,
                                    allowedInMultiplesOf: 9,
                                    returnPriceForBaseUnit: 98,
                                    saleUnitConversionToBase: 12,
                                    suggestedRetailSellingPrice: 288,
                                    baseUom: "baseUom 8234",
                                    gtin: "gtin32842878",
                                    showOnEcommerce: true,
                                    showOnEngagement: false,
                                    displayProductSize: "asd",
                                    productType: "abcd",
                                    status: true,
                                    isReturnable: true,
                                    hasDeposit: false,
                                    descriptionERP: "descriptionERP99435",
                                    descriptionBusniess: "descriptionBusniess 398",
                                    brandId: "brandId 893284",
                                    displayBrandId: "Brand2342",
                                    subBrandId: "subBrandId 32849",
                                    erpCategory: "erpCategory 101",
                                    categoryId: "categoryid 3829",
                                    category: "category123",
                                    subCategoryId: "subCategoryId 8349",
                                    localized: {
                                        en: {
                                            displayName: "en_displayName_9834",
                                            displaySize: "en_displaySize_9834",
                                            longDescription: "en_longDescription_9834",
                                            shortDescription: "en_shortDescription_9834",
                                            productFlavor: "en_productFlavor_9834",
                                            productBrand: "en_productBrand_9834",
                                            displayBrand: "en_displayBrand_9834",
                                            productSubBrand: "en_productSubBrand_9834",
                                            productBusinessCategory:
                                                "en_productBusinessCategory_9834",
                                            subCategory: "en_subCategory_9834",
                                        },
                                        es: {
                                            displayName: "de_displayName_9834",
                                            displaySize: "de_displaySize_9834",
                                            longDescription: "de_longDescription_9834",
                                            shortDescription: "de_shortDescription_9834",
                                            productFlavor: "de_productFlavor_9834",
                                            productBrand: "de_productBrand_9834",
                                            displayBrand: "de_displayBrand_9834",
                                            productSubBrand: "de_productSubBrand_9834",
                                            productBusinessCategory:
                                                "de_productBusinessCategory_9834",
                                            subCategory: "de_subCategory_9834",
                                        },
                                    },
                                    taxes: [
                                        {
                                            TaxTypeCode: "TaxTypeCode_834211",
                                            TaxCountryCode: "AU",
                                            TaxCode: "TaxCode_834211",
                                            TaxName: "TaxName_834211",
                                        },
                                    ],
                                    saleUoms: [
                                        {
                                            code: "code_5234272",
                                            name: "name_5234272",
                                            baseUomConversionNumerator: 83749,
                                            baseUomConversionDenominator: 1231,
                                        },
                                    ],
                                    location: ["8717", "8616"],
                                },
                            ],
                        },
                    },
                },
                parameters: [
                    {
                        $ref: "#/components/parameters/xApiKey",
                    },
                    {
                        name: "siteId",
                        in: "header",
                        required: true,
                        description: "siteId",
                        schema: {
                            type: "string",
                            default: "7",
                        },
                    },
                ],
                responses: {
                    200: {
                        description: "OK",
                        content: {
                            "application/json": {
                                examples: {
                                    success: {
                                        value: {
                                            success: true,
                                            topicName: "Product",
                                            message: "imported successfully",
                                        },
                                    },
                                    failure: {
                                        value: {
                                            success: false,
                                            msg: "You have exceed the limit request of the body",
                                        },
                                    },
                                },
                            },
                        },
                    },
                    400: {
                        description: "Bad Request",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/ErrorMessage",
                                },
                            },
                        },
                    },
                    415: {
                        description: "Unsupported Media Type",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/ErrorMessage",
                                },
                            },
                        },
                    },
                    500: {
                        description: "Internal Server Error",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/Problem",
                                },
                            },
                        },
                    },
                },
            },
        },
        "/data-import/vendor": {
            post: {
                tags: ["Data Migration"],
                summary: "Create Vendor",
                description: "Migration For Vendor",
                requestBody: {
                    content: {
                        "application/json": {
                            schema: {
                                type: "object",
                            },
                            example: [
                                {
                                    vendorName: "8732",
                                    vendorId: "8732",
                                    deliveryService: "24",
                                    cutoffTime: "17:00",
                                    deliveryDays: "2;3;4;7",
                                    deliveryType: "External",
                                    externalId: "871234",
                                    businessCategory: "Snack",
                                    location: "test location",
                                },
                            ],
                        },
                    },
                },
                parameters: [
                    {
                        $ref: "#/components/parameters/xApiKey",
                    },
                    {
                        name: "siteId",
                        in: "header",
                        required: true,
                        description: "siteId",
                        schema: {
                            type: "string",
                            default: "7",
                        },
                    },
                ],
                responses: {
                    200: {
                        description: "OK",
                        content: {
                            "application/json": {
                                examples: {
                                    success: {
                                        value: {
                                            success: true,
                                            topicName: "Customer",
                                            message: "imported successfully",
                                        },
                                    },
                                    failure: {
                                        value: {
                                            success: false,
                                            msg: "You have exceed the limit request of the body",
                                        },
                                    },
                                },
                            },
                        },
                    },
                    400: {
                        description: "Bad Request",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/ErrorMessage",
                                },
                            },
                        },
                    },
                    415: {
                        description: "Unsupported Media Type",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/ErrorMessage",
                                },
                            },
                        },
                    },
                    500: {
                        description: "Internal Server Error",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/Problem",
                                },
                            },
                        },
                    },
                },
            },
        },
        "/api/v2/salesHistory": {
            get: {
                operationId: "getSalesHistoryByIdGet",
                tags: ["Sales History"],
                summary: "Get sales history of a User",
                description:
                    "Get sales history of a User. This is also where offline order appear for europe.",
                parameters: [
                    {
                        $ref: "#/components/parameters/oktaAccessToken",
                    },
                    {
                        $ref: "#/components/parameters/siteId",
                    },
                    {
                        $ref: "#/components/parameters/storeIdInHeader",
                    },
                    {
                        $ref: "#/components/parameters/limit",
                    },
                    {
                        $ref: "#/components/parameters/offset",
                    },
                ],
                responses: {
                    200: {
                        description: "OK",
                        content: {
                            "application/json": {
                                schema: {
                                    $ref: "#/components/schemas/GetSalesHistoryResponseDto",
                                },
                                examples: {
                                    default: {
                                        value: {
                                            metadata: {
                                                __comment: "Info about the JSON File.",
                                                itemKey: "Sales History",
                                                totalItems: 48,
                                            },
                                            orders: [
                                                {
                                                    contractualDiscount: null,
                                                    currency: null,
                                                    customerAddress: null,
                                                    deliveredPoints: 320,
                                                    deliveryDate: "2023-10-09",
                                                    ediNumber: null,
                                                    erpOrderNumber: null,
                                                    erpShippingCondition: null,
                                                    estimatedDeliveryDate: null,
                                                    estimatedPoints: null,
                                                    id: 3018164,
                                                    invoiceNumber: null,
                                                    orderType: "E-Order",
                                                    documentId: "322_2023_ZZ532063888",
                                                    totalPrice: 400,
                                                    documentSource: "Invoice",
                                                    orderDate: null,
                                                    dollarSpend: null,
                                                    dollarSpendCalculated: null,
                                                    customerId: "1176577",
                                                    itemCount: 1,
                                                    orderLocationId: null,
                                                    orderNote: null,
                                                    orderNumber: null,
                                                    orderStatus: "Delivered",
                                                    paymentTermCode: null,
                                                    poNUmber: null,
                                                    promotionalDiscount: null,
                                                    rejectedNote: null,
                                                    releaseFlag: null,
                                                    routeId: null,
                                                    routeMarketplaceId: null,
                                                    routeOrderTypeCode: null,
                                                    routeOrderTypeSubCode: null,
                                                    routeRepId: null,
                                                    routeVisitSchedule: null,
                                                    sourceSystemId: null,
                                                    subtotal: null,
                                                    taxAmount: null,
                                                    totalDiscount: null,
                                                    totalNetPrice: 400,
                                                    voucherCode: null,
                                                    sourceOrderId: null,
                                                    snackTotalCount: null,
                                                    bevTotalCount: null,
                                                    snackTotalAmt: null,
                                                    bevTotalAmt: null,
                                                },
                                                {
                                                    contractualDiscount: null,
                                                    currency: null,
                                                    customerAddress: null,
                                                    deliveredPoints: 240,
                                                    deliveryDate: "2023-10-05",
                                                    ediNumber: null,
                                                    erpOrderNumber: null,
                                                    erpShippingCondition: null,
                                                    estimatedDeliveryDate: null,
                                                    estimatedPoints: null,
                                                    id: 2948062,
                                                    invoiceNumber: null,
                                                    orderType: "E-Order",
                                                    documentId: "322_2023_ZZ53206327101",
                                                    totalPrice: 300,
                                                    documentSource: "Invoice",
                                                    orderDate: null,
                                                    dollarSpend: null,
                                                    dollarSpendCalculated: null,
                                                    customerId: "1176577",
                                                    itemCount: 1,
                                                    orderLocationId: null,
                                                    orderNote: null,
                                                    orderNumber: null,
                                                    orderStatus: "Delivered",
                                                    paymentTermCode: null,
                                                    poNUmber: null,
                                                    promotionalDiscount: null,
                                                    rejectedNote: null,
                                                    releaseFlag: null,
                                                    routeId: null,
                                                    routeMarketplaceId: null,
                                                    routeOrderTypeCode: null,
                                                    routeOrderTypeSubCode: null,
                                                    routeRepId: null,
                                                    routeVisitSchedule: null,
                                                    sourceSystemId: null,
                                                    subtotal: null,
                                                    taxAmount: null,
                                                    totalDiscount: null,
                                                    totalNetPrice: 300,
                                                    voucherCode: null,
                                                    sourceOrderId: null,
                                                    snackTotalCount: null,
                                                    bevTotalCount: null,
                                                    snackTotalAmt: null,
                                                    bevTotalAmt: null,
                                                },
                                            ],
                                        },
                                    },
                                    order_detail: {
                                        value: {
                                            metadata: {
                                                __comment: "Info about the JSON File.",
                                                itemKey: "Sales History",
                                                totalItems: 1,
                                            },
                                            orders: [
                                                {
                                                    contractualDiscount: null,
                                                    currency: null,
                                                    customerAddress: null,
                                                    deliveredPoints: 47762.4927888,
                                                    deliveryDate: "2023-09-26",
                                                    ediNumber: null,
                                                    erpOrderNumber: null,
                                                    erpShippingCondition: null,
                                                    estimatedDeliveryDate: null,
                                                    estimatedPoints: null,
                                                    id: 2744721,
                                                    invoiceNumber: null,
                                                    orderType: "E-Order",
                                                    documentId: "322_2023_ZZ532055361",
                                                    totalPrice: 59703.115986,
                                                    documentSource: "Invoice",
                                                    orderDate: null,
                                                    dollarSpend: null,
                                                    dollarSpendCalculated: null,
                                                    customerId: "1176577",
                                                    itemCount: 12,
                                                    items: [
                                                        {
                                                            acceptedQuantity: null,
                                                            id: 395484,
                                                            productId: "32093",
                                                            netAmount: 36589.915134,
                                                            category: "Snack",
                                                            categoryType: "Акция",
                                                            isAssorted: true,
                                                            externalId: "32093",
                                                            name: {
                                                                en: "Cheetos Ketchup",
                                                                ar_SA: "Читос Кетчуп",
                                                            },
                                                            size: {
                                                                en: "50г",
                                                                ar_SA: "50г",
                                                            },
                                                            imageURL:
                                                                "https://cepsaadmin.spo.nonprod.wspdop.pepsico.com/Images/Product/32093.png",
                                                            points: null,
                                                            rewardType: null,
                                                            quantity: 6,
                                                            quantityFulfilled: null,
                                                            unitType: null,
                                                            packageSize: null,
                                                            baseUnit: null,
                                                            counponInfo: [],
                                                            unitPrice: 6098.319189000001,
                                                            itemState: null,
                                                            tax: null,
                                                            conversionMultiplierToBase: null,
                                                            saleUnitConversionToBase: 24,
                                                            basePrice: null,
                                                            finalPrice: 36589.915134,
                                                            categoryId: "120781",
                                                            isPromotional: false,
                                                            isSubstituted: false,
                                                            substitutedById: null,
                                                            status: null,
                                                            gtInValue: null,
                                                            rejectedReason: null,
                                                            deliveryMethod: null,
                                                            digitalAddress: "",
                                                            type: "",
                                                            saleUnit: "",
                                                            fulfilledPrice: null,
                                                            finalNetPrice: null,
                                                        },
                                                        {
                                                            acceptedQuantity: null,
                                                            id: 402605,
                                                            productId: "11494",
                                                            netAmount: 23113.200852,
                                                            category: "Drinks and Beverages",
                                                            categoryType: "Акция",
                                                            isAssorted: true,
                                                            externalId: "11494",
                                                            name: {
                                                                en: "Lipton Lemon",
                                                                ar_SA: "Липтон Чай Лимон",
                                                            },
                                                            size: {
                                                                en: "0.5л",
                                                                ar_SA: "0.5л",
                                                            },
                                                            imageURL:
                                                                "https://cepsaadmin.spo.nonprod.wspdop.pepsico.com/Images/Product/11494.png",
                                                            points: null,
                                                            rewardType: null,
                                                            quantity: 6,
                                                            quantityFulfilled: null,
                                                            unitType: null,
                                                            packageSize: null,
                                                            baseUnit: null,
                                                            counponInfo: [],
                                                            unitPrice: 3852.200142,
                                                            itemState: null,
                                                            tax: null,
                                                            conversionMultiplierToBase: null,
                                                            saleUnitConversionToBase: 12,
                                                            basePrice: null,
                                                            finalPrice: 23113.200852,
                                                            categoryId: "109121",
                                                            isPromotional: false,
                                                            isSubstituted: false,
                                                            substitutedById: null,
                                                            status: null,
                                                            gtInValue: null,
                                                            rejectedReason: null,
                                                            deliveryMethod: null,
                                                            digitalAddress: "",
                                                            type: "",
                                                            saleUnit: "",
                                                            fulfilledPrice: null,
                                                            finalNetPrice: null,
                                                        },
                                                    ],
                                                    orderLocationId: null,
                                                    orderNote: null,
                                                    orderNumber: null,
                                                    orderStatus: "Delivered",
                                                    paymentTermCode: null,
                                                    poNUmber: null,
                                                    promotionalDiscount: null,
                                                    rejectedNote: null,
                                                    releaseFlag: null,
                                                    routeId: null,
                                                    routeMarketplaceId: null,
                                                    routeOrderTypeCode: null,
                                                    routeOrderTypeSubCode: null,
                                                    routeRepId: null,
                                                    routeVisitSchedule: null,
                                                    sourceSystemId: null,
                                                    subtotal: null,
                                                    taxAmount: null,
                                                    totalDiscount: null,
                                                    totalNetPrice: 59703.115986,
                                                    voucherCode: null,
                                                    sourceOrderId: null,
                                                    snackTotalCount: null,
                                                    bevTotalCount: null,
                                                    snackTotalAmt: null,
                                                    bevTotalAmt: null,
                                                },
                                            ],
                                        },
                                    },
                                },
                            },
                        },
                    },
                    400: {
                        description: "Bad Request",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/ErrorMessage",
                                },
                            },
                        },
                    },
                    415: {
                        description: "Unsupported Media Type",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/ErrorMessage",
                                },
                            },
                        },
                    },
                    500: {
                        description: "Internal Server Error",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/Problem",
                                },
                            },
                        },
                    },
                },
            },
        },
        "/api/v2/salesHistory/{invoiceId}": {
            get: {
                operationId: "getSalesHistoryByInvoiceId",
                tags: ["Sales History"],
                summary: "Get sales history of a User",
                description:
                    "Get sales history of a User. This is also where offline order appear for europe.",
                parameters: [
                    {
                        $ref: "#/components/parameters/invoiceId",
                    },
                    {
                        $ref: "#/components/parameters/oktaAccessToken",
                    },
                    {
                        $ref: "#/components/parameters/siteId",
                    },
                    {
                        $ref: "#/components/parameters/storeIdInHeader",
                    },
                    {
                        $ref: "#/components/parameters/limit",
                    },
                    {
                        $ref: "#/components/parameters/offset",
                    },
                ],
                responses: {
                    200: {
                        description: "OK",
                        content: {
                            "application/json": {
                                schema: {
                                    $ref: "#/components/schemas/GetSalesHistoryResponseDto",
                                },
                                examples: {
                                    default: {
                                        value: {
                                            metadata: {
                                                __comment: "Info about the JSON File.",
                                                itemKey: "Sales History",
                                                totalItems: 48,
                                            },
                                            orders: [
                                                {
                                                    contractualDiscount: null,
                                                    currency: null,
                                                    customerAddress: null,
                                                    deliveredPoints: 320,
                                                    deliveryDate: "2023-10-09",
                                                    ediNumber: null,
                                                    erpOrderNumber: null,
                                                    erpShippingCondition: null,
                                                    estimatedDeliveryDate: null,
                                                    estimatedPoints: null,
                                                    id: 3018164,
                                                    invoiceNumber: null,
                                                    orderType: "E-Order",
                                                    documentId: "322_2023_ZZ532063888",
                                                    totalPrice: 400,
                                                    documentSource: "Invoice",
                                                    orderDate: null,
                                                    dollarSpend: null,
                                                    dollarSpendCalculated: null,
                                                    customerId: "1176577",
                                                    itemCount: 1,
                                                    orderLocationId: null,
                                                    orderNote: null,
                                                    orderNumber: null,
                                                    orderStatus: "Delivered",
                                                    paymentTermCode: null,
                                                    poNUmber: null,
                                                    promotionalDiscount: null,
                                                    rejectedNote: null,
                                                    releaseFlag: null,
                                                    routeId: null,
                                                    routeMarketplaceId: null,
                                                    routeOrderTypeCode: null,
                                                    routeOrderTypeSubCode: null,
                                                    routeRepId: null,
                                                    routeVisitSchedule: null,
                                                    sourceSystemId: null,
                                                    subtotal: null,
                                                    taxAmount: null,
                                                    totalDiscount: null,
                                                    totalNetPrice: 400,
                                                    voucherCode: null,
                                                    sourceOrderId: null,
                                                    snackTotalCount: null,
                                                    bevTotalCount: null,
                                                    snackTotalAmt: null,
                                                    bevTotalAmt: null,
                                                },
                                                {
                                                    contractualDiscount: null,
                                                    currency: null,
                                                    customerAddress: null,
                                                    deliveredPoints: 240,
                                                    deliveryDate: "2023-10-05",
                                                    ediNumber: null,
                                                    erpOrderNumber: null,
                                                    erpShippingCondition: null,
                                                    estimatedDeliveryDate: null,
                                                    estimatedPoints: null,
                                                    id: 2948062,
                                                    invoiceNumber: null,
                                                    orderType: "E-Order",
                                                    documentId: "322_2023_ZZ53206327101",
                                                    totalPrice: 300,
                                                    documentSource: "Invoice",
                                                    orderDate: null,
                                                    dollarSpend: null,
                                                    dollarSpendCalculated: null,
                                                    customerId: "1176577",
                                                    itemCount: 1,
                                                    orderLocationId: null,
                                                    orderNote: null,
                                                    orderNumber: null,
                                                    orderStatus: "Delivered",
                                                    paymentTermCode: null,
                                                    poNUmber: null,
                                                    promotionalDiscount: null,
                                                    rejectedNote: null,
                                                    releaseFlag: null,
                                                    routeId: null,
                                                    routeMarketplaceId: null,
                                                    routeOrderTypeCode: null,
                                                    routeOrderTypeSubCode: null,
                                                    routeRepId: null,
                                                    routeVisitSchedule: null,
                                                    sourceSystemId: null,
                                                    subtotal: null,
                                                    taxAmount: null,
                                                    totalDiscount: null,
                                                    totalNetPrice: 300,
                                                    voucherCode: null,
                                                    sourceOrderId: null,
                                                    snackTotalCount: null,
                                                    bevTotalCount: null,
                                                    snackTotalAmt: null,
                                                    bevTotalAmt: null,
                                                },
                                            ],
                                        },
                                    },
                                    order_detail: {
                                        value: {
                                            metadata: {
                                                __comment: "Info about the JSON File.",
                                                itemKey: "Sales History",
                                                totalItems: 1,
                                            },
                                            orders: [
                                                {
                                                    contractualDiscount: null,
                                                    currency: null,
                                                    customerAddress: null,
                                                    deliveredPoints: 47762.4927888,
                                                    deliveryDate: "2023-09-26",
                                                    ediNumber: null,
                                                    erpOrderNumber: null,
                                                    erpShippingCondition: null,
                                                    estimatedDeliveryDate: null,
                                                    estimatedPoints: null,
                                                    id: 2744721,
                                                    invoiceNumber: null,
                                                    orderType: "E-Order",
                                                    documentId: "322_2023_ZZ532055361",
                                                    totalPrice: 59703.115986,
                                                    documentSource: "Invoice",
                                                    orderDate: null,
                                                    dollarSpend: null,
                                                    dollarSpendCalculated: null,
                                                    customerId: "1176577",
                                                    itemCount: 12,
                                                    items: [
                                                        {
                                                            acceptedQuantity: null,
                                                            id: 395484,
                                                            productId: "32093",
                                                            netAmount: 36589.915134,
                                                            category: "Snack",
                                                            categoryType: "Акция",
                                                            isAssorted: true,
                                                            externalId: "32093",
                                                            name: {
                                                                en: "Cheetos Ketchup",
                                                                ar_SA: "Читос Кетчуп",
                                                            },
                                                            size: {
                                                                en: "50г",
                                                                ar_SA: "50г",
                                                            },
                                                            imageURL:
                                                                "https://cepsaadmin.spo.nonprod.wspdop.pepsico.com/Images/Product/32093.png",
                                                            points: null,
                                                            rewardType: null,
                                                            quantity: 6,
                                                            quantityFulfilled: null,
                                                            unitType: null,
                                                            packageSize: null,
                                                            baseUnit: null,
                                                            counponInfo: [],
                                                            unitPrice: 6098.319189000001,
                                                            itemState: null,
                                                            tax: null,
                                                            conversionMultiplierToBase: null,
                                                            saleUnitConversionToBase: 24,
                                                            basePrice: null,
                                                            finalPrice: 36589.915134,
                                                            categoryId: "120781",
                                                            isPromotional: false,
                                                            isSubstituted: false,
                                                            substitutedById: null,
                                                            status: null,
                                                            gtInValue: null,
                                                            rejectedReason: null,
                                                            deliveryMethod: null,
                                                            digitalAddress: "",
                                                            type: "",
                                                            saleUnit: "",
                                                            fulfilledPrice: null,
                                                            finalNetPrice: null,
                                                        },
                                                        {
                                                            acceptedQuantity: null,
                                                            id: 402605,
                                                            productId: "11494",
                                                            netAmount: 23113.200852,
                                                            category: "Drinks and Beverages",
                                                            categoryType: "Акция",
                                                            isAssorted: true,
                                                            externalId: "11494",
                                                            name: {
                                                                en: "Lipton Lemon",
                                                                ar_SA: "Липтон Чай Лимон",
                                                            },
                                                            size: {
                                                                en: "0.5л",
                                                                ar_SA: "0.5л",
                                                            },
                                                            imageURL:
                                                                "https://cepsaadmin.spo.nonprod.wspdop.pepsico.com/Images/Product/11494.png",
                                                            points: null,
                                                            rewardType: null,
                                                            quantity: 6,
                                                            quantityFulfilled: null,
                                                            unitType: null,
                                                            packageSize: null,
                                                            baseUnit: null,
                                                            counponInfo: [],
                                                            unitPrice: 3852.200142,
                                                            itemState: null,
                                                            tax: null,
                                                            conversionMultiplierToBase: null,
                                                            saleUnitConversionToBase: 12,
                                                            basePrice: null,
                                                            finalPrice: 23113.200852,
                                                            categoryId: "109121",
                                                            isPromotional: false,
                                                            isSubstituted: false,
                                                            substitutedById: null,
                                                            status: null,
                                                            gtInValue: null,
                                                            rejectedReason: null,
                                                            deliveryMethod: null,
                                                            digitalAddress: "",
                                                            type: "",
                                                            saleUnit: "",
                                                            fulfilledPrice: null,
                                                            finalNetPrice: null,
                                                        },
                                                    ],
                                                    orderLocationId: null,
                                                    orderNote: null,
                                                    orderNumber: null,
                                                    orderStatus: "Delivered",
                                                    paymentTermCode: null,
                                                    poNUmber: null,
                                                    promotionalDiscount: null,
                                                    rejectedNote: null,
                                                    releaseFlag: null,
                                                    routeId: null,
                                                    routeMarketplaceId: null,
                                                    routeOrderTypeCode: null,
                                                    routeOrderTypeSubCode: null,
                                                    routeRepId: null,
                                                    routeVisitSchedule: null,
                                                    sourceSystemId: null,
                                                    subtotal: null,
                                                    taxAmount: null,
                                                    totalDiscount: null,
                                                    totalNetPrice: 59703.115986,
                                                    voucherCode: null,
                                                    sourceOrderId: null,
                                                    snackTotalCount: null,
                                                    bevTotalCount: null,
                                                    snackTotalAmt: null,
                                                    bevTotalAmt: null,
                                                },
                                            ],
                                        },
                                    },
                                },
                            },
                        },
                    },
                    400: {
                        description: "Bad Request",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/ErrorMessage",
                                },
                            },
                        },
                    },
                    415: {
                        description: "Unsupported Media Type",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/ErrorMessage",
                                },
                            },
                        },
                    },
                    500: {
                        description: "Internal Server Error",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/Problem",
                                },
                            },
                        },
                    },
                },
            },
        },

        "/api/v1/test/customer-otp/{accountId}": {
            get: {
                operationId: "GetCustomerOTP",
                tags: ["CustomerOTP"],
                summary: "Get Customer OTP",
                description: "Get Customer OTP",
                parameters: [
                    {
                        $ref: "#/components/parameters/accountId",
                    },
                    {
                        $ref: "#/components/parameters/storeIdInHeader",
                    },
                    {
                        $ref: "#/components/parameters/siteIdInQuery",
                    },
                ],

                responses: {
                    200: {
                        description: "OK",
                        content: {
                            "application/json": {
                                schema: {
                                    $ref: "#/components/schemas/GetCustomerOTPResponse",
                                },
                                example: {
                                    status: "ok",
                                    message: "Customer OTP Fetched",
                                    data: {
                                        otp: "977418",
                                    },
                                },
                            },
                        },
                    },
                    400: {
                        description: "Bad Request",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/ErrorMessage",
                                },
                            },
                        },
                    },
                    415: {
                        description: "Unsupported Media Type",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/ErrorMessage",
                                },
                            },
                        },
                    },
                    500: {
                        description: "Internal Server Error",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/Problem",
                                },
                            },
                        },
                    },
                },
            },
        },
        "/api/v1/survey": {
            get: {
                operationId: "getSurveyGetV1",
                tags: ["Survey"],
                summary: "Get Survey",
                description: "Get Survey",
                parameters: [
                    {
                        $ref: "#/components/parameters/oktaAccessToken",
                    },
                    {
                        $ref: "#/components/parameters/storeIdInHeader",
                    },
                ],
                responses: {
                    200: {
                        description: "OK",
                        content: {
                            "application/json": {
                                schema: {
                                    type: "array",
                                    items: {
                                        $ref: "#/components/schemas/Survey",
                                    },
                                },
                                example: {
                                    title: "Survey",
                                    siteid: "14",
                                    displayOrder: 1,
                                    image: "",
                                    question: {
                                        en: "Which type of product did you sign up for on Dükkan Senin?",
                                        tr: "Dükkan Senin'e hangi tür ürüne üye oldunuz?",
                                    },
                                    answer: [
                                        {
                                            en: "Snacks",
                                            tr: "Atıştırmalıklar",
                                        },
                                        {
                                            en: "Beverages",
                                            tr: "İçecekler",
                                        },
                                        {
                                            en: "text",
                                        },
                                        {
                                            en: "lang",
                                        },
                                    ],
                                },
                            },
                        },
                    },
                    400: {
                        description: "Bad Request",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/ErrorMessage",
                                },
                            },
                        },
                    },
                    415: {
                        description: "Unsupported Media Type",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/ErrorMessage",
                                },
                            },
                        },
                    },
                    500: {
                        description: "Internal Server Error",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/Problem",
                                },
                            },
                        },
                    },
                },
            },
            post: {
                operationId: "postSurveyPostV1",
                tags: ["Survey"],
                summary: "Create and update Survey",
                description: "Create and update survey",
                requestBody: {
                    content: {
                        "application/json": {
                            schema: {
                                $ref: "#/components/schemas/GetSurveyRequestDto",
                            },
                            example: [
                                {
                                    survey: [
                                        {
                                            title: "survey",
                                            storeid: "5468507",
                                            question:
                                                "Which Pepsi Product is your favorite products in snacks category and also do let us know Which Pepsi Product is your favorite products in Beverage category.",
                                            answer: "Good Day",
                                            date: "2023-02-22",
                                            time: "12:09",
                                        },
                                    ],
                                },
                            ],
                        },
                    },
                },
                parameters: [
                    {
                        $ref: "#/components/parameters/oktaAccessToken",
                    },
                    {
                        $ref: "#/components/parameters/storeIdInHeader",
                    },
                ],
                responses: {
                    200: {
                        description: "OK",
                        content: {
                            "application/json": {
                                schema: {
                                    $ref: "#/components/schemas/GetSurveyResponseDto",
                                },
                                examples: {
                                    success: {
                                        value: {
                                            success: true,
                                            msg: "Survey Data Save Sucesssfully",
                                        },
                                    },
                                    failure: {
                                        value: {
                                            success: false,
                                            errorCode: "103",
                                            httpCode: "200",
                                            msg: "Survey already available for this store",
                                        },
                                    },
                                },
                            },
                        },
                    },
                    400: {
                        description: "Bad Request",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/ErrorMessage",
                                },
                            },
                        },
                    },
                    415: {
                        description: "Unsupported Media Type",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/ErrorMessage",
                                },
                            },
                        },
                    },
                    500: {
                        description: "Internal Server Error",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/Problem",
                                },
                            },
                        },
                    },
                },
            },
        },
        "/data-import/orderDocument": {
            post: {
                tags: ["Data Migration"],
                summary: "Create Sales History",
                description: "Migration For Sales History",
                requestBody: {
                    content: {
                        "application/json": {
                            schema: {
                                type: "object",
                            },
                            example: [
                                {
                                    orderTypeCode: "WAYBILL",
                                    requestedDeliveryDateTime: "0000-00-00T00:00:00.00",
                                    sourceSystemName: "E-ORDER",
                                    soldToCustomerId: "802022",
                                    createdDateTime: "2023-03-27",
                                    referenceInvoiceId: "13784234",
                                    netTotalAmount: 154.79,
                                    items: [
                                        {
                                            alternateOrderLineNumber: "1053784-1",
                                            fulfillments: [],
                                            requestedQty: 0,
                                            totalNetAmount: 17.26,
                                            confirmedQty: 14,
                                            isBundleProduct: true,
                                            materialId: "F11125",
                                            productTypeCode: "SNACK",
                                        },
                                    ],
                                },
                            ],
                        },
                    },
                },
                parameters: [
                    {
                        $ref: "#/components/parameters/xApiKey",
                    },
                    {
                        name: "siteId",
                        in: "header",
                        required: true,
                        description: "siteId",
                        schema: {
                            type: "string",
                            default: "7",
                        },
                    },
                ],
                responses: {
                    200: {
                        description: "OK",
                        content: {
                            "application/json": {
                                examples: {
                                    success: {
                                        value: {
                                            success: true,
                                            topicName: "OrderDocument",
                                            message: "imported successfully",
                                        },
                                    },
                                    failure: {
                                        value: {
                                            success: false,
                                            msg: "You have exceed the limit request of the body",
                                        },
                                    },
                                },
                            },
                        },
                    },
                    400: {
                        description: "Bad Request",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/ErrorMessage",
                                },
                            },
                        },
                    },
                    415: {
                        description: "Unsupported Media Type",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/ErrorMessage",
                                },
                            },
                        },
                    },
                    500: {
                        description: "Internal Server Error",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/Problem",
                                },
                            },
                        },
                    },
                },
            },
        },
        "/api/v1/categories/list": {
            get: {
                tags: ["Categories"],
                summary: "Get the list of categories (Route Types)",
                description:
                    "This API is used to get the list of categories (Route Types).",
                responses: {
                    200: {
                        description: "OK",
                        content: {
                            "application/json": {
                                example: {
                                    success: true,
                                    data: [
                                        {
                                            siteId: "21",
                                            routeTypeId: "1",
                                            routeId: "MX1B61",
                                            locationId: "80DG",
                                            nextVisitDate: "2023-07-24",
                                            cutoffTime: null,
                                            minimumOrderAmount: 100,
                                            title: {
                                                en: "Sabritas & Global Brands",
                                                es_MX: "Sabritas & Marcas Globales",
                                            },
                                            thumbnail: {
                                                en: "https://via.placeholder.com/150",
                                                es_MX: "https://via.placeholder.com/150",
                                            },
                                            gallery: {
                                                en: [
                                                    "https://via.placeholder.com/150",
                                                    "https://via.placeholder.com/150",
                                                    "https://via.placeholder.com/150",
                                                ],
                                                es_MX: [
                                                    "https://via.placeholder.com/150",
                                                    "https://via.placeholder.com/150",
                                                    "https://via.placeholder.com/150",
                                                ],
                                            },
                                        },
                                        {
                                            siteId: "21",
                                            routeTypeId: "10",
                                            nextVisitDate: "2023-07-24",
                                            cutoffTime: null,
                                            minimumOrderAmount: 100,
                                            thumbnail: {
                                                en: "Paketaxo & Local Brands",
                                                es_MX: "Paketaxo & Marcas Locales",
                                            },
                                            image: {
                                                en: "https://via.placeholder.com/150",
                                                es_MX: "https://via.placeholder.com/150",
                                            },
                                            gallery: {
                                                en: [
                                                    "https://via.placeholder.com/150",
                                                    "https://via.placeholder.com/150",
                                                ],
                                                es_MX: [
                                                    "https://via.placeholder.com/150",
                                                    "https://via.placeholder.com/150",
                                                ],
                                            },
                                        },
                                        {
                                            siteId: "21",
                                            routeTypeId: "30",
                                            nextVisitDate: "2023-07-24",
                                            cutoffTime: null,
                                            minimumOrderAmount: 100,
                                            title: {
                                                en: "Gamesa",
                                                es_MX: "Gamesa",
                                            },
                                            thumbnail: {
                                                en: "https://via.placeholder.com/150",
                                                es_MX: "https://via.placeholder.com/150",
                                            },
                                            gallery: {},
                                        },
                                    ],
                                },
                            },
                        },
                    },
                    401: {
                        description: "Unauthorized.",
                        content: {
                            "application/json": {
                                example: {
                                    success: false,
                                    msg: "Unauthorized.",
                                },
                            },
                        },
                    },
                    404: {
                        description: "No categories found.",
                        content: {
                            "application/json": {
                                example: {
                                    success: false,
                                    msg: "No categories found.",
                                },
                            },
                        },
                    },
                },
                parameters: [
                    {
                        $ref: "#/components/parameters/xApiKey",
                    },
                    {
                        name: "SiteId",
                        in: "header",
                        required: true,
                        description: "SiteId",
                        schema: {
                            type: "number",
                            default: 21,
                        },
                    },
                    {
                        name: "okta-accesstoken",
                        in: "header",
                        required: true,
                        description: "okta-accesstoken",
                        schema: {
                            type: "string",
                            default: "",
                        },
                    },
                ],
            },
        },
        "/api/v1/gtc": {
            post: {
                operationId: "runCronJob",
                tags: ["Cron Jobs"],
                summary: "Trigger cron jobs instantly",
                description: "Trigger cron jobs instantly",
                parameters: [
                    {
                        $ref: "#/components/parameters/siteId",
                    },
                    {
                        $ref: "#/components/parameters/oktaAccessToken",
                    },
                    {
                        $ref: "#/components/parameters/cronJobType",
                    },
                ],
                requestBody: {
                    content: {
                        "application/json": {
                            schema: {
                                type: "string",
                            },
                        },
                    },
                },
                responses: {
                    200: {
                        description: "OK",
                        content: {
                            "application/json": {
                                schema: {
                                    $ref: "#/components/schemas/BasicResponseDto",
                                },
                                example: {
                                    success: true,
                                    msg: "Verification code sent on xyz@gmail.com.",
                                },
                            },
                        },
                    },
                    400: {
                        description: "Bad Request",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/ErrorMessage",
                                },
                            },
                        },
                    },
                    415: {
                        description: "Unsupported Media Type",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/ErrorMessage",
                                },
                            },
                        },
                    },
                    500: {
                        description: "Internal Server Error",
                        content: {
                            "*/*": {
                                schema: {
                                    $ref: "#/components/schemas/Problem",
                                },
                            },
                        },
                    },
                },
            },
        },
        "/it/b2b/bff/v1/orders/moa": {
            get: {
                tags: ["BFF"],
                summary: "Get order MOA details",
                description:
                    "Retrieves order MOA details for a specific site and store.",
                parameters: [
                    {
                        $ref: "#/components/parameters/siteIdInQuery",
                    },
                    {
                        $ref: "#/components/parameters/storeIdInQuery",
                    },
                ],
                responses: {
                    200: {
                        description: "Order details retrieved successfully",
                        content: {
                            "application/json": {
                                schema: {
                                    type: "object",
                                    properties: {
                                        minAmount: {
                                            type: "number",
                                            example: 350,
                                        },
                                        criteriaMet: {
                                            type: "boolean",
                                            example: false,
                                        },
                                    },
                                },
                            },
                        },
                    },
                    400: {
                        description: "Bad request",
                        content: {
                            "application/json": {
                                schema: {
                                    type: "object",
                                    properties: {
                                        error: {
                                            type: "string",
                                            example: "Invalid input data",
                                        },
                                    },
                                },
                            },
                        },
                    },
                    404: {
                        description: "Not found",
                        content: {
                            "application/json": {
                                schema: {
                                    type: "object",
                                    properties: {
                                        error: {
                                            type: "string",
                                            example: "store not found",
                                        },
                                    },
                                },
                            },
                        },
                    },
                    500: {
                        description: "Internal server error",
                        content: {
                            "application/json": {
                                schema: {
                                    type: "object",
                                    properties: {
                                        error: {
                                            type: "string",
                                            example: "An unexpected error occurred",
                                        },
                                    },
                                },
                            },
                        },
                    },
                },
                security: [
                    {
                        bearerAuth: [],
                    },
                ],
            },
        },
        "/it/b2b/bff/v1/orders": {
            post: {
                tags: ["BFF"],
                summary: "Create a new order",
                description: "Endpoint to create a new order",
                operationId: "createOrder",
                parameters: [
                    {
                        $ref: "#/components/parameters/siteIdInQuery",
                    },
                    {
                        $ref: "#/components/parameters/storeIdInQuery",
                    },
                ],
                requestBody: {
                    required: true,
                    content: {
                        "application/json": {
                            schema: {
                                $ref: "#/components/schemas/CreateOrderRequestDto",
                            },
                        },
                    },
                },
                responses: {
                    200: {
                        description: "Order created successfully",
                        content: {
                            "application/json": {
                                schema: {
                                    $ref: "#/components/schemas/CreateOrderResponseDto",
                                },
                            },
                        },
                    },
                    400: {
                        description: "Bad Request",
                        content: {
                            "application/json": {
                                schema: {
                                    type: "object",
                                    properties: {
                                        error: {
                                            type: "string",
                                            example: "Invalid input",
                                        },
                                    },
                                },
                            },
                        },
                    },
                },
            },
        },
    },
    components: {
        securitySchemes: {
            bearerAuth: {
                type: "http",
                scheme: "bearer",
                bearerFormat: "JWT",
            },
        },
        schemas: {
            Problem: {
                type: "object",
                properties: {
                    logRef: {
                        type: "string",
                    },
                    message: {
                        type: "string",
                    },
                },
            },
            ErrorMessage: {
                type: "object",
                properties: {
                    errors: {
                        type: "array",
                        items: {
                            type: "string",
                        },
                    },
                },
            },
            BasicResponseDto: {
                type: "object",
                properties: {
                    success: {
                        type: "boolean",
                    },
                    errorCode: {
                        type: "object",
                    },
                    msg: {
                        type: "string",
                    },
                    successCode: {
                        type: "object",
                    },
                    message: {
                        type: "string",
                    },
                },
            },
            GetSurveyResponseDto: {
                type: "object",
                properties: {
                    success: {
                        type: "boolean",
                    },
                    errorCode: {
                        type: "string",
                    },
                    httpCode: {
                        type: "string",
                    },
                    msg: {
                        type: "string",
                    },
                },
                required: ["success", "errorCode", "httpCode", "msg"],
            },
            GetSurveyRequestDto: {
                type: "object",
                properties: {
                    survey: {
                        type: "array",
                        items: {
                            type: "object",
                            properties: {
                                title: {
                                    type: "string",
                                },
                                storeid: {
                                    type: "string",
                                },
                                question: {
                                    type: "string",
                                },
                                answer: {
                                    type: "string",
                                },
                                date: {
                                    type: "string",
                                    format: "date",
                                },
                                time: {
                                    type: "string",
                                    format: "time",
                                },
                            },
                            required: [
                                "title",
                                "storeid",
                                "question",
                                "answer",
                                "date",
                                "time",
                            ],
                        },
                    },
                },
                required: ["survey"],
            },
            NavArrayItem: {
                type: "object",
                properties: {
                    title: {
                        type: "object",
                        additionalProperties: true,
                    },
                    displayOrder: {
                        type: "number",
                    },
                    path: {
                        type: "string",
                    },
                },
            },
            Contact: {
                type: "array",
                items: {
                    type: "object",
                    properties: {
                        isActive: {
                            type: "boolean",
                        },
                        creditBlock: {
                            type: "string",
                        },
                        creditLimitAmount: {
                            type: "string",
                        },
                        mobile: {
                            type: "string",
                        },
                        default: {
                            type: "boolean",
                        },
                        isStore: {
                            type: "boolean",
                        },
                        storeId: {
                            type: "string",
                        },
                        name: {
                            type: "string",
                        },
                        nickName: {
                            type: "string",
                        },
                        minimumOrderAmount: {
                            type: "number",
                        },
                        image: {
                            type: "string",
                            format: "uri",
                        },
                        companyCode: {
                            type: "string",
                        },
                        type: {
                            type: "string",
                        },
                        postalCode: {
                            type: "string",
                        },
                        VATNumber: {
                            type: "string",
                        },
                        phoneNumber: {
                            type: "string",
                        },
                        permissions: {
                            type: "string",
                        },
                        profileStatus: {
                            type: "string",
                        },
                        points: {
                            type: "integer",
                        },
                        lifetimePoints: {
                            type: "integer",
                        },
                        addresses: {
                            type: "object",
                            properties: {
                                addressLine1: {
                                    type: "string",
                                },
                                addressLine2: {
                                    type: "string",
                                },
                                city: {
                                    type: "string",
                                },
                                state: {
                                    type: "string",
                                },
                                country: {
                                    type: "string",
                                },
                            },
                        },
                        location: {
                            type: "array",
                            items: {
                                $ref: "#/components/schemas/Location",
                            },
                        },
                        businessType: {
                            type: "string",
                        },
                    },
                },
            },
            CustomAddress: {
                type: "object",
                properties: {
                    name: {
                        type: "string",
                    },
                    isdefault: {
                        type: "boolean",
                    },
                    position: {
                        type: "integer",
                    },
                    type: {
                        type: "string",
                    },
                    buildingNumber: {
                        type: "string",
                    },
                    addressLine1: {
                        type: "string",
                    },
                    addressLine2: {
                        type: "string",
                    },
                    province: {
                        type: "string",
                    },
                    city: {
                        type: "string",
                    },
                    district: {
                        type: "string",
                    },
                    state: {
                        type: "string",
                    },
                    country: {
                        type: "string",
                    },
                    postalCode: {
                        type: "string",
                    },
                    taxAreaCode: {
                        type: "string",
                    },
                    phoneNumber: {
                        type: "integer",
                    },
                },
            },
            TermsAccepted: {
                type: "object",
                properties: {
                    contentId: {
                        type: "string",
                    },
                    version_number: {
                        type: "string",
                    },
                    deviceSerial: {
                        type: "string",
                    },
                    ipAddress: {
                        type: "string",
                    },
                    date: {
                        type: "string",
                    },
                    time: {
                        type: "string",
                    },
                },
            },
            Preferences: {
                type: "object",
                properties: {
                    accessType: {
                        type: "string",
                    },
                    businessType: {
                        type: "string",
                    },
                    executionType: {
                        type: "string",
                    },
                    smsPermission: {
                        type: "string",
                    },
                    whatsappPermission: {
                        type: "string",
                    },
                    emailPermission: {
                        type: "string",
                    },
                    pushNotificationPermission: {
                        type: "string",
                    },
                    cookiePermission: {
                        type: "string",
                    },
                    segments: {
                        type: "array",
                        items: {
                            type: "object",
                            properties: {
                                segmentCode: {
                                    type: "string",
                                },
                                segmentName: {
                                    type: "string",
                                },
                            },
                        },
                    },
                },
            },
            Extra: {
                type: "object",
                properties: {
                    totalCredit: {
                        type: "number",
                    },
                    levelId: {
                        type: "integer",
                    },
                    level: {
                        type: "string",
                    },
                    points: {
                        type: "integer",
                    },
                    lifetimePoints: {
                        type: "integer",
                    },
                },
            },
            Location: {
                type: "object",
                properties: {
                    locationId: {
                        type: "string",
                    },
                    locationType: {
                        type: "string",
                    },
                    accountCode: {
                        type: "string",
                    },
                },
            },
            AccountDetailsDto: {
                type: "object",
                properties: {
                    metadata: {
                        type: "object",
                        properties: {
                            __comment: {
                                type: "string",
                            },
                            itemKey: {
                                type: "string",
                            },
                            totalItems: {
                                type: "integer",
                            },
                        },
                    },
                    customers: {
                        type: "object",
                        properties: {
                            id: {
                                type: "integer",
                            },
                            userId: {
                                type: "string",
                            },
                            role: {
                                type: "string",
                            },
                            memberType: {
                                type: "string",
                            },
                            firstName: {
                                type: "string",
                            },
                            lastName: {
                                type: "string",
                            },
                            profileImgUrl: {
                                type: "string",
                                format: "uri",
                            },
                            dob: {
                                type: "string",
                                nullable: true,
                            },
                            deviceToken: {
                                type: "string",
                                nullable: true,
                            },
                            maid: {
                                type: "string",
                                nullable: true,
                            },
                            deviceType: {
                                type: "string",
                                nullable: true,
                            },
                            email: {
                                type: "string",
                            },
                            phone: {
                                type: "string",
                            },
                            alternateNumber: {
                                type: "string",
                            },
                            contact: {
                                type: "array",
                                items: {
                                    $ref: "#/components/schemas/Contact",
                                },
                            },
                            customAddresses: {
                                $ref: "#/components/schemas/CustomAddress",
                            },
                            termsAccepted: {
                                $ref: "#/components/schemas/TermsAccepted",
                            },
                            preferences: {
                                $ref: "#/components/schemas/Preferences",
                            },
                            extra: {
                                $ref: "#/components/schemas/Extra",
                            },
                        },
                    },
                },
            },
            EmployeeAddress: {
                type: "object",
                properties: {
                    name: {
                        type: "string",
                    },
                    id: {
                        type: "string",
                    },
                    companyCode: {
                        type: "string",
                    },
                    type: {
                        type: "string",
                    },
                    buildingNumber: {
                        type: "string",
                        nullable: true,
                    },
                    addressLine1: {
                        type: "string",
                        nullable: true,
                    },
                    addressLine2: {
                        type: "string",
                    },
                    province: {
                        type: "string",
                        nullable: true,
                    },
                    city: {
                        type: "string",
                        nullable: true,
                    },
                    state: {
                        type: "string",
                        nullable: true,
                    },
                    country: {
                        type: "string",
                        nullable: true,
                    },
                    postalCode: {
                        type: "string",
                        nullable: true,
                    },
                    taxAreaCode: {
                        type: "string",
                        nullable: true,
                    },
                    VATNumber: {
                        type: "integer",
                    },
                    phoneNumber: {
                        type: "integer",
                    },
                    permissions: {
                        type: "string",
                    },
                    profileStatus: {
                        type: "string",
                    },
                    points: {
                        type: "integer",
                    },
                    lifetimePoints: {
                        type: "integer",
                    },
                },
            },
            Employee: {
                type: "object",
                properties: {
                    id: {
                        type: "integer",
                    },
                    externalId: {
                        type: "string",
                    },
                    isActive: {
                        type: "boolean",
                    },
                    role: {
                        type: "string",
                    },
                    memberType: {
                        type: "string",
                    },
                    firstName: {
                        type: "string",
                    },
                    lastName: {
                        type: "string",
                    },
                    username: {
                        type: "string",
                        format: "email",
                    },
                    profileImgUrl: {
                        type: "string",
                        format: "uri",
                        nullable: true,
                    },
                    contact: {
                        type: "object",
                        properties: {
                            email: {
                                type: "string",
                                format: "email",
                            },
                            phone: {
                                type: "integer",
                            },
                            mobile: {
                                type: "integer",
                            },
                            address: {
                                $ref: "#/components/schemas/EmployeeAddress",
                            },
                        },
                    },
                    preferences: {
                        $ref: "#/components/schemas/Preferences",
                    },
                    extra: {
                        $ref: "#/components/schemas/Extra",
                    },
                },
            },
            CartItem: {
                type: "object",
                properties: {
                    id: {
                        type: "string",
                    },
                    materialCode: {
                        type: "string",
                    },
                    materialName: {
                        type: "string",
                    },
                    image: {
                        type: "string",
                    },
                    count: {
                        type: "string",
                    },
                    quantity: {
                        type: "number",
                    },
                    salesUOM: {
                        type: "string",
                    },
                    __comment: {
                        type: "string",
                    },
                    total: {
                        type: "number",
                    },
                },
            },
            CartResponseDto: {
                type: "object",
                properties: {
                    orderType: {
                        type: "string",
                        enum: ["ZOR", "Rewards"],
                    },
                    items: {
                        type: "array",
                        items: {
                            $ref: "#/components/schemas/CartItem",
                        },
                    },
                    freeItems: {
                        type: "array",
                        items: {
                            $ref: "#/components/schemas/CartItem",
                        },
                    },
                    appliedCoupons: {
                        type: "array",
                        items: {
                            type: "string",
                        },
                    },
                    subtotal: {
                        type: "string",
                    },
                    grandTotal: {
                        type: "string",
                    },
                    count: {
                        type: "number",
                    },
                    currency: {
                        type: "string",
                    },
                },
            },
            GamesResponseDTO: {
                type: "object",
                properties: {
                    count: {
                        type: "integer",
                        example: 10,
                    },
                    data: {
                        type: "array",
                        items: {
                            $ref: "#/components/schemas/Game",
                        },
                    },
                },
            },
            Game: {
                type: "object",
                properties: {
                    Id: {
                        type: "integer",
                        example: 2230163,
                    },
                    Url: {
                        type: "string",
                        format: "uri",
                        example:
                            "https://admin-fd.cep.qa.tr.pepsico.com/api/v1/games/play/2230163",
                    },
                    Image: {
                        type: "string",
                        format: "uri",
                        nullable: true,
                        example: null,
                    },
                    LogoImage: {
                        type: "string",
                        format: "uri",
                        nullable: true,
                        example: null,
                    },
                    GameType: {
                        type: "string",
                        example: "Free Kick",
                    },
                    Title: {
                        $ref: "#/components/schemas/LocalizedString",
                    },
                    Description: {
                        $ref: "#/components/schemas/LocalizedString",
                    },
                    LogoDescription: {
                        $ref: "#/components/schemas/LocalizedString",
                    },
                    HowToPlayRule: {
                        $ref: "#/components/schemas/LocalizedString",
                    },
                    DisplayStartDate: {
                        type: "string",
                        format: "date-time",
                        example: "2023-08-16T05:00:00.000000Z",
                    },
                    DisplayEndDate: {
                        type: "string",
                        format: "date-time",
                        example: "2024-12-30T18:30:00.000000Z",
                    },
                    Status: {
                        type: "string",
                        example: "Active",
                    },
                    StartTime: {
                        type: "string",
                        example: "00:00",
                    },
                    EndTime: {
                        type: "string",
                        example: "23:45",
                    },
                    DaysOfTheWeekUnavailable: {
                        type: "array",
                        items: {
                            type: "string",
                        },
                        nullable: true,
                        example: null,
                    },
                    DailyLimit: {
                        type: "integer",
                        example: 99,
                    },
                    AvailableDailyLimit: {
                        type: "integer",
                        example: 99,
                    },
                    ActivityId: {
                        type: "integer",
                        example: 2230164,
                    },
                    ActivitySlug: {
                        type: "string",
                        example: "reward-points::free-kick-play",
                    },
                    GameSettings: {
                        type: "object",
                        properties: {
                            Budget: {
                                type: "string",
                                example: "95",
                            },
                            BudgetPeriod: {
                                type: "string",
                                nullable: true,
                                example: null,
                            },
                            SliceSettings: {
                                type: "array",
                                items: {
                                    $ref: "#/components/schemas/SliceSetting",
                                },
                            },
                            Quiz: {
                                $ref: "#/components/schemas/LocalizedQuiz",
                            },
                        },
                        nullable: true,
                    },
                },
            },
            LocalizedString: {
                type: "object",
                properties: {
                    en: {
                        type: "string",
                        nullable: true,
                    },
                    tr: {
                        type: "string",
                        nullable: true,
                    },
                    ru: {
                        type: "string",
                        nullable: true,
                    },
                },
                example: {
                    en: "Example text in English",
                    tr: "Example text in Turkish",
                },
            },
            SliceSetting: {
                type: "object",
                properties: {
                    SliceText: {
                        $ref: "#/components/schemas/LocalizedString",
                    },
                    Point: {
                        type: "integer",
                        example: 10,
                    },
                    Probability: {
                        type: "integer",
                        example: 30,
                    },
                    SliceColor: {
                        type: "string",
                        example: "#3fdb0f",
                    },
                },
            },
            LocalizedQuiz: {
                type: "object",
                properties: {
                    en: {
                        type: "array",
                        items: {
                            $ref: "#/components/schemas/QuizItem",
                        },
                    },
                    tr: {
                        type: "array",
                        items: {
                            $ref: "#/components/schemas/QuizItem",
                        },
                    },
                },
            },
            QuizItem: {
                type: "object",
                properties: {
                    Question: {
                        type: "string",
                        example: "What is turkey Site Id?",
                    },
                    correctAnswer: {
                        type: "string",
                        example: "14",
                    },
                    answers: {
                        type: "array",
                        items: {
                            type: "string",
                        },
                        example: ["14", "100", "101"],
                    },
                },
            },
            CartRequestDto: {
                type: "object",
                properties: {
                    orderType: {
                        type: "string",
                        enum: ["ZOR", "Rewards"],
                    },
                    items: {
                        type: "array",
                        items: {
                            $ref: "#/components/schemas/CartItem",
                        },
                    },
                },
            },
            Address: {
                type: "object",
                properties: {
                    externalId: {
                        type: "string",
                    },
                    customerName: {
                        type: "string",
                    },
                    email: {
                        type: "string",
                    },
                    phone: {
                        type: "string",
                    },
                    state: {
                        type: "string",
                    },
                    postalCode: {
                        type: "string",
                    },
                    district: {
                        type: "string",
                    },
                    addressLine1: {
                        type: "string",
                    },
                    addressLine2: {
                        type: "string",
                    },
                    city: {
                        type: "string",
                    },
                    countryCode: {
                        type: "string",
                    },
                    IDNumber: {
                        type: "string",
                    },
                    DOB: {
                        type: "string",
                    },
                },
            },
            CreateOrderRequestCartItem: {
                type: "object",
                properties: {
                    id: {
                        type: "integer",
                    },
                    externalId: {
                        type: "string",
                    },
                    categoryId: {
                        type: "string",
                    },
                    isPromotional: {
                        type: "boolean",
                    },
                    saleUoms: {
                        $ref: "#/components/schemas/CreateOrderRequestSaleUoms",
                    },
                    conversionMulitplierToBase: {
                        type: "integer",
                    },
                    quantity: {
                        type: "integer",
                    },
                    basePrice: {
                        type: "string",
                    },
                    finalPrice: {
                        type: "number",
                    },
                    subTotal: {
                        type: "number",
                    },
                    tax: {
                        type: "number",
                    },
                    discountedPrice: {
                        type: "number",
                    },
                    depositPrice: {
                        type: "number",
                    },
                    isReturnItem: {
                        type: "boolean",
                    },
                    discountedPercentage: {
                        type: "number",
                        example: 15,
                    },
                },
            },
            CreateOrderRequestSaleUoms: {
                type: "object",
                properties: {
                    saleUomsName: {
                        type: "string",
                    },
                    saleUomsCode: {
                        type: "string",
                    },
                },
            },
            CreateOrderRequestDto: {
                type: "array",
                items: {
                    $ref: "#/components/schemas/CreateOrderRequestOrder",
                },
            },
            CreateOrderRequestOrder: {
                type: "object",
                properties: {
                    orderType: {
                        type: "string",
                        enum: ["ZOR", "Rewards"],
                    },
                    orderDate: {
                        type: "string",
                        format: "date",
                    },
                    subtotal: {
                        type: "number",
                    },
                    productDiscounts: {
                        type: "number",
                    },
                    totalTax: {
                        type: "number",
                    },
                    totalPrice: {
                        type: "number",
                    },
                    billingAddress: {
                        $ref: "#/components/schemas/Address",
                    },
                    browserCart: {
                        type: "boolean",
                    },
                    cartItems: {
                        type: "array",
                        items: {
                            $ref: "#/components/schemas/CreateOrderRequestCartItem",
                        },
                    },
                    estimatedDeliveryDate: {
                        type: "string",
                        format: "date",
                    },
                    splitType: {
                        type: "string",
                        enum: ["Internal", "External"],
                    },
                    promotionCode: {
                        type: "array",
                        items: {
                            type: "string",
                        },
                    },
                    estimatedPoints: {
                        type: "number",
                    },
                    paymentType: {
                        type: "string",
                    },
                    useNetAmount: {
                        type: "boolean",
                    },
                    routeId: {
                        type: "string",
                        example: "PLB007",
                    },
                    soldToCustomerId: {
                        type: "string",
                    },
                    shipToCustomerId: {
                        type: "string",
                    },
                    billToCustomerId: {
                        type: "string",
                    },
                    alternateSoldToCustomerId: {
                        type: "string",
                    },
                },
            },
            CreateOrderResponseDto: {
                type: "object",
                properties: {
                    Internal: {
                        type: "array",
                        items: {
                            $ref: "#/components/schemas/CreateOrderResponse",
                        },
                    },
                    External: {
                        type: "array",
                        items: {
                            $ref: "#/components/schemas/CreateOrderResponse",
                        },
                    },
                },
            },
            CreateOrderResponse: {
                type: "object",
                properties: {
                    orderId: {
                        type: "string",
                        description: "Order Id",
                    },
                    orderNumber: {
                        type: "string",
                        description: "Order Number",
                    },
                    sourceOrderId: {
                        type: "string",
                        description: "Source Order Id",
                    },
                    estimatedPoints: {
                        type: "number",
                        description: "Estimated Points",
                    },
                    cartId: {
                        type: "string",
                        description: "Cart Id",
                    },
                },
            },
            CEPOrderItem: {
                type: "object",
                properties: {
                    id: {
                        type: "number",
                    },
                    externalId: {
                        type: "string",
                    },
                    name: {
                        type: "object",
                        additionalProperties: true,
                    },
                    imageURL: {
                        type: "string",
                    },
                    quantity: {
                        type: "number",
                    },
                    quantityFulfilled: {
                        type: "number",
                    },
                    fulfilledPrice: {
                        type: "number",
                    },
                    acceptedQuantity: {
                        type: "number",
                    },
                    rejectedQuantity: {
                        type: "number",
                    },
                    baseUnit: {
                        type: "string",
                    },
                    saleUnit: {
                        type: "string",
                    },
                    converstionMultiplierToBase: {
                        type: "number",
                    },
                    basePrice: {
                        type: "number",
                    },
                    finalPrice: {
                        type: "number",
                    },
                    categoryId: {
                        type: "string",
                    },
                    isPromotional: {
                        type: "boolean",
                    },
                    isSubstituted: {
                        type: "boolean",
                    },
                    substitutedById: {
                        type: "number",
                    },
                    status: {
                        type: "string",
                    },
                    gtInValue: {
                        type: "number",
                    },
                    rejectedReason: {
                        type: "string",
                    },
                    deliveryMethod: {
                        type: "string",
                    },
                    digitalAddress: {
                        type: "string",
                    },
                    type: {
                        type: "string",
                    },
                    counponInfo: {
                        type: "array",
                        items: {
                            type: "object",
                            properties: {
                                code: {
                                    type: "string",
                                },
                                codeExpiredDate: {
                                    type: "string",
                                },
                                expired: {
                                    type: "boolean",
                                },
                            },
                        },
                    },
                },
            },
            CEPOrder: {
                type: "object",
                properties: {
                    id: {
                        type: "number",
                    },
                    sourceOrderId: {
                        type: "string",
                    },
                    orderType: {
                        type: "string",
                    },
                    orderStatus: {
                        type: "string",
                    },
                    orderNumber: {
                        type: "string",
                    },
                    releaseFlag: {
                        type: "string",
                    },
                    erpOrderNumber: {
                        type: "string",
                    },
                    poNumber: {
                        type: "string",
                    },
                    ediNumber: {
                        type: "string",
                    },
                    invoiceNumber: {
                        type: "string",
                    },
                    orderLocationId: {
                        type: "string",
                    },
                    orderDate: {
                        type: "string",
                    },
                    poDate: {
                        type: "string",
                    },
                    estimatedDeliveryDate: {
                        type: "string",
                    },
                    deliveryDate: {
                        type: "string",
                    },
                    erpShippingCondition: {
                        type: "string",
                    },
                    productDiscount: {
                        type: "number",
                    },
                    promotionalDiscount: {
                        type: "number",
                    },
                    totalTax: {
                        type: "number",
                    },
                    subtotal: {
                        type: "number",
                    },
                    totalPrice: {
                        type: "number",
                    },
                    estimatedPoints: {
                        type: "number",
                    },
                    deliveredPoints: {
                        type: "number",
                    },
                    paymentTermCode: {
                        type: "string",
                    },
                    currency: {
                        type: "string",
                    },
                    routeId: {
                        type: "string",
                    },
                    routeRepId: {
                        type: "string",
                    },
                    routeOrderTypeCode: {
                        type: "string",
                    },
                    routeOrderTypeSubCode: {
                        type: "string",
                    },
                    routeVisitSchedule: {
                        type: "string",
                    },
                    routeMarketplaceId: {
                        type: "string",
                    },
                    sourceSystemId: {
                        type: "string",
                    },
                    voucherCode: {
                        type: "string",
                    },
                    routeTypeName: {
                        type: "array",
                        items: {
                            type: "string",
                        },
                    },
                    items: {
                        type: "array",
                        items: {
                            $ref: "#/components/schemas/CEPOrderItem",
                        },
                    },
                    shippingAddress: {
                        $ref: "#/components/schemas/Address",
                    },
                    billingAddress: {
                        $ref: "#/components/schemas/Address",
                    },
                    customerAddress: {
                        $ref: "#/components/schemas/Address",
                    },
                    orderNote: {
                        type: "string",
                    },
                    rejectedNote: {
                        type: "string",
                    },
                    alternateOrderId: {
                        type: "string",
                    },
                },
            },
            CEPOrderV2: {
                type: "object",
                properties: {
                    id: {
                        type: "number",
                    },
                    sourceOrderId: {
                        type: "string",
                    },
                    orderType: {
                        type: "string",
                    },
                    orderStatus: {
                        type: "string",
                    },
                    orderNumber: {
                        type: "string",
                    },
                    releaseFlag: {
                        type: "string",
                    },
                    erpOrderNumber: {
                        type: "string",
                    },
                    customerPONumber: {
                        type: "string",
                    },
                    ediNumber: {
                        type: "string",
                    },
                    invoiceNumber: {
                        type: "string",
                    },
                    orderLocationId: {
                        type: "string",
                    },
                    orderDate: {
                        type: "string",
                    },
                    poDate: {
                        type: "string",
                    },
                    estimatedDeliveryDate: {
                        type: "string",
                    },
                    deliveryDate: {
                        type: "string",
                    },
                    erpShippingCondition: {
                        type: "string",
                    },
                    productDiscount: {
                        type: "number",
                    },
                    promotionalDiscount: {
                        type: "number",
                    },
                    totalTax: {
                        type: "number",
                    },
                    subtotal: {
                        type: "number",
                    },
                    totalPrice: {
                        type: "number",
                    },
                    estimatedPoints: {
                        type: "number",
                    },
                    deliveredPoints: {
                        type: "number",
                    },
                    paymentTermCode: {
                        type: "string",
                    },
                    currency: {
                        type: "string",
                    },
                    routeId: {
                        type: "string",
                    },
                    routeRepId: {
                        type: "string",
                    },
                    routeOrderTypeCode: {
                        type: "string",
                    },
                    routeOrderTypeSubCode: {
                        type: "string",
                    },
                    routeVisitSchedule: {
                        type: "string",
                    },
                    routeMarketplaceId: {
                        type: "string",
                    },
                    sourceSystemId: {
                        type: "string",
                    },
                    voucherCode: {
                        type: "string",
                    },
                    routeTypeName: {
                        type: "array",
                        items: {
                            type: "string",
                        },
                    },
                    items: {
                        type: "array",
                        items: {
                            $ref: "#/components/schemas/CEPOrderItem",
                        },
                    },
                    shippingAddress: {
                        $ref: "#/components/schemas/Address",
                    },
                    billingAddress: {
                        $ref: "#/components/schemas/Address",
                    },
                    customerAddress: {
                        $ref: "#/components/schemas/Address",
                    },
                    orderNote: {
                        type: "string",
                    },
                    rejectedNote: {
                        type: "string",
                    },
                    alternateOrderId: {
                        type: "string",
                    },
                },
            },
            GetOrderResponseDto: {
                type: "object",
                properties: {
                    metadata: {
                        type: "object",
                        properties: {
                            __comment: {
                                type: "string",
                            },
                            itemKey: {
                                type: "string",
                            },
                            totalItems: {
                                type: "number",
                            },
                        },
                    },
                    orders: {
                        type: "array",
                        items: {
                            $ref: "#/components/schemas/CEPOrderV2",
                        },
                    },
                    rewardType: {
                        type: "array",
                        items: {
                            type: "string",
                        },
                    },
                    rewardTypeCount: {
                        type: "number",
                    },
                },
            },
            GetDeliveryEstimateResponseDto: {
                type: "object",
                properties: {
                    deliveryDay: {
                        type: "string",
                        example: "Saturday",
                    },
                    deliveryDate: {
                        type: "string",
                        example: "2022-07-02",
                    },
                },
            },
            DeliveryEstimateV2: {
                type: "object",
                properties: {
                    deliveryDay: {
                        type: "string",
                    },
                    deliveryDate: {
                        type: "string",
                        format: "date",
                    },
                    timeZone: {
                        type: "string",
                    },
                    deliveryDays: {
                        type: "array",
                        items: {
                            type: "string",
                        },
                    },
                    deliveryService: {
                        type: "string",
                    },
                    cutoffTime: {
                        type: "string",
                    },
                },
            },
            GetDeliveryEstimateResponseDtoV2: {
                type: "object",
                properties: {
                    internal: {
                        $ref: "#/components/schemas/DeliveryEstimateV2",
                    },
                    external: {
                        $ref: "#/components/schemas/DeliveryEstimateV2",
                    },
                },
            },
            GetDeliveryDates: {
                type: "object",
                properties: {
                    deliveryDates: {
                        type: "array",
                        items: {
                            type: "object"
                        }
                    },
                    date: {
                        type: "string"
                    }
                }
            },
            GetRewardsEstimatedPointsResponseDto: {
                type: "object",
                properties: {
                    success: {
                        type: "boolean",
                    },
                    pointsEarned: {
                        type: "number",
                    },
                },
            },
            GetRewardsEstimatedPointsResponseDtoV2: {
                type: "object",
                properties: {
                    success: {
                        type: "boolean",
                    },
                    internalPointsEarned: {
                        type: "number",
                    },
                    externalPointsEarned: {
                        type: "number",
                    },
                },
            },
            CreateOrderRequestCartItemV4: {
                type: "object",
                properties: {
                    id: {
                        type: "number",
                    },
                    externalId: {
                        type: "number",
                    },
                    categoryId: {
                        type: "number",
                    },
                    sourceOrderLineId: {
                        type: "number",
                    },
                    isPromotional: {
                        type: "boolean",
                    },
                    saleUoms: {
                        type: "object",
                        properties: {
                            saleUomsName: {
                                type: "string",
                            },
                            saleUomsCode: {
                                type: "string",
                            },
                        },
                    },
                    conversionMulitplierToBase: {
                        type: "number",
                    },
                    quantity: {
                        type: "number",
                    },
                    basePrice: {
                        type: "number",
                    },
                    finalPrice: {
                        type: "number",
                    },
                    subTotal: {
                        type: "number",
                    },
                    tax: {
                        type: "number",
                    },
                    discountedPrice: {
                        type: "number",
                    },
                    depositPrice: {
                        type: "number",
                    },
                    isReturnItem: {
                        type: "boolean",
                    },
                },
            },
            CreateOrderRequestDtoV4: {
                type: "object",
                properties: {
                    orderType: {
                        type: "string",
                        enum: ["ZOR", "Rewards"],
                    },
                    orderDate: {
                        type: "string",
                        format: "date",
                    },
                    sourceOrderId: {
                        type: "string",
                    },
                    customerPONumber: {
                        type: "string",
                    },
                    subTotal: {
                        type: "number",
                    },
                    productDiscounts: {
                        type: "number",
                    },
                    totalTax: {
                        type: "number",
                    },
                    totalPrice: {
                        type: "number",
                    },
                    billingAddress: {
                        $ref: "#/components/schemas/Address",
                    },
                    browserCart: {
                        type: "boolean",
                    },
                    cartItems: {
                        type: "array",
                        items: {
                            $ref: "#/components/schemas/CreateOrderRequestCartItemV4",
                        },
                    },
                    estimatedDeliveryDate: {
                        type: "string",
                        format: "date",
                    },
                    split: {
                        type: "string",
                    },
                    promotionCode: {
                        type: "array",
                        items: {
                            type: "string",
                        },
                    },
                    estimatedPoints: {
                        type: "number",
                    },
                    useNetAmount: {
                        type: "boolean",
                    },
                    status: {
                        type: "string",
                        enum: [
                            "In Progress",
                            "Delivered",
                            "Cancelled",
                            "In-Transit",
                            "Rejected",
                            "Partially Accepted",
                            "Not Created",
                            "Test Order",
                            "Reserved Order",
                        ],
                    },
                },
            },
            AddStoreRequestDto: {
                type: "object",
                properties: {
                    userIdType: {
                        type: "string",
                    },
                    userId: {
                        type: "string",
                    },
                    erpID: {
                        type: "string",
                    },
                    taxID: {
                        type: "string",
                    },
                },
            },
            ProductMetadata: {
                type: "object",
                properties: {
                    __comment: {
                        type: "string",
                    },
                    itemKey: {
                        type: "string",
                    },
                    itemType: {
                        type: "string",
                    },
                    totalItems: {
                        type: "integer",
                    },
                    productPointsEligibleCategory: {
                        type: "string",
                    },
                    allProducts: {
                        type: "string",
                        format: "uri",
                    },
                    beverageImage: {
                        type: "string",
                        nullable: true,
                    },
                    snackImage: {
                        type: "string",
                        nullable: true,
                    },
                    displayCategory: {
                        type: "object",
                        additionalProperties: {
                            type: "array",
                            items: {
                                type: "string",
                            },
                        },
                    },
                    displaySize: {
                        type: "array",
                        items: {
                            type: "string",
                        },
                    },
                },
            },
            Product: {
                type: "object",
                properties: {
                    id: {
                        type: "integer",
                    },
                    externalId: {
                        type: "string",
                    },
                    edoFlag: {
                        type: "string",
                    },
                    displayOrder: {
                        type: "integer",
                        nullable: true,
                    },
                    name: {
                        type: "string",
                    },
                    displayName: {
                        type: "object",
                        additionalProperties: true,
                    },
                    displaySize: {
                        type: "object",
                        additionalProperties: true,
                    },
                    shortDescription: {
                        type: "object",
                        additionalProperties: true,
                    },
                    longDescription: {
                        type: "object",
                        additionalProperties: true,
                    },
                    thumbnailUrl: {
                        type: "string",
                        format: "uri",
                    },
                    barCode: {
                        type: "string",
                    },
                    skuNumber: {
                        type: "string",
                    },
                    brandId: {
                        type: "string",
                    },
                    brand: {
                        type: "object",
                        additionalProperties: true,
                    },
                    brandImageUrl: {
                        type: "string",
                        format: "uri",
                    },
                    subBrandId: {
                        type: "string",
                    },
                    subBrand: {
                        type: "object",
                        additionalProperties: true,
                    },
                    subBrandImageUrl: {
                        type: "string",
                        format: "uri",
                        nullable: true,
                    },
                    categoryId: {
                        type: "string",
                    },
                    categoryType: {
                        type: "string",
                    },
                    category: {
                        type: "object",
                        additionalProperties: true,
                    },
                    categoryImageUrl: {
                        type: "string",
                        format: "uri",
                        nullable: true,
                    },
                    subCategoryId: {
                        type: "string",
                    },
                    subCategory: {
                        type: "object",
                        additionalProperties: true,
                    },
                    subCategoryImageUrl: {
                        type: "string",
                        format: "uri",
                    },
                    details: {
                        type: "object",
                        properties: {
                            erpCategory: {
                                type: "string",
                            },
                            size: {
                                type: "string",
                            },
                            package: {
                                type: "string",
                                nullable: true,
                            },
                            flavor: {
                                type: "object",
                                additionalProperties: {
                                    type: "string",
                                },
                            },
                            colourCode: {
                                type: "string",
                            },
                            imageUrl: {
                                type: "string",
                                format: "uri",
                            },
                            additionalImageUrls: {
                                type: "array",
                                items: {
                                    type: "string",
                                },
                            },
                        },
                    },
                    price: {
                        $ref: "#/components/schemas/ProductPrice",
                    },
                    outStock: {
                        type: "boolean",
                    },
                    warehouse: {
                        type: "array",
                        items: {
                            type: "string",
                            enum: ["Internal", "External"],
                        },
                    },
                    minimumOrderQuantity: {
                        type: "integer",
                        nullable: true,
                    },
                    maximumOrderQuantity: {
                        type: "integer",
                        nullable: true,
                    },
                    allowedInMultiplesOf: {
                        type: "integer",
                    },
                    returnPriceForBaseUnit: {
                        type: "number",
                        format: "float",
                    },
                    tags: {
                        type: "array",
                        items: {
                            type: "string",
                        },
                        nullable: true,
                    },
                    wishlist: {
                        type: "string",
                    },
                    status: {
                        type: "boolean",
                    },
                    rewardPoints: {
                        type: "integer",
                    },
                    hasDeposit: {
                        type: "boolean",
                    },
                    isReturnable: {
                        type: "boolean",
                    },
                    productContainer: {
                        type: "string",
                    },
                    productLine: {
                        type: "string",
                    },
                    productGroup: {
                        type: "string",
                    },
                    productSize: {
                        type: "string",
                    },
                },
            },
            ProductPrice: {
                type: "object",
                properties: {
                    suggestedRetailSellingPrice: {
                        type: "number",
                        nullable: true,
                    },
                    basePrice: {
                        type: "number",
                    },
                    pricelistId: {
                        type: "string",
                    },
                    campaignMultiplier: {
                        type: "number",
                    },
                    baseUOM: {
                        type: "string",
                    },
                    baseUnitMSRP: {
                        type: "number",
                        nullable: true,
                    },
                    returnBaseUOM: {
                        type: "string",
                    },
                    adjustments: {
                        type: "array",
                        items: {
                            $ref: "#/components/schemas/ProductPriceAdjustment",
                        },
                    },
                    adjustmentPrice: {
                        type: "number",
                        nullable: true,
                    },
                    saleUoms: {
                        type: "array",
                        items: {
                            $ref: "#/components/schemas/ProductUom",
                        },
                    },
                    returnUoms: {
                        type: "array",
                        items: {
                            $ref: "#/components/schemas/ProductUom",
                        },
                    },
                    tax: {
                        type: "array",
                        items: {
                            $ref: "#/components/schemas/ProductTax",
                        },
                    },
                    depositPrice: {
                        type: "number",
                        nullable: true,
                    },
                    currency: {
                        type: "string",
                    },
                },
            },
            ProductPriceAdjustment: {
                type: "object",
                properties: {
                    type: {
                        type: "string",
                    },
                    rate: {
                        type: "number",
                    },
                    unit: {
                        type: "string",
                    },
                    id: {
                        type: "string",
                    },
                    rewardId: {
                        type: "string",
                        nullable: true,
                    },
                    ruleId: {
                        type: "string",
                        nullable: true,
                    },
                },
            },
            ProductUom: {
                type: "object",
                properties: {
                    code: {
                        type: "string",
                    },
                    name: {
                        type: "string",
                    },
                    conversionMultiplierToBase: {
                        type: "integer",
                    },
                },
            },
            ProductTax: {
                type: "object",
                properties: {
                    type: {
                        type: "string",
                    },
                    valueType: {
                        type: "string",
                    },
                    value: {
                        type: "number",
                    },
                },
            },
            GetProductsResponseDto: {
                type: "object",
                properties: {
                    metadata: {
                        $ref: "#/components/schemas/ProductMetadata",
                    },
                    products: {
                        type: "array",
                        items: {
                            $ref: "#/components/schemas/Product",
                        },
                    },
                },
            },
            InventoryProductItem: {
                type: "object",
                properties: {
                    id: {
                        type: "number",
                    },
                    externalId: {
                        type: "string",
                    },
                    outStock: {
                        type: "boolean",
                    },
                },
            },
            GetProductsInventoryDto: {
                type: "object",
                properties: {
                    metadata: {
                        type: "object",
                        properties: {
                            __comment: {
                                type: "string",
                            },
                            itemKey: {
                                type: "string",
                            },
                            totalItems: {
                                type: "number",
                            },
                        },
                    },
                    products: {
                        type: "array",
                        items: {
                            $ref: "#/components/schemas/InventoryProductItem",
                        },
                    },
                },
            },
            SalesHistoryOrderItem: {
                type: "object",
                properties: {
                    acceptedQuantity: {
                        type: "number",
                    },
                    id: {
                        type: "number",
                    },
                    productId: {
                        type: "string",
                    },
                    netAmount: {
                        type: "number",
                    },
                    category: {
                        type: "string",
                    },
                    categoryType: {
                        type: "string",
                    },
                    isAssorted: {
                        type: "boolean",
                    },
                    externalId: {
                        type: "string",
                    },
                    name: {
                        type: "object",
                        additionalProperties: true,
                    },
                    size: {
                        type: "object",
                        additionalProperties: true,
                    },
                    imageURL: {
                        type: "string",
                    },
                    points: {
                        type: "number",
                    },
                    rewardType: {
                        type: "string",
                    },
                    quantity: {
                        type: "number",
                    },
                    quantityFulfilled: {
                        type: "number",
                    },
                    unitType: {
                        type: "string",
                    },
                    packageSize: {
                        type: "string",
                    },
                    baseUnit: {
                        type: "string",
                    },
                    counponInfo: {
                        type: "array",
                        items: {
                            type: "object",
                        },
                    },
                    unitPrice: {
                        type: "number",
                    },
                    itemState: {
                        type: "string",
                    },
                    tax: {
                        type: "number",
                    },
                    conversionMultiplierToBase: {
                        type: "number",
                    },
                    saleUnitConversionToBase: {
                        type: "number",
                    },
                    basePrice: {
                        type: "number",
                    },
                    finalPrice: {
                        type: "number",
                    },
                    categoryId: {
                        type: "string",
                    },
                    isPromotional: {
                        type: "boolean",
                    },
                    isSubstituted: {
                        type: "boolean",
                    },
                    substitutedById: {
                        type: "number",
                    },
                    status: {
                        type: "string",
                    },
                    gtInValue: {
                        type: "number",
                    },
                    rejectedReason: {
                        type: "string",
                    },
                    deliveryMethod: {
                        type: "string",
                    },
                    digitalAddress: {
                        type: "string",
                    },
                    type: {
                        type: "string",
                    },
                    saleUnit: {
                        type: "string",
                    },
                    fulfilledPrice: {
                        type: "number",
                    },
                    finalNetPrice: {
                        type: "number",
                    },
                    removeStatus: {
                        type: "boolean",
                    },
                    additionalItemStatus: {
                        type: "boolean",
                    },
                    isReturn: {
                        type: "boolean",
                    },
                },
            },
            SalesHistoryOrder: {
                type: "object",
                properties: {
                    contractualDiscount: {
                        type: "string",
                    },
                    currency: {
                        type: "string",
                    },
                    customerAddress: {
                        $ref: "#/components/schemas/Address",
                    },
                    deliveredPoints: {
                        type: "number",
                    },
                    deliveryDate: {
                        type: "string",
                    },
                    ediNumber: {
                        type: "string",
                    },
                    erpOrderNumber: {
                        type: "string",
                    },
                    erpShippingCondition: {
                        type: "string",
                    },
                    estimatedDeliveryDate: {
                        type: "string",
                    },
                    estimatedPoints: {
                        type: "number",
                    },
                    id: {
                        type: "number",
                    },
                    invoiceNumber: {
                        type: "string",
                    },
                    orderType: {
                        type: "string",
                    },
                    documentId: {
                        type: "string",
                    },
                    totalPrice: {
                        type: "number",
                    },
                    documentSource: {
                        type: "string",
                    },
                    orderDate: {
                        type: "string",
                    },
                    dollarSpend: {
                        type: "number",
                    },
                    dollarSpendCalculated: {
                        type: "number",
                    },
                    customerId: {
                        type: "string",
                    },
                    itemCount: {
                        type: "number",
                    },
                    items: {
                        type: "array",
                        items: {
                            $ref: "#/components/schemas/SalesHistoryOrderItem",
                        },
                    },
                    orderLocationId: {
                        type: "string",
                    },
                    orderNote: {
                        type: "string",
                    },
                    orderNumber: {
                        type: "string",
                    },
                    orderStatus: {
                        type: "string",
                    },
                    paymentTermCode: {
                        type: "string",
                    },
                    poNUmber: {
                        type: "string",
                    },
                    promotionalDiscount: {
                        type: "number",
                    },
                    rejectedNote: {
                        type: "string",
                    },
                    releaseFlag: {
                        type: "string",
                    },
                    routeId: {
                        type: "string",
                    },
                    routeMarketplaceId: {
                        type: "string",
                    },
                    routeOrderTypeCode: {
                        type: "string",
                    },
                    routeOrderTypeSubCode: {
                        type: "string",
                    },
                    routeRepId: {
                        type: "string",
                    },
                    routeVisitSchedule: {
                        type: "string",
                    },
                    sourceSystemId: {
                        type: "string",
                    },
                    subtotal: {
                        type: "number",
                    },
                    taxAmount: {
                        type: "number",
                    },
                    totalDiscount: {
                        type: "number",
                    },
                    totalNetPrice: {
                        type: "number",
                    },
                    voucherCode: {
                        type: "string",
                    },
                    sourceOrderId: {
                        type: "string",
                    },
                    snackTotalCount: {
                        type: "number",
                    },
                    bevTotalCount: {
                        type: "number",
                    },
                    snackTotalAmt: {
                        type: "number",
                    },
                    bevTotalAmt: {
                        type: "number",
                    },
                },
            },
            GetSalesHistoryResponseDto: {
                type: "object",
                properties: {
                    metadata: {
                        type: "object",
                        properties: {
                            __comment: {
                                type: "string",
                            },
                            itemKey: {
                                type: "string",
                            },
                            totalItems: {
                                type: "number",
                            },
                        },
                    },
                    orders: {
                        type: "array",
                        items: {
                            $ref: "#/components/schemas/SalesHistoryOrder",
                        },
                    },
                    rewardType: {
                        type: "array",
                        items: {
                            type: "string",
                        },
                    },
                    rewardTypeCount: {
                        type: "number",
                    },
                },
            },
            Survey: {
                type: "object",
                properties: {
                    title: {
                        type: "string",
                    },
                    siteid: {
                        type: "string",
                    },
                    displayOrder: {
                        type: "integer",
                    },
                    image: {
                        type: "string",
                    },
                    question: {
                        type: "object",
                        properties: {
                            en: {
                                type: "string",
                            },
                            tr: {
                                type: "string",
                            },
                        },
                        required: ["en", "tr"],
                    },
                    answer: {
                        type: "array",
                        items: {
                            type: "object",
                            properties: {
                                en: {
                                    type: "string",
                                },
                                tr: {
                                    type: "string",
                                },
                            },
                            required: ["en"],
                        },
                    },
                },
            },
            GetCustomerOTPResponse: {
                type: "object",
                properties: {
                    status: {
                        type: "string",
                    },
                    message: {
                        type: "string",
                    },
                    data: {
                        type: "object",
                        properties: {
                            otp: {
                                type: "string",
                            },
                        },
                        required: ["otp"],
                    },
                },
                required: ["status", "message", "data"],
            },
            GetPromotionResponseDto: {
                type: "object",
                properties: {
                    metadata: {
                        $ref: "#/components/schemas/PromotionsMetadata",
                    },
                    promotions: {
                        type: "array",
                        items: {
                            $ref: "#/components/schemas/Promotion",
                        },
                    },
                },
                required: ["metadata", "promotions"],
            },
            Promotion: {
                type: "object",
                properties: {
                    id: {
                        type: "string",
                    },
                    title: {
                        type: "object",
                        properties: {
                            en: {
                                type: "string",
                            },
                            es: {
                                type: "string",
                            },
                        },
                        additionalProperties: true,
                        required: ["en", "es"],
                    },
                    description: {
                        type: "string",
                    },
                    startDate: {
                        type: "string",
                    },
                    endDate: {
                        type: "string",
                    },
                    isPublic: {
                        type: "boolean",
                    },
                    isActive: {
                        type: "string",
                    },
                    behavior: {
                        type: "string",
                    },
                    actions: {
                        type: "array",
                        items: {
                            $ref: "#/components/schemas/PromotionAction",
                        },
                    },
                    conditions: {
                        type: "array",
                        items: {
                            $ref: "#/components/schemas/PromotionCondition",
                        },
                    },
                },
                required: [
                    "id",
                    "title",
                    "startDate",
                    "endDate",
                    "isPublic",
                    "isActive",
                    "behavior",
                    "actions",
                    "conditions",
                ],
            },
            PromotionsMetadata: {
                type: "object",
                properties: {
                    totalItems: {
                        type: "integer",
                    },
                },
                required: ["totalItems"],
            },
            PromotionAction: {
                type: "object",
                properties: {
                    type: {
                        type: "string",
                    },
                    message: {
                        type: "object",
                        properties: {
                            en: {
                                type: "string",
                            },
                            es: {
                                type: "string",
                            },
                        },
                        required: ["en", "es"],
                    },
                    fields: {
                        type: "array",
                        items: {
                            $ref: "#/components/schemas/PromotionField",
                        },
                    },
                },
                required: ["type", "message", "fields"],
            },
            PromotionField: {
                type: "object",
                properties: {
                    reward_qty: {
                        type: "integer",
                    },
                    buy_qty: {
                        type: "integer",
                    },
                    purchaseAmount: {
                        type: "number",
                    },
                    product: {
                        type: "integer",
                    },
                    maxAllowedUsage: {
                        type: "integer",
                    },
                    maxAllowPercentage: {
                        type: "number",
                    },
                    resetFrequency: {
                        type: "integer",
                    },
                    gift_product: {
                        type: "integer",
                    },
                    fixed_discount: {
                        type: "number",
                    },
                    additional_discount: {
                        type: "number",
                    },
                    buy_qty_second: {
                        type: "integer",
                    },
                    buy_qty_third: {
                        type: "integer",
                    },
                    buy_qty_fourth: {
                        type: "integer",
                    },
                    first_layered_discount: {
                        type: "number",
                    },
                    second_layered_discount: {
                        type: "number",
                    },
                    third_layered_discount: {
                        type: "number",
                    },
                    fourth_layered_discount: {
                        type: "number",
                    },
                    buy_quantity: {
                        type: "string",
                    },
                    percentage: {
                        type: "number",
                    },
                },
            },
            PromotionCondition: {
                type: "object",
                properties: {
                    operator: {
                        type: "string",
                    },
                    type: {
                        type: "string",
                    },
                    products: {
                        type: "array",
                        items: {
                            type: "integer",
                        },
                    },
                    starting: {
                        type: "string",
                    },
                    ending: {
                        type: "string",
                    },
                },
                required: ["operator", "type", "starting", "ending"],
            },
            ValidateProfileRequestDto: {
                type: "object",
                properties: {
                    userIdType: {
                        type: "string",
                        enum: ["Email", "Phone", "SSO", "CustomerID"],
                    },
                    userId: {
                        type: "string",
                    },
                    email: {
                        type: "string",
                    },
                    taxID: {
                        type: "string",
                    },
                    registrationType: {
                        type: "string",
                        enum: ["New", "Update", "SSO"],
                    },
                    phoneNumber: {
                        type: "string",
                    },
                },
            },
            TargetResponseDTO: {
                type: "object",
                properties: {
                    totalCount: {
                        type: "integer",
                    },
                    data: {
                        type: "array",
                        items: {
                            type: "object",
                            properties: {
                                salesTargetId: {
                                    type: "integer",
                                },
                                type: {
                                    type: "string",
                                },
                                VATIncluded: {
                                    type: "boolean",
                                },
                                title: {
                                    type: "object",
                                    properties: {
                                        en: {
                                            type: "string",
                                        },
                                        tr: {
                                            type: "string",
                                        },
                                    },
                                    required: ["en", "tr"],
                                    additionalProperties: false,
                                },
                                excerpt: {
                                    type: "object",
                                    properties: {
                                        en: {
                                            type: "string",
                                        },
                                        tr: {
                                            type: "string",
                                        },
                                    },
                                    required: ["en", "tr"],
                                    additionalProperties: false,
                                },
                                content: {
                                    type: "object",
                                    properties: {
                                        en: {
                                            type: "string",
                                        },
                                        tr: {
                                            type: "string",
                                        },
                                    },
                                    required: ["en", "tr"],
                                    additionalProperties: false,
                                },
                                terms: {
                                    type: "object",
                                    properties: {
                                        en: {
                                            type: "string",
                                        },
                                        tr: {
                                            type: "string",
                                        },
                                    },
                                    required: ["en", "tr"],
                                    additionalProperties: false,
                                },
                                category: {
                                    type: "object",
                                    properties: {
                                        en: {
                                            type: "string",
                                        },
                                        tr: {
                                            type: "string",
                                        },
                                    },
                                    required: ["en", "tr"],
                                    additionalProperties: false,
                                },
                                design: {
                                    type: "object",
                                    properties: {
                                        textColor: {
                                            type: "string",
                                        },
                                        backgroundColor: {
                                            type: "string",
                                        },
                                        buttonTextColor: {
                                            type: "string",
                                        },
                                        buttonBackgroundColor: {
                                            type: "string",
                                        },
                                    },
                                    required: [
                                        "textColor",
                                        "backgroundColor",
                                        "buttonTextColor",
                                        "buttonBackgroundColor",
                                    ],
                                    additionalProperties: false,
                                },
                                images: {
                                    type: "array",
                                    items: {
                                        type: "object",
                                    },
                                },
                                url: {
                                    type: "string",
                                },
                                product: {
                                    type: "array",
                                    items: {
                                        type: "object",
                                    },
                                },
                                isPlatformOnly: {
                                    type: "boolean",
                                },
                                orderTypes: {
                                    type: "array",
                                    items: {
                                        type: "string",
                                    },
                                },
                                displayStartDate: {
                                    type: "string",
                                    format: "date-time",
                                },
                                displayEndDate: {
                                    type: "string",
                                    format: "date-time",
                                },
                                activeStartDate: {
                                    type: "string",
                                    format: "date-time",
                                },
                                activeEndDate: {
                                    type: "string",
                                    format: "date-time",
                                },
                                rewardDate: {
                                    type: "string",
                                    format: "date-time",
                                },
                                rewardsAwarded: {
                                    type: "boolean",
                                },
                                requireOptIn: {
                                    type: "boolean",
                                },
                                optedIn: {
                                    type: "boolean",
                                },
                                status: {
                                    type: "string",
                                },
                                dollarSpend: {
                                    type: "number",
                                },
                                dollarTarget: {
                                    type: "number",
                                },
                                pointsToAward: {
                                    type: "number",
                                },
                                pointsAwarded: {
                                    type: "number",
                                },
                                pointDisplay: {
                                    type: "string",
                                },
                                previousYearBaseRevenue: {
                                    type: "number",
                                },
                                prerequisiteTargets: {
                                    type: "array",
                                    items: {
                                        type: "object",
                                    },
                                },
                                prerequisiteParent: {
                                    type: "array",
                                    items: {
                                        type: "object",
                                    },
                                },
                            },
                            required: [
                                "salesTargetId",
                                "type",
                                "VATIncluded",
                                "title",
                                "excerpt",
                                "content",
                                "terms",
                                "category",
                                "design",
                                "images",
                                "url",
                                "product",
                                "isPlatformOnly",
                                "orderTypes",
                                "displayStartDate",
                                "displayEndDate",
                                "activeStartDate",
                                "activeEndDate",
                                "rewardDate",
                                "rewardsAwarded",
                                "requireOptIn",
                                "optedIn",
                                "status",
                                "dollarSpend",
                                "dollarTarget",
                                "pointsToAward",
                                "pointsAwarded",
                                "pointDisplay",
                                "previousYearBaseRevenue",
                                "prerequisiteTargets",
                                "prerequisiteParent",
                            ],
                            additionalProperties: false,
                        },
                    },
                },
            },
            GamesScoreRequestDto: {
                type: "object",
                properties: {
                    score: {
                        type: "string",
                    },
                },
            },
            PlayquizRequestDto: {
                type: "object",
                properties: {
                    quizPassed: {
                        type: "boolean",
                    },
                },
            },
            PlayquizResponseDto: {
                type: "object",
                properties: {
                    success: {
                        type: "boolean",
                    },
                    errorCode: {
                        type: "object",
                    },
                    msg: {
                        type: "string",
                    },
                    message: {
                        type: "string",
                    },
                },
            },
            VerifyCodeRequestDto: {
                type: "object",
                properties: {
                    userId: {
                        type: "string",
                    },
                    code: {
                        type: "string",
                    },
                    registrationType: {
                        type: "string",
                        enum: ["New", "Update", "SSO"],
                    },
                },
            },
            ProfileRegistrationPreferences: {
                type: "object",
                properties: {
                    allowEmailNotifications: {
                        type: "boolean",
                    },
                    allowNewsletterNotifications: {
                        type: "boolean",
                    },
                },
            },
            ProfileRegistrationCredentials: {
                type: "object",
                properties: {
                    password: {
                        type: "object",
                        properties: {
                            value: {
                                type: "string",
                            },
                        },
                    },
                },
            },
            FAQ: {
                type: "object",
                properties: {
                    id: {
                        type: "integer",
                        description: "Unique identifier for the FAQ",
                    },
                    title: {
                        type: "object",
                        properties: {
                            en: {
                                type: "string",
                                description: "Title in English",
                            },
                            tr: {
                                type: "string",
                                description: "Title in Turkish",
                            },
                        },
                    },
                    description: {
                        type: "object",
                        properties: {
                            en: {
                                type: "string",
                                description: "Description in English",
                            },
                            tr: {
                                type: "string",
                                description: "Description in Turkish",
                            },
                        },
                    },
                    image: {
                        type: "string",
                        description: "URL of the image",
                    },
                    siteId: {
                        type: "string",
                        description: "Identifier for the site",
                    },
                    isPublic: {
                        type: "boolean",
                        description: "Indicates if the FAQ is public",
                    },
                    created: {
                        type: "object",
                        format: "date-time",
                        description: "Date and time when the FAQ was created",
                    },
                    modified: {
                        type: "object",
                        format: "date-time",
                        description: "Date and time when the FAQ was last modified",
                    },
                    tags: {
                        type: "array",
                        items: {
                            type: "string",
                        },
                        description: "Tags associated with the FAQ",
                    },
                    displayOrder: {
                        type: "integer",
                        description: "Display order of the FAQ",
                    },
                    section: {
                        type: "array",
                        items: {
                            $ref: "#/components/schemas/Section",
                        },
                        description: "Sections within the FAQ",
                    },
                },
            },
            Section: {
                type: "object",
                properties: {
                    sectionId: {
                        type: "integer",
                        description: "Unique identifier for the section",
                    },
                    title: {
                        type: "object",
                        properties: {
                            en: {
                                type: "string",
                                description: "Title of the section in English",
                            },
                            tr: {
                                type: "string",
                                description: "Title of the section in Turkish",
                            },
                        },
                    },
                    faqs: {
                        type: "array",
                        items: {
                            $ref: "#/components/schemas/FAQItem",
                        },
                        description: "FAQs within the section",
                    },
                },
            },
            FAQItem: {
                type: "object",
                properties: {
                    question: {
                        type: "object",
                        properties: {
                            en: {
                                type: "string",
                                description: "Question in English",
                            },
                            tr: {
                                type: "string",
                                description: "Question in Turkish",
                            },
                        },
                    },
                    answer: {
                        type: "object",
                        properties: {
                            en: {
                                type: "string",
                                description: "Answer in English",
                            },
                            tr: {
                                type: "string",
                                description: "Answer in Turkish",
                            },
                        },
                    },
                    image: {
                        type: "string",
                        description: "URL of the image related to the FAQ item",
                    },
                },
            },
            Profile: {
                type: "object",
                properties: {
                    userId: {
                        type: "string",
                    },
                    registrationType: {
                        type: "string",
                        enum: ["New", "Update", "SSO"],
                    },
                    firstName: {
                        type: "string",
                    },
                    lastName: {
                        type: "string",
                    },
                    secondLastName: {
                        type: "string",
                    },
                    email: {
                        type: "string",
                    },
                    mobilePhone: {
                        type: "string",
                    },
                    role: {
                        type: "string",
                    },
                    dob: {
                        type: "string",
                    },
                    language: {
                        type: "string",
                    }
                },
            },
            FAQsResponseDto: {
                type: "object",
                properties: {
                    totalCount: {
                        type: "integer",
                        description: "Total number of FAQs",
                    },
                    data: {
                        type: "array",
                        items: {
                            $ref: "#/components/schemas/FAQ",
                        },
                    },
                },
            },
            ProfileRegistrationRequestConsent: {
                type: "object",
                properties: {
                    pushNotification: {
                        type: "boolean",
                    },
                    emailNotification: {
                        type: "boolean",
                    },
                    smsNotification: {
                        type: "boolean",
                    },
                    callNotification: {
                        type: "boolean",
                    },
                },
            },
            ProfileRegistrationRequestDto: {
                type: "object",
                properties: {
                    profile: {
                        $ref: "#/components/schemas/Profile",
                    },
                    credentials: {
                        $ref: "#/components/schemas/ProfileRegistrationCredentials",
                    },
                    preferences: {
                        $ref: "#/components/schemas/ProfileRegistrationPreferences",
                    },
                    termsAccepted: {
                        type: "array",
                        items: {
                            $ref: "#/components/schemas/TermsAccepted",
                        },
                    },
                    consent: {
                        $ref: "#/components/schemas/ProfileRegistrationRequestConsent",
                    },
                },
            },
            ProfileDeletionRequestDto: {
                type: "object",
                properties: {
                    userId: {
                        type: "string",
                    },
                },
            },
            ForgotPasswordRequestDto: {
                type: "object",
                properties: {
                    userIdType: {
                        type: "string",
                        enum: ["Email", "Phone"],
                    },
                    username: {
                        type: "string",
                    },
                },
            },
            VerificationResponseProfile: {
                type: "object",
                properties: {
                    login: {
                        type: "string",
                    },
                    firstName: {
                        type: "string",
                    },
                    lastName: {
                        type: "string",
                    },
                    locale: {
                        type: "string",
                    },
                    timeZone: {
                        type: "string",
                    },
                },
            },
            VerificationResponseUser: {
                type: "object",
                properties: {
                    id: {
                        type: "string",
                    },
                    passwordChanged: {
                        type: "string",
                        format: "date-time",
                    },
                    profile: {
                        $ref: "#/components/schemas/VerificationResponseProfile",
                    },
                },
            },
            VerificationResponseLink: {
                type: "object",
                properties: {
                    name: {
                        type: "string",
                    },
                    href: {
                        type: "string",
                    },
                    hints: {
                        type: "object",
                        properties: {
                            allow: {
                                type: "array",
                                items: {
                                    type: "string",
                                },
                            },
                        },
                    },
                },
            },
            ForgotPasswordResponseDto: {
                type: "object",
                properties: {
                    expiresAt: {
                        type: "string",
                        format: "date-time",
                    },
                    status: {
                        type: "string",
                    },
                    recoveryToken: {
                        type: "string",
                    },
                    recoveryType: {
                        type: "string",
                    },
                    _embedded: {
                        type: "object",
                        properties: {
                            user: {
                                $ref: "#/components/schemas/VerificationResponseUser",
                            },
                        },
                    },
                    _links: {
                        type: "object",
                        additionalProperties: {
                            $ref: "#/components/schemas/VerificationResponseLink",
                        },
                    },
                },
            },
            VerifyRecoveryRequestDto: {
                type: "object",
                properties: {
                    username: {
                        type: "string",
                    },
                    recoveryToken: {
                        type: "string",
                    },
                    code: {
                        type: "number",
                    },
                },
            },
            VerifyRecoveryResponsePolicy: {
                type: "object",
                properties: {
                    complexity: {
                        type: "object",
                        properties: {
                            minLength: {
                                type: "number",
                            },
                            minLowerCase: {
                                type: "number",
                            },
                            minUpperCase: {
                                type: "number",
                            },
                            minNumber: {
                                type: "number",
                            },
                            minSymbol: {
                                type: "number",
                            },
                            excludeUsername: {
                                type: "boolean",
                            },
                        },
                    },
                    age: {
                        type: "object",
                        properties: {
                            minAgeMinutes: {
                                type: "number",
                            },
                            historyCount: {
                                type: "number",
                            },
                        },
                    },
                },
            },
            VerifyRecoveryResponseDto: {
                type: "object",
                properties: {
                    stateToken: {
                        type: "string",
                    },
                    expiresAt: {
                        type: "string",
                        format: "date-time",
                    },
                    status: {
                        type: "string",
                    },
                    recoveryType: {
                        type: "string",
                    },
                    _embedded: {
                        type: "object",
                        properties: {
                            user: {
                                $ref: "#/components/schemas/VerificationResponseUser",
                            },
                            policy: {
                                $ref: "#/components/schemas/VerifyRecoveryResponsePolicy",
                            },
                        },
                    },
                    _links: {
                        type: "object",
                        additionalProperties: {
                            $ref: "#/components/schemas/VerificationResponseLink",
                        },
                    },
                },
            },
            ResetPasswordRequestDto: {
                type: "object",
                properties: {
                    stateToken: {
                        type: "string",
                    },
                    newPassword: {
                        type: "string",
                    },
                },
            },
            ResetPasswordResponseDto: {
                type: "object",
                properties: {
                    expiresAt: {
                        type: "string",
                        format: "date-time",
                    },
                    status: {
                        type: "string",
                    },
                    sessionToken: {
                        type: "string",
                    },
                    _embedded: {
                        type: "object",
                        properties: {
                            user: {
                                $ref: "#/components/schemas/VerificationResponseUser",
                            },
                        },
                    },
                    _links: {
                        type: "object",
                        additionalProperties: {
                            $ref: "#/components/schemas/VerificationResponseLink",
                        },
                    },
                },
            },
            ChangePasswordResponseDto: {
                type: "object",
                properties: {
                    password: {
                        type: "array",
                        items: {
                            type: "string",
                        },
                    },
                    provider: {
                        type: "object",
                        properties: {
                            type: {
                                type: "string",
                            },
                            name: {
                                type: "string",
                            },
                        },
                    },
                },
            },
            PointsTransferRequestDto: {
                type: "object",
                properties: {
                    fromAccountId: {
                        type: "string",
                    },
                    toAccountId: {
                        type: "string",
                    },
                    points: {
                        type: "integer",
                    },
                    identifier: {
                        type: "string",
                    },
                    tags: {
                        type: "string",
                    },
                    metadata: {
                        type: "string",
                    },
                },
            },
            ChangePasswordRequestDto: {
                type: "object",
                properties: {
                    user_id: {
                        type: "object",
                        properties: {
                            value: {
                                type: "string",
                            },
                        },
                    },
                    oldPassword: {
                        type: "object",
                        properties: {
                            value: {
                                type: "string",
                            },
                        },
                    },
                    newPassword: {
                        type: "object",
                        properties: {
                            value: {
                                type: "string",
                            },
                        },
                    },
                },
            },
            GetAuthStoresResponseDto: {
                type: "object",
                properties: {
                    success: {
                        type: "boolean",
                    },
                    data: {
                        type: "array",
                        items: {
                            $ref: "#/components/schemas/Store",
                        },
                    },
                },
            },
            RegisterEmployeeRequestStore: {
                type: "object",
                properties: {
                    storeId: {
                        type: "string",
                    },
                    permission: {
                        type: "string",
                    },
                },
            },
            RegisterEmployeeRequestDto: {
                type: "object",
                properties: {
                    userId: {
                        type: "string",
                    },
                    parentErpId: {
                        type: "string",
                    },
                    stores: {
                        type: "array",
                        items: {
                            $ref: "#/components/schemas/RegisterEmployeeRequestStore",
                        },
                    },
                },
            },
            ValidateTaxIdRequestDto: {
                type: "object",
                properties: {
                    erpId: {
                        type: "string",
                    },
                    taxId: {
                        type: "string",
                    },
                },
            },
            GenerateCodeRequestDto: {
                type: "object",
                properties: {
                    userId: {
                        type: "string",
                    },
                    userIdType: {
                        type: "string",
                    },
                    alternativePhone: {
                        type: "string",
                    },
                    email: {
                        type: "string",
                    },
                },
            },
            ResendOtpRequestDto: {
                type: "object",
                properties: {
                    otpType: {
                        type: "string",
                        enum: ["Email", "Phone"],
                    },
                    otpId: {
                        type: "string",
                    },
                    userId: {
                        type: "string",
                    },
                    registrationType: {
                        type: "string",
                        enum: ["New", "Update"],
                    },
                },
            },
            SentmessagesResponseDto: {
                type: "object",
                properties: {
                    metadata: {
                        type: "object",
                        properties: {
                            totalItems: {
                                type: "string",
                            },
                            unreadCount: {
                                type: "integer",
                            },
                        },
                    },
                    notification: {
                        type: "array",
                        items: {
                            $ref: "#/components/schemas/sentMessageNotification",
                        },
                    },
                },
            },
            sentMessageNotification: {
                type: "object",
                properties: {
                    id: {
                        type: "integer",
                    },
                    title: {
                        type: "string",
                    },
                    subTitle: {
                        type: "string",
                    },
                    description: {
                        type: "string",
                    },
                    landingPage: {
                        type: "string",
                    },
                    type: {
                        type: "string",
                    },
                    imageURL: {
                        type: "string",
                        format: "uri",
                    },
                    isUnread: {
                        type: "boolean",
                    },
                    date: {
                        type: "string",
                        format: "date",
                    },
                },
            },
            SendFeedbackFormRequestDto: {
                type: "object",
                properties: {
                    storeId: {
                        type: "string",
                    },
                    storeName: {
                        type: "string",
                    },
                    note: {
                        type: "string",
                    },
                },
            },
            NotificationProcessReadRequestDto: {
                type: "object",
                properties: {
                    notificationID: {
                        type: "string",
                    },
                },
            },
            VerifyUserIdResponseDto: {
                type: "object",
                properties: {
                    userId: {
                        type: "string",
                    },
                    status: {
                        type: "string",
                        enum: ["ACTIVE", "INACTIVE"],
                    },
                    numberUpdateInProgress: {
                        type: "boolean",
                    },
                    oktaStatus: {
                        type: "string",
                        enum: [
                            "ACTIVE",
                            "DEPROVISIONED",
                            "LOCKED_OUT",
                            "SUSPENDED",
                            "PROVISIONED",
                        ],
                    },
                },
            },
            NewUserIdOtpRequestDto: {
                type: "object",
                properties: {
                    userIdType: {
                        type: "string",
                        enum: ["Phone", "Email"],
                    },
                    newUserId: {
                        type: "string",
                    },
                },
            },
            ChangeUserIdRequestDto: {
                type: "object",
                properties: {
                    profile: {
                        type: "object",
                        properties: {
                            newUserId: {
                                type: "string",
                            },
                            storeId: {
                                type: "string",
                            },
                        },
                    },
                    credentials: {
                        type: "object",
                        properties: {
                            password: {
                                type: "object",
                                properties: {
                                    value: {
                                        type: "string",
                                    },
                                },
                            },
                        },
                    },
                },
            },
            LeaderBoardGameResponseDto: {
                type: "object",
                properties: {
                    success: {
                        type: "boolean",
                    },
                    WeeklyPrizes: {
                        type: "array",
                        items: {
                            type: "object",
                            properties: {
                                rank: {
                                    type: "integer",
                                },
                                points: {
                                    type: "string",
                                },
                            },
                            required: ["rank", "points"],
                        },
                    },
                    MonthlyPrizes: {
                        type: "array",
                        items: {
                            type: "object",
                            properties: {
                                rank: {
                                    type: "integer",
                                },
                                points: {
                                    type: "string",
                                },
                            },
                            required: ["rank", "points"],
                        },
                    },
                    GameId: {
                        type: "string",
                    },
                },
                required: ["WeeklyPrizes", "MonthlyPrizes", "GameId"],
            },
            SpinGameGetResponseDto: {
                type: "object",
                properties: {
                    data: {
                        type: "object",
                        properties: {
                            sliceWon: {
                                type: "integer",
                            },
                            pointsWon: {
                                type: "integer",
                            },
                            pointsLeft: {
                                type: "integer",
                            },
                        },
                        required: ["sliceWon", "pointsWon", "pointsLeft"],
                    },
                },
                required: ["success", "msg", "data"],
            },
            PerfectStoreFirstPositionTargetListDto: {
                type: "object",
                properties: {
                    count: {
                        type: "integer",
                        example: 1,
                    },
                    data: {
                        type: "array",
                        items: {
                            $ref: "#/components/schemas/Target",
                        },
                    },
                },
            },
            Target: {
                type: "object",
                properties: {
                    id: {
                        type: "string",
                        example: "15471121",
                    },
                    targetType: {
                        type: "string",
                        example: "First Position Target",
                    },
                    targetName: {
                        type: "object",
                        properties: {
                            en: {
                                type: "string",
                            },
                            tr: {
                                type: "string",
                            },
                        },
                    },
                    targetDescription: {
                        type: "object",
                        properties: {
                            en: {
                                type: "string",
                            },
                            tr: {
                                type: "string",
                            },
                        },
                    },
                    targetImage: {
                        type: "string",
                    },
                    targetStartDate: {
                        type: "string",
                        format: "date-time",
                        example: "2024-05-31T18:30:00.000000Z",
                    },
                    targetEndDate: {
                        type: "string",
                        format: "date-time",
                        example: "2024-07-30T18:30:00.000000Z",
                    },
                    store: {
                        $ref: "#/components/schemas/StoreDto",
                    },
                },
            },
            StoreDto: {
                type: "object",
                properties: {
                    id: {
                        type: "integer",
                        example: 15471123,
                    },
                    erpId: {
                        type: "string",
                        example: "5463413",
                    },
                    visits: {
                        type: "array",
                        items: {
                            $ref: "#/components/schemas/StoreVisit",
                        },
                    },
                    totalVisit: {
                        type: "integer",
                        example: 4,
                    },
                    totalPointAvailable: {
                        type: "integer",
                        example: 160,
                    },
                    totalPointAwarded: {
                        type: "integer",
                        example: 0,
                    },
                    status: {
                        type: "string",
                        example: "Active",
                    },
                },
            },
            StoreVisit: {
                type: "object",
                properties: {
                    visitNumber: {
                        type: "integer",
                        example: 1,
                    },
                    pointAvailable: {
                        type: "integer",
                        example: 10,
                    },
                    status: {
                        type: "string",
                        example: "Pending",
                    },
                    pointAwarded: {
                        type: "integer",
                        example: 0,
                    },
                    visitUploadedDate: {
                        type: "object",
                        format: "date-time",
                        example: "2024-06-21 07:10:07",
                    },
                },
            },
            PerfectStoreRacksCoolersTargetListDto: {
                type: "object",
                properties: {
                    count: {
                        type: "integer",
                        example: 1,
                    },
                    data: {
                        type: "array",
                        items: {
                            type: "object",
                            properties: {
                                id: {
                                    type: "string",
                                    example: "15233386",
                                },
                                targetType: {
                                    type: "string",
                                    example: "Must Have Products Target",
                                },
                                mustHaveTargetType: {
                                    type: "string",
                                    example: "ProductGroup-with minimum threshold",
                                },
                                targetName: {
                                    type: "object",
                                    properties: {
                                        en: {
                                            type: "string",
                                            example: "RMK Must Haves Threshold 002",
                                        },
                                        tr: {
                                            type: "string",
                                            example: "RMK Must Haves Threshold 002 TR",
                                        },
                                    },
                                },
                                targetDescription: {
                                    type: "object",
                                    properties: {
                                        en: {
                                            type: "string",
                                            example:
                                                "RMK Must Haves Threshold 002 description English content",
                                        },
                                        tr: {
                                            type: "string",
                                            example: "RMK Must Haves Threshold 002 TR content",
                                        },
                                    },
                                },
                                isLayered: {
                                    type: "boolean",
                                    example: false,
                                },
                                targetProductType: {
                                    type: "string",
                                    nullable: true,
                                },
                                targetImage: {
                                    type: "string",
                                    format: "uri",
                                    example:
                                        "https://admin.cep.qa.tr.pepsico.com/_default_upload_bucket/MicrosoftTeams-image%20%281%29_1.png",
                                },
                                targerBannerImageURL: {
                                    type: "string",
                                    format: "uri",
                                    nullable: true,
                                },
                                targetDisplayStartDate: {
                                    type: "string",
                                    format: "date-time",
                                    example: "2024-01-31T18:30:00.000000Z",
                                },
                                targetDisplayEndDate: {
                                    type: "string",
                                    format: "date-time",
                                    example: "2025-03-02T18:30:00.000000Z",
                                },
                                targetActiveStartDate: {
                                    type: "string",
                                    format: "date-time",
                                    example: "2024-01-31T18:30:00.000000Z",
                                },
                                targetActiveEndDate: {
                                    type: "string",
                                    format: "date-time",
                                    example: "2025-03-02T18:30:00.000000Z",
                                },
                                targetRewardDate: {
                                    type: "string",
                                    format: "date-time",
                                    example: "2025-03-02T18:30:00.000000Z",
                                },
                                targetDeliveredDate: {
                                    type: "string",
                                    format: "date-time",
                                    nullable: true,
                                },
                                targetGoalStatus: {
                                    type: "string",
                                    example: "achieved",
                                },
                                pointsToEarn: {
                                    type: "string",
                                    example: "750",
                                },
                                targetProductFlag: {
                                    type: "boolean",
                                    example: false,
                                },
                                targetProductGroupFlag: {
                                    type: "boolean",
                                    example: true,
                                },
                                minThresholdFlag: {
                                    type: "boolean",
                                    example: true,
                                },
                                minThreshold: {
                                    type: "string",
                                    example: "1",
                                },
                                minThresholdPercentage: {
                                    type: "number",
                                    format: "float",
                                    example: 33.33333333333333,
                                },
                                targetAmount: {
                                    type: "integer",
                                    example: 3,
                                },
                                actualAmount: {
                                    type: "integer",
                                    example: 1,
                                },
                                productGroupList: {
                                    type: "array",
                                    items: {
                                        type: "object",
                                        properties: {
                                            productGroupName: {
                                                type: "string",
                                                example: "RMK P Group 1",
                                            },
                                            productGroupImage: {
                                                type: "string",
                                                format: "uri",
                                                example:
                                                    "https://admin.cep.qa.tr.pepsico.com/_default_upload_bucket/PepsiCan_Notif_002_1.png",
                                            },
                                            productList: {
                                                type: "array",
                                                items: {
                                                    type: "object",
                                                    additionalProperties: {
                                                        type: "boolean",
                                                    },
                                                    example: {
                                                        38167: false,
                                                    },
                                                },
                                            },
                                        },
                                    },
                                },
                                availableProductGroup: {
                                    type: "array",
                                    items: {
                                        type: "string",
                                        example: "RMK P Group 3",
                                    },
                                },
                                actualAvailableSKUPercentage: {
                                    type: "number",
                                    format: "float",
                                    example: 33.33333333333333,
                                },
                                estimatedPointsToAllocate: {
                                    type: "integer",
                                    example: 250,
                                },
                            },
                        },
                    },
                },
                required: ["count", "data"],
            },
            PerfectStoreMustHaveTargetListDto: {
                type: "object",
                properties: {
                    count: {
                        type: "integer",
                        example: 1,
                    },
                    data: {
                        type: "array",
                        items: {
                            type: "object",
                            properties: {
                                id: {
                                    type: "string",
                                    example: "15233386",
                                },
                                targetType: {
                                    type: "string",
                                    example: "Must Have Products Target",
                                },
                                mustHaveTargetType: {
                                    type: "string",
                                    example: "ProductGroup-with minimum threshold",
                                },
                                targetName: {
                                    type: "object",
                                    properties: {
                                        en: {
                                            type: "string",
                                            example: "RMK Must Haves Threshold 002",
                                        },
                                        tr: {
                                            type: "string",
                                            example: "RMK Must Haves Threshold 002 TR",
                                        },
                                    },
                                },
                                targetDescription: {
                                    type: "object",
                                    properties: {
                                        en: {
                                            type: "string",
                                            example:
                                                "RMK Must Haves Threshold 002 description English content",
                                        },
                                        tr: {
                                            type: "string",
                                            example: "RMK Must Haves Threshold 002 TR content",
                                        },
                                    },
                                },
                                isLayered: {
                                    type: "boolean",
                                    example: false,
                                },
                                targetProductType: {
                                    type: "string",
                                    nullable: true,
                                },
                                targetImage: {
                                    type: "string",
                                    format: "uri",
                                    example:
                                        "https://admin.cep.qa.tr.pepsico.com/_default_upload_bucket/MicrosoftTeams-image%20%281%29_1.png",
                                },
                                targerBannerImageURL: {
                                    type: "string",
                                    format: "uri",
                                    nullable: true,
                                },
                                targetDisplayStartDate: {
                                    type: "string",
                                    format: "date-time",
                                    example: "2024-01-31T18:30:00.000000Z",
                                },
                                targetDisplayEndDate: {
                                    type: "string",
                                    format: "date-time",
                                    example: "2025-03-02T18:30:00.000000Z",
                                },
                                targetActiveStartDate: {
                                    type: "string",
                                    format: "date-time",
                                    example: "2024-01-31T18:30:00.000000Z",
                                },
                                targetActiveEndDate: {
                                    type: "string",
                                    format: "date-time",
                                    example: "2025-03-02T18:30:00.000000Z",
                                },
                                targetRewardDate: {
                                    type: "string",
                                    format: "date-time",
                                    example: "2025-03-02T18:30:00.000000Z",
                                },
                                targetDeliveredDate: {
                                    type: "string",
                                    format: "date-time",
                                    nullable: true,
                                },
                                targetGoalStatus: {
                                    type: "string",
                                    example: "achieved",
                                },
                                pointsToEarn: {
                                    type: "string",
                                    example: "750",
                                },
                                targetProductFlag: {
                                    type: "boolean",
                                    example: false,
                                },
                                targetProductGroupFlag: {
                                    type: "boolean",
                                    example: true,
                                },
                                minThresholdFlag: {
                                    type: "boolean",
                                    example: true,
                                },
                                minThreshold: {
                                    type: "string",
                                    example: "1",
                                },
                                minThresholdPercentage: {
                                    type: "number",
                                    format: "float",
                                    example: 33.33333333333333,
                                },
                                targetAmount: {
                                    type: "integer",
                                    example: 3,
                                },
                                actualAmount: {
                                    type: "integer",
                                    example: 1,
                                },
                                productGroupList: {
                                    type: "array",
                                    items: {
                                        type: "object",
                                        properties: {
                                            productGroupName: {
                                                type: "string",
                                                example: "RMK P Group 1",
                                            },
                                            productGroupImage: {
                                                type: "string",
                                                format: "uri",
                                                example:
                                                    "https://admin.cep.qa.tr.pepsico.com/_default_upload_bucket/PepsiCan_Notif_002_1.png",
                                            },
                                            productList: {
                                                type: "array",
                                                items: {
                                                    type: "object",
                                                    additionalProperties: {
                                                        type: "boolean",
                                                    },
                                                    example: {
                                                        38167: false,
                                                    },
                                                },
                                            },
                                        },
                                    },
                                },
                                availableProductGroup: {
                                    type: "array",
                                    items: {
                                        type: "string",
                                        example: "RMK P Group 3",
                                    },
                                },
                                actualAvailableSKUPercentage: {
                                    type: "number",
                                    format: "float",
                                    example: 33.33333333333333,
                                },
                                estimatedPointsToAllocate: {
                                    type: "integer",
                                    example: 250,
                                },
                            },
                        },
                    },
                },
                required: ["count", "data"],
            },
            ExecutionUpdateStatusDto: {
                type: "object",
                properties: {
                    executionId: {
                        type: "string",
                    },
                    status: {
                        type: "string",
                    },
                },
            },
            ExecutionTargetImageUploadDto: {
                type: "object",
                properties: {
                    executionId: {
                        type: "string",
                        example: "1327542129",
                    },
                    tasks: {
                        type: "array",
                        items: {
                            type: "object",
                            properties: {
                                images: {
                                    type: "array",
                                    items: {
                                        type: "object",
                                        properties: {
                                            image: {
                                                type: "string",
                                                items: {
                                                    type: "string",
                                                    example: ["base64format send ", "base64format send"]
                                                }
                                            }
                                        }
                                    },
                                },
                            },
                        },
                    },
                },
            },
            GetNavigationResponseDto: {
                type: "object",
                properties: {
                    metadata: {
                        type: "object",
                        properties: {
                            itemKey: {
                                type: "string",
                            },
                            totalItems: {
                                type: "number",
                            },
                        },
                    },
                    navItems: {
                        type: "object",
                        properties: {
                            footer: {
                                type: "array",
                                items: {
                                    $ref: "#/components/schemas/NavArrayItem",
                                },
                            },
                            header: {
                                type: "object",
                                additionalProperties: {
                                    $ref: "#/components/schemas/NavArrayItem",
                                },
                            },
                            subItems: {
                                type: "array",
                                items: {
                                    $ref: "#/components/schemas/NavArrayItem",
                                },
                            },
                        },
                    },
                },
            },
            PutAccountDetailsRequestDto: {
                type: "object",
                properties: {
                    firstName: {
                        type: "string",
                    },
                    lastName: {
                        type: "string",
                    },
                    email: {
                        type: "string",
                    },
                    phone: {
                        type: "string",
                    },
                    alternateNumber: {
                        type: "string",
                    },
                    notificationSettings: {
                        type: "object",
                        properties: {
                            SMS: {
                                type: "array",
                                items: {
                                    type: "string",
                                },
                            },
                            Email: {
                                type: "array",
                                items: {
                                    type: "string",
                                },
                            },
                        },
                    },
                    customAddress: {
                        $ref: "#/components/schemas/CustomAddress",
                    },
                    termsAccepted: {
                        type: "array",
                        items: {
                            $ref: "#/components/schemas/TermsAccepted",
                        },
                    },
                },
            },
            GetAccountEmployeesResponseDto: {
                type: "object",
                properties: {
                    metadata: {
                        type: "object",
                        properties: {
                            itemKey: {
                                type: "string",
                            },
                            totalItems: {
                                type: "number",
                            },
                        },
                    },
                    employees: {
                        type: "array",
                        items: {
                            $ref: "#/components/schemas/Employee",
                        },
                    },
                },
            },
            AddCustomerImageRequestDto: {
                type: "object",
                properties: {
                    imagebase64: {
                        type: "string",
                        description: "Base64 encoded image data",
                    },
                },
            },
            AddCustomerImageV1ResponseDto: {
                type: "object",
                properties: {
                    success: {
                        type: "object",
                        properties: {
                            imageURL: {
                                type: "string",
                            },
                        },
                    },
                },
            },
            AddStoreMapImageRequestDto: {
                type: "object",
                properties: {
                    imagebase64: {
                        type: "string",
                        description: "Base64 encoded image data",
                    },
                    storeId: {
                        type: "string",
                        description: "Store identifier",
                    },
                    nickName: {
                        type: "string",
                        description: "Nickname",
                    },
                },
                required: ["imagebase64", "storeId"],
            },
            AddStoreMapImageResponseDto: {
                type: "object",
                properties: {
                    success: {
                        type: "object",
                        properties: {
                            imageURL: {
                                type: "string",
                            },
                            nickName: {
                                type: "string",
                            },
                        },
                    },
                },
            },
            AccountResponseV2ContactAddress: {
                type: "object",
                properties: {
                    addressLine1: {
                        type: "string",
                    },
                    addressLine2: {
                        type: "string",
                    },
                    city: {
                        type: "string",
                    },
                    state: {
                        type: "string",
                    },
                    country: {
                        type: "string",
                    },
                    province: {
                        type: "string",
                    },
                },
            },
            AccountResponseV2Contact: {
                type: "object",
                properties: {
                    isActive: {
                        type: "boolean",
                    },
                    creditBlock: {
                        type: "string",
                    },
                    creditLimitAmount: {
                        type: "string",
                    },
                    salesBlockStatus: {
                        type: "boolean",
                    },
                    mobile: {
                        type: "string",
                    },
                    default: {
                        type: "boolean",
                    },
                    isStore: {
                        type: "boolean",
                    },
                    storeId: {
                        type: "string",
                    },
                    name: {
                        type: "string",
                    },
                    nickName: {
                        type: "string",
                    },
                    returnAllowed: {
                        type: "boolean",
                    },
                    surveyCompleted: {
                        type: "boolean",
                    },
                    internalMOA: {
                        type: "number",
                    },
                    externalMOA: {
                        type: "number",
                    },
                    image: {
                        type: "string",
                    },
                    companyCode: {
                        type: "string",
                    },
                    companyName: {
                        type: "string",
                    },
                    type: {
                        type: "string",
                    },
                    postalCode: {
                        type: "string",
                    },
                    VATNumber: {
                        type: "string",
                    },
                    taxId: {
                        type: "string",
                    },
                    phoneNumber: {
                        type: "string",
                    },
                    permissions: {
                        type: "string",
                    },
                    profileStatus: {
                        type: "string",
                    },
                    newCustomerWelcomeSegmentFlag: {
                        type: "boolean",
                    },
                    welcomePointsEarned: {
                        type: "string",
                    },
                    points: {
                        type: "number",
                    },
                    lifetimePoints: {
                        type: "number",
                    },
                    addresses: {
                        $ref: "#/components/schemas/AccountResponseV2ContactAddress",
                    },
                    location: {
                        type: "array",
                        items: {
                            $ref: "#/components/schemas/Location",
                        },
                    },
                    businessType: {
                        type: "string",
                    },
                    videoBannerURL: {
                        type: "string",
                    },
                    userCategoryType: {
                        type: "string",
                    },
                },
            },
            RelatedStore: {
                type: "object",
                properties: {
                    erpId: {
                        type: "string",
                    },
                    typeCode: {
                        type: "string",
                    },
                },
            },
            AccountResponseV2Customer: {
                type: "object",
                properties: {
                    id: {
                        type: "number",
                    },
                    userId: {
                        type: "string",
                    },
                    role: {
                        type: "string",
                    },
                    memberType: {
                        type: "string",
                    },
                    firstName: {
                        type: "string",
                    },
                    lastName: {
                        type: "string",
                    },
                    profileImgUrl: {
                        type: "string",
                    },
                    dob: {
                        type: "string",
                    },
                    deviceToken: {
                        type: "string",
                    },
                    loginExpiryDate: {
                        type: "string",
                    },
                    maid: {
                        type: "string",
                    },
                    deviceType: {
                        type: "string",
                    },
                    email: {
                        type: "string",
                    },
                    gender: {
                        type: "string",
                    },
                    phone: {
                        type: "string",
                    },
                    emailVerifyStatus: {
                        type: "string",
                    },
                    mobileVerifyStatus: {
                        type: "string",
                    },
                    alternateNumber: {
                        type: "string",
                    },
                    isStorePermissionUpdated: {
                        type: "boolean",
                    },
                    showOnBoardingScreen: {
                        type: "string",
                    },
                    contact: {
                        type: "array",
                        items: {
                            $ref: "#/components/schemas/AccountResponseV2Contact",
                        },
                    },
                    customAddresses: {
                        type: "array",
                        items: {
                            $ref: "#/components/schemas/CustomAddress",
                        },
                    },
                    termsAccepted: {
                        type: "array",
                        items: {
                            $ref: "#/components/schemas/TermsAccepted",
                        },
                    },
                    relatedStore: {
                        type: "array",
                        items: {
                            $ref: "#/components/schemas/RelatedStore",
                        },
                    },
                    preferences: {
                        $ref: "#/components/schemas/Preferences",
                    },
                    extra: {
                        $ref: "#/components/schemas/Extra",
                    },
                    autoVerified: {
                        type: "boolean",
                    },
                    msg: {
                        type: "string",
                    },
                },
            },
            GetContentResponseDto: {
                type: "object",
                properties: {
                    totalCount: {
                        type: "integer",
                        description: "Total count of brands",
                    },
                    data: {
                        type: "array",
                        items: {
                            type: "object",
                            properties: {
                                id: {
                                    type: "string",
                                    description: "Unique identifier for the brand",
                                },
                                hideBanner: {
                                    type: "boolean",
                                    description: "Flag indicating if the banner is hidden",
                                },
                                hidePromotion: {
                                    type: "boolean",
                                    description: "Flag indicating if the promotion is hidden",
                                },
                                remainingOverallQuota: {
                                    type: "integer",
                                    description: "Remaining overall quota",
                                },
                                overallQuota: {
                                    type: "integer",
                                    description: "Overall quota",
                                },
                                images: {
                                    type: "array",
                                    items: {
                                        type: "object",
                                        properties: {
                                            image: {
                                                type: "string",
                                                format: "uri",
                                                description: "URL of the image",
                                            },
                                            imageText: {
                                                type: "object",
                                                properties: {
                                                    en: {
                                                        type: "string",
                                                        description: "Text in English",
                                                    },
                                                    tr: {
                                                        type: "string",
                                                        description: "Text in Turkish",
                                                    },
                                                },
                                            },
                                            imageTextWithTags: {
                                                type: "object",
                                                properties: {
                                                    en: {
                                                        type: "string",
                                                        description: "Text in English with HTML tags",
                                                    },
                                                    tr: {
                                                        type: "string",
                                                        description: "Text in Turkish with HTML tags",
                                                    },
                                                },
                                            },
                                            id: {
                                                type: "string",
                                                description: "Unique identifier for the image",
                                            },
                                        },
                                    },
                                },
                                videos: {
                                    type: "array",
                                    items: {},
                                },
                                attachment: {
                                    type: "array",
                                    items: {},
                                },
                                title: {
                                    type: "object",
                                    properties: {
                                        en: {
                                            type: "string",
                                            description: "Title in English",
                                        },
                                        tr: {
                                            type: "string",
                                            description: "Title in Turkish",
                                        },
                                    },
                                },
                                excerpt: {
                                    type: "object",
                                    properties: {
                                        en: {
                                            type: "string",
                                            description: "Excerpt in English",
                                        },
                                        tr: {
                                            type: "string",
                                            description: "Excerpt in Turkish",
                                        },
                                    },
                                },
                                content: {
                                    type: "object",
                                    properties: {
                                        en: {
                                            type: "string",
                                            description: "Content in English",
                                        },
                                        tr: {
                                            type: "string",
                                            description: "Content in Turkish",
                                        },
                                    },
                                },
                                contentWithTags: {
                                    type: "object",
                                    properties: {
                                        en: {
                                            type: "string",
                                            description: "Content in English with HTML tags",
                                        },
                                        tr: {
                                            type: "string",
                                            description: "Content in Turkish with HTML tags",
                                        },
                                    },
                                },
                                parameters: {
                                    type: "array",
                                    items: {},
                                },
                                StartDate: {
                                    type: "object",
                                    format: "date-time",
                                    description: "Start date of the brand",
                                },
                                EndDate: {
                                    type: "object",
                                    format: "date-time",
                                    description: "End date of the brand",
                                },
                                created: {
                                    type: "object",
                                    format: "date-time",
                                    description: "Date when the brand was created",
                                },
                                modified: {
                                    type: "object",
                                    format: "date-time",
                                    description: "Date when the brand was last modified",
                                },
                                displayOrder: {
                                    type: "string",
                                    description: "Display order of the brand",
                                },
                                url: {
                                    type: "string",
                                    format: "uri",
                                    description: "URL associated with the brand",
                                },
                                siteId: {
                                    type: "string",
                                    description: "ID of the site",
                                },
                                is_public: {
                                    type: "boolean",
                                    description: "Flag indicating if the brand is public",
                                },
                                version_number: {
                                    type: "integer",
                                    description: "Version number of the brand",
                                },
                                landingPage: {
                                    type: "string",
                                    description: "Landing page of the brand",
                                },
                                selector: {
                                    type: "string",
                                    description: "Selector of the brand",
                                },
                                reward: {
                                    type: "array",
                                    items: {
                                        type: "string",
                                    },
                                },
                                points: {
                                    type: "integer",
                                    description: "Points associated with the brand",
                                },
                                promotionID: {
                                    type: "string",
                                    description: "ID of the promotion associated with the brand",
                                },
                                tags: {
                                    type: "array",
                                    items: {
                                        type: "string",
                                    },
                                },
                                navigations: {
                                    type: "array",
                                    items: {},
                                },
                            },
                        },
                    },
                },
            },
            GetAccountResponseDtoV2: {
                type: "object",
                properties: {
                    metadata: {
                        type: "object",
                        properties: {
                            __comment: {
                                type: "string",
                            },
                            itemKey: {
                                type: "string",
                            },
                            paymentType: {
                                type: "string",
                            },
                            totalItems: {
                                type: "number",
                            },
                        },
                    },
                    customers: {
                        $ref: "#/components/schemas/AccountResponseV2Customer",
                    },
                },
            },
            GetOrderResponseDtoV3: {
                type: "object",
                properties: {
                    metadata: {
                        type: "object",
                        properties: {
                            __comment: {
                                type: "string",
                            },
                            itemKey: {
                                type: "string",
                            },
                            totalItems: {
                                type: "number",
                            },
                        },
                    },
                    orders: {
                        type: "array",
                        items: {
                            $ref: "#/components/schemas/HistoryOrder",
                        },
                    },
                },
            },
            HistoryOrder: {
                type: "object",
                properties: {
                    id: {
                        type: "integer",
                    },
                    sourceOrderId: {
                        type: "string",
                    },
                    orderType: {
                        type: "string",
                    },
                    orderStatus: {
                        type: "string",
                    },
                    orderNumber: {
                        type: "string",
                    },
                    poNUmber: {
                        type: "string",
                    },
                    releaseFlag: {
                        type: "string",
                    },
                    erpOrderNumber: {
                        type: "string",
                    },
                    poNumber: {
                        type: "string",
                    },
                    ediNumber: {
                        type: "string",
                    },
                    invoiceNumber: {
                        type: "string",
                    },
                    orderLocationId: {
                        type: "string",
                    },
                    orderDate: {
                        type: "string",
                        format: "date",
                    },
                    orderDateTime: {
                        type: "string",
                        format: "date-time",
                    },
                    estimatedDeliveryDate: {
                        type: "string",
                        format: "date",
                    },
                    deliveryDate: {
                        type: "string",
                        format: "date",
                    },
                    erpShippingCondition: {
                        type: "string",
                    },
                    contractualDiscount: {
                        type: "integer",
                    },
                    promotionalDiscount: {
                        type: "integer",
                    },
                    totalDiscount: {
                        type: "integer",
                    },
                    taxAmount: {
                        type: "string",
                    },
                    subtotal: {
                        type: "string",
                    },
                    totalPrice: {
                        type: "string",
                    },
                    totalNetPrice: {
                        type: "string",
                    },
                    estimatedPoints: {
                        type: "integer",
                    },
                    deliveredPoints: {
                        type: "integer",
                    },
                    paymentTermCode: {
                        type: "string",
                    },
                    currency: {
                        type: "string",
                    },
                    routeId: {
                        type: "string",
                    },
                    routeTypeId: {
                        type: "string",
                    },
                    routeRepId: {
                        type: "string",
                    },
                    routeOrderTypeCode: {
                        type: "string",
                    },
                    routeOrderTypeSubCode: {
                        type: "string",
                    },
                    routeVisitSchedule: {
                        type: "string",
                    },
                    routeMarketplaceId: {
                        type: "string",
                    },
                    sourceSystemId: {
                        type: "string",
                    },
                    voucherCode: {
                        type: "string",
                    },
                    itemCount: {
                        type: "integer",
                    },
                    items: {
                        type: "array",
                        items: {
                            $ref: "#/components/schemas/HistoryOrderItem",
                        },
                    },
                    customerAddress: {
                        $ref: "#/components/schemas/OrderCustomerAddress",
                    },
                    orderNote: {
                        type: "string",
                    },
                    rejectedNote: {
                        type: "string",
                    },
                    cancelationReason: {
                        type: "string",
                    },
                    invoiceDownload: {
                        type: "string",
                    },
                    categoryType: {
                        type: "string",
                    },
                    alternateOrderId: {
                        type: "string",
                    },
                    sugarLevy: {
                        type: "string",
                    },
                },
            },
            HistoryOrderItem: {
                type: "object",
                properties: {
                    id: {
                        type: "integer",
                    },
                    externalId: {
                        type: "string",
                    },
                    name: {
                        $ref: "#/components/schemas/LocalizedTextOrder",
                    },
                    size: {
                        $ref: "#/components/schemas/LocalizedTextOrder",
                    },
                    imageURL: {
                        type: "string",
                    },
                    points: {
                        type: "integer",
                    },
                    rewardType: {
                        type: "string",
                    },
                    quantity: {
                        type: "integer",
                    },
                    quantityFulfilled: {
                        type: "integer",
                    },
                    unitType: {
                        type: "string",
                    },
                    packageSize: {
                        type: "string",
                    },
                    unitPrice: {
                        type: "string",
                    },
                    subTotal: {
                        type: "string",
                    },
                    tax: {
                        type: "string",
                    },
                    fulfilledPrice: {
                        type: "string",
                    },
                    acceptedQuantity: {
                        type: "integer",
                    },
                    rejectedQuantity: {
                        type: "integer",
                    },
                    baseUnit: {
                        type: "string",
                    },
                    saleUnit: {
                        type: "string",
                    },
                    conversionMultiplierToBase: {
                        type: "string",
                    },
                    basePrice: {
                        type: "string",
                    },
                    finalPrice: {
                        type: "string",
                    },
                    finalNetPrice: {
                        type: "string",
                    },
                    category: {
                        type: "string",
                    },
                    categoryId: {
                        type: "string",
                    },
                    isPromotional: {
                        type: "boolean",
                    },
                    isSubstituted: {
                        type: "boolean",
                    },
                    substitutedById: {
                        type: "string",
                    },
                    status: {
                        type: "string",
                    },
                    gtInValue: {
                        type: "string",
                    },
                    rejectedReason: {
                        type: "string",
                    },
                    deliveryMethod: {
                        type: "string",
                    },
                    digitalAddress: {
                        type: "string",
                    },
                    type: {
                        type: "string",
                    },
                    counponInfo: {
                        type: "array",
                        items: {
                            type: "string",
                        },
                    },
                    removeStatus: {
                        type: "boolean",
                    },
                    additionalItemStatus: {
                        type: "boolean",
                    },
                    isReturn: {
                        type: "boolean",
                    },
                    sugarLevy: {
                        type: "string",
                    },
                },
            },
            OrderCustomerAddress: {
                type: "object",
                properties: {
                    externalId: {
                        type: "string",
                    },
                    customerName: {
                        type: "string",
                    },
                    email: {
                        type: "string",
                    },
                    phone: {
                        type: "string",
                    },
                    addressLine1: {
                        type: "string",
                    },
                    addressLine2: {
                        type: "string",
                    },
                    city: {
                        type: "string",
                    },
                    state: {
                        type: "string",
                    },
                    postalCode: {
                        type: "string",
                    },
                    district: {
                        type: "string",
                    },
                    countryCode: {
                        type: "string",
                    },
                    IDNumber: {
                        type: "string",
                    },
                    DOB: {
                        type: "string",
                    },
                },
            },
            LocalizedTextOrder: {
                type: "object",
                additionalProperties: true,
            },
            Store: {
                type: "object",
                properties: {
                    storeId: {
                        type: "string",
                    },
                    storeName: {
                        type: "string",
                    },
                    type: {
                        type: "string",
                    },
                    city: {
                        type: "string",
                    },
                    state: {
                        type: "string",
                    },
                    country: {
                        type: "string",
                    },
                    postalCode: {
                        type: "string",
                    },
                    permissions: {
                        type: "string",
                    },
                    addresses: {
                        $ref: "#/components/schemas/CustomAddress",
                    },
                },
            },
            GetRewardListResponseDto: {
                type: "object",
                properties: {
                    rewardType: {
                        type: "array",
                        items: {
                            type: "string",
                            enum: ["Digital Coupon", "Pep Voucher", "Digital"],
                        },
                    },
                    rewardTypeCount: {
                        type: "integer",
                    },
                    items: {
                        type: "array",
                        items: {
                            type: "object",
                            properties: {
                                productId: {
                                    type: "string",
                                },
                                rewardCode: {
                                    type: "string",
                                },
                                title: {
                                    type: "object",
                                    properties: {
                                        en: {
                                            type: "string",
                                        },
                                        tr: {
                                            type: "string",
                                        },
                                    },
                                },
                                mainCategory: {
                                    type: "object",
                                    properties: {
                                        en: {
                                            type: "string",
                                        },
                                        tr: {
                                            type: "string",
                                        },
                                    },
                                },
                                subCategory: {
                                    type: "object",
                                    properties: {
                                        en: {
                                            type: "string",
                                        },
                                        tr: {
                                            type: "string",
                                        },
                                    },
                                },
                                shortDescription: {
                                    type: "object",
                                    properties: {
                                        en: {
                                            type: "string",
                                        },
                                        tr: {
                                            type: "string",
                                        },
                                    },
                                },
                                longDescription: {
                                    type: "object",
                                    properties: {
                                        en: {
                                            type: "string",
                                        },
                                        tr: {
                                            type: "string",
                                        },
                                    },
                                },
                                detail: {
                                    type: "object",
                                    properties: {
                                        size: {
                                            type: "object",
                                            properties: {
                                                en: {
                                                    type: "string",
                                                },
                                                tr: {
                                                    type: "string",
                                                },
                                            },
                                        },
                                        dimensions: {
                                            type: "object",
                                            properties: {
                                                en: {
                                                    type: "string",
                                                },
                                                tr: {
                                                    type: "string",
                                                },
                                            },
                                        },
                                        weight: {
                                            type: "object",
                                            properties: {
                                                en: {
                                                    type: "string",
                                                },
                                                tr: {
                                                    type: "string",
                                                },
                                            },
                                        },
                                        color: {
                                            type: "object",
                                            properties: {
                                                en: {
                                                    type: "string",
                                                },
                                                tr: {
                                                    type: "string",
                                                },
                                            },
                                        },
                                        package: {
                                            type: "object",
                                            properties: {
                                                en: {
                                                    type: "string",
                                                },
                                                tr: {
                                                    type: "string",
                                                },
                                            },
                                        },
                                    },
                                },
                                thumbnailImage: {
                                    type: "string",
                                    format: "uri",
                                },
                                extraFields: {
                                    type: "string",
                                },
                                endDate: {
                                    type: "string",
                                    format: "date-time",
                                },
                                inStock: {
                                    type: "boolean",
                                },
                                taxRate: {
                                    type: "integer",
                                },
                                inventory: {
                                    type: "integer",
                                },
                                showOnHomePage: {
                                    type: "boolean",
                                },
                                rewardId: {
                                    type: "string",
                                },
                                rewardType: {
                                    type: "string",
                                    enum: ["Digital Coupon", "Pep Voucher", "Digital"],
                                },
                                points: {
                                    type: "integer",
                                },
                                additionalImages: {
                                    type: "string",
                                },
                                tags: {
                                    type: "string",
                                },
                                value: {
                                    type: "string",
                                },
                                id: {
                                    type: "integer",
                                },
                            },
                        },
                    },
                },
            },
            PointsTransferResponseDto: {
                type: "object",
                properties: {
                    success: {
                        type: "boolean",
                    },
                    message: {
                        type: "string",
                    },
                    pointsTransferred: {
                        type: "integer",
                    },
                    fromAccountPoints: {
                        type: "number",
                        format: "float",
                    },
                    toAccountPoints: {
                        type: "number",
                        format: "float",
                    },
                },
            },
            GetRewardPointsBalanceResponseDto: {
                type: "object",
                properties: {
                    accountId: {
                        type: "string",
                    },
                    points: {
                        type: "number",
                    },
                    lifetimePoints: {
                        type: "number",
                    },
                },
            },
            GetActivityHistoryResponseDto: {
                type: "object",
                properties: {
                    totalCount: {
                        type: "integer",
                        description: "Total number of activities",
                    },
                    data: {
                        type: "array",
                        items: {
                            $ref: "#/components/schemas/ActivityData",
                        },
                    },
                },
            },
            ActivityData: {
                type: "object",
                properties: {
                    id: {
                        type: "string",
                        description: "The unique identifier for the activity",
                    },
                    type: {
                        type: "string",
                        description: "Type of the activity",
                    },
                    points: {
                        type: "integer",
                        description: "Number of points associated with the activity",
                    },
                    identifier: {
                        type: "string",
                        description: "Composite identifier of the activity",
                    },
                    pointStatus: {
                        type: "string",
                        description: "Status of the points (e.g., earned, pending)",
                    },
                    tags: {
                        type: "string",
                        description: "Tags associated with the activity",
                    },
                    metadata: {
                        $ref: "#/components/schemas/ActivityMetadata",
                    },
                    event_name: {
                        type: "string",
                        description: "Name of the event",
                    },
                    created: {
                        type: "string",
                        description: "Creation date and time of the activity",
                    },
                },
            },
            ActivityMetadata: {
                type: "object",
                properties: {
                    storeId: {
                        type: "string",
                        description: "ID of the store",
                    },
                    storeTargetId: {
                        type: "string",
                    },
                    targetId: {
                        type: "string",
                    },
                    visitNumber: {
                        type: "string",
                    },
                    points: {
                        type: "string",
                        description: "Points associated with the activity",
                    },
                    ActivityTimestamp: {
                        type: "string",
                        format: "date-time",
                        description: "Timestamp of the activity",
                        nullable: true,
                    },
                    ActivityDate: {
                        type: "integer",
                        description: "Date of the activity in Unix timestamp format",
                    },
                    ActivitySlug: {
                        type: "string",
                        description: "Slug representing the activity",
                    },
                    ActivityName: {
                        type: "string",
                        description: "Name of the activity",
                    },
                    StartingPointBalance: {
                        type: "number",
                        description: "Starting balance of points before the activity",
                    },
                    EndingPointBalance: {
                        type: "number",
                        description: "Ending balance of points after the activity",
                    },
                },
            },
        },
        parameters: {
            siteId: {
                name: "siteId",
                in: "header",
                required: true,
                description:
                    "Mexico WA:6,\n Colombia:7,\n Brazil:11,\n Spain:13,\n Turkey:14,\n Russia:15,\n Portugal:16,\n NewZealand:17,\n Romania:18,\n DominicanRepublic:20,\n Mexico:21",
                schema: {
                    type: "number",
                    default: 7,
                },
            },
            xApiKey: {
                name: "x-api-key",
                in: "header",
                required: true,
                description: "x-api-key",
                schema: {
                    type: "string",
                    default: "00Ynu7Osnis3pcorDPsI2T7lK_Cs32gkP2wQU4K46X",
                },
            },
            siteIdInQuery: {
                name: "siteId",
                in: "query",
                required: true,
                description: "siteId|erpId",
                schema: {
                    type: "string",
                    default: "14",
                },
            },

            taxId: {
                name: "taxId",
                in: "query",
                required: false,
                description: "Tax Id of the Customer",
                schema: {
                    type: "string",
                },
            },
            mobile: {
                name: "mobile",
                in: "query",
                required: false,
                description: "Mobile number of the Customer",
                schema: {
                    type: "string",
                },
            },
            storeIdInHeader: {
                name: "storeId",
                in: "header",
                required: true,
                description: "storeId|erpId",
                schema: {
                    type: "string",
                },
            },
            storeIdInQuery: {
                name: "storeId",
                in: "query",
                required: true,
                description: "storeId|erpId",
                schema: {
                    type: "string",
                },
            },
            storeIdInPath: {
                name: "storeId",
                in: "path",
                required: true,
                description: "storeId|erpId",
                schema: {
                    type: "string",
                },
            },
            oktaAccessToken: {
                name: "okta-accesstoken",
                in: "header",
                required: true,
                description: "okta-accesstoken",
                schema: {
                    type: "string",
                    default:
                        "eyJraWQiOiI2ZTdPNHpMdThqckMtZW5aUHhMVS0yZ2lIUW5xY3ZTMTRzQVNrXy1kbXJNIiwiYWxnIjoiUlMyNTYifQ",
                },
            },
            userId: {
                name: "userId",
                in: "query",
                required: true,
                description: "User Id of the customer",
                schema: {
                    type: "string",
                    default: null,
                },
            },
            orderType: {
                name: "orderType",
                in: "query",
                required: false,
                description: "Order type",
                schema: {
                    type: "string",
                    default: "ZOR",
                    enum: ["ZOR", "Rewards"],
                },
            },
            limit: {
                name: "limit",
                in: "query",
                required: false,
                description: "limit",
                schema: {
                    type: "number",
                    default: 20,
                    maximum: 100,
                },
            },
            offset: {
                name: "offset",
                in: "query",
                required: false,
                description: "offset",
                schema: {
                    type: "number",
                    default: 0,
                },
            },
            tags: {
                name: "tags",
                in: "query",
                required: false,
                description: "tags",
                schema: {
                    type: "array",
                    items: {
                        type: "string",
                    },
                },
            },
            plainText: {
                name: "plainText",
                in: "query",
                required: false,
                description: "plainText",
                schema: {
                    type: "boolean",
                    default: false,
                },
            },
            orderNumber: {
                name: "orderNumber",
                in: "query",
                required: false,
                description: "Order Number",
                schema: {
                    type: "string",
                },
            },
            invoiceId: {
                name: "invoiceId",
                in: "path",
                required: true,
                description: "Invoice Id",
                schema: {
                    type: "string",
                },
            },
            startFromDate: {
                name: "from",
                in: "query",
                required: false,
                description: "Order Date start from",
                schema: {
                    type: "string",
                },
            },
            tillDate: {
                name: "till",
                in: "query",
                required: false,
                description: "Order Date end from",
                schema: {
                    type: "string",
                },
            },
            gameId: {
                name: "gameId",
                in: "path",
                required: true,
                description: "Game Id",
                schema: {
                    type: "string",
                },
            },
            accountId: {
                name: "accountId",
                in: "path",
                description:
                    "Store or Employee Id. Store Id = customer.contact.addresses[0].id. Employee Id = customer.id",
                required: true,
                schema: {
                    type: "string",
                },
            },
            cronJobType: {
                name: "type",
                in: "header",
                required: true,
                description: "type of cron job",
                schema: {
                    type: "string",
                    enum: [
                        "orderprocess",
                        "orderacknowledge",
                        "orderdocument",
                        "deliverypointprocess",
                        "importcustomeroch",
                        "uploadtemplate",
                        "offlineorder",
                    ],
                },
            },
        },
    },
};
